import { Slider, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux"


export const VideoSegmentSilder = () => {
  const selectedID = useSelector(store => store.webgldata.selectedItemID);
  const projectJSON = useSelector(store => store.projectdata.projectJSON);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const isChangeSegment = useSelector(store => store.editdata.isChangeSegment);
  const startSegment = useSelector(store => store.editdata.startSegment);
  const endSegment = useSelector(store => store.editdata.endSegment);
  const [videoDuration, setVideoDuration] = useState(100)
  const [value, setValue] = useState([0, 100]);
  const [sliderMax, setSliderMax] = useState(100);
  useEffect(() => {
    if(videoElements[selectedID]){
      setSliderMax(videoElements[selectedID].duration * 1000)
      setValue([projectJSON.videos[selectedID].segment_start, projectJSON.videos[selectedID].segment_end])

      let tempTime = new Date(videoElements[selectedID].duration * 1000)
      setVideoDuration(tempTime.MMSSMM())
    }
   
  }, [selectedID])

  useEffect(() => {
    setValue([startSegment,endSegment]);
  }, [isChangeSegment,startSegment,endSegment])

    useEffect(() => {
    }, [projectJSON])
    function valuetext(value) {
      return `${value}°C`;
    }
    return (
        <Stack direction={'row'} 
        display={isChangeSegment? 'flex':'none'}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={1}
          sx={{background: 'linear-gradient(0deg, #00000061, #ffffffa1);'}}
         width={'100%'} zIndex={999} position={"absolute"} bottom={'10px'}>
          <Typography variant='body1'>00:00:000</Typography>
          <Slider
            getAriaLabel={() => 'Temperature range'}
            value={value}
            getAriaValueText={valuetext}
            max={sliderMax}
          />
          <Typography variant='body1'>{videoDuration}</Typography>
      </Stack>
    )
}