import { TOOLKEY, SUBTITLESTATEKEY, ELEMENTSTATEKEY, DRAWSTATEKEY, 
  MENUBARREF, CAPTUREDIALOG, LANGUAGESTRS, ISELEMENTDRAGING,
  ISCHANGSEGMENT, STARTSEGMENT, ENDSEGMENT, ISTIMELINESCROLL } from './../constants/editConst';

export const videoEditStore = (state = {}, action) => {
  switch (action.type) {
    case MENUBARREF: {
      return {
        ...state, menubarRef: action.payload
      }
    }

    case TOOLKEY: {
      return {
        ...state, selectedTool: action.payload
      }
    }

    case SUBTITLESTATEKEY: {
      return {
        ...state,
        SubtitlesState: action.payload
      };
    }

    case ELEMENTSTATEKEY: {
      return {
        ...state,
        ElementState: action.payload
      };
    }

    case DRAWSTATEKEY: {
      return {
        ...state,
        DrawState: action.payload
      };
    }

    case CAPTUREDIALOG: {
      return {
        ...state,
        captureDialog: {
          ...state.captureDialog,
          ...action.payload
        }
      }
    }

    case LANGUAGESTRS: {
      return {
        ...state,
        languageStrs: action.payload
      }
    }

    case ISELEMENTDRAGING: {
      return {
        ...state,
        isElementDraging: action.payload
      }
    }

    case ISCHANGSEGMENT: {
      return {
        ...state,
        isChangeSegment: action.payload
      }
    }

    case STARTSEGMENT: {
      return {
        ...state,
        startSegment: action.payload
      }
    }

    case ENDSEGMENT: {
      return {
        ...state,
        endSegment: action.payload
      }
    }

    case ISTIMELINESCROLL: {
      return {
        ...state,
        isTimelineScroll: action.payload
      }
    }

    default: {
      return { ...state };
    }
  }
};