import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Slider, MenuItem, FormControl, Select } from '@mui/material';

import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined'
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

import { SectionHeader } from './common/section.header';
import { DrawRemove, DrawLine } from '../../../Icons';
import { DrawState_Store } from './../../../store/actions/editAction';

export const TooltapDraw = (props) => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const dispatch = useDispatch();
  const DrawState = useSelector((store) => store.editdata.DrawState);
  const DrawSettingHandle = () => { dispatch(DrawState_Store(true)); }
  const DrawTemplateHandle = () => { dispatch(DrawState_Store(false)); }
  const HeaderInfo = { title: languageStrs.draw, className: 'pl-3' };
  if (!DrawState) {
    return (
      <>
        <SectionHeader {...HeaderInfo} />
        <Box md={12} className='px-10 pt-0'>
          <Typography className='v-block-footer mb-2'>{languageStrs.select_brush_stock_start_draw}</Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className='v-Draw-box v-hoverbackcolor1 v-center' onClick={DrawSettingHandle}>
                <DrawRemove />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box className='v-Draw-box v-hoverbackcolor1 v-center' onClick={DrawSettingHandle}>
                <DrawLine />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    )
  } else {
    return (
      <>
        <SectionHeader {...HeaderInfo} />
        <Box md={12} className='px-10 pt-0'>
          <Box className='v-block-header pb-3'>
            <Typography className='v-block-header-left'>{languageStrs.brush_stroke}</Typography>
          </Box>

          <FormControl fullWidth className='v-autocomplate'>
            <Select labelId="demo-simple-select-label"
              className='v-hoverborder v-radius v-commonboxheight'
              value={'Round'} label={languageStrs.brush_stroke}>
              <MenuItem value={'Round'} className='p-3 v-font-2-3'>{languageStrs.round}</MenuItem>
              <MenuItem value={'Sliced'} className='p-3 v-font-2-3'>{languageStrs.sliced}</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box className='px-10 pt-0 mt-3'>
          <Box className='v-block-header pb-3'>
            <Typography className='v-block-header-left'>{languageStrs.color}</Typography>
          </Box>

          <Grid container spacing={0} className='v-border v-radius p-3'>
            <Grid item xs={12} container spacing={0} className='v-border_b v-font-2-4'>
              <Grid item xs={3} className='v-commonboxheight v-left v-font-2-2'>Hex</Grid>
              <Grid item xs={7} className='v-commonboxheight v-right'>#EBF5FF</Grid>
              <Grid item xs={2} className='v-commonboxheight v-center'>
                <FormatColorFillIcon className='v-font-3-2' />
              </Grid>
            </Grid>

            <Grid item xs={12} container spacing={0} className='v-border_b v-font-2-4'>
              <Grid item xs={3} className='v-commonboxheight v-left v-font-2-2'>{languageStrs.opacity}</Grid>
              <Grid item xs={7} className='v-commonboxheight v-center'>
                <Slider value={30} aria-labelledby="input-slider" />
              </Grid>

              <Grid item xs={2} className='v-commonboxheight v-font-2-2 v-center'>100%</Grid>
            </Grid>

            <Grid item xs={12} container spacing={0} className='v-font-2-4'>
              <Grid item xs={3} className='v-commonboxheight v-left v-font-2-2'>{languageStrs.size}</Grid>
              <Grid item xs={7} className='v-commonboxheight v-center'>
                <Slider value={30} aria-labelledby="input-slider" />
              </Grid>
              <Grid item xs={2} className='v-commonboxheight v-font-2-2 v-center'>100px</Grid>
            </Grid>
          </Grid>
        </Box>

        <Box md={12} className='px-10 pt-0 mt-3'>
          <Box className='v-block-header pb-2'>
            <Typography className='v-block-header-left'>{languageStrs.duration}</Typography>
            <Typography className='v-block-header-right mr-3'>00:00.0</Typography>
          </Box>

          <Box className='v-border v-radius v-commonboxheight v-center'>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <AccessAlarmOutlinedIcon className='v-font-4' />
              </Grid>

              <Grid item className='v-font-2-3 v-center' xs={2}> Start</Grid>
              <Grid item className='v-font-2-3 v-center' xs={2}> 00:08.7</Grid>
              <Grid item className='v-font-2-3 v-center' xs={2}> End</Grid>
              <Grid item className='v-font-2-3 v-center' xs={2}> 00:04.3</Grid>

              <Grid item className='v-font-2-3 v-center' xs={2}>
                <AccessAlarmOutlinedIcon className='v-font-4' />
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className='v-addbutton v-center v-commonboxheight v-pointer v-font-2-3 mt-4'
          onClick={DrawTemplateHandle}>
          + {languageStrs.add_new_line}
        </Box>
      </>
    )
  }
}