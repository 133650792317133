import H_VideooTarget from './../../../../assets/image/home/VideooTarget.gif';

// ======================= transitions image import start ==========================================
import T_Dissolve from './../../../../assets/image/video-edit/transitions/Dissolve.png';
import T_FadetoBlack from './../../../../assets/image/video-edit/transitions/FadetoBlack.png';
import T_FadetoWhite from './../../../../assets/image/video-edit/transitions/FadetoWhite.png';
import T_ZoomIn from './../../../../assets/image/video-edit/transitions/ZoomIn.png';
import T_CrossBlur from './../../../../assets/image/video-edit/transitions/CrossBlur.png';
import T_PushLeft from './../../../../assets/image/video-edit/transitions/PushLeft.png';
import T_PushDown from './../../../../assets/image/video-edit/transitions/PushDown.png';
import T_Rotate from './../../../../assets/image/video-edit/transitions/Rotate.png';
import T_Circle from './../../../../assets/image/video-edit/transitions/Circle.png';
import T_CrossWarp from './../../../../assets/image/video-edit/transitions/CrossWarp.png';
// ======================= transitions image import end ==========================================

// ======================= Filters image import start ==========================================
// ------ color grading -------------------------------------------------------
import FC_Daphne from './../../../../assets/image/video-edit/Filter/ColorGrading/Daphne.png';
import FC_Penelope from './../../../../assets/image/video-edit/Filter/ColorGrading/Penelope.png';
import FC_Phoebe from './../../../../assets/image/video-edit/Filter/ColorGrading/Phoebe.png';
import FC_Thalia from './../../../../assets/image/video-edit/Filter/ColorGrading/Thalia.png';
import FC_Chloe from './../../../../assets/image/video-edit/Filter/ColorGrading/Chloe.png';
import FC_Hermione from './../../../../assets/image/video-edit/Filter/ColorGrading/Hermione.png';
import FC_Hera from './../../../../assets/image/video-edit/Filter/ColorGrading/Hera.png';
import FC_Rhea from './../../../../assets/image/video-edit/Filter/ColorGrading/Rhea.png';
import FC_Donna from './../../../../assets/image/video-edit/Filter/ColorGrading/Donna.png';
import FC_Martina from './../../../../assets/image/video-edit/Filter/ColorGrading/Martina.png';
import FC_Gabriella from './../../../../assets/image/video-edit/Filter/ColorGrading/Gabriella.png';
import FC_Elisabetta from './../../../../assets/image/video-edit/Filter/ColorGrading/Elisabetta.png';
import FC_BlackWhite from './../../../../assets/image/video-edit/Filter/ColorGrading/BlackWhite.png';
import FC_Luna from './../../../../assets/image/video-edit/Filter/ColorGrading/Luna.png';
import FC_Viola from './../../../../assets/image/video-edit/Filter/ColorGrading/Viola.png';
import FC_Zita from './../../../../assets/image/video-edit/Filter/ColorGrading/Zita.png';
import FC_Donata from './../../../../assets/image/video-edit/Filter/ColorGrading/Donata.png';
import FC_Maddalena from './../../../../assets/image/video-edit/Filter/ColorGrading/Maddalena.png';
import FC_Tecla from './../../../../assets/image/video-edit/Filter/ColorGrading/Tecla.png';
import FC_Narcisa from './../../../../assets/image/video-edit/Filter/ColorGrading/Narcisa.png';

// ----- Effects -------------------------------------------------
import FE_Bokeh from './../../../../assets/image/video-edit/Filter/Effects/Bokeh.png';
import FE_FilmBurn from './../../../../assets/image/video-edit/Filter/Effects/FilmBurn.png';
import FE_Super8 from './../../../../assets/image/video-edit/Filter/Effects/Super8.png';
import FE_VideooPortra from './../../../../assets/image/video-edit/Filter/Effects/VideooPortra.png';
import FE_GlitchParty from './../../../../assets/image/video-edit/Filter/Effects/GlitchParty.png';
import FE_Glitch from './../../../../assets/image/video-edit/Filter/Effects/Glitch.png';
import FE_FishEye from './../../../../assets/image/video-edit/Filter/Effects/FishEye.png';
import FE_LensFlare from './../../../../assets/image/video-edit/Filter/Effects/LensFlare.png';
import FE_LightLeaks from './../../../../assets/image/video-edit/Filter/Effects/LightLeaks.png';
import FE_Television from './../../../../assets/image/video-edit/Filter/Effects/Television.png';
import FE_VHSOverlay from './../../../../assets/image/video-edit/Filter/Effects/VHSOverlay.png';
import FE_CRT from './../../../../assets/image/video-edit/Filter/Effects/CRT.png';
import FE_VHS from './../../../../assets/image/video-edit/Filter/Effects/VHS.png';
import FE_Polaroid from './../../../../assets/image/video-edit/Filter/Effects/Polaroid.png';
import FE_Old from './../../../../assets/image/video-edit/Filter/Effects/Old.png';
import FE_Filmic from './../../../../assets/image/video-edit/Filter/Effects/Filmic.png';
import FE_DustyFilm from './../../../../assets/image/video-edit/Filter/Effects/DustyFilm.png';
import FE_Strobe from './../../../../assets/image/video-edit/Filter/Effects/Strobe.png';
import FE_NightVision from './../../../../assets/image/video-edit/Filter/Effects/NightVision.png';
import FE_ThermalVision from './../../../../assets/image/video-edit/Filter/Effects/ThermalVision.png';
import FE_DreamVision from './../../../../assets/image/video-edit/Filter/Effects/DreamVision.png';
import FE_Invert from './../../../../assets/image/video-edit/Filter/Effects/Invert.png';
import FE_Prisma from './../../../../assets/image/video-edit/Filter/Effects/Prisma.png';
import FE_Viewfinder from './../../../../assets/image/video-edit/Filter/Effects/Viewfinder.png';
import FE_RGBSplitDream from './../../../../assets/image/video-edit/Filter/Effects/RGBSplitDream.png';
import FE_RGBSplit from './../../../../assets/image/video-edit/Filter/Effects/RGBSplit.png';
import FE_DisplacementNightmare from './../../../../assets/image/video-edit/Filter/Effects/DisplacementNightmare.png';
import FE_GhostDream from './../../../../assets/image/video-edit/Filter/Effects/GhostDream.png';
import FE_Ghosting from './../../../../assets/image/video-edit/Filter/Effects/Ghosting.png';
import FE_Snowy from './../../../../assets/image/video-edit/Filter/Effects/Snowy.png';
import FE_ScanlinesParty from './../../../../assets/image/video-edit/Filter/Effects/ScanlinesParty.png';
import FE_VignetteSepia from './../../../../assets/image/video-edit/Filter/Effects/VignetteSepia.png';
import FE_Pixelate from './../../../../assets/image/video-edit/Filter/Effects/Pixelate.png';
import FE_PixelateandPosterise from './../../../../assets/image/video-edit/Filter/Effects/PixelateandPosterise.png';
import FE_PixelateandBlur from './../../../../assets/image/video-edit/Filter/Effects/PixelateandBlur.png';
import FE_Posterise from './../../../../assets/image/video-edit/Filter/Effects/Posterise.png';
import FE_BokehBlur from './../../../../assets/image/video-edit/Filter/Effects/BokehBlur.png';
import FE_BoxBlur from './../../../../assets/image/video-edit/Filter/Effects/BoxBlur.png';

// ======================= Filters image import end ==========================================


export const HVideooTarget = H_VideooTarget;
// ==================== transitions const define start ======================================
export const TDissolve = T_Dissolve;
export const TFadetoBlack = T_FadetoBlack;
export const TFadetoWhite = T_FadetoWhite;
export const TZoomIn = T_ZoomIn;
export const TCrossBlur = T_CrossBlur;
export const TPushLeft = T_PushLeft;
export const TPushDown = T_PushDown;
export const TRotate = T_Rotate;
export const TCircle = T_Circle;
export const TCrossWarp = T_CrossWarp;
// ==================== transitions const define end ======================================


// ==================== filters const define start ======================================
// ------ color grading -------------------------------------------------------
export const FDaphne = FC_Daphne;
export const FPenelope = FC_Penelope;
export const FPhoebe = FC_Phoebe;
export const FThalia = FC_Thalia;
export const FChloe = FC_Chloe;
export const FHermione = FC_Hermione;
export const FHera = FC_Hera;
export const FRhea = FC_Rhea;
export const FDonna = FC_Donna;
export const FMartina = FC_Martina;
export const FGabriella = FC_Gabriella;
export const FElisabetta = FC_Elisabetta;
export const FBlackWhite = FC_BlackWhite;
export const FLuna = FC_Luna;
export const FViola = FC_Viola;
export const FZita = FC_Zita;
export const FDonata = FC_Donata;
export const FMaddalena = FC_Maddalena;
export const FTecla = FC_Tecla;
export const FNarcisa = FC_Narcisa;

// ----- Effects -------------------------------------------------
export const FBokeh = FE_Bokeh;
export const FFilmBurn = FE_FilmBurn;
export const FSuper8 = FE_Super8;
export const FVideooPortra = FE_VideooPortra;
export const FGlitchParty = FE_GlitchParty;
export const FGlitch = FE_Glitch;
export const FFishEye = FE_FishEye;
export const FLensFlare = FE_LensFlare;
export const FLightLeaks = FE_LightLeaks;
export const FTelevision = FE_Television;
export const FVHSOverlay = FE_VHSOverlay;
export const FCRT = FE_CRT;
export const FVHS = FE_VHS;
export const FPolaroid = FE_Polaroid;
export const FOld = FE_Old;
export const FFilmic = FE_Filmic;
export const FDustyFilm = FE_DustyFilm;
export const FStrobe = FE_Strobe;
export const FNightVision = FE_NightVision;
export const FThermalVision = FE_ThermalVision;
export const FDreamVision = FE_DreamVision;
export const FInvert = FE_Invert;
export const FPrisma = FE_Prisma;
export const FViewfinder = FE_Viewfinder;
export const FRGBSplitDream = FE_RGBSplitDream;
export const FRGBSplit = FE_RGBSplit;
export const FDisplacementNightmare = FE_DisplacementNightmare;
export const FGhostDream = FE_GhostDream;
export const FGhosting = FE_Ghosting;
export const FSnowy = FE_Snowy;
export const FScanlinesParty = FE_ScanlinesParty;
export const FVignetteSepia = FE_VignetteSepia;
export const FPixelate = FE_Pixelate;
export const FPixelateandPosterise = FE_PixelateandPosterise;
export const FPixelateandBlur = FE_PixelateandBlur;
export const FPosterise = FE_Posterise;
export const FBokehBlur = FE_BokehBlur;
export const FBoxBlur = FE_BoxBlur;

// ==================== filters const define end ======================================

