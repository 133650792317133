import { timelineConfig } from '../timeline.config';
import { SaveVideoPlaying_Store, TimeCount_Store } from '../../../../store/actions/videoPlayAction';

// Timeline CommonFunction Start
export const IndicatorPlayPosition = async (timelineData, TimeCount, dispatch, isTimelineScroll) => {

  const cTime = TimeCount.getTime();
  const maxTime = timelineData.maxTime;
  let indicatorRef = timelineData.indicator;
  const legendInfo = timelineData.legendInfo;
  let timelineRef = timelineData.timeline;
  const StaticW = timelineRef.offsetWidth;
  const { SecondW } = await GetFieldWidth(legendInfo);
  let newLeft = SecondW * cTime / 1000;
  if(isTimelineScroll){
    indicatorRef.style.left = `${newLeft + 10}px`;
  }else{
    indicatorRef.style.left = `${newLeft + 10}px`;
  }
  
  
  
  if (SecondW * maxTime <= newLeft) {
    dispatch(SaveVideoPlaying_Store(false));
  }
  return;
}

export const ResetIsIndicatorScroll = (timelineData, isTimelineScroll) => {
  let timelineRef = timelineData.timeline;
  
  let indicatorRef = timelineData.indicator;
  if(timelineRef && indicatorRef){
    const StaticW = timelineRef.offsetWidth;
    if(isTimelineScroll) { 
      timelineRef.style.paddingLeft='0px';
      indicatorRef.style.marginLeft = '0px';
    }else{
      timelineRef.style.paddingLeft= (StaticW / 2)+'px';
      indicatorRef.style.marginLeft = (StaticW / 2)+'px';
    }
  }
}

export const IndicatorFocus = (timelineData, status, isTimelineScroll) => {
  let timeContainer = timelineData.container;
  let timelineRef = timelineData.timeline;
  let indicatorRef = timelineData.indicator;

  const ScrollTop = timeContainer.scrollTop;
  const StaticW = timelineRef.offsetWidth;
  const ScrollLeft = timelineRef.scrollLeft;
  const Left = indicatorRef.offsetLeft;
  const LeftWidth = Left - ScrollLeft;
  if(!isTimelineScroll){
    timelineRef.scrollLeft = Left - Math.floor(StaticW / 2) - 0.01;
  }else{
    if (LeftWidth > StaticW - 50 || LeftWidth < 0) timelineRef.scrollLeft = Left - 10;
  }
  /*setTimeout(() => {
    timeContainer.scrollTop = ScrollTop;
  }, 100);*/
  return;
}

export const fadeInOutVolumeValue = (duration, currentTime, originVolume, elementItem) => {
  const chinVal = (2.0/duration) * 100;
  const progressTime = ((currentTime / duration) * 100);
  let outPutVolume = originVolume;
  if(progressTime < 50) {
    if(elementItem?.fade_in_audio){
      
      let fadeInVal = progressTime / chinVal;
      if(fadeInVal > 1){
        fadeInVal = 1;
      }
      outPutVolume = originVolume * fadeInVal;
    }else{
      outPutVolume = originVolume;
    }
  }else{
    if(elementItem?.fade_out_audio){
      let fadeOutVal = (100 - progressTime) / chinVal;
      if(fadeOutVal > 1){
        fadeOutVal = 1;
      }
      outPutVolume = originVolume * fadeOutVal;
    }else{
      outPutVolume = originVolume;
    }
  }
  return outPutVolume;
}

export const zoomChangeIndication = async (TimeContext, TimeCount) => {
  const timeContainer = TimeContext.container;
  const timelineRef = TimeContext.timeline;
  const indicatorRef = TimeContext.indicator;
  const legendInfo = TimeContext.legendInfo;
  const ScrollLeft = timelineRef.scrollLeft;
  const ScrollTop = timeContainer.scrollTop;
  const offsetX = indicatorRef.offsetLeft;

  const { SecondW } = await GetFieldWidth(legendInfo);
  const cTime = TimeCount.getTime();
  const LeftWidth = SecondW * cTime / 1000;
  indicatorRef.style.left = `${LeftWidth + 10}px`;
  timelineRef.scrollLeft = LeftWidth + 10 + ScrollLeft - offsetX;
  /*setTimeout(() => {
    timeContainer.scrollTop = ScrollTop;
  }, 100);*/
  return;
}

/* indicate drag event start */
export const dragImgDenable = (e) => {
  const crt = e.target.cloneNode(true);
  if (!!e.dataTransfer) e.dataTransfer.setDragImage(crt, 0, 0);
}

export const onIndicateDrag = (e, leftW, offsetX, timelineData) => {
  let timelineRef = timelineData.timeline;
  let elementsRef = timelineData.elements;

  let LeftW = leftW + e.screenX - offsetX;
  if (LeftW <= 0 && timelineRef.scrollLeft > 0) {
    LeftW = 15;
    if (timelineRef.scrollLeft > 15) timelineRef.scrollLeft -= 15;
    else timelineRef.scrollLeft = 0;
  }

  if (LeftW + 2 >= timelineRef.offsetWidth) {
    LeftW = timelineRef.offsetWidth - 15;
    const rightScrollW = timelineRef.scrollWidth - (timelineRef.scrollLeft + timelineRef.offsetWidth);
    if (rightScrollW > 15) timelineRef.scrollLeft += 15;
    else timelineRef.scrollLeft = timelineRef.scrollWidth - timelineRef.offsetWidth;
  }

  const totalW = elementsRef.offsetWidth;
  const ScrollL = timelineRef.scrollLeft;
  let offset = ScrollL + LeftW;

  if (offset < 10) offset = 10;
  if (offset > totalW - 40) offset = totalW - 40;
  e.target.style.left = `${offset}px`;
}

export const cursorGrabbing = (e) => { e.target.style.cursor = 'grabbing'; }
export const cursorGrab = (e) => { e.target.style.cursor = 'grab'; }
/* indicate drag event end */
// Timeline CommonFunction End


// Zoom rate value Calculation Start
export const GetTimelineGridInfo = (zoomRate) => {
  let zoom, diffCount, sRuller, sChild;
  switch (zoomRate) {
    case 10: case 9: case 8: case 7:
    case 6: case 5: case 4: case 3:
      zoom = zoomRate;
      diffCount = 5;
      sRuller = 5;
      sChild = 5;
      break;

    case 2: case 1:
      zoom = zoomRate;
      diffCount = 5;
      sRuller = 5;
      sChild = 2;
      break;

    case 0: case -1:
      zoom = zoomRate;
      diffCount = 5;
      sRuller = 5;
      sChild = 1;
      break;

    case -2: case -3:
      zoom = zoomRate + 1;
      diffCount = 10;
      sRuller = 2;
      sChild = 1;
      break;

    case -4: case -5:
      zoom = zoomRate + 3;
      diffCount = 25;
      sRuller = 3;
      sChild = 1;
      break;

    case -6: case -7:
      zoom = zoomRate + 5;
      diffCount = 50;
      sRuller = 2;
      sChild = 1;
      break;

    case -8: case -9:
      zoom = zoomRate + 7;
      diffCount = 75;
      sRuller = 3;
      sChild = 1;
      break;

    case -10:
      zoom = zoomRate + 8;
      diffCount = 100;
      sRuller = 2;
      sChild = 1;
      break;

    default:
      zoom = 0;
      diffCount = 5;
      sRuller = 5;
      sChild = 1;
      break;
  }

  return { zoom: zoom, diffCount: diffCount, sRuller: sRuller, sChild: sChild };
}

export const checkIsType = (projectJson, type, groupID) => {
  let isType = false;
  let keys = Object.keys(projectJson[type])
  keys.map(kItem => {
    if(projectJson[type][kItem].groups == groupID){
      isType = true;
    }
  })
  return isType;
}

export const GetFieldWidth = (legendInfo) => {
  let fieldW = timelineConfig.Field;
  const lineW = timelineConfig.lineW;
  const startL = timelineConfig.startLeft;

  if (legendInfo.zoom > 0) fieldW *= 2 * Math.floor(legendInfo.zoom);
  if (legendInfo.zoom < 0) fieldW /= (-2 * Math.floor(legendInfo.zoom));
  fieldW *= 5 / legendInfo.sRuller;
  const SecondWidth = fieldW * legendInfo.sRuller / legendInfo.diffCount;

  return { fieldW: fieldW, SecondW: SecondWidth, lineW: lineW, startL: startL }
}
// Zoom rate value Calculation End

// Get Indicator Time Function Start
export const CalculatorTime = async (TimeContext, dispatch, Time_Count) => {
  const current_count = Time_Count.getTime();
  const indicatorRef = TimeContext.indicator;
  const legendInfo = TimeContext.legendInfo;
  const offsetX = indicatorRef.offsetLeft - 10;

  const { SecondW } = await GetFieldWidth(legendInfo);
  const cTime = new Date(offsetX / SecondW * 1000);
  if (current_count !== offsetX / SecondW * 1000) dispatch(TimeCount_Store(cTime));
}
// Get Indicator Time Function End

// Get Video & Text elements width function Start
export const GetElementsWidthLeft = async (ElOb, legendInfo) => {
  const { SecondW } = await GetFieldWidth(legendInfo);
  var tmpSpeed = 1;
  if(ElOb.speed){
    tmpSpeed = tmpSpeed;
  }
  const Width = SecondW * ElOb.duration / 1000;
  const Left = SecondW * ElOb.timeline_start / 1000;
  return [Width, Left];
}
// Get Video & Text elements width function End

// Get Elements drag changed width & left function Start
export const onDragElements = async (timelineData, e, dragType, offsetX, leftW, contentW, scrollX, groupObs, wrapperRef, projectJSON, selectedID) => {
  const legendInfo = timelineData.legendInfo;
  const maxTime = timelineData.maxTime;
  const ScrollL = timelineData.timeline.scrollLeft
  const { SecondW } = await GetFieldWidth(legendInfo);
  const maxW = SecondW * maxTime;

  let diffL = (dragType === 'left' ? ScrollL : scrollX) + leftW, diffW = contentW;
  let moveW = offsetX - e.screenX;

  if (dragType === 'left') diffL -= moveW;
  if (dragType === 'width-left') {
    if (-moveW >= contentW) moveW = -contentW;
    diffL -= moveW + (scrollX - ScrollL);
    diffW += (diffL >= 0 ? moveW : leftW) + (scrollX - ScrollL);
  }

  if (dragType === 'width-right') {
    diffW -= moveW - (ScrollL - scrollX);
    if (diffL + diffW >= maxW) diffW = maxW - diffL;
  }

  if (diffW < 0) diffW = 0;
  if (diffL < 0) diffL = 0;
  if (diffL + contentW >= maxW && dragType === 'left') diffL = maxW - contentW;

  let bEndW = 0;
  let nStartW = maxW;
  const groupKeys = Object.keys(groupObs);
  groupKeys.forEach((key) => {
    const ItemEndW = SecondW * groupObs[key].timeline_end / 1000;
    const ItemStartW = SecondW * groupObs[key].timeline_start / 1000;
    if (bEndW < ItemEndW && ItemEndW < diffL + diffW) bEndW = ItemEndW;
    if (nStartW > ItemStartW && ItemStartW > diffL) nStartW = ItemStartW;
  })

  if (dragType === 'width-left' && diffL < bEndW) {
    diffW += diffL - bEndW;
    diffL = bEndW;
  }

  if (dragType === 'width-right' && diffL + diffW > nStartW) {
    diffW += nStartW - (diffL + diffW);
    diffL = nStartW - diffW;
  }

  if (dragType === 'left') {
    let filterKeys = [];
    let target = wrapperRef;
    const ElLeft = target.offsetLeft;

    if (ElLeft > diffL) {
      filterKeys = groupKeys.filter((key) => {
        let ElData = Object.assign({}, groupObs[key]);
        const startW = SecondW * ElData.timeline_start / 1000;
        return startW < diffL + diffW;
      })
      filterKeys.sort((keyA, keyB) => { return groupObs[keyB].timeline_start - groupObs[keyA].timeline_start; })

    } else if (ElLeft < diffL) {
      filterKeys = groupKeys.filter((key) => {
        let ElData = Object.assign({}, groupObs[key]);
        const endW = SecondW * ElData.timeline_end / 1000;
        return endW > diffL;
      })
      filterKeys.sort((keyA, keyB) => { return groupObs[keyA].timeline_start - groupObs[keyB].timeline_start; })
    }

    filterKeys.forEach((key) => {
      let ElData = Object.assign({}, groupObs[key]);
      const startW = SecondW * ElData.timeline_start / 1000;
      const endW = SecondW * ElData.timeline_end / 1000;
      const diffEL = diffL + diffW;

      let clashState1 = startW < diffL && diffL < endW;
      let clashState2 = startW < diffEL && diffEL < endW;
      let clashState3 = diffL < startW && startW < diffEL;
      let clashState4 = diffL < endW && endW < diffEL;
      let clashState5 = diffL === startW && diffEL == endW;
      if (clashState1 || clashState2 || clashState3 || clashState4 || clashState5) {
        let bEndW = 0;
        let nStartW = maxW;
        groupKeys.forEach((key) => {
          const ItemEndW = SecondW * groupObs[key].timeline_end / 1000;
          const ItemStartW = SecondW * groupObs[key].timeline_start / 1000;
          if (bEndW < ItemEndW && ItemEndW < ElLeft + diffW) bEndW = ItemEndW;
          if (nStartW > ItemStartW && ItemStartW > ElLeft) nStartW = ItemStartW;
        })

        if (ElLeft > diffL) diffL = bEndW;
        else if (ElLeft < diffL) diffL = nStartW - diffW;
      }
    })
  }

  let minBig1 = 0;
  let minBig2 = 0;
  let maxSmall1 = maxW;
  let maxSmall2 = maxW;
  const tempJSON = JSON.parse(JSON.stringify(projectJSON));
  const allElements = { ...tempJSON.text, ...tempJSON.images, ...tempJSON.videos }
  const allElementKeys = Object.keys(allElements);

  let cStartW = diffL;
  let cEndW = diffL + diffW;
  allElementKeys.forEach((key) => {
    if (selectedID !== key) {
      const ItemEndW = SecondW * allElements[key].timeline_end / 1000;
      const ItemStartW = SecondW * allElements[key].timeline_start / 1000;

      if (cStartW > ItemEndW && minBig1 < ItemEndW) minBig1 = ItemEndW;
      if (cStartW > ItemStartW && minBig1 < ItemStartW) minBig1 = ItemStartW;
      if (cStartW < ItemEndW && maxSmall1 > ItemEndW) maxSmall1 = ItemEndW;
      if (cStartW < ItemStartW && maxSmall1 > ItemStartW) maxSmall1 = ItemStartW;

      if (cEndW > ItemEndW && minBig2 < ItemEndW) minBig2 = ItemEndW;
      if (cEndW > ItemStartW && minBig2 < ItemStartW) minBig2 = ItemStartW;

      if (cEndW < ItemEndW && maxSmall2 > ItemEndW) maxSmall2 = ItemEndW;
      if (cEndW < ItemStartW && maxSmall2 > ItemStartW) maxSmall2 = ItemStartW;
    }
  })

  let tempNextStart = minBig1;
  let tempNextEnd = maxSmall2;
  if (cEndW > maxSmall1 && cStartW - minBig1 > maxSmall1 - cStartW) tempNextStart = maxSmall1;
  if (cStartW < minBig2 && cEndW - minBig2 < maxSmall2 - cEndW) tempNextEnd = minBig2;

  let diffEnd = cEndW - tempNextEnd;
  let diffStart = cStartW - tempNextStart;

  if (dragType === 'width-left'){
    const offsetW = legendInfo.zoom > -2 ? Math.ceil(SecondW / ((legendInfo.zoom + 10) * 2)) : SecondW;
    if (tempNextStart !== 0 && Math.abs(diffStart) < offsetW) {
      diffW = cEndW - tempNextStart;
      diffL = tempNextStart;
    }
}
  if (dragType === 'width-right'){
    const offsetW = legendInfo.zoom > -2 ? Math.ceil(SecondW / ((legendInfo.zoom + 10) * 2)) : SecondW;
    if (tempNextEnd !== maxW && Math.abs(diffEnd) < offsetW){
      diffW = tempNextEnd - diffL;
    }
  }
    

  return { diffL: diffL, diffW: diffW };
}

export const PixeltoTime = async (legendInfo, pixel) => {
  const { SecondW } = await GetFieldWidth(legendInfo);
  return pixel / SecondW * 1000;
}

export const TimetoPixel = async (legendInfo, time) => {
  const { SecondW } = await GetFieldWidth(legendInfo);
  return SecondW * time / 1000;
}
// Get Elements drag changed width & left function End


// Touch Scroll Desable / Enable Start
export const scrollDesable = (timelineData) => {
  const wrapperEL = timelineData.timeline;
  if (wrapperEL.style.overflowX !== 'hidden')
  {  wrapperEL.style.overflowX = 'hidden';   }
  if (wrapperEL.style.overflowY !== 'hidden'){
    wrapperEL.style.overflowY = 'hidden';  
  }
}

export const scrollEnable = (timelineData) => {
  const wrapperEL = timelineData.timeline;
  if (wrapperEL.style.overflowX !== 'auto')
    wrapperEL.style.overflowX = 'auto';
}
// Touch Scroll Desable / Enable End

export const getTotalProjectTime = async (projectJSON) => {
  let totalTime = 0;
  if (!projectJSON.videos || !projectJSON.text) return false;
  const totalItems = { ...projectJSON.videos, ...projectJSON.text, ...projectJSON.images, ...projectJSON.audios }
  const keys = Object.keys(totalItems);

  keys.forEach((key) => {
    const item = totalItems[key];
    if (totalTime < item.timeline_end) totalTime = item.timeline_end;
  })

  return totalTime;
}