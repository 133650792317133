import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Slider } from '@mui/material';

import { useWebGL } from '../videoEdit.provider';
import { transitionsList } from './tooltap.config';
import { SectionHeader } from './common/section.header';
import { dragImgDenable } from '../timeline/common/commonFunction';
import { ProjectJSON_Store } from '../../../store/actions/projectdata';

let previousValue = -1;
export const transitionMarks = [
  { value: 1000, label: '' }, { value: 2000, label: '' },
  { value: 3000, label: '' }, { value: 4000, label: '' },
];

const associatedTexutreTypes = {
  "videos": "Video",
  "images": "Image",
  "text": "Text"
}
export const TooltapTransitions = () => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const dispatch = useDispatch();
  const [webGLContext] = useWebGL();
  const projectJSON = useSelector((store => store.projectdata.projectJSON));
  
  const HeaderInfo = { title: languageStrs.transitions, className: 'pl-3' };
  const tsManual = webGLContext.tsManual;
  const [preset, setPreset] = useState('');
  const [duration, setDuration] = useState(0);
  useEffect(() => {
    let activeTs = { preset: '', duration: 0 };
    if (!!tsManual.tsID && !!projectJSON[tsManual.type]) {
      let tempOptions = Object.assign({}, projectJSON[tsManual.type]);
      let itemOb = tempOptions[tsManual.tsID];
      let ts = itemOb?.transition;
      if (!!ts && tsManual.flag === 'left') activeTs = ts.start;
      else if (!!ts && tsManual.flag === 'right') {
        if (!!tsManual.clashID && !!tempOptions[tsManual.clashID]) {
          let clashOb = tempOptions[tsManual.clashID];
          let clashPreset = clashOb.transition.start.preset;
          if (ts.end.preset === clashPreset) activeTs = ts.end;
        } else activeTs = ts.end;
      }else if(!!ts && tsManual.flag === 'neighbour'){
        if (!!tsManual.neighbourId && !!tempOptions[tsManual.neighbourId]){
          if(projectJSON[tsManual.type][tsManual.neighbourId].timeline_start.toFixed(2) == itemOb.timeline_end.toFixed(2)){
            let clashOb = tempOptions[tsManual.neighbourId];
            let clashPreset = clashOb.transition.start.preset;
            if (ts.end.preset === clashPreset) activeTs = ts.end;
          }else{
            activeTs = {};
            
           // dispatch(ProjectJSON_Store({ ...projectJSON, [activeType]: groupOptions }));
          }
        }else activeTs = ts.end;
      }
    }
    setPreset(activeTs.preset);
    setDuration(activeTs.duration)
  }, [tsManual, projectJSON])

  const transitionChange = (e) => {
    let value = e.target.value;
    let types = ['text', 'videos', 'images'];
    let checkType = types.indexOf(tsManual.type);
    let values = [0, 1000, 2000, 3000, 4000, 5000];


    if (values.indexOf(value) === -1) return;
    if (!preset || previousValue === value) return;
    if (!tsManual.tsID || checkType === -1) return;

    let activeType = types[checkType];
    let tempJSON = Object.assign({}, projectJSON);
    let ItemOb = tempJSON[activeType][tsManual.tsID];

    if (tsManual.flag === 'right') ItemOb.transition.end.duration = value;
    if (tsManual.flag === 'left') ItemOb.transition.start.duration = value;
    if (tsManual.flag === 'right' && tsManual.clashID) {
      let clashOb = tempJSON[activeType][tsManual.clashID];
      clashOb.transition.start.duration = value;
    }
    if(tsManual.flag === "neighbour"){
      ItemOb.transition.end.duration = value;
      let neighbourOb = tempJSON[activeType][tsManual.neighbourId];
      neighbourOb.transition.start.duration = value;
    }

    previousValue = value;
    setDuration(value)
    dispatch(ProjectJSON_Store(tempJSON));
  }

  return (
    <>
      <SectionHeader {...HeaderInfo} />

      <Box className='px-10 pt-0'>
        <Grid container spacing={1}>
          {transitionsList.map((transition, key) =>
            <TransitionItem key={key} preset={preset}
              transition={transition}
            />
          )}
        </Grid>
      </Box>

      {!!preset && (
        <Box className='p-2 pt-3'>
          <Typography component='span'>{languageStrs.transition_duration} </Typography>
          <Box className='pl-5 pr-5'>
            <Slider aria-label="Volume" step={1000}
              value={duration}
              min={0} max={5000}
              marks={transitionMarks}
              onChange={transitionChange}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

const TransitionItem = ({ transition, preset }) => {
  const dispatch = useDispatch();
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const [moving, setMoving] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [webGLContext, setWebGLContext] = useWebGL();
  const projectJSON = useSelector((store => store.projectdata.projectJSON));

  const onMouseOver = (e) => {
    if (transition.type === 'none') return;
    setPlaying(true);
  }

  const onMouseLeave = (e) => {
    if (transition.type === 'none') return;
    setPlaying(false);
  }

  // const [width, setWidth] = useState(-1);
  // const [offset, setOffset] = useState({ l: -1, t: -1 });
  // const [screen, setScreen] = useState({ x: -1, y: -1 });
  const onDrag = (e) => {
    if (transition.type === 'comingsoon') return;
    // let target = e.currentTarget;
    // let x = offset.l + e.screenX - screen.x;
    // let y = offset.t + e.screenY - screen.y;

    // target.style.top = y + 'px';
    // target.style.left = x + 'px';
    // target.style.width = width + 'px';
    // target.style.zIndex = '100';
    // target.style.position = 'fixed';
    return true;
  }

  const onDragStart = (e) => {
    if (transition.type === 'comingsoon') dragImgDenable(e);
    if (transition.type === 'comingsoon') return;
    // let x = e.screenX;
    // let y = e.screenY;
    // let target = e.currentTarget;
    // let T = target.getBoundingClientRect().top;
    // let L = target.getBoundingClientRect().left;

    // setWidth(target.offsetWidth);
    // setScreen({ x: x, y: y });
    // setOffset({ l: L, t: T });
    // dragImgDenable(e);
    setTransitionState(transition.type, true);
    setMoving(true);
  }

  const onDragEnd = (e) => {
    if (transition.type === '---') return;
    // let target = e.currentTarget;
    // target.style.top = '0px';
    // target.style.left = '0px';
    // target.style.width = '100%';
    // target.style.position = 'relative';
    // target.style.zIndex = 'auto';
    // setScreen({ x: -1, y: -1 });
    // setOffset({ l: -1, t: -1 });
    setTransitionState('', false);
    setMoving(false);
    onMouseLeave();
  }

  const setTransitionState = (type, flag) => {
    setWebGLContext({
      ...webGLContext,
      transition: { type: type, moving: flag }
    });
  }

  const tsManual = webGLContext.tsManual;
  
  const onClick = () => {
    if (transition.type === '---') return;
    if (!tsManual.tsID) return;
    let types = ['text', 'videos', 'images'];
    let activeType = types.find((text) => text === tsManual.type);
    if (!activeType) return;

    let groupOptions = JSON.parse(JSON.stringify(projectJSON[activeType]));
    let ItemOb = groupOptions[tsManual.tsID];

    let duration = transition.type === 'none' ? 0 : 2000;
    let resultType = transition.type === 'none' ? '-' : transition.type;
    let result = { duration: duration, preset: resultType };
    if (tsManual.flag === 'right') {
        ItemOb.transition.end = {
        ...result,
        associated_texture: '-',
        associated_texture_type: '-'
      };
    }
    if (tsManual.flag === 'left') {
      ItemOb.transition.start = {
        ...result,
        associated_texture: '-',
        associated_texture_type: '-'
      };
    }
    if(tsManual.flag === "neighbour") {
      if(result.preset == '-'){
        ItemOb.transition.end = {
          ...result,
          associated_texture: '-',
          associated_texture_type: '-'
        }
        groupOptions[tsManual.neighbourId].transition.start = {
          ...result,
          associated_texture: '-',
          associated_texture_type: '-'
        }
      }else{
        ItemOb.transition.end = {
          ...result,
          associated_texture: tsManual.neighbourId? tsManual.neighbourId : '-',
          associated_texture_type: associatedTexutreTypes[activeType]? associatedTexutreTypes[activeType] : '-'
        }
        groupOptions[tsManual.neighbourId].transition.start = {
          ...result,
          associated_texture: tsManual.tsID? tsManual.tsID : '-',
          associated_texture_type: associatedTexutreTypes[activeType]? associatedTexutreTypes[activeType] : '-'
        }
      }
      
    }
    
    if (tsManual.flag === 'right' && tsManual.clashID) {
      let clashOb = groupOptions[tsManual.clashID];
      clashOb.transition.start = {
        ...result,
        associated_texture: tsManual.neighbourId? tsManual.neighbourId : '-',
        associated_texture_type: associatedTexutreTypes[activeType]? associatedTexutreTypes[activeType] : '-'
      };
    }

    dispatch(ProjectJSON_Store({ ...projectJSON, [activeType]: groupOptions }));
  }
  const print_title = (titleKey) =>{
    return languageStrs[titleKey];
  }
  return (
    <Grid item xs={6} md={4} lg={6}>
      <Box className={`v-transitionsCard ${(preset === transition.type || (transition.type === 'none' && preset === '-')) && ('active-preset')}`}
          onDrag={onDrag} onDragStart={onDragStart} onDragEnd={onDragEnd}
          onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}
          onClick={onClick}
      >
        <Box className='v-transitionscard-img v-radius' draggable="true"
          style={{ backgroundImage: `${transition.type === "none" ? "unset" : `url(${transition.img})`}`, cursor: `${transition.type === "comingsoon" ? "not-allowed " : "pointer"}` }}
        >
          <img src={transition.img} style={{visibility: "hidden"}} alt="hidden" />
          <Box 
           position='absolute' 
           top={0} 
           left={0} 
           right={0} 
           bottom={0} 
           display="flex" 
           justifyContent="center" 
           flexDirection="column"
           alignItems="center"
           >
            {transition.type !== "none" && transition.type !== "comingsoon" && 
              <img 
              className='v-radius' 
              src={transition.preview} 
              alt="transition preview"
              style={{ 
                display: !playing ? 'none' : 'block',
                height: "100%",
                width: "100%"
              }}
              />
            }
            {transition.type === "none" && transition.icon}
            {transition.type === "comingsoon" && <Box className='v-transition-comingsoon'><p>{languageStrs.coming_soon}</p></Box>}
          </Box>
          {/* {transition.type !== 'none' ? <video type="video/mp4" ref={videoRef}
            src={transition.video} className='v-radius'
            style={{ display: !playing ? 'none' : 'flex' }}
            loop muted
          /> : transition.icon} */}
        </Box>
        {!moving && <Typography className='v-TransitionsVideotitle pl-1' 
          style={{ cursor: `${transition.type === "comingsoon" ? "not-allowed " : "pointer"}` }}
          >
          {print_title(transition.langKey)}
        </Typography>}
      </Box>
    </Grid>
  )
}