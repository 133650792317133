import { NoneIcon } from '../../../Icons';
import { TFadetoBlack } from './common/ImageList';
import fadeBlackPreview from '../../../assets/image/video-edit/transitions/preview/fadecolor-black.gif';

import FadeToBlackPreview from '../../../assets/image/video-edit/transitions/preview/FadeToBlack.gif';
import FadeToWhitePreview from '../../../assets/image/video-edit/transitions/preview/FadeToWhite.gif';
import BlurPreview from '../../../assets/image/video-edit/transitions/preview/Blur.gif';
import FadePreview from '../../../assets/image/video-edit/transitions/preview/Fade.gif';
import MosaicPreview from '../../../assets/image/video-edit/transitions/preview/Mosaic.gif';
import PushPreview from '../../../assets/image/video-edit/transitions/preview/Push.gif';
import SlidePreview from '../../../assets/image/video-edit/transitions/preview/Slide.gif';
import WipePreview from '../../../assets/image/video-edit/transitions/preview/Wipe.gif';
import ZoomInPreview from '../../../assets/image/video-edit/transitions/preview/ZoomIn.gif';
import ZoomOutPreview from '../../../assets/image/video-edit/transitions/preview/ZoomIn.gif';


import { FDaphne, FPenelope, FPhoebe, FThalia, FChloe, FHermione, FHera, FRhea, FDonna, FMartina } from './common/ImageList';
import { FGabriella, FElisabetta, FBlackWhite, FLuna, FViola, FZita, FDonata, FMaddalena, FTecla, FNarcisa } from './common/ImageList';

export const showingCount = 52;
export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;
export const EmojiCommonUrl = BASE_STATIC_STORAGE_URL + '/elements/';

export const EmojiNames = {
  Smileys: [
    { url: '1F600.svg' },
    { url: '1F601.svg' },
    { url: '1F602.svg' },
    { url: '1F603.svg' },
    { url: '1F604.svg' },
    { url: '1F605.svg' },
    { url: '1F606.svg' },
    { url: '1F607.svg' },
    { url: '1F608.svg' },
    { url: '1F609.svg' },
    { url: '1F60A.svg' },
    { url: '1F60B.svg' },
    { url: '1F60C.svg' },
    { url: '1F60D.svg' },
    { url: '1F60E.svg' },
    { url: '1F60F.svg' },
    { url: '1F610.svg' },
    { url: '1F611.svg' },
    { url: '1F612.svg' },
    { url: '1F613.svg' },
    { url: '1F614.svg' },
    { url: '1F615.svg' },
    { url: '1F616.svg' },
    { url: '1F617.svg' },
    { url: '1F618.svg' },
    { url: '1F619.svg' },
    { url: '1F61A.svg' },
    { url: '1F61B.svg' },
    { url: '1F61C.svg' },
    { url: '1F61D.svg' },
    { url: '1F61E.svg' },
    { url: '1F61F.svg' },
    { url: '1F620.svg' },
    { url: '1F621.svg' },
    { url: '1F622.svg' },
    { url: '1F623.svg' },
    { url: '1F624.svg' },
    { url: '1F625.svg' },
    { url: '1F626.svg' },
    { url: '1F627.svg' },
    { url: '1F628.svg' },
    { url: '1F629.svg' },
    { url: '1F62A.svg' },
    { url: '1F62B.svg' },
    { url: '1F62C.svg' },
    { url: '1F62D.svg' },
    { url: '1F62E-200D-1F4A8.svg' },
    { url: '1F62E.svg' },
    { url: '1F62F.svg' },
    { url: '1F630.svg' },
    { url: '1F631.svg' },
    { url: '1F632.svg' },
    { url: '1F633.svg' },
    { url: '1F634.svg' },
    { url: '1F635-200D-1F4AB.svg' },
    { url: '1F635.svg' },
    { url: '1F636-200D-1F32B-FE0F.svg' },
    { url: '1F636.svg' },
    { url: '1F637.svg' },
    { url: '1F638.svg' },
    { url: '1F639.svg' },
    { url: '1F63A.svg' },
    { url: '1F63B.svg' },
    { url: '1F63C.svg' },
    { url: '1F63D.svg' },
    { url: '1F63E.svg' },
    { url: '1F63F.svg' },
    { url: '1F640.svg' },
    { url: '1F641.svg' },
    { url: '1F642.svg' },
    { url: '1F643.svg' },
    { url: '1F644.svg' },
    { url: '1F645-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F645-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F645-1F3FB.svg' },
    { url: '1F645-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F645-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F645-1F3FC.svg' },
    { url: '1F645-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F645-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F645-1F3FD.svg' },
    { url: '1F645-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F645-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F645-1F3FE.svg' },
    { url: '1F645-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F645-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F645-1F3FF.svg' },
    { url: '1F645-200D-2640-FE0F.svg' },
    { url: '1F645-200D-2642-FE0F.svg' },
    { url: '1F645.svg' },
    { url: '1F646-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F646-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F646-1F3FB.svg' },
    { url: '1F646-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F646-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F646-1F3FC.svg' },
    { url: '1F646-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F646-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F646-1F3FD.svg' },
    { url: '1F646-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F646-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F646-1F3FE.svg' },
    { url: '1F646-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F646-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F646-1F3FF.svg' },
    { url: '1F646-200D-2640-FE0F.svg' },
    { url: '1F646-200D-2642-FE0F.svg' },
    { url: '1F646.svg' },
    { url: '1F647-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F647-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F647-1F3FB.svg' },
    { url: '1F647-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F647-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F647-1F3FC.svg' },
    { url: '1F647-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F647-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F647-1F3FD.svg' },
    { url: '1F647-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F647-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F647-1F3FE.svg' },
    { url: '1F647-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F647-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F647-1F3FF.svg' },
    { url: '1F647-200D-2640-FE0F.svg' },
    { url: '1F647-200D-2642-FE0F.svg' },
    { url: '1F647.svg' },
    { url: '1F648.svg' },
    { url: '1F649.svg' },
    { url: '1F64A.svg' },
    { url: '1F64B-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F64B-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F64B-1F3FB.svg' },
    { url: '1F64B-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F64B-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F64B-1F3FC.svg' },
    { url: '1F64B-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F64B-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F64B-1F3FD.svg' },
    { url: '1F64B-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F64B-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F64B-1F3FE.svg' },
    { url: '1F64B-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F64B-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F64B-1F3FF.svg' },
    { url: '1F64B-200D-2640-FE0F.svg' },
    { url: '1F64B-200D-2642-FE0F.svg' },
    { url: '1F64B.svg' },
    { url: '1F64C-1F3FB.svg' },
    { url: '1F64C-1F3FC.svg' },
    { url: '1F64C-1F3FD.svg' },
    { url: '1F64C-1F3FE.svg' },
    { url: '1F64C-1F3FF.svg' },
    { url: '1F64C.svg' },
    { url: '1F64D-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F64D-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F64D-1F3FB.svg' },
    { url: '1F64D-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F64D-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F64D-1F3FC.svg' },
    { url: '1F64D-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F64D-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F64D-1F3FD.svg' },
    { url: '1F64D-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F64D-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F64D-1F3FE.svg' },
    { url: '1F64D-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F64D-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F64D-1F3FF.svg' },
    { url: '1F64D-200D-2640-FE0F.svg' },
    { url: '1F64D-200D-2642-FE0F.svg' },
    { url: '1F64D.svg' },
    { url: '1F64E-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F64E-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F64E-1F3FB.svg' },
    { url: '1F64E-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F64E-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F64E-1F3FC.svg' },
    { url: '1F64E-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F64E-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F64E-1F3FD.svg' },
    { url: '1F64E-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F64E-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F64E-1F3FE.svg' },
    { url: '1F64E-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F64E-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F64E-1F3FF.svg' },
    { url: '1F64E-200D-2640-FE0F.svg' },
    { url: '1F64E-200D-2642-FE0F.svg' },
    { url: '1F64E.svg' },
    { url: '1F64F-1F3FB.svg' },
    { url: '1F64F-1F3FC.svg' },
    { url: '1F64F-1F3FD.svg' },
    { url: '1F64F-1F3FE.svg' },
    { url: '1F64F-1F3FF.svg' },
    { url: '1F64F.svg' },
    { url: '1F680.svg' },
    { url: '1F681.svg' },
    { url: '1F682.svg' },
    { url: '1F683.svg' },
    { url: '1F684.svg' },
    { url: '1F685.svg' },
    { url: '1F686.svg' },
    { url: '1F687.svg' },
    { url: '1F688.svg' },
    { url: '1F689.svg' },
    { url: '1F68A.svg' },
    { url: '1F68B.svg' },
    { url: '1F68C.svg' },
    { url: '1F68D.svg' },
    { url: '1F68E.svg' },
    { url: '1F68F.svg' },
    { url: '1F690.svg' },
    { url: '1F691.svg' },
    { url: '1F692.svg' },
    { url: '1F693.svg' },
    { url: '1F694.svg' },
    { url: '1F695.svg' },
    { url: '1F696.svg' },
    { url: '1F697.svg' },
    { url: '1F698.svg' },
    { url: '1F699.svg' },
    { url: '1F69A.svg' },
    { url: '1F69B.svg' },
    { url: '1F69C.svg' },
    { url: '1F69D.svg' },
    { url: '1F69E.svg' },
    { url: '1F69F.svg' },
    { url: '1F6A0.svg' },
    { url: '1F6A1.svg' },
    { url: '1F6A2.svg' },
    { url: '1F6A3-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F6A3-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F6A3-1F3FB.svg' },
    { url: '1F6A3-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F6A3-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F6A3-1F3FC.svg' },
    { url: '1F6A3-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F6A3-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F6A3-1F3FD.svg' },
    { url: '1F6A3-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F6A3-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F6A3-1F3FE.svg' },
    { url: '1F6A3-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F6A3-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F6A3-1F3FF.svg' },
    { url: '1F6A3-200D-2640-FE0F.svg' },
    { url: '1F6A3-200D-2642-FE0F.svg' },
    { url: '1F6A3.svg' },
    { url: '1F6A4.svg' },
    { url: '1F6A5.svg' },
    { url: '1F6A6.svg' },
    { url: '1F6A7.svg' },
    { url: '1F6A8.svg' },
    { url: '1F6A9.svg' },
    { url: '1F6AA.svg' },
    { url: '1F6AB.svg' },
    { url: '1F6AC.svg' },
    { url: '1F6AD.svg' },
    { url: '1F6AE.svg' },
    { url: '1F6AF.svg' },
    { url: '1F6B0.svg' },
    { url: '1F6B1.svg' },
    { url: '1F6B2.svg' },
    { url: '1F6B3.svg' },
    { url: '1F6B4-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F6B4-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F6B4-1F3FB.svg' },
    { url: '1F6B4-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F6B4-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F6B4-1F3FC.svg' },
    { url: '1F6B4-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F6B4-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F6B4-1F3FD.svg' },
    { url: '1F6B4-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F6B4-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F6B4-1F3FE.svg' },
    { url: '1F6B4-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F6B4-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F6B4-1F3FF.svg' },
    { url: '1F6B4-200D-2640-FE0F.svg' },
    { url: '1F6B4-200D-2642-FE0F.svg' },
    { url: '1F6B4.svg' },
    { url: '1F6B5-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F6B5-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F6B5-1F3FB.svg' },
    { url: '1F6B5-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F6B5-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F6B5-1F3FC.svg' },
    { url: '1F6B5-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F6B5-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F6B5-1F3FD.svg' },
    { url: '1F6B5-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F6B5-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F6B5-1F3FE.svg' },
    { url: '1F6B5-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F6B5-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F6B5-1F3FF.svg' },
    { url: '1F6B5-200D-2640-FE0F.svg' },
    { url: '1F6B5-200D-2642-FE0F.svg' },
    { url: '1F6B5.svg' },
    { url: '1F6B6-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F6B6-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F6B6-1F3FB.svg' },
    { url: '1F6B6-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F6B6-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F6B6-1F3FC.svg' },
    { url: '1F6B6-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F6B6-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F6B6-1F3FD.svg' },
    { url: '1F6B6-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F6B6-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F6B6-1F3FE.svg' },
    { url: '1F6B6-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F6B6-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F6B6-1F3FF.svg' },
    { url: '1F6B6-200D-2640-FE0F.svg' },
    { url: '1F6B6-200D-2642-FE0F.svg' },
    { url: '1F6B6.svg' },
    { url: '1F6B7.svg' },
    { url: '1F6B8.svg' },
    { url: '1F6B9.svg' },
    { url: '1F6BA.svg' },
    { url: '1F6BB.svg' },
    { url: '1F6BC.svg' },
    { url: '1F6BD.svg' },
    { url: '1F6BE.svg' },
    { url: '1F6BF.svg' },
    { url: '1F6C0-1F3FB.svg' },
    { url: '1F6C0-1F3FC.svg' },
    { url: '1F6C0-1F3FD.svg' },
    { url: '1F6C0-1F3FE.svg' },
    { url: '1F6C0-1F3FF.svg' },
    { url: '1F6C0.svg' },
    { url: '1F6C1.svg' },
    { url: '1F6C2.svg' },
    { url: '1F6C3.svg' },
    { url: '1F6C4.svg' },
    { url: '1F6C5.svg' },
    { url: '1F6CB.svg' },
    { url: '1F6CC-1F3FB.svg' },
    { url: '1F6CC-1F3FC.svg' },
    { url: '1F6CC-1F3FD.svg' },
    { url: '1F6CC-1F3FE.svg' },
    { url: '1F6CC-1F3FF.svg' },
    { url: '1F6CC.svg' },
    { url: '1F6CD.svg' },
    { url: '1F6CE.svg' },
    { url: '1F6CF.svg' },
    { url: '1F6D0.svg' },
    { url: '1F6D1.svg' },
    { url: '1F6D2.svg' },
    { url: '1F6D5.svg' },
    { url: '1F6D6.svg' },
    { url: '1F6D7.svg' },
    { url: '1F6DD.svg' },
    { url: '1F6DE.svg' },
    { url: '1F6DF.svg' },
    { url: '1F6E0.svg' },
    { url: '1F6E1.svg' },
    { url: '1F6E2.svg' },
    { url: '1F6E3.svg' },
    { url: '1F6E4.svg' },
    { url: '1F6E5.svg' },
    { url: '1F6E9.svg' },
    { url: '1F6EB.svg' },
    { url: '1F6EC.svg' },
    { url: '1F6F0.svg' },
    { url: '1F6F3.svg' },
    { url: '1F6F4.svg' },
    { url: '1F6F5.svg' },
    { url: '1F6F6.svg' },
    { url: '1F6F7.svg' },
    { url: '1F6F8.svg' },
    { url: '1F6F9.svg' },
    { url: '1F6FA.svg' },
    { url: '1F6FB.svg' },
    { url: '1F6FC.svg' },
    { url: '1F7E0.svg' },
    { url: '1F7E1.svg' },
    { url: '1F7E2.svg' },
    { url: '1F7E3.svg' },
    { url: '1F7E4.svg' },
    { url: '1F7E5.svg' },
    { url: '1F7E6.svg' },
    { url: '1F7E7.svg' },
    { url: '1F7E8.svg' },
    { url: '1F7E9.svg' },
    { url: '1F7EA.svg' },
    { url: '1F7EB.svg' },
    { url: '1F7F0.svg' },
    { url: '1F90C-1F3FB.svg' },
    { url: '1F90C-1F3FC.svg' },
    { url: '1F90C-1F3FD.svg' },
    { url: '1F90C-1F3FE.svg' },
    { url: '1F90C-1F3FF.svg' },
    { url: '1F90C.svg' },
    { url: '1F90D.svg' },
    { url: '1F90E.svg' },
    { url: '1F90F-1F3FB.svg' },
    { url: '1F90F-1F3FC.svg' },
    { url: '1F90F-1F3FD.svg' },
    { url: '1F90F-1F3FE.svg' },
    { url: '1F90F-1F3FF.svg' },
    { url: '1F90F.svg' },
    { url: '1F910.svg' },
    { url: '1F911.svg' },
    { url: '1F912.svg' },
    { url: '1F913.svg' },
    { url: '1F914.svg' },
    { url: '1F915.svg' },
    { url: '1F916.svg' },
    { url: '1F917.svg' },
    { url: '1F918-1F3FB.svg' },
    { url: '1F918-1F3FC.svg' },
    { url: '1F918-1F3FD.svg' },
    { url: '1F918-1F3FE.svg' },
    { url: '1F918-1F3FF.svg' },
    { url: '1F918.svg' },
    { url: '1F919-1F3FB.svg' },
    { url: '1F919-1F3FC.svg' },
    { url: '1F919-1F3FD.svg' },
    { url: '1F919-1F3FE.svg' },
    { url: '1F919-1F3FF.svg' },
    { url: '1F919.svg' },
    { url: '1F91A-1F3FB.svg' },
    { url: '1F91A-1F3FC.svg' },
    { url: '1F91A-1F3FD.svg' },
    { url: '1F91A-1F3FE.svg' },
    { url: '1F91A-1F3FF.svg' },
    { url: '1F91A.svg' },
    { url: '1F91B-1F3FB.svg' },
    { url: '1F91B-1F3FC.svg' },
    { url: '1F91B-1F3FD.svg' },
    { url: '1F91B-1F3FE.svg' },
    { url: '1F91B-1F3FF.svg' },
    { url: '1F91B.svg' },
    { url: '1F91C-1F3FB.svg' },
    { url: '1F91C-1F3FC.svg' },
    { url: '1F91C-1F3FD.svg' },
    { url: '1F91C-1F3FE.svg' },
    { url: '1F91C-1F3FF.svg' },
    { url: '1F91C.svg' },
    { url: '1F91D-1F3FB.svg' },
    { url: '1F91D-1F3FC.svg' },
    { url: '1F91D-1F3FD.svg' },
    { url: '1F91D-1F3FE.svg' },
    { url: '1F91D-1F3FF.svg' },
    { url: '1F91D.svg' },
    { url: '1F91E-1F3FB.svg' },
    { url: '1F91E-1F3FC.svg' },
    { url: '1F91E-1F3FD.svg' },
    { url: '1F91E-1F3FE.svg' },
    { url: '1F91E-1F3FF.svg' },
    { url: '1F91E.svg' },
    { url: '1F91F-1F3FB.svg' },
    { url: '1F91F-1F3FC.svg' },
    { url: '1F91F-1F3FD.svg' },
    { url: '1F91F-1F3FE.svg' },
    { url: '1F91F-1F3FF.svg' },
    { url: '1F91F.svg' },
    { url: '1F920.svg' },
    { url: '1F921.svg' },
    { url: '1F922.svg' },
    { url: '1F923.svg' },
    { url: '1F924.svg' },
    { url: '1F925.svg' },
    { url: '1F926-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F926-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F926-1F3FB.svg' },
    { url: '1F926-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F926-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F926-1F3FC.svg' },
    { url: '1F926-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F926-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F926-1F3FD.svg' },
    { url: '1F926-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F926-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F926-1F3FE.svg' },
    { url: '1F926-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F926-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F926-1F3FF.svg' },
    { url: '1F926-200D-2640-FE0F.svg' },
    { url: '1F926-200D-2642-FE0F.svg' },
    { url: '1F926.svg' },
    { url: '1F927.svg' },
    { url: '1F928.svg' },
    { url: '1F929.svg' },
    { url: '1F92A.svg' },
    { url: '1F92B.svg' },
    { url: '1F92C.svg' },
    { url: '1F92D.svg' },
    { url: '1F92E.svg' },
    { url: '1F92F.svg' },
    { url: '1F930-1F3FB.svg' },
    { url: '1F930-1F3FC.svg' },
    { url: '1F930-1F3FD.svg' },
    { url: '1F930-1F3FE.svg' },
    { url: '1F930-1F3FF.svg' },
    { url: '1F930.svg' },
    { url: '1F931-1F3FB.svg' },
    { url: '1F931-1F3FC.svg' },
    { url: '1F931-1F3FD.svg' },
    { url: '1F931-1F3FE.svg' },
    { url: '1F931-1F3FF.svg' },
    { url: '1F931.svg' },
    { url: '1F932-1F3FB.svg' },
    { url: '1F932-1F3FC.svg' },
    { url: '1F932-1F3FD.svg' },
    { url: '1F932-1F3FE.svg' },
    { url: '1F932-1F3FF.svg' },
    { url: '1F932.svg' },
    { url: '1F933-1F3FB.svg' },
    { url: '1F933-1F3FC.svg' },
    { url: '1F933-1F3FD.svg' },
    { url: '1F933-1F3FE.svg' },
    { url: '1F933-1F3FF.svg' },
    { url: '1F933.svg' },
    { url: '1F934-1F3FB.svg' },
    { url: '1F934-1F3FC.svg' },
    { url: '1F934-1F3FD.svg' },
    { url: '1F934-1F3FE.svg' },
    { url: '1F934-1F3FF.svg' },
    { url: '1F934.svg' },
    { url: '1F935-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F935-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F935-1F3FB.svg' },
    { url: '1F935-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F935-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F935-1F3FC.svg' },
    { url: '1F935-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F935-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F935-1F3FD.svg' },
    { url: '1F935-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F935-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F935-1F3FE.svg' },
    { url: '1F935-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F935-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F935-1F3FF.svg' },
    { url: '1F935-200D-2640-FE0F.svg' },
    { url: '1F935-200D-2642-FE0F.svg' },
    { url: '1F935.svg' },
    { url: '1F936-1F3FB.svg' },
    { url: '1F936-1F3FC.svg' },
    { url: '1F936-1F3FD.svg' },
    { url: '1F936-1F3FE.svg' },
    { url: '1F936-1F3FF.svg' },
    { url: '1F936.svg' }
  ],

  Animals: [
    { url: '1F400.svg' },
    { url: '1F401.svg' },
    { url: '1F402.svg' },
    { url: '1F403.svg' },
    { url: '1F404.svg' },
    { url: '1F405.svg' },
    { url: '1F406.svg' },
    { url: '1F407.svg' },
    { url: '1F408-200D-2B1B.svg' },
    { url: '1F408.svg' },
    { url: '1F409.svg' },
    { url: '1F40A.svg' },
    { url: '1F40B.svg' },
    { url: '1F40C.svg' },
    { url: '1F40D.svg' },
    { url: '1F40E.svg' },
    { url: '1F40F.svg' },
    { url: '1F410.svg' },
    { url: '1F411.svg' },
    { url: '1F412.svg' },
    { url: '1F413.svg' },
    { url: '1F414.svg' },
    { url: '1F415-200D-1F9BA.svg' },
    { url: '1F415.svg' },
    { url: '1F416.svg' },
    { url: '1F417.svg' },
    { url: '1F418.svg' },
    { url: '1F419.svg' },
    { url: '1F41A.svg' },
    { url: '1F41B.svg' },
    { url: '1F41C.svg' },
    { url: '1F41D.svg' },
    { url: '1F41E.svg' },
    { url: '1F41F.svg' },
    { url: '1F420.svg' },
    { url: '1F421.svg' },
    { url: '1F422.svg' },
    { url: '1F423.svg' },
    { url: '1F424.svg' },
    { url: '1F425.svg' },
    { url: '1F426.svg' },
    { url: '1F427.svg' },
    { url: '1F428.svg' },
    { url: '1F429.svg' },
    { url: '1F42A.svg' },
    { url: '1F42B.svg' },
    { url: '1F42C.svg' },
    { url: '1F42D.svg' },
    { url: '1F42E.svg' },
    { url: '1F42F.svg' },
    { url: '1F430.svg' },
    { url: '1F431-200D-1F4BB.svg' },
    { url: '1F431.svg' },
    { url: '1F432.svg' },
    { url: '1F433.svg' },
    { url: '1F434.svg' },
    { url: '1F435.svg' },
    { url: '1F436.svg' },
    { url: '1F437.svg' },
    { url: '1F438.svg' },
    { url: '1F439.svg' }
  ],

  People: [
    { url: '1F440.svg' },
    { url: '1F441-FE0F-200D-1F5E8-FE0F.svg' },
    { url: '1F441.svg' },
    { url: '1F442-1F3FB.svg' },
    { url: '1F442-1F3FC.svg' },
    { url: '1F442-1F3FD.svg' },
    { url: '1F442-1F3FE.svg' },
    { url: '1F442-1F3FF.svg' },
    { url: '1F442.svg' },
    { url: '1F443-1F3FB.svg' },
    { url: '1F443-1F3FC.svg' },
    { url: '1F443-1F3FD.svg' },
    { url: '1F443-1F3FE.svg' },
    { url: '1F443-1F3FF.svg' },
    { url: '1F443.svg' },
    { url: '1F444.svg' },
    { url: '1F445.svg' },
    { url: '1F446-1F3FB.svg' },
    { url: '1F446-1F3FC.svg' },
    { url: '1F446-1F3FD.svg' },
    { url: '1F446-1F3FE.svg' },
    { url: '1F446-1F3FF.svg' },
    { url: '1F446.svg' },
    { url: '1F447-1F3FB.svg' },
    { url: '1F447-1F3FC.svg' },
    { url: '1F447-1F3FD.svg' },
    { url: '1F447-1F3FE.svg' },
    { url: '1F447-1F3FF.svg' },
    { url: '1F447.svg' },
    { url: '1F448-1F3FB.svg' },
    { url: '1F448-1F3FC.svg' },
    { url: '1F448-1F3FD.svg' },
    { url: '1F448-1F3FE.svg' },
    { url: '1F448-1F3FF.svg' },
    { url: '1F448.svg' },
    { url: '1F449-1F3FB.svg' },
    { url: '1F449-1F3FC.svg' },
    { url: '1F449-1F3FD.svg' },
    { url: '1F449-1F3FE.svg' },
    { url: '1F449-1F3FF.svg' },
    { url: '1F449.svg' },
    { url: '1F44A-1F3FB.svg' },
    { url: '1F44A-1F3FC.svg' },
    { url: '1F44A-1F3FD.svg' },
    { url: '1F44A-1F3FE.svg' },
    { url: '1F44A-1F3FF.svg' },
    { url: '1F44A.svg' },
    { url: '1F44B-1F3FB.svg' },
    { url: '1F44B-1F3FC.svg' },
    { url: '1F44B-1F3FD.svg' },
    { url: '1F44B-1F3FE.svg' },
    { url: '1F44B-1F3FF.svg' },
    { url: '1F44B.svg' },
    { url: '1F44C-1F3FB.svg' },
    { url: '1F44C-1F3FC.svg' },
    { url: '1F44C-1F3FD.svg' },
    { url: '1F44C-1F3FE.svg' },
    { url: '1F44C-1F3FF.svg' },
    { url: '1F44C.svg' },
    { url: '1F44D-1F3FB.svg' },
    { url: '1F44D-1F3FC.svg' },
    { url: '1F44D-1F3FD.svg' },
    { url: '1F44D-1F3FE.svg' },
    { url: '1F44D-1F3FF.svg' },
    { url: '1F44D.svg' },
    { url: '1F44E-1F3FB.svg' },
    { url: '1F44E-1F3FC.svg' },
    { url: '1F44E-1F3FD.svg' },
    { url: '1F44E-1F3FE.svg' },
    { url: '1F44E-1F3FF.svg' },
    { url: '1F44E.svg' },
    { url: '1F44F-1F3FB.svg' },
    { url: '1F44F-1F3FC.svg' },
    { url: '1F44F-1F3FD.svg' },
    { url: '1F44F-1F3FE.svg' },
    { url: '1F44F-1F3FF.svg' },
    { url: '1F44F.svg' },
    { url: '1F450-1F3FB.svg' },
    { url: '1F450-1F3FC.svg' },
    { url: '1F450-1F3FD.svg' },
    { url: '1F450-1F3FE.svg' },
    { url: '1F450-1F3FF.svg' },
    { url: '1F450.svg' },
    { url: '1F451.svg' },
    { url: '1F452.svg' },
    { url: '1F453.svg' },
    { url: '1F454.svg' },
    { url: '1F455.svg' },
    { url: '1F456.svg' },
    { url: '1F457.svg' },
    { url: '1F458.svg' },
    { url: '1F459.svg' },
    { url: '1F45A.svg' },
    { url: '1F45B.svg' },
    { url: '1F45C.svg' },
    { url: '1F45D.svg' },
    { url: '1F45E.svg' },
    { url: '1F45F.svg' },
    { url: '1F460.svg' },
    { url: '1F461.svg' },
    { url: '1F462.svg' },
    { url: '1F463.svg' },
    { url: '1F464.svg' },
    { url: '1F465.svg' },
    { url: '1F466-1F3FB.svg' },
    { url: '1F466-1F3FC.svg' },
    { url: '1F466-1F3FD.svg' },
    { url: '1F466-1F3FE.svg' },
    { url: '1F466-1F3FF.svg' },
    { url: '1F466.svg' },
    { url: '1F467-1F3FB.svg' },
    { url: '1F467-1F3FC.svg' },
    { url: '1F467-1F3FD.svg' },
    { url: '1F467-1F3FE.svg' },
    { url: '1F467-1F3FF.svg' },
    { url: '1F467.svg' },
    { url: '1F468-1F3FB-200D-1F33E.svg' },
    { url: '1F468-1F3FB-200D-1F373.svg' },
    { url: '1F468-1F3FB-200D-1F37C.svg' },
    { url: '1F468-1F3FB-200D-1F393.svg' },
    { url: '1F468-1F3FB-200D-1F3A4.svg' },
    { url: '1F468-1F3FB-200D-1F3A8.svg' },
    { url: '1F468-1F3FB-200D-1F3EB.svg' },
    { url: '1F468-1F3FB-200D-1F3ED.svg' },
    { url: '1F468-1F3FB-200D-1F4BB.svg' },
    { url: '1F468-1F3FB-200D-1F4BC.svg' },
    { url: '1F468-1F3FB-200D-1F527.svg' },
    { url: '1F468-1F3FB-200D-1F52C.svg' },
    { url: '1F468-1F3FB-200D-1F680.svg' },
    { url: '1F468-1F3FB-200D-1F692.svg' },
    { url: '1F468-1F3FB-200D-1F91D-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FB-200D-1F91D-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FB-200D-1F91D-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FB-200D-1F91D-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FB-200D-1F9AF.svg' },
    { url: '1F468-1F3FB-200D-1F9B0.svg' },
    { url: '1F468-1F3FB-200D-1F9B1.svg' },
    { url: '1F468-1F3FB-200D-1F9B2.svg' },
    { url: '1F468-1F3FB-200D-1F9B3.svg' },
    { url: '1F468-1F3FB-200D-1F9BC.svg' },
    { url: '1F468-1F3FB-200D-1F9BD.svg' },
    { url: '1F468-1F3FB-200D-2695-FE0F.svg' },
    { url: '1F468-1F3FB-200D-2696-FE0F.svg' },
    { url: '1F468-1F3FB-200D-2708-FE0F.svg' },
    { url: '1F468-1F3FB-200D-2764-FE0F-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FB-200D-2764-FE0F-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FB-200D-2764-FE0F-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FB-200D-2764-FE0F-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FB-200D-2764-FE0F-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FB.svg' },
    { url: '1F468-1F3FC-200D-1F33E.svg' },
    { url: '1F468-1F3FC-200D-1F373.svg' },
    { url: '1F468-1F3FC-200D-1F37C.svg' },
    { url: '1F468-1F3FC-200D-1F393.svg' },
    { url: '1F468-1F3FC-200D-1F3A4.svg' },
    { url: '1F468-1F3FC-200D-1F3A8.svg' },
    { url: '1F468-1F3FC-200D-1F3EB.svg' },
    { url: '1F468-1F3FC-200D-1F3ED.svg' },
    { url: '1F468-1F3FC-200D-1F4BB.svg' },
    { url: '1F468-1F3FC-200D-1F4BC.svg' },
    { url: '1F468-1F3FC-200D-1F527.svg' },
    { url: '1F468-1F3FC-200D-1F52C.svg' },
    { url: '1F468-1F3FC-200D-1F680.svg' },
    { url: '1F468-1F3FC-200D-1F692.svg' },
    { url: '1F468-1F3FC-200D-1F91D-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FC-200D-1F91D-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FC-200D-1F91D-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FC-200D-1F91D-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FC-200D-1F9AF.svg' },
    { url: '1F468-1F3FC-200D-1F9B0.svg' },
    { url: '1F468-1F3FC-200D-1F9B1.svg' },
    { url: '1F468-1F3FC-200D-1F9B2.svg' },
    { url: '1F468-1F3FC-200D-1F9B3.svg' },
    { url: '1F468-1F3FC-200D-1F9BC.svg' },
    { url: '1F468-1F3FC-200D-1F9BD.svg' },
    { url: '1F468-1F3FC-200D-2695-FE0F.svg' },
    { url: '1F468-1F3FC-200D-2696-FE0F.svg' },
    { url: '1F468-1F3FC-200D-2708-FE0F.svg' },
    { url: '1F468-1F3FC-200D-2764-FE0F-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FC-200D-2764-FE0F-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FC-200D-2764-FE0F-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FC-200D-2764-FE0F-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FC-200D-2764-FE0F-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FC.svg' },
    { url: '1F468-1F3FD-200D-1F33E.svg' },
    { url: '1F468-1F3FD-200D-1F373.svg' },
    { url: '1F468-1F3FD-200D-1F37C.svg' },
    { url: '1F468-1F3FD-200D-1F393.svg' },
    { url: '1F468-1F3FD-200D-1F3A4.svg' },
    { url: '1F468-1F3FD-200D-1F3A8.svg' },
    { url: '1F468-1F3FD-200D-1F3EB.svg' },
    { url: '1F468-1F3FD-200D-1F3ED.svg' },
    { url: '1F468-1F3FD-200D-1F4BB.svg' },
    { url: '1F468-1F3FD-200D-1F4BC.svg' },
    { url: '1F468-1F3FD-200D-1F527.svg' },
    { url: '1F468-1F3FD-200D-1F52C.svg' },
    { url: '1F468-1F3FD-200D-1F680.svg' },
    { url: '1F468-1F3FD-200D-1F692.svg' },
    { url: '1F468-1F3FD-200D-1F91D-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FD-200D-1F91D-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FD-200D-1F91D-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FD-200D-1F91D-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FD-200D-1F9AF.svg' },
    { url: '1F468-1F3FD-200D-1F9B0.svg' },
    { url: '1F468-1F3FD-200D-1F9B1.svg' },
    { url: '1F468-1F3FD-200D-1F9B2.svg' },
    { url: '1F468-1F3FD-200D-1F9B3.svg' },
    { url: '1F468-1F3FD-200D-1F9BC.svg' },
    { url: '1F468-1F3FD-200D-1F9BD.svg' },
    { url: '1F468-1F3FD-200D-2695-FE0F.svg' },
    { url: '1F468-1F3FD-200D-2696-FE0F.svg' },
    { url: '1F468-1F3FD-200D-2708-FE0F.svg' },
    { url: '1F468-1F3FD-200D-2764-FE0F-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FD-200D-2764-FE0F-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FD-200D-2764-FE0F-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FD-200D-2764-FE0F-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FD-200D-2764-FE0F-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FD.svg' },
    { url: '1F468-1F3FE-200D-1F33E.svg' },
    { url: '1F468-1F3FE-200D-1F373.svg' },
    { url: '1F468-1F3FE-200D-1F37C.svg' },
    { url: '1F468-1F3FE-200D-1F393.svg' },
    { url: '1F468-1F3FE-200D-1F3A4.svg' },
    { url: '1F468-1F3FE-200D-1F3A8.svg' },
    { url: '1F468-1F3FE-200D-1F3EB.svg' },
    { url: '1F468-1F3FE-200D-1F3ED.svg' },
    { url: '1F468-1F3FE-200D-1F4BB.svg' },
    { url: '1F468-1F3FE-200D-1F4BC.svg' },
    { url: '1F468-1F3FE-200D-1F527.svg' },
    { url: '1F468-1F3FE-200D-1F52C.svg' },
    { url: '1F468-1F3FE-200D-1F680.svg' },
    { url: '1F468-1F3FE-200D-1F692.svg' },
    { url: '1F468-1F3FE-200D-1F91D-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FE-200D-1F91D-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FE-200D-1F91D-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FE-200D-1F91D-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FE-200D-1F9AF.svg' },
    { url: '1F468-1F3FE-200D-1F9B0.svg' },
    { url: '1F468-1F3FE-200D-1F9B1.svg' },
    { url: '1F468-1F3FE-200D-1F9B2.svg' },
    { url: '1F468-1F3FE-200D-1F9B3.svg' },
    { url: '1F468-1F3FE-200D-1F9BC.svg' },
    { url: '1F468-1F3FE-200D-1F9BD.svg' },
    { url: '1F468-1F3FE-200D-2695-FE0F.svg' },
    { url: '1F468-1F3FE-200D-2696-FE0F.svg' },
    { url: '1F468-1F3FE-200D-2708-FE0F.svg' },
    { url: '1F468-1F3FE-200D-2764-FE0F-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FE-200D-2764-FE0F-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FE-200D-2764-FE0F-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FE-200D-2764-FE0F-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FE-200D-2764-FE0F-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FE.svg' },
    { url: '1F468-1F3FF-200D-1F33E.svg' },
    { url: '1F468-1F3FF-200D-1F373.svg' },
    { url: '1F468-1F3FF-200D-1F37C.svg' },
    { url: '1F468-1F3FF-200D-1F393.svg' },
    { url: '1F468-1F3FF-200D-1F3A4.svg' },
    { url: '1F468-1F3FF-200D-1F3A8.svg' },
    { url: '1F468-1F3FF-200D-1F3EB.svg' },
    { url: '1F468-1F3FF-200D-1F3ED.svg' },
    { url: '1F468-1F3FF-200D-1F4BB.svg' },
    { url: '1F468-1F3FF-200D-1F4BC.svg' },
    { url: '1F468-1F3FF-200D-1F527.svg' },
    { url: '1F468-1F3FF-200D-1F52C.svg' },
    { url: '1F468-1F3FF-200D-1F680.svg' },
    { url: '1F468-1F3FF-200D-1F692.svg' },
    { url: '1F468-1F3FF-200D-1F91D-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FF-200D-1F91D-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FF-200D-1F91D-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FF-200D-1F91D-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FF-200D-1F9AF.svg' },
    { url: '1F468-1F3FF-200D-1F9B0.svg' },
    { url: '1F468-1F3FF-200D-1F9B1.svg' },
    { url: '1F468-1F3FF-200D-1F9B2.svg' },
    { url: '1F468-1F3FF-200D-1F9B3.svg' },
    { url: '1F468-1F3FF-200D-1F9BC.svg' },
    { url: '1F468-1F3FF-200D-1F9BD.svg' },
    { url: '1F468-1F3FF-200D-2695-FE0F.svg' },
    { url: '1F468-1F3FF-200D-2696-FE0F.svg' },
    { url: '1F468-1F3FF-200D-2708-FE0F.svg' },
    { url: '1F468-1F3FF-200D-2764-FE0F-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FF-200D-2764-FE0F-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FF-200D-2764-FE0F-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FF-200D-2764-FE0F-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FF-200D-2764-FE0F-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FB.svg' },
    { url: '1F468-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FC.svg' },
    { url: '1F468-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FD.svg' },
    { url: '1F468-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FE.svg' },
    { url: '1F468-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FF.svg' },
    { url: '1F468-1F3FF.svg' },
    { url: '1F468-200D-1F33E.svg' },
    { url: '1F468-200D-1F373.svg' },
    { url: '1F468-200D-1F37C.svg' },
    { url: '1F468-200D-1F393.svg' },
    { url: '1F468-200D-1F3A4.svg' },
    { url: '1F468-200D-1F3A8.svg' },
    { url: '1F468-200D-1F3EB.svg' },
    { url: '1F468-200D-1F3ED.svg' },
    { url: '1F468-200D-1F466-200D-1F466.svg' },
    { url: '1F468-200D-1F466.svg' },
    { url: '1F468-200D-1F467-200D-1F466.svg' },
    { url: '1F468-200D-1F467-200D-1F467.svg' },
    { url: '1F468-200D-1F467.svg' },
    { url: '1F468-200D-1F468-200D-1F466-200D-1F466.svg' },
    { url: '1F468-200D-1F468-200D-1F466.svg' },
    { url: '1F468-200D-1F468-200D-1F467-200D-1F466.svg' },
    { url: '1F468-200D-1F468-200D-1F467-200D-1F467.svg' },
    { url: '1F468-200D-1F468-200D-1F467.svg' },
    { url: '1F468-200D-1F469-200D-1F466-200D-1F466.svg' },
    { url: '1F468-200D-1F469-200D-1F466.svg' },
    { url: '1F468-200D-1F469-200D-1F467-200D-1F466.svg' },
    { url: '1F468-200D-1F469-200D-1F467-200D-1F467.svg' },
    { url: '1F468-200D-1F469-200D-1F467.svg' },
    { url: '1F468-200D-1F4BB.svg' },
    { url: '1F468-200D-1F4BC.svg' },
    { url: '1F468-200D-1F527.svg' },
    { url: '1F468-200D-1F52C.svg' },
    { url: '1F468-200D-1F680.svg' },
    { url: '1F468-200D-1F692.svg' },
    { url: '1F468-200D-1F9AF.svg' },
    { url: '1F468-200D-1F9B0.svg' },
    { url: '1F468-200D-1F9B1.svg' },
    { url: '1F468-200D-1F9B2.svg' },
    { url: '1F468-200D-1F9B3.svg' },
    { url: '1F468-200D-1F9BC.svg' },
    { url: '1F468-200D-1F9BD.svg' },
    { url: '1F468-200D-1FAA9.svg' },
    { url: '1F468-200D-2695-FE0F.svg' },
    { url: '1F468-200D-2696-FE0F.svg' },
    { url: '1F468-200D-2708-FE0F.svg' },
    { url: '1F468-200D-2764-FE0F-200D-1F468.svg' },
    { url: '1F468-200D-2764-FE0F-200D-1F48B-200D-1F468.svg' },
    { url: '1F468.svg' },
    { url: '1F469-1F3FB-200D-1F33E.svg' },
    { url: '1F469-1F3FB-200D-1F373.svg' },
    { url: '1F469-1F3FB-200D-1F37C.svg' },
    { url: '1F469-1F3FB-200D-1F393.svg' },
    { url: '1F469-1F3FB-200D-1F3A4.svg' },
    { url: '1F469-1F3FB-200D-1F3A8.svg' },
    { url: '1F469-1F3FB-200D-1F3EB.svg' },
    { url: '1F469-1F3FB-200D-1F3ED.svg' },
    { url: '1F469-1F3FB-200D-1F4BB.svg' },
    { url: '1F469-1F3FB-200D-1F4BC.svg' },
    { url: '1F469-1F3FB-200D-1F527.svg' },
    { url: '1F469-1F3FB-200D-1F52C.svg' },
    { url: '1F469-1F3FB-200D-1F680.svg' },
    { url: '1F469-1F3FB-200D-1F692.svg' },
    { url: '1F469-1F3FB-200D-1F91D-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FB-200D-1F91D-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FB-200D-1F91D-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FB-200D-1F91D-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FB-200D-1F91D-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FB-200D-1F91D-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FB-200D-1F91D-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FB-200D-1F91D-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FB-200D-1F9AF.svg' },
    { url: '1F469-1F3FB-200D-1F9B0.svg' },
    { url: '1F469-1F3FB-200D-1F9B1.svg' },
    { url: '1F469-1F3FB-200D-1F9B2.svg' },
    { url: '1F469-1F3FB-200D-1F9B3.svg' },
    { url: '1F469-1F3FB-200D-1F9BC.svg' },
    { url: '1F469-1F3FB-200D-1F9BD.svg' },
    { url: '1F469-1F3FB-200D-2695-FE0F.svg' },
    { url: '1F469-1F3FB-200D-2696-FE0F.svg' },
    { url: '1F469-1F3FB-200D-2708-FE0F.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FB-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FB.svg' },
    { url: '1F469-1F3FC-200D-1F33E.svg' },
    { url: '1F469-1F3FC-200D-1F373.svg' },
    { url: '1F469-1F3FC-200D-1F37C.svg' },
    { url: '1F469-1F3FC-200D-1F393.svg' },
    { url: '1F469-1F3FC-200D-1F3A4.svg' },
    { url: '1F469-1F3FC-200D-1F3A8.svg' },
    { url: '1F469-1F3FC-200D-1F3EB.svg' },
    { url: '1F469-1F3FC-200D-1F3ED.svg' },
    { url: '1F469-1F3FC-200D-1F4BB.svg' },
    { url: '1F469-1F3FC-200D-1F4BC.svg' },
    { url: '1F469-1F3FC-200D-1F527.svg' },
    { url: '1F469-1F3FC-200D-1F52C.svg' },
    { url: '1F469-1F3FC-200D-1F680.svg' },
    { url: '1F469-1F3FC-200D-1F692.svg' },
    { url: '1F469-1F3FC-200D-1F91D-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FC-200D-1F91D-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FC-200D-1F91D-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FC-200D-1F91D-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FC-200D-1F91D-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FC-200D-1F91D-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FC-200D-1F91D-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FC-200D-1F91D-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FC-200D-1F9AF.svg' },
    { url: '1F469-1F3FC-200D-1F9B0.svg' },
    { url: '1F469-1F3FC-200D-1F9B1.svg' },
    { url: '1F469-1F3FC-200D-1F9B2.svg' },
    { url: '1F469-1F3FC-200D-1F9B3.svg' },
    { url: '1F469-1F3FC-200D-1F9BC.svg' },
    { url: '1F469-1F3FC-200D-1F9BD.svg' },
    { url: '1F469-1F3FC-200D-2695-FE0F.svg' },
    { url: '1F469-1F3FC-200D-2696-FE0F.svg' },
    { url: '1F469-1F3FC-200D-2708-FE0F.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FC-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FC.svg' },
    { url: '1F469-1F3FD-200D-1F33E.svg' },
    { url: '1F469-1F3FD-200D-1F373.svg' },
    { url: '1F469-1F3FD-200D-1F37C.svg' },
    { url: '1F469-1F3FD-200D-1F393.svg' },
    { url: '1F469-1F3FD-200D-1F3A4.svg' },
    { url: '1F469-1F3FD-200D-1F3A8.svg' },
    { url: '1F469-1F3FD-200D-1F3EB.svg' },
    { url: '1F469-1F3FD-200D-1F3ED.svg' },
    { url: '1F469-1F3FD-200D-1F4BB.svg' },
    { url: '1F469-1F3FD-200D-1F4BC.svg' },
    { url: '1F469-1F3FD-200D-1F527.svg' },
    { url: '1F469-1F3FD-200D-1F52C.svg' },
    { url: '1F469-1F3FD-200D-1F680.svg' },
    { url: '1F469-1F3FD-200D-1F692.svg' },
    { url: '1F469-1F3FD-200D-1F91D-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FD-200D-1F91D-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FD-200D-1F91D-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FD-200D-1F91D-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FD-200D-1F91D-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FD-200D-1F91D-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FD-200D-1F91D-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FD-200D-1F91D-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FD-200D-1F9AF.svg' },
    { url: '1F469-1F3FD-200D-1F9B0.svg' },
    { url: '1F469-1F3FD-200D-1F9B1.svg' },
    { url: '1F469-1F3FD-200D-1F9B2.svg' },
    { url: '1F469-1F3FD-200D-1F9B3.svg' },
    { url: '1F469-1F3FD-200D-1F9BC.svg' },
    { url: '1F469-1F3FD-200D-1F9BD.svg' },
    { url: '1F469-1F3FD-200D-2695-FE0F.svg' },
    { url: '1F469-1F3FD-200D-2696-FE0F.svg' },
    { url: '1F469-1F3FD-200D-2708-FE0F.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FD-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FD.svg' },
    { url: '1F469-1F3FE-200D-1F33E.svg' },
    { url: '1F469-1F3FE-200D-1F373.svg' },
    { url: '1F469-1F3FE-200D-1F37C.svg' },
    { url: '1F469-1F3FE-200D-1F393.svg' },
    { url: '1F469-1F3FE-200D-1F3A4.svg' },
    { url: '1F469-1F3FE-200D-1F3A8.svg' },
    { url: '1F469-1F3FE-200D-1F3EB.svg' },
    { url: '1F469-1F3FE-200D-1F3ED.svg' },
    { url: '1F469-1F3FE-200D-1F4BB.svg' },
    { url: '1F469-1F3FE-200D-1F4BC.svg' },
    { url: '1F469-1F3FE-200D-1F527.svg' },
    { url: '1F469-1F3FE-200D-1F52C.svg' },
    { url: '1F469-1F3FE-200D-1F680.svg' },
    { url: '1F469-1F3FE-200D-1F692.svg' },
    { url: '1F469-1F3FE-200D-1F91D-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FE-200D-1F91D-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FE-200D-1F91D-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FE-200D-1F91D-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FE-200D-1F91D-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FE-200D-1F91D-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FE-200D-1F91D-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FE-200D-1F91D-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FE-200D-1F9AF.svg' },
    { url: '1F469-1F3FE-200D-1F9B0.svg' },
    { url: '1F469-1F3FE-200D-1F9B1.svg' },
    { url: '1F469-1F3FE-200D-1F9B2.svg' },
    { url: '1F469-1F3FE-200D-1F9B3.svg' },
    { url: '1F469-1F3FE-200D-1F9BC.svg' },
    { url: '1F469-1F3FE-200D-1F9BD.svg' },
    { url: '1F469-1F3FE-200D-2695-FE0F.svg' },
    { url: '1F469-1F3FE-200D-2696-FE0F.svg' },
    { url: '1F469-1F3FE-200D-2708-FE0F.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FE-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FE.svg' },
    { url: '1F469-1F3FF-200D-1F33E.svg' },
    { url: '1F469-1F3FF-200D-1F373.svg' },
    { url: '1F469-1F3FF-200D-1F37C.svg' },
    { url: '1F469-1F3FF-200D-1F393.svg' },
    { url: '1F469-1F3FF-200D-1F3A4.svg' },
    { url: '1F469-1F3FF-200D-1F3A8.svg' },
    { url: '1F469-1F3FF-200D-1F3EB.svg' },
    { url: '1F469-1F3FF-200D-1F3ED.svg' },
    { url: '1F469-1F3FF-200D-1F4BB.svg' },
    { url: '1F469-1F3FF-200D-1F4BC.svg' },
    { url: '1F469-1F3FF-200D-1F527.svg' },
    { url: '1F469-1F3FF-200D-1F52C.svg' },
    { url: '1F469-1F3FF-200D-1F680.svg' },
    { url: '1F469-1F3FF-200D-1F692.svg' },
    { url: '1F469-1F3FF-200D-1F91D-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FF-200D-1F91D-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FF-200D-1F91D-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FF-200D-1F91D-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FF-200D-1F91D-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FF-200D-1F91D-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FF-200D-1F91D-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FF-200D-1F91D-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FF-200D-1F9AF.svg' },
    { url: '1F469-1F3FF-200D-1F9B0.svg' },
    { url: '1F469-1F3FF-200D-1F9B1.svg' },
    { url: '1F469-1F3FF-200D-1F9B2.svg' },
    { url: '1F469-1F3FF-200D-1F9B3.svg' },
    { url: '1F469-1F3FF-200D-1F9BC.svg' },
    { url: '1F469-1F3FF-200D-1F9BD.svg' },
    { url: '1F469-1F3FF-200D-2695-FE0F.svg' },
    { url: '1F469-1F3FF-200D-2696-FE0F.svg' },
    { url: '1F469-1F3FF-200D-2708-FE0F.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FB.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FC.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FD.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FE.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F468-1F3FF.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FB.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FC.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FD.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FE.svg' },
    { url: '1F469-1F3FF-200D-2764-FE0F-200D-1F48B-200D-1F469-1F3FF.svg' },
    { url: '1F469-1F3FF.svg' },
    { url: '1F469-200D-1F33E.svg' },
    { url: '1F469-200D-1F373.svg' },
    { url: '1F469-200D-1F37C.svg' },
    { url: '1F469-200D-1F393.svg' },
    { url: '1F469-200D-1F3A4.svg' },
    { url: '1F469-200D-1F3A8.svg' },
    { url: '1F469-200D-1F3EB.svg' },
    { url: '1F469-200D-1F3ED.svg' },
    { url: '1F469-200D-1F466-200D-1F466.svg' },
    { url: '1F469-200D-1F466.svg' },
    { url: '1F469-200D-1F467-200D-1F466.svg' },
    { url: '1F469-200D-1F467-200D-1F467.svg' },
    { url: '1F469-200D-1F467.svg' },
    { url: '1F469-200D-1F469-200D-1F466-200D-1F466.svg' },
    { url: '1F469-200D-1F469-200D-1F466.svg' },
    { url: '1F469-200D-1F469-200D-1F467-200D-1F466.svg' },
    { url: '1F469-200D-1F469-200D-1F467-200D-1F467.svg' },
    { url: '1F469-200D-1F469-200D-1F467.svg' },
    { url: '1F469-200D-1F4BB.svg' },
    { url: '1F469-200D-1F4BC.svg' },
    { url: '1F469-200D-1F527.svg' },
    { url: '1F469-200D-1F52C.svg' },
    { url: '1F469-200D-1F680.svg' },
    { url: '1F469-200D-1F692.svg' },
    { url: '1F469-200D-1F9AF.svg' },
    { url: '1F469-200D-1F9B0.svg' },
    { url: '1F469-200D-1F9B1.svg' },
    { url: '1F469-200D-1F9B2.svg' },
    { url: '1F469-200D-1F9B3.svg' },
    { url: '1F469-200D-1F9BC.svg' },
    { url: '1F469-200D-1F9BD.svg' },
    { url: '1F469-200D-1FAA9.svg' },
    { url: '1F469-200D-2695-FE0F.svg' },
    { url: '1F469-200D-2696-FE0F.svg' },
    { url: '1F469-200D-2708-FE0F.svg' },
    { url: '1F469-200D-2764-FE0F-200D-1F468.svg' },
    { url: '1F469-200D-2764-FE0F-200D-1F469.svg' },
    { url: '1F469-200D-2764-FE0F-200D-1F48B-200D-1F468.svg' },
    { url: '1F469-200D-2764-FE0F-200D-1F48B-200D-1F469.svg' },
    { url: '1F469.svg' },
    { url: '1F46A.svg' },
    { url: '1F46B-1F3FB.svg' },
    { url: '1F46B-1F3FC.svg' },
    { url: '1F46B-1F3FD.svg' },
    { url: '1F46B-1F3FE.svg' },
    { url: '1F46B-1F3FF.svg' },
    { url: '1F46B.svg' },
    { url: '1F46C-1F3FB.svg' },
    { url: '1F46C-1F3FC.svg' },
    { url: '1F46C-1F3FD.svg' },
    { url: '1F46C-1F3FE.svg' },
    { url: '1F46C-1F3FF.svg' },
    { url: '1F46C.svg' },
    { url: '1F46D-1F3FB.svg' },
    { url: '1F46D-1F3FC.svg' },
    { url: '1F46D-1F3FD.svg' },
    { url: '1F46D-1F3FE.svg' },
    { url: '1F46D-1F3FF.svg' },
    { url: '1F46D.svg' },
    { url: '1F46E-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F46E-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F46E-1F3FB.svg' },
    { url: '1F46E-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F46E-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F46E-1F3FC.svg' },
    { url: '1F46E-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F46E-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F46E-1F3FD.svg' },
    { url: '1F46E-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F46E-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F46E-1F3FE.svg' },
    { url: '1F46E-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F46E-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F46E-1F3FF.svg' },
    { url: '1F46E-200D-2640-FE0F.svg' },
    { url: '1F46E-200D-2642-FE0F.svg' },
    { url: '1F46E.svg' },
    { url: '1F46F-200D-2640-FE0F.svg' },
    { url: '1F46F-200D-2642-FE0F.svg' },
    { url: '1F46F.svg' },
    { url: '1F470-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F470-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F470-1F3FB.svg' },
    { url: '1F470-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F470-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F470-1F3FC.svg' },
    { url: '1F470-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F470-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F470-1F3FD.svg' },
    { url: '1F470-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F470-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F470-1F3FE.svg' },
    { url: '1F470-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F470-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F470-1F3FF.svg' },
    { url: '1F470-200D-2640-FE0F.svg' },
    { url: '1F470-200D-2642-FE0F.svg' },
    { url: '1F470.svg' },
    { url: '1F471-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F471-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F471-1F3FB.svg' },
    { url: '1F471-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F471-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F471-1F3FC.svg' },
    { url: '1F471-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F471-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F471-1F3FD.svg' },
    { url: '1F471-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F471-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F471-1F3FE.svg' },
    { url: '1F471-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F471-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F471-1F3FF.svg' },
    { url: '1F471-200D-2640-FE0F.svg' },
    { url: '1F471-200D-2642-FE0F.svg' },
    { url: '1F471.svg' },
    { url: '1F472-1F3FB.svg' },
    { url: '1F472-1F3FC.svg' },
    { url: '1F472-1F3FD.svg' },
    { url: '1F472-1F3FE.svg' },
    { url: '1F472-1F3FF.svg' },
    { url: '1F472.svg' },
    { url: '1F473-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F473-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F473-1F3FB.svg' },
    { url: '1F473-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F473-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F473-1F3FC.svg' },
    { url: '1F473-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F473-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F473-1F3FD.svg' },
    { url: '1F473-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F473-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F473-1F3FE.svg' },
    { url: '1F473-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F473-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F473-1F3FF.svg' },
    { url: '1F473-200D-2640-FE0F.svg' },
    { url: '1F473-200D-2642-FE0F.svg' },
    { url: '1F473.svg' },
    { url: '1F474-1F3FB.svg' },
    { url: '1F474-1F3FC.svg' },
    { url: '1F474-1F3FD.svg' },
    { url: '1F474-1F3FE.svg' },
    { url: '1F474-1F3FF.svg' },
    { url: '1F474.svg' },
    { url: '1F475-1F3FB.svg' },
    { url: '1F475-1F3FC.svg' },
    { url: '1F475-1F3FD.svg' },
    { url: '1F475-1F3FE.svg' },
    { url: '1F475-1F3FF.svg' },
    { url: '1F475.svg' },
    { url: '1F476-1F3FB.svg' },
    { url: '1F476-1F3FC.svg' },
    { url: '1F476-1F3FD.svg' },
    { url: '1F476-1F3FE.svg' },
    { url: '1F476-1F3FF.svg' },
    { url: '1F476.svg' },
    { url: '1F477-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F477-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F477-1F3FB.svg' },
    { url: '1F477-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F477-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F477-1F3FC.svg' },
    { url: '1F477-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F477-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F477-1F3FD.svg' },
    { url: '1F477-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F477-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F477-1F3FE.svg' },
    { url: '1F477-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F477-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F477-1F3FF.svg' },
    { url: '1F477-200D-2640-FE0F.svg' },
    { url: '1F477-200D-2642-FE0F.svg' },
    { url: '1F477.svg' },
    { url: '1F478-1F3FB.svg' },
    { url: '1F478-1F3FC.svg' },
    { url: '1F478-1F3FD.svg' },
    { url: '1F478-1F3FE.svg' },
    { url: '1F478-1F3FF.svg' },
    { url: '1F478.svg' },
    { url: '1F479.svg' },
    { url: '1F47A.svg' },
    { url: '1F47B.svg' },
    { url: '1F47C-1F3FB.svg' },
    { url: '1F47C-1F3FC.svg' },
    { url: '1F47C-1F3FD.svg' },
    { url: '1F47C-1F3FE.svg' },
    { url: '1F47C-1F3FF.svg' },
    { url: '1F47C.svg' },
    { url: '1F47D.svg' },
    { url: '1F47E.svg' },
    { url: '1F47F.svg' },
    { url: '1F480.svg' },
    { url: '1F481-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F481-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F481-1F3FB.svg' },
    { url: '1F481-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F481-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F481-1F3FC.svg' },
    { url: '1F481-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F481-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F481-1F3FD.svg' },
    { url: '1F481-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F481-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F481-1F3FE.svg' },
    { url: '1F481-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F481-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F481-1F3FF.svg' },
    { url: '1F481-200D-2640-FE0F.svg' },
    { url: '1F481-200D-2642-FE0F.svg' },
    { url: '1F481.svg' },
    { url: '1F482-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F482-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F482-1F3FB.svg' },
    { url: '1F482-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F482-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F482-1F3FC.svg' },
    { url: '1F482-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F482-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F482-1F3FD.svg' },
    { url: '1F482-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F482-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F482-1F3FE.svg' },
    { url: '1F482-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F482-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F482-1F3FF.svg' },
    { url: '1F482-200D-2640-FE0F.svg' },
    { url: '1F482-200D-2642-FE0F.svg' },
    { url: '1F482.svg' },
    { url: '1F483-1F3FB.svg' },
    { url: '1F483-1F3FC.svg' },
    { url: '1F483-1F3FD.svg' },
    { url: '1F483-1F3FE.svg' },
    { url: '1F483-1F3FF.svg' },
    { url: '1F483.svg' },
    { url: '1F484.svg' },
    { url: '1F485-1F3FB.svg' },
    { url: '1F485-1F3FC.svg' },
    { url: '1F485-1F3FD.svg' },
    { url: '1F485-1F3FE.svg' },
    { url: '1F485-1F3FF.svg' },
    { url: '1F485.svg' },
    { url: '1F486-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F486-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F486-1F3FB.svg' },
    { url: '1F486-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F486-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F486-1F3FC.svg' },
    { url: '1F486-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F486-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F486-1F3FD.svg' },
    { url: '1F486-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F486-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F486-1F3FE.svg' },
    { url: '1F486-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F486-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F486-1F3FF.svg' },
    { url: '1F486-200D-2640-FE0F.svg' },
    { url: '1F486-200D-2642-FE0F.svg' },
    { url: '1F486.svg' },
    { url: '1F487-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F487-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F487-1F3FB.svg' },
    { url: '1F487-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F487-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F487-1F3FC.svg' },
    { url: '1F487-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F487-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F487-1F3FD.svg' },
    { url: '1F487-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F487-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F487-1F3FE.svg' },
    { url: '1F487-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F487-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F487-1F3FF.svg' },
    { url: '1F487-200D-2640-FE0F.svg' },
    { url: '1F487-200D-2642-FE0F.svg' },
    { url: '1F487.svg' },
    { url: '1F488.svg' },
    { url: '1F489.svg' },
    { url: '1F48A.svg' },
    { url: '1F48B.svg' },
    { url: '1F48C.svg' },
    { url: '1F48D.svg' },
    { url: '1F48E.svg' },
    { url: '1F48F-1F3FB.svg' },
    { url: '1F48F-1F3FC.svg' },
    { url: '1F48F-1F3FD.svg' },
    { url: '1F48F-1F3FE.svg' },
    { url: '1F48F-1F3FF.svg' },
    { url: '1F48F.svg' },
    { url: '1F490.svg' },
    { url: '1F491-1F3FB.svg' },
    { url: '1F491-1F3FC.svg' },
    { url: '1F491-1F3FD.svg' },
    { url: '1F491-1F3FE.svg' },
    { url: '1F491-1F3FF.svg' },
    { url: '1F491.svg' }
  ],

  Jobs: [
    { url: '1F440.svg' },
    { url: '1F441-FE0F-200D-1F5E8-FE0F.svg' },
    { url: '1F441.svg' },
    { url: '1F442-1F3FB.svg' },
    { url: '1F442-1F3FC.svg' },
    { url: '1F442-1F3FD.svg' },
    { url: '1F442-1F3FE.svg' },
    { url: '1F442-1F3FF.svg' },
    { url: '1F442.svg' },
    { url: '1F443-1F3FB.svg' },
    { url: '1F443-1F3FC.svg' },
    { url: '1F443-1F3FD.svg' },
    { url: '1F443-1F3FE.svg' },
    { url: '1F443-1F3FF.svg' },
    { url: '1F443.svg' },
    { url: '1F444.svg' },
    { url: '1F445.svg' },
    { url: '1F446-1F3FB.svg' },
    { url: '1F446-1F3FC.svg' },
    { url: '1F446-1F3FD.svg' },
    { url: '1F446-1F3FE.svg' },
    { url: '1F446-1F3FF.svg' },
    { url: '1F446.svg' },
    { url: '1F447-1F3FB.svg' },
    { url: '1F447-1F3FC.svg' },
    { url: '1F447-1F3FD.svg' },
    { url: '1F447-1F3FE.svg' },
    { url: '1F447-1F3FF.svg' },
    { url: '1F447.svg' },
    { url: '1F448-1F3FB.svg' },
    { url: '1F448-1F3FC.svg' },
    { url: '1F448-1F3FD.svg' },
    { url: '1F448-1F3FE.svg' },
    { url: '1F448-1F3FF.svg' },
    { url: '1F448.svg' },
    { url: '1F449-1F3FB.svg' },
    { url: '1F449-1F3FC.svg' },
    { url: '1F449-1F3FD.svg' },
    { url: '1F449-1F3FE.svg' },
    { url: '1F449-1F3FF.svg' },
    { url: '1F449.svg' },
    { url: '1F44A-1F3FB.svg' },
    { url: '1F44A-1F3FC.svg' },
    { url: '1F44A-1F3FD.svg' },
    { url: '1F44A-1F3FE.svg' },
    { url: '1F44A-1F3FF.svg' },
    { url: '1F44A.svg' },
    { url: '1F44B-1F3FB.svg' },
    { url: '1F44B-1F3FC.svg' },
    { url: '1F44B-1F3FD.svg' },
    { url: '1F44B-1F3FE.svg' },
    { url: '1F44B-1F3FF.svg' },
    { url: '1F44B.svg' },
    { url: '1F44C-1F3FB.svg' },
    { url: '1F44C-1F3FC.svg' },
    { url: '1F44C-1F3FD.svg' },
    { url: '1F44C-1F3FE.svg' },
    { url: '1F44C-1F3FF.svg' },
    { url: '1F44C.svg' },
    { url: '1F44D-1F3FB.svg' },
    { url: '1F44D-1F3FC.svg' },
    { url: '1F44D-1F3FD.svg' },
    { url: '1F44D-1F3FE.svg' },
    { url: '1F44D-1F3FF.svg' },
    { url: '1F44D.svg' },
    { url: '1F44E-1F3FB.svg' },
    { url: '1F44E-1F3FC.svg' },
    { url: '1F44E-1F3FD.svg' },
    { url: '1F44E-1F3FE.svg' },
    { url: '1F44E-1F3FF.svg' },
    { url: '1F44E.svg' },
    { url: '1F44F-1F3FB.svg' },
    { url: '1F44F-1F3FC.svg' },
    { url: '1F44F-1F3FD.svg' },
    { url: '1F44F-1F3FE.svg' },
    { url: '1F44F-1F3FF.svg' },
    { url: '1F44F.svg' },
    { url: '1F450-1F3FB.svg' },
    { url: '1F450-1F3FC.svg' },
    { url: '1F450-1F3FD.svg' },
    { url: '1F450-1F3FE.svg' },
    { url: '1F450-1F3FF.svg' },
    { url: '1F450.svg' },
    { url: '1F451.svg' },
    { url: '1F452.svg' },
    { url: '1F453.svg' },
    { url: '1F454.svg' },
    { url: '1F455.svg' },
    { url: '1F456.svg' },
    { url: '1F457.svg' },
    { url: '1F458.svg' },
    { url: '1F459.svg' },
    { url: '1F45A.svg' },
    { url: '1F45B.svg' },
    { url: '1F45C.svg' },
    { url: '1F45D.svg' },
    { url: '1F45E.svg' },
    { url: '1F45F.svg' },
    { url: '1F460.svg' },
    { url: '1F461.svg' },
    { url: '1F462.svg' },
    { url: '1F463.svg' },
    { url: '1F464.svg' },
    { url: '1F465.svg' },
    { url: '1F466-1F3FB.svg' },
    { url: '1F466-1F3FC.svg' },
    { url: '1F466-1F3FD.svg' },
    { url: '1F466-1F3FE.svg' },
    { url: '1F466-1F3FF.svg' },
    { url: '1F466.svg' },
    { url: '1F467-1F3FB.svg' },
    { url: '1F467-1F3FC.svg' },
    { url: '1F467-1F3FD.svg' },
    { url: '1F467-1F3FE.svg' },
    { url: '1F467-1F3FF.svg' },
    { url: '1F467.svg' },
    { url: '1F468-1F3FB-200D-1F33E.svg' }
  ],

  Sports: [
    { url: '1F3C2-1F3FB.svg' },
    { url: '1F3C2-1F3FC.svg' },
    { url: '1F3C2-1F3FD.svg' },
    { url: '1F3C2-1F3FE.svg' },
    { url: '1F3C2-1F3FF.svg' },
    { url: '1F3C2.svg' },
    { url: '1F3C3-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F3C3-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F3C3-1F3FB.svg' },
    { url: '1F3C3-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F3C3-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F3C3-1F3FC.svg' },
    { url: '1F3C3-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F3C3-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F3C3-1F3FD.svg' },
    { url: '1F3C3-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F3C3-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F3C3-1F3FE.svg' },
    { url: '1F3C3-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F3C3-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F3C3-1F3FF.svg' },
    { url: '1F3C3-200D-2640-FE0F.svg' },
    { url: '1F3C3-200D-2642-FE0F.svg' },
    { url: '1F3C3.svg' },
    { url: '1F3C4-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F3C4-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F3C4-1F3FB.svg' },
    { url: '1F3C4-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F3C4-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F3C4-1F3FC.svg' },
    { url: '1F3C4-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F3C4-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F3C4-1F3FD.svg' },
    { url: '1F3C4-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F3C4-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F3C4-1F3FE.svg' },
    { url: '1F3C4-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F3C4-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F3C4-1F3FF.svg' },
    { url: '1F3C4-200D-2640-FE0F.svg' },
    { url: '1F3C4-200D-2642-FE0F.svg' },
    { url: '1F3C4.svg' },
    { url: '1F3C5.svg' },
    { url: '1F3C6.svg' },
    { url: '1F3C7-1F3FB.svg' },
    { url: '1F3C7-1F3FC.svg' },
    { url: '1F3C7-1F3FD.svg' },
    { url: '1F3C7-1F3FE.svg' },
    { url: '1F3C7-1F3FF.svg' },
    { url: '1F3C7.svg' },
    { url: '1F3C8.svg' },
    { url: '1F3C9.svg' },
    { url: '1F3CA-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F3CA-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F3CA-1F3FB.svg' },
    { url: '1F3CA-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F3CA-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F3CA-1F3FC.svg' },
    { url: '1F3CA-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F3CA-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F3CA-1F3FD.svg' },
    { url: '1F3CA-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F3CA-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F3CA-1F3FE.svg' },
    { url: '1F3CA-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F3CA-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F3CA-1F3FF.svg' },
    { url: '1F3CA-200D-2640-FE0F.svg' },
    { url: '1F3CA-200D-2642-FE0F.svg' },
    { url: '1F3CA.svg' },
    { url: '1F3CB-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F3CB-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F3CB-1F3FB.svg' },
    { url: '1F3CB-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F3CB-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F3CB-1F3FC.svg' },
    { url: '1F3CB-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F3CB-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F3CB-1F3FD.svg' },
    { url: '1F3CB-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F3CB-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F3CB-1F3FE.svg' },
    { url: '1F3CB-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F3CB-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F3CB-1F3FF.svg' },
    { url: '1F3CB-FE0F-200D-2640-FE0F.svg' },
    { url: '1F3CB-FE0F-200D-2642-FE0F.svg' },
    { url: '1F3CB.svg' },
    { url: '1F3CC-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F3CC-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F3CC-1F3FB.svg' },
    { url: '1F3CC-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F3CC-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F3CC-1F3FC.svg' },
    { url: '1F3CC-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F3CC-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F3CC-1F3FD.svg' },
    { url: '1F3CC-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F3CC-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F3CC-1F3FE.svg' },
    { url: '1F3CC-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F3CC-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F3CC-1F3FF.svg' },
    { url: '1F3CC-FE0F-200D-2640-FE0F.svg' },
    { url: '1F3CC-FE0F-200D-2642-FE0F.svg' },
    { url: '1F3CC.svg' },
    { url: '1F3CD.svg' },
    { url: '1F3CE.svg' },
    { url: '1F3CF.svg' },
    { url: '1F3D0.svg' },
    { url: '1F3D1.svg' },
    { url: '1F3D2.svg' },
    { url: '1F3D3.svg' },
    { url: '1F3D4.svg' },
    { url: '1F3D5.svg' },
    { url: '1F3D6.svg' }
  ],

  Flags: [
    { url: '1F1E6-1F1E8.svg' },
    { url: '1F1E6-1F1E9.svg' },
    { url: '1F1E6-1F1EA.svg' },
    { url: '1F1E6-1F1EB.svg' },
    { url: '1F1E6-1F1EC.svg' },
    { url: '1F1E6-1F1EE.svg' },
    { url: '1F1E6-1F1F1.svg' },
    { url: '1F1E6-1F1F2.svg' },
    { url: '1F1E6-1F1F4.svg' },
    { url: '1F1E6-1F1F6-1F48E.svg' },
    { url: '1F1E6-1F1F6.svg' },
    { url: '1F1E6-1F1F7.svg' },
    { url: '1F1E6-1F1F8.svg' },
    { url: '1F1E6-1F1F9.svg' },
    { url: '1F1E6-1F1FA.svg' },
    { url: '1F1E6-1F1FC.svg' },
    { url: '1F1E6-1F1FD.svg' },
    { url: '1F1E6-1F1FF.svg' },
    { url: '1F1E6.svg' },
    { url: '1F1E7-1F1E6.svg' },
    { url: '1F1E7-1F1E7.svg' },
    { url: '1F1E7-1F1E9.svg' },
    { url: '1F1E7-1F1EA.svg' },
    { url: '1F1E7-1F1EB.svg' },
    { url: '1F1E7-1F1EC.svg' },
    { url: '1F1E7-1F1ED.svg' },
    { url: '1F1E7-1F1EE.svg' },
    { url: '1F1E7-1F1EF.svg' },
    { url: '1F1E7-1F1F1.svg' },
    { url: '1F1E7-1F1F2.svg' },
    { url: '1F1E7-1F1F3.svg' },
    { url: '1F1E7-1F1F4.svg' },
    { url: '1F1E7-1F1F6.svg' },
    { url: '1F1E7-1F1F7.svg' },
    { url: '1F1E7-1F1F8.svg' },
    { url: '1F1E7-1F1F9.svg' },
    { url: '1F1E7-1F1FB.svg' },
    { url: '1F1E7-1F1FC.svg' },
    { url: '1F1E7-1F1FE.svg' },
    { url: '1F1E7-1F1FF.svg' },
    { url: '1F1E7.svg' },
    { url: '1F1E8-1F1E6.svg' },
    { url: '1F1E8-1F1E8.svg' },
    { url: '1F1E8-1F1E9.svg' },
    { url: '1F1E8-1F1EB.svg' },
    { url: '1F1E8-1F1EC.svg' },
    { url: '1F1E8-1F1ED.svg' },
    { url: '1F1E8-1F1EE.svg' },
    { url: '1F1E8-1F1F0.svg' },
    { url: '1F1E8-1F1F1.svg' },
    { url: '1F1E8-1F1F2.svg' },
    { url: '1F1E8-1F1F3.svg' },
    { url: '1F1E8-1F1F4.svg' },
    { url: '1F1E8-1F1F5.svg' },
    { url: '1F1E8-1F1F7.svg' },
    { url: '1F1E8-1F1FA.svg' },
    { url: '1F1E8-1F1FB.svg' },
    { url: '1F1E8-1F1FC.svg' },
    { url: '1F1E8-1F1FD.svg' },
    { url: '1F1E8-1F1FE.svg' },
    { url: '1F1E8-1F1FF.svg' },
    { url: '1F1E8.svg' },
    { url: '1F1E9-1F1EA.svg' },
    { url: '1F1E9-1F1EC.svg' },
    { url: '1F1E9-1F1EF.svg' },
    { url: '1F1E9-1F1F0.svg' },
    { url: '1F1E9-1F1F2.svg' },
    { url: '1F1E9-1F1F4.svg' },
    { url: '1F1E9-1F1FF.svg' },
    { url: '1F1E9.svg' },
    { url: '1F1EA-1F1E6.svg' },
    { url: '1F1EA-1F1E8.svg' },
    { url: '1F1EA-1F1EA.svg' },
    { url: '1F1EA-1F1EC.svg' },
    { url: '1F1EA-1F1ED.svg' },
    { url: '1F1EA-1F1F7.svg' },
    { url: '1F1EA-1F1F8.svg' },
    { url: '1F1EA-1F1F9.svg' },
    { url: '1F1EA-1F1FA.svg' },
    { url: '1F1EA.svg' },
    { url: '1F1EB-1F1EE.svg' },
    { url: '1F1EB-1F1EF.svg' },
    { url: '1F1EB-1F1F0.svg' },
    { url: '1F1EB-1F1F2.svg' },
    { url: '1F1EB-1F1F4.svg' },
    { url: '1F1EB-1F1F7.svg' },
    { url: '1F1EB.svg' },
    { url: '1F1EC-1F1E6.svg' },
    { url: '1F1EC-1F1E7.svg' },
    { url: '1F1EC-1F1E9.svg' },
    { url: '1F1EC-1F1EA.svg' },
    { url: '1F1EC-1F1EB.svg' },
    { url: '1F1EC-1F1EC.svg' },
    { url: '1F1EC-1F1ED.svg' },
    { url: '1F1EC-1F1EE.svg' },
    { url: '1F1EC-1F1F1.svg' },
    { url: '1F1EC-1F1F2.svg' },
    { url: '1F1EC-1F1F3.svg' },
    { url: '1F1EC-1F1F5.svg' },
    { url: '1F1EC-1F1F6.svg' },
    { url: '1F1EC-1F1F7.svg' },
    { url: '1F1EC-1F1F8.svg' },
    { url: '1F1EC-1F1F9.svg' },
    { url: '1F1EC-1F1FA.svg' },
    { url: '1F1EC-1F1FC.svg' },
    { url: '1F1EC-1F1FE.svg' },
    { url: '1F1EC.svg' },
    { url: '1F1ED-1F1F0.svg' },
    { url: '1F1ED-1F1F2.svg' },
    { url: '1F1ED-1F1F3.svg' },
    { url: '1F1ED-1F1F7.svg' },
    { url: '1F1ED-1F1F9.svg' },
    { url: '1F1ED-1F1FA.svg' },
    { url: '1F1ED.svg' },
    { url: '1F1EE-1F1E8.svg' },
    { url: '1F1EE-1F1E9.svg' },
    { url: '1F1EE-1F1EA.svg' },
    { url: '1F1EE-1F1F1.svg' },
    { url: '1F1EE-1F1F2.svg' },
    { url: '1F1EE-1F1F3.svg' },
    { url: '1F1EE-1F1F4.svg' },
    { url: '1F1EE-1F1F6.svg' },
    { url: '1F1EE-1F1F7.svg' },
    { url: '1F1EE-1F1F8.svg' },
    { url: '1F1EE-1F1F9.svg' },
    { url: '1F1EE.svg' },
    { url: '1F1EF-1F1EA.svg' },
    { url: '1F1EF-1F1F2.svg' },
    { url: '1F1EF-1F1F4.svg' },
    { url: '1F1EF-1F1F5.svg' },
    { url: '1F1EF.svg' },
    { url: '1F1F0-1F1EA.svg' },
    { url: '1F1F0-1F1EC.svg' },
    { url: '1F1F0-1F1ED.svg' },
    { url: '1F1F0-1F1EE.svg' },
    { url: '1F1F0-1F1F2.svg' },
    { url: '1F1F0-1F1F3.svg' },
    { url: '1F1F0-1F1F5.svg' },
    { url: '1F1F0-1F1F7.svg' },
    { url: '1F1F0-1F1FC.svg' },
    { url: '1F1F0-1F1FE.svg' },
    { url: '1F1F0-1F1FF.svg' },
    { url: '1F1F0.svg' },
    { url: '1F1F1-1F1E6.svg' },
    { url: '1F1F1-1F1E7.svg' },
    { url: '1F1F1-1F1E8.svg' },
    { url: '1F1F1-1F1EE.svg' },
    { url: '1F1F1-1F1F0.svg' },
    { url: '1F1F1-1F1F7.svg' },
    { url: '1F1F1-1F1F8.svg' },
    { url: '1F1F1-1F1F9.svg' },
    { url: '1F1F1-1F1FA.svg' },
    { url: '1F1F1-1F1FB.svg' },
    { url: '1F1F1-1F1FE.svg' },
    { url: '1F1F1.svg' },
    { url: '1F1F2-1F1E6.svg' },
    { url: '1F1F2-1F1E8.svg' },
    { url: '1F1F2-1F1E9.svg' },
    { url: '1F1F2-1F1EA.svg' },
    { url: '1F1F2-1F1EB.svg' },
    { url: '1F1F2-1F1EC.svg' },
    { url: '1F1F2-1F1ED.svg' },
    { url: '1F1F2-1F1F0.svg' },
    { url: '1F1F2-1F1F1.svg' },
    { url: '1F1F2-1F1F2.svg' },
    { url: '1F1F2-1F1F3.svg' },
    { url: '1F1F2-1F1F4.svg' },
    { url: '1F1F2-1F1F5.svg' },
    { url: '1F1F2-1F1F6.svg' },
    { url: '1F1F2-1F1F7.svg' },
    { url: '1F1F2-1F1F8.svg' },
    { url: '1F1F2-1F1F9.svg' },
    { url: '1F1F2-1F1FA.svg' },
    { url: '1F1F2-1F1FB.svg' },
    { url: '1F1F2-1F1FC.svg' },
    { url: '1F1F2-1F1FD.svg' },
    { url: '1F1F2-1F1FE.svg' },
    { url: '1F1F2-1F1FF.svg' },
    { url: '1F1F2.svg' },
    { url: '1F1F3-1F1E6.svg' },
    { url: '1F1F3-1F1E8.svg' },
    { url: '1F1F3-1F1EA.svg' },
    { url: '1F1F3-1F1EB.svg' },
    { url: '1F1F3-1F1EC.svg' },
    { url: '1F1F3-1F1EE.svg' },
    { url: '1F1F3-1F1F1.svg' },
    { url: '1F1F3-1F1F4.svg' },
    { url: '1F1F3-1F1F5.svg' },
    { url: '1F1F3-1F1F7.svg' },
    { url: '1F1F3-1F1FA.svg' },
    { url: '1F1F3-1F1FF.svg' },
    { url: '1F1F3.svg' },
    { url: '1F1F4-1F1F2.svg' },
    { url: '1F1F4.svg' },
    { url: '1F1F5-1F1E6.svg' },
    { url: '1F1F5-1F1EA.svg' },
    { url: '1F1F5-1F1EB.svg' },
    { url: '1F1F5-1F1EC.svg' },
    { url: '1F1F5-1F1ED.svg' },
    { url: '1F1F5-1F1F0.svg' },
    { url: '1F1F5-1F1F1.svg' },
    { url: '1F1F5-1F1F2.svg' },
    { url: '1F1F5-1F1F3.svg' },
    { url: '1F1F5-1F1F7.svg' },
    { url: '1F1F5-1F1F8.svg' },
    { url: '1F1F5-1F1F9.svg' },
    { url: '1F1F5-1F1FC.svg' },
    { url: '1F1F5-1F1FE.svg' },
    { url: '1F1F5.svg' },
    { url: '1F1F6-1F1E6.svg' },
    { url: '1F1F6.svg' },
    { url: '1F1F7-1F1EA.svg' },
    { url: '1F1F7-1F1F4.svg' },
    { url: '1F1F7-1F1F8.svg' },
    { url: '1F1F7-1F1FA.svg' },
    { url: '1F1F7-1F1FC.svg' },
    { url: '1F1F7.svg' },
    { url: '1F1F8-1F1E6.svg' },
    { url: '1F1F8-1F1E7.svg' },
    { url: '1F1F8-1F1E8.svg' },
    { url: '1F1F8-1F1E9.svg' },
    { url: '1F1F8-1F1EA.svg' },
    { url: '1F1F8-1F1EC.svg' },
    { url: '1F1F8-1F1ED.svg' },
    { url: '1F1F8-1F1EE.svg' },
    { url: '1F1F8-1F1EF.svg' },
    { url: '1F1F8-1F1F0.svg' },
    { url: '1F1F8-1F1F1.svg' },
    { url: '1F1F8-1F1F2.svg' },
    { url: '1F1F8-1F1F3.svg' },
    { url: '1F1F8-1F1F4.svg' },
    { url: '1F1F8-1F1F7.svg' },
    { url: '1F1F8-1F1F8.svg' },
    { url: '1F1F8-1F1F9.svg' },
    { url: '1F1F8-1F1FB.svg' },
    { url: '1F1F8-1F1FD.svg' },
    { url: '1F1F8-1F1FE.svg' },
    { url: '1F1F8-1F1FF.svg' },
    { url: '1F1F8.svg' },
    { url: '1F1F9-1F1E6.svg' },
    { url: '1F1F9-1F1E8.svg' },
    { url: '1F1F9-1F1E9.svg' },
    { url: '1F1F9-1F1EB.svg' },
    { url: '1F1F9-1F1EC.svg' },
    { url: '1F1F9-1F1ED.svg' },
    { url: '1F1F9-1F1EF.svg' },
    { url: '1F1F9-1F1F0.svg' },
    { url: '1F1F9-1F1F1.svg' },
    { url: '1F1F9-1F1F2.svg' },
    { url: '1F1F9-1F1F3.svg' },
    { url: '1F1F9-1F1F4.svg' },
    { url: '1F1F9-1F1F7.svg' },
    { url: '1F1F9-1F1F9.svg' },
    { url: '1F1F9-1F1FB.svg' },
    { url: '1F1F9-1F1FC.svg' },
    { url: '1F1F9-1F1FF.svg' },
    { url: '1F1F9.svg' },
    { url: '1F1FA-1F1E6.svg' },
    { url: '1F1FA-1F1EC.svg' },
    { url: '1F1FA-1F1F2.svg' },
    { url: '1F1FA-1F1F3.svg' },
    { url: '1F1FA-1F1F8.svg' },
    { url: '1F1FA-1F1FE.svg' },
    { url: '1F1FA-1F1FF.svg' },
    { url: '1F1FA.svg' },
    { url: '1F1FB-1F1E6.svg' },
    { url: '1F1FB-1F1E8.svg' },
    { url: '1F1FB-1F1EA.svg' },
    { url: '1F1FB-1F1EC.svg' },
    { url: '1F1FB-1F1EE.svg' },
    { url: '1F1FB-1F1F3.svg' },
    { url: '1F1FB-1F1FA.svg' },
    { url: '1F1FB.svg' },
    { url: '1F1FC-1F1EB.svg' },
    { url: '1F1FC-1F1F8.svg' },
    { url: '1F1FC.svg' },
    { url: '1F1FD-1F1F0.svg' },
    { url: '1F1FD.svg' },
    { url: '1F1FE-1F1EA.svg' },
    { url: '1F1FE-1F1F9.svg' },
    { url: '1F1FE.svg' },
    { url: '1F1FF-1F1E6.svg' },
    { url: '1F1FF-1F1F2.svg' },
    { url: '1F1FF-1F1FC.svg' }
  ],

  Signs: [
    { url: '1FAF0-1F3FB.svg' },
    { url: '1FAF0-1F3FC.svg' },
    { url: '1FAF0-1F3FD.svg' },
    { url: '1FAF0-1F3FE.svg' },
    { url: '1FAF0-1F3FF.svg' },
    { url: '1FAF0.svg' },
    { url: '1FAF1-1F3FB-200D-1FAF2-1F3FC.svg' },
    { url: '1FAF1-1F3FB-200D-1FAF2-1F3FD.svg' },
    { url: '1FAF1-1F3FB-200D-1FAF2-1F3FE.svg' },
    { url: '1FAF1-1F3FB-200D-1FAF2-1F3FF.svg' },
    { url: '1FAF1-1F3FB.svg' },
    { url: '1FAF1-1F3FC-200D-1FAF2-1F3FB.svg' },
    { url: '1FAF1-1F3FC-200D-1FAF2-1F3FD.svg' },
    { url: '1FAF1-1F3FC-200D-1FAF2-1F3FE.svg' },
    { url: '1FAF1-1F3FC-200D-1FAF2-1F3FF.svg' },
    { url: '1FAF1-1F3FC.svg' },
    { url: '1FAF1-1F3FD-200D-1FAF2-1F3FB.svg' },
    { url: '1FAF1-1F3FD-200D-1FAF2-1F3FC.svg' },
    { url: '1FAF1-1F3FD-200D-1FAF2-1F3FE.svg' },
    { url: '1FAF1-1F3FD-200D-1FAF2-1F3FF.svg' },
    { url: '1FAF1-1F3FD.svg' },
    { url: '1FAF1-1F3FE-200D-1FAF2-1F3FB.svg' },
    { url: '1FAF1-1F3FE-200D-1FAF2-1F3FC.svg' },
    { url: '1FAF1-1F3FE-200D-1FAF2-1F3FD.svg' },
    { url: '1FAF1-1F3FE-200D-1FAF2-1F3FF.svg' },
    { url: '1FAF1-1F3FE.svg' },
    { url: '1FAF1-1F3FF-200D-1FAF2-1F3FB.svg' },
    { url: '1FAF1-1F3FF-200D-1FAF2-1F3FC.svg' },
    { url: '1FAF1-1F3FF-200D-1FAF2-1F3FD.svg' },
    { url: '1FAF1-1F3FF-200D-1FAF2-1F3FE.svg' },
    { url: '1FAF1-1F3FF.svg' },
    { url: '1FAF1.svg' },
    { url: '1FAF2-1F3FB.svg' },
    { url: '1FAF2-1F3FC.svg' },
    { url: '1FAF2-1F3FD.svg' },
    { url: '1FAF2-1F3FE.svg' },
    { url: '1FAF2-1F3FF.svg' },
    { url: '1FAF2.svg' },
    { url: '1FAF3-1F3FB.svg' },
    { url: '1FAF3-1F3FC.svg' },
    { url: '1FAF3-1F3FD.svg' },
    { url: '1FAF3-1F3FE.svg' },
    { url: '1FAF3-1F3FF.svg' },
    { url: '1FAF3.svg' },
    { url: '1FAF4-1F3FB.svg' },
    { url: '1FAF4-1F3FC.svg' },
    { url: '1FAF4-1F3FD.svg' },
    { url: '1FAF4-1F3FE.svg' },
    { url: '1FAF4-1F3FF.svg' },
    { url: '1FAF4.svg' },
    { url: '1FAF5-1F3FB.svg' },
    { url: '1FAF5-1F3FC.svg' },
    { url: '1FAF5-1F3FD.svg' },
    { url: '1FAF5-1F3FE.svg' },
    { url: '1FAF5-1F3FF.svg' },
    { url: '1FAF5.svg' },
    { url: '1FAF6-1F3FB.svg' },
    { url: '1FAF6-1F3FC.svg' },
    { url: '1FAF6-1F3FD.svg' },
    { url: '1FAF6-1F3FE.svg' },
    { url: '1FAF6-1F3FF.svg' },
    { url: '1FAF6.svg' },
    { url: '1FBC5.svg' },
    { url: '1FBC6-200D-1F457.svg' },
    { url: '1FBC6.svg' },
    { url: '1FBC7-200D-1F457.svg' },
    { url: '1FBC7.svg' },
    { url: '1FBC8-200D-1F457.svg' },
    { url: '1FBC8.svg' },
    { url: '1FBC9.svg' }
  ],

  Nature: [
    { url: '1F303.svg' },
    { url: '1F304.svg' },
    { url: '1F305.svg' },
    { url: '1F306.svg' },
    { url: '1F307.svg' },
    { url: '1F308.svg' },
    { url: '1F309.svg' },
    { url: '1F30A.svg' },
    { url: '1F30B.svg' },
    { url: '1F30C.svg' },
    { url: '1F30D.svg' },
    { url: '1F30E.svg' },
    { url: '1F30F.svg' },
    { url: '1F310.svg' },
    { url: '1F311.svg' },
    { url: '1F312.svg' },
    { url: '1F313.svg' },
    { url: '1F314.svg' },
    { url: '1F315.svg' },
    { url: '1F316.svg' },
    { url: '1F317.svg' },
    { url: '1F318.svg' },
    { url: '1F319.svg' },
    { url: '1F31A.svg' },
    { url: '1F31B.svg' },
    { url: '1F31C.svg' },
    { url: '1F31D.svg' },
    { url: '1F31E.svg' },
    { url: '1F31F.svg' },
    { url: '1F320.svg' },
    { url: '1F321.svg' },
    { url: '1F324.svg' },
    { url: '1F325.svg' },
    { url: '1F326.svg' },
    { url: '1F327.svg' },
    { url: '1F328.svg' },
    { url: '1F329.svg' },
    { url: '1F32A.svg' },
    { url: '1F32B.svg' },
    { url: '1F32C.svg' },
    { url: '1F32D.svg' },
    { url: '1F32E.svg' },
    { url: '1F32F.svg' },
    { url: '1F330.svg' },
    { url: '1F331.svg' },
    { url: '1F332.svg' },
    { url: '1F333.svg' },
    { url: '1F334.svg' },
    { url: '1F335.svg' },
    { url: '1F336.svg' },
    { url: '1F337.svg' },
    { url: '1F338.svg' },
    { url: '1F339.svg' },
    { url: '1F33A.svg' },
    { url: '1F33B.svg' },
    { url: '1F33C.svg' },
    { url: '1F33D.svg' },
    { url: '1F33E.svg' },
    { url: '1F33F.svg' },
    { url: '1F340.svg' },
    { url: '1F341.svg' },
    { url: '1F342.svg' },
    { url: '1F343.svg' },
    { url: '1F344.svg' },
    { url: '1F345.svg' },
    { url: '1F346.svg' },
    { url: '1F347.svg' },
    { url: '1F348.svg' },
    { url: '1F349.svg' },
    { url: '1F34A.svg' },
    { url: '1F34B.svg' },
    { url: '1F34C.svg' },
    { url: '1F34D.svg' },
    { url: '1F34E.svg' },
    { url: '1F34F.svg' },
    { url: '1F350.svg' },
    { url: '1F351.svg' },
    { url: '1F352.svg' },
    { url: '1F353.svg' },
    { url: '1F354.svg' },
    { url: '1F355.svg' },
    { url: '1F356.svg' },
    { url: '1F357.svg' },
    { url: '1F358.svg' },
    { url: '1F359.svg' },
    { url: '1F35A.svg' },
    { url: '1F35B.svg' },
    { url: '1F35C.svg' },
    { url: '1F35D.svg' },
    { url: '1F35E.svg' },
    { url: '1F35F.svg' },
    { url: '1F360.svg' },
    { url: '1F361.svg' },
    { url: '1F362.svg' },
    { url: '1F363.svg' },
    { url: '1F364.svg' },
    { url: '1F365.svg' },
    { url: '1F366.svg' },
    { url: '1F367.svg' },
    { url: '1F368.svg' },
    { url: '1F369.svg' },
    { url: '1F36A.svg' },
    { url: '1F36B.svg' },
    { url: '1F36C.svg' },
    { url: '1F36D.svg' },
    { url: '1F36E.svg' },
    { url: '1F36F.svg' },
    { url: '1F370.svg' },
    { url: '1F371.svg' },
    { url: '1F372.svg' },
    { url: '1F373.svg' },
    { url: '1F374.svg' },
    { url: '1F375.svg' },
    { url: '1F376.svg' },
    { url: '1F377.svg' },
    { url: '1F378.svg' },
    { url: '1F379.svg' },
    { url: '1F37A.svg' },
    { url: '1F37B.svg' },
    { url: '1F37C.svg' },
    { url: '1F37D.svg' },
    { url: '1F37E.svg' },
    { url: '1F37F.svg' },
    { url: '1F380.svg' },
    { url: '1F381.svg' },
    { url: '1F382.svg' },
    { url: '1F383.svg' },
    { url: '1F384.svg' },
    { url: '1F385-1F3FB.svg' },
    { url: '1F385-1F3FC.svg' },
    { url: '1F385-1F3FD.svg' },
    { url: '1F385-1F3FE.svg' },
    { url: '1F385-1F3FF.svg' },
    { url: '1F385.svg' },
    { url: '1F386.svg' },
    { url: '1F387.svg' },
    { url: '1F388.svg' },
    { url: '1F389.svg' },
    { url: '1F38A.svg' },
    { url: '1F38B.svg' },
    { url: '1F38C.svg' },
    { url: '1F38D.svg' },
    { url: '1F38E.svg' },
    { url: '1F38F.svg' },
    { url: '1F390.svg' },
    { url: '1F391.svg' },
    { url: '1F392.svg' },
    { url: '1F393.svg' },
    { url: '1F396.svg' },
    { url: '1F397.svg' },
    { url: '1F399.svg' }
  ],

  Travel: [
    { url: '1F93A.svg' },
    { url: '1F93C-200D-2640-FE0F.svg' },
    { url: '1F93C-200D-2642-FE0F.svg' },
    { url: '1F93C.svg' },
    { url: '1F93D-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F93D-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F93D-1F3FB.svg' },
    { url: '1F93D-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F93D-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F93D-1F3FC.svg' },
    { url: '1F93D-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F93D-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F93D-1F3FD.svg' },
    { url: '1F93D-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F93D-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F93D-1F3FE.svg' },
    { url: '1F93D-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F93D-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F93D-1F3FF.svg' },
    { url: '1F93D-200D-2640-FE0F.svg' },
    { url: '1F93D-200D-2642-FE0F.svg' },
    { url: '1F93D.svg' },
    { url: '1F93E-1F3FB-200D-2640-FE0F.svg' },
    { url: '1F93E-1F3FB-200D-2642-FE0F.svg' },
    { url: '1F93E-1F3FB.svg' },
    { url: '1F93E-1F3FC-200D-2640-FE0F.svg' },
    { url: '1F93E-1F3FC-200D-2642-FE0F.svg' },
    { url: '1F93E-1F3FC.svg' },
    { url: '1F93E-1F3FD-200D-2640-FE0F.svg' },
    { url: '1F93E-1F3FD-200D-2642-FE0F.svg' },
    { url: '1F93E-1F3FD.svg' },
    { url: '1F93E-1F3FE-200D-2640-FE0F.svg' },
    { url: '1F93E-1F3FE-200D-2642-FE0F.svg' },
    { url: '1F93E-1F3FE.svg' },
    { url: '1F93E-1F3FF-200D-2640-FE0F.svg' },
    { url: '1F93E-1F3FF-200D-2642-FE0F.svg' },
    { url: '1F93E-1F3FF.svg' },
    { url: '1F93E-200D-2640-FE0F.svg' },
    { url: '1F93E-200D-2642-FE0F.svg' },
    { url: '1F93E.svg' },
    { url: '1F93F.svg' },
    { url: '1F940.svg' },
    { url: '1F941.svg' },
    { url: '1F942.svg' },
    { url: '1F943.svg' },
    { url: '1F944.svg' },
    { url: '1F945.svg' },
    { url: '1F947.svg' },
    { url: '1F948.svg' },
    { url: '1F949.svg' },
    { url: '1F94A.svg' },
    { url: '1F94B.svg' },
    { url: '1F94C.svg' },
    { url: '1F94D.svg' },
    { url: '1F94E.svg' },
    { url: '1F94F.svg' },
    { url: '1F950.svg' },
    { url: '1F951.svg' },
    { url: '1F952.svg' },
    { url: '1F953.svg' },
    { url: '1F954.svg' },
    { url: '1F955.svg' },
    { url: '1F956.svg' },
    { url: '1F957.svg' },
    { url: '1F958.svg' },
    { url: '1F959.svg' },
    { url: '1F95A.svg' },
    { url: '1F95B.svg' },
    { url: '1F95C.svg' },
    { url: '1F95D.svg' },
    { url: '1F95E.svg' },
    { url: '1F95F.svg' },
    { url: '1F960.svg' },
    { url: '1F961.svg' },
    { url: '1F962.svg' },
    { url: '1F963.svg' },
    { url: '1F964.svg' },
    { url: '1F965.svg' },
    { url: '1F966.svg' },
    { url: '1F967.svg' },
    { url: '1F968.svg' },
    { url: '1F969.svg' },
    { url: '1F96A.svg' },
    { url: '1F96B.svg' },
    { url: '1F96C.svg' },
    { url: '1F96D.svg' },
    { url: '1F96E.svg' },
    { url: '1F96F.svg' },
    { url: '1F970.svg' },
    { url: '1F971.svg' },
    { url: '1F972.svg' },
    { url: '1F973.svg' },
    { url: '1F974.svg' },
    { url: '1F975.svg' },
    { url: '1F976.svg' },
    { url: '1F977-1F3FB.svg' },
    { url: '1F977-1F3FC.svg' },
    { url: '1F977-1F3FD.svg' },
    { url: '1F977-1F3FE.svg' },
    { url: '1F977-1F3FF.svg' },
    { url: '1F977.svg' },
    { url: '1F978.svg' },
    { url: '1F979.svg' },
    { url: '1F97A.svg' },
    { url: '1F97B.svg' },
    { url: '1F97C.svg' },
    { url: '1F97D.svg' },
    { url: '1F97E.svg' },
    { url: '1F97F.svg' },
    { url: '1F980.svg' },
    { url: '1F981.svg' },
    { url: '1F982.svg' },
    { url: '1F983.svg' },
    { url: '1F984.svg' },
    { url: '1F985.svg' },
    { url: '1F986.svg' },
    { url: '1F987.svg' },
    { url: '1F988.svg' },
    { url: '1F989.svg' },
    { url: '1F98A.svg' },
    { url: '1F98B.svg' },
    { url: '1F98C.svg' },
    { url: '1F98D.svg' },
    { url: '1F98E.svg' },
    { url: '1F98F.svg' },
    { url: '1F990.svg' },
    { url: '1F991.svg' },
    { url: '1F992.svg' },
    { url: '1F993.svg' },
    { url: '1F994.svg' },
    { url: '1F995.svg' },
    { url: '1F996.svg' },
    { url: '1F997.svg' },
    { url: '1F998.svg' },
    { url: '1F999.svg' },
    { url: '1F99A.svg' },
    { url: '1F99B.svg' },
    { url: '1F99C.svg' },
    { url: '1F99D.svg' },
    { url: '1F99E.svg' },
    { url: '1F99F.svg' },
    { url: '1F9A0.svg' },
    { url: '1F9A1.svg' },
    { url: '1F9A2.svg' },
    { url: '1F9A3.svg' }
  ],

  Letters: [
    { url: '1F1E6.svg' },
    { url: '1F1E7-1F1E6.svg' },
    { url: '1F1E7-1F1E7.svg' },
    { url: '1F1E7-1F1E9.svg' },
    { url: '1F1E7-1F1EA.svg' },
    { url: '1F1E7-1F1EB.svg' },
    { url: '1F1E7-1F1EC.svg' },
    { url: '1F1E7-1F1ED.svg' },
    { url: '1F1E7-1F1EE.svg' },
    { url: '1F1E7-1F1EF.svg' },
    { url: '1F1E7-1F1F1.svg' },
    { url: '1F1E7-1F1F2.svg' },
    { url: '1F1E7-1F1F3.svg' },
    { url: '1F1E7-1F1F4.svg' },
    { url: '1F1E7-1F1F6.svg' },
    { url: '1F1E7-1F1F7.svg' },
    { url: '1F1E7-1F1F8.svg' },
    { url: '1F1E7-1F1F9.svg' },
    { url: '1F1E7-1F1FB.svg' },
    { url: '1F1E7-1F1FC.svg' },
    { url: '1F1E7-1F1FE.svg' },
    { url: '1F1E7-1F1FF.svg' },
    { url: '1F1E7.svg' },
    { url: '1F1E8-1F1E6.svg' },
    { url: '1F1E8-1F1E8.svg' },
    { url: '1F1E8-1F1E9.svg' },
    { url: '1F1E8-1F1EB.svg' },
    { url: '1F1E8-1F1EC.svg' },
    { url: '1F1E8-1F1ED.svg' },
    { url: '1F1E8-1F1EE.svg' },
    { url: '1F1E8-1F1F0.svg' },
    { url: '1F1E8-1F1F1.svg' },
    { url: '1F1E8-1F1F2.svg' },
    { url: '1F1E8-1F1F3.svg' },
    { url: '1F1E8-1F1F4.svg' },
    { url: '1F1E8-1F1F5.svg' },
    { url: '1F1E8-1F1F7.svg' },
    { url: '1F1E8-1F1FA.svg' },
    { url: '1F1E8-1F1FB.svg' },
    { url: '1F1E8-1F1FC.svg' },
    { url: '1F1E8-1F1FD.svg' },
    { url: '1F1E8-1F1FE.svg' },
    { url: '1F1E8-1F1FF.svg' },
    { url: '1F1E8.svg' },
    { url: '1F1E9-1F1EA.svg' },
    { url: '1F1E9-1F1EC.svg' },
    { url: '1F1E9-1F1EF.svg' },
    { url: '1F1E9-1F1F0.svg' },
    { url: '1F1E9-1F1F2.svg' },
    { url: '1F1E9-1F1F4.svg' },
    { url: '1F1E9-1F1FF.svg' },
    { url: '1F1E9.svg' },
    { url: '1F1EA-1F1E6.svg' },
    { url: '1F1EA-1F1E8.svg' },
    { url: '1F1EA-1F1EA.svg' },
    { url: '1F1EA-1F1EC.svg' },
    { url: '1F1EA-1F1ED.svg' },
    { url: '1F1EA-1F1F7.svg' },
    { url: '1F1EA-1F1F8.svg' },
    { url: '1F1EA-1F1F9.svg' },
    { url: '1F1EA-1F1FA.svg' },
    { url: '1F1EA.svg' },
    { url: '1F1EB-1F1EE.svg' },
    { url: '1F1EB-1F1EF.svg' },
    { url: '1F1EB-1F1F0.svg' },
    { url: '1F1EB-1F1F2.svg' },
    { url: '1F1EB-1F1F4.svg' },
    { url: '1F1EB-1F1F7.svg' },
    { url: '1F1EB.svg' },
    { url: '1F1EC-1F1E6.svg' },
    { url: '1F1EC-1F1E7.svg' },
    { url: '1F1EC-1F1E9.svg' },
    { url: '1F1EC-1F1EA.svg' },
    { url: '1F1EC-1F1EB.svg' },
    { url: '1F1EC-1F1EC.svg' },
    { url: '1F1EC-1F1ED.svg' },
    { url: '1F1EC-1F1EE.svg' },
    { url: '1F1EC-1F1F1.svg' },
    { url: '1F1EC-1F1F2.svg' },
    { url: '1F1EC-1F1F3.svg' },
    { url: '1F1EC-1F1F5.svg' },
    { url: '1F1EC-1F1F6.svg' },
    { url: '1F1EC-1F1F7.svg' },
    { url: '1F1EC-1F1F8.svg' },
    { url: '1F1EC-1F1F9.svg' },
    { url: '1F1EC-1F1FA.svg' },
    { url: '1F1EC-1F1FC.svg' },
    { url: '1F1EC-1F1FE.svg' },
    { url: '1F1EC.svg' },
    { url: '1F1ED-1F1F0.svg' },
    { url: '1F1ED-1F1F2.svg' },
    { url: '1F1ED-1F1F3.svg' },
    { url: '1F1ED-1F1F7.svg' },
    { url: '1F1ED-1F1F9.svg' },
    { url: '1F1ED-1F1FA.svg' },
    { url: '1F1ED.svg' },
    { url: '1F1EE-1F1E8.svg' },
    { url: '1F1EE-1F1E9.svg' },
    { url: '1F1EE-1F1EA.svg' },
    { url: '1F1EE-1F1F1.svg' },
    { url: '1F1EE-1F1F2.svg' },
    { url: '1F1EE-1F1F3.svg' },
    { url: '1F1EE-1F1F4.svg' },
    { url: '1F1EE-1F1F6.svg' },
    { url: '1F1EE-1F1F7.svg' },
    { url: '1F1EE-1F1F8.svg' },
    { url: '1F1EE-1F1F9.svg' },
    { url: '1F1EE.svg' },
    { url: '1F1EF-1F1EA.svg' },
    { url: '1F1EF-1F1F2.svg' },
    { url: '1F1EF-1F1F4.svg' },
    { url: '1F1EF-1F1F5.svg' },
    { url: '1F1EF.svg' },
    { url: '1F1F0-1F1EA.svg' },
    { url: '1F1F0-1F1EC.svg' },
    { url: '1F1F0-1F1ED.svg' },
    { url: '1F1F0-1F1EE.svg' },
    { url: '1F1F0-1F1F2.svg' },
    { url: '1F1F0-1F1F3.svg' },
    { url: '1F1F0-1F1F5.svg' },
    { url: '1F1F0-1F1F7.svg' },
    { url: '1F1F0-1F1FC.svg' },
    { url: '1F1F0-1F1FE.svg' },
    { url: '1F1F0-1F1FF.svg' },
    { url: '1F1F0.svg' },
    { url: '1F1F1-1F1E6.svg' },
    { url: '1F1F1-1F1E7.svg' },
    { url: '1F1F1-1F1E8.svg' },
    { url: '1F1F1-1F1EE.svg' },
    { url: '1F1F1-1F1F0.svg' },
    { url: '1F1F1-1F1F7.svg' },
    { url: '1F1F1-1F1F8.svg' },
    { url: '1F1F1-1F1F9.svg' },
    { url: '1F1F1-1F1FA.svg' },
    { url: '1F1F1-1F1FB.svg' },
    { url: '1F1F1-1F1FE.svg' },
    { url: '1F1F1.svg' },
    { url: '1F1F2-1F1E6.svg' },
    { url: '1F1F2-1F1E8.svg' },
    { url: '1F1F2-1F1E9.svg' },
    { url: '1F1F2-1F1EA.svg' },
    { url: '1F1F2-1F1EB.svg' },
    { url: '1F1F2-1F1EC.svg' },
    { url: '1F1F2-1F1ED.svg' },
    { url: '1F1F2-1F1F0.svg' },
    { url: '1F1F2-1F1F1.svg' },
    { url: '1F1F2-1F1F2.svg' },
    { url: '1F1F2-1F1F3.svg' },
    { url: '1F1F2-1F1F4.svg' },
    { url: '1F1F2-1F1F5.svg' },
    { url: '1F1F2-1F1F6.svg' },
    { url: '1F1F2-1F1F7.svg' },
    { url: '1F1F2-1F1F8.svg' },
    { url: '1F1F2-1F1F9.svg' },
    { url: '1F1F2-1F1FA.svg' },
    { url: '1F1F2-1F1FB.svg' },
    { url: '1F1F2-1F1FC.svg' },
    { url: '1F1F2-1F1FD.svg' },
    { url: '1F1F2-1F1FE.svg' },
    { url: '1F1F2-1F1FF.svg' },
    { url: '1F1F2.svg' },
    { url: '1F1F3-1F1E6.svg' },
    { url: '1F1F3-1F1E8.svg' },
    { url: '1F1F3-1F1EA.svg' },
    { url: '1F1F3-1F1EB.svg' },
    { url: '1F1F3-1F1EC.svg' },
    { url: '1F1F3-1F1EE.svg' },
    { url: '1F1F3-1F1F1.svg' },
    { url: '1F1F3-1F1F4.svg' },
    { url: '1F1F3-1F1F5.svg' },
    { url: '1F1F3-1F1F7.svg' },
    { url: '1F1F3-1F1FA.svg' },
    { url: '1F1F3-1F1FF.svg' },
    { url: '1F1F3.svg' },
    { url: '1F1F4-1F1F2.svg' },
    { url: '1F1F4.svg' },
    { url: '1F1F5-1F1E6.svg' },
    { url: '1F1F5-1F1EA.svg' },
    { url: '1F1F5-1F1EB.svg' },
    { url: '1F1F5-1F1EC.svg' },
    { url: '1F1F5-1F1ED.svg' },
    { url: '1F1F5-1F1F0.svg' },
    { url: '1F1F5-1F1F1.svg' },
    { url: '1F1F5-1F1F2.svg' },
    { url: '1F1F5-1F1F3.svg' },
    { url: '1F1F5-1F1F7.svg' },
    { url: '1F1F5-1F1F8.svg' },
    { url: '1F1F5-1F1F9.svg' },
    { url: '1F1F5-1F1FC.svg' },
    { url: '1F1F5-1F1FE.svg' },
    { url: '1F1F5.svg' },
    { url: '1F1F6-1F1E6.svg' },
    { url: '1F1F6.svg' },
    { url: '1F1F7-1F1EA.svg' },
    { url: '1F1F7-1F1F4.svg' },
    { url: '1F1F7-1F1F8.svg' },
    { url: '1F1F7-1F1FA.svg' },
    { url: '1F1F7-1F1FC.svg' },
    { url: '1F1F7.svg' },
    { url: '1F1F8-1F1E6.svg' },
    { url: '1F1F8-1F1E7.svg' },
    { url: '1F1F8-1F1E8.svg' },
    { url: '1F1F8-1F1E9.svg' },
    { url: '1F1F8-1F1EA.svg' },
    { url: '1F1F8-1F1EC.svg' },
    { url: '1F1F8-1F1ED.svg' },
    { url: '1F1F8-1F1EE.svg' },
    { url: '1F1F8-1F1EF.svg' },
    { url: '1F1F8-1F1F0.svg' },
    { url: '1F1F8-1F1F1.svg' },
    { url: '1F1F8-1F1F2.svg' },
    { url: '1F1F8-1F1F3.svg' },
    { url: '1F1F8-1F1F4.svg' },
    { url: '1F1F8-1F1F7.svg' },
    { url: '1F1F8-1F1F8.svg' },
    { url: '1F1F8-1F1F9.svg' },
    { url: '1F1F8-1F1FB.svg' },
    { url: '1F1F8-1F1FD.svg' },
    { url: '1F1F8-1F1FE.svg' },
    { url: '1F1F8-1F1FF.svg' },
    { url: '1F1F8.svg' },
    { url: '1F1F9-1F1E6.svg' },
    { url: '1F1F9-1F1E8.svg' },
    { url: '1F1F9-1F1E9.svg' },
    { url: '1F1F9-1F1EB.svg' },
    { url: '1F1F9-1F1EC.svg' },
    { url: '1F1F9-1F1ED.svg' },
    { url: '1F1F9-1F1EF.svg' },
    { url: '1F1F9-1F1F0.svg' },
    { url: '1F1F9-1F1F1.svg' },
    { url: '1F1F9-1F1F2.svg' },
    { url: '1F1F9-1F1F3.svg' },
    { url: '1F1F9-1F1F4.svg' },
    { url: '1F1F9-1F1F7.svg' },
    { url: '1F1F9-1F1F9.svg' },
    { url: '1F1F9-1F1FB.svg' },
    { url: '1F1F9-1F1FC.svg' },
    { url: '1F1F9-1F1FF.svg' },
    { url: '1F1F9.svg' },
    { url: '1F1FA-1F1E6.svg' },
    { url: '1F1FA-1F1EC.svg' },
    { url: '1F1FA-1F1F2.svg' },
    { url: '1F1FA-1F1F3.svg' },
    { url: '1F1FA-1F1F8.svg' },
    { url: '1F1FA-1F1FE.svg' },
    { url: '1F1FA-1F1FF.svg' },
    { url: '1F1FA.svg' },
    { url: '1F1FB-1F1E6.svg' },
    { url: '1F1FB-1F1E8.svg' },
    { url: '1F1FB-1F1EA.svg' },
    { url: '1F1FB-1F1EC.svg' },
    { url: '1F1FB-1F1EE.svg' },
    { url: '1F1FB-1F1F3.svg' },
    { url: '1F1FB-1F1FA.svg' },
    { url: '1F1FB.svg' },
    { url: '1F1FC-1F1EB.svg' },
    { url: '1F1FC-1F1F8.svg' },
    { url: '1F1FC.svg' },
    { url: '1F1FD-1F1F0.svg' },
    { url: '1F1FD.svg' },
    { url: '1F1FE-1F1EA.svg' },
    { url: '1F1FE-1F1F9.svg' },
    { url: '1F1FE.svg' },
    { url: '1F1FF-1F1E6.svg' },
    { url: '1F1FF-1F1F2.svg' },
    { url: '1F1FF-1F1FC.svg' },
    { url: '1F1FF.svg' }
  ],

  Misc: [
    { url: '262E.svg' },
    { url: '262F.svg' },
    { url: '2638.svg' },
    { url: '2639.svg' },
    { url: '263A.svg' },
    { url: '2640.svg' },
    { url: '2642.svg' },
    { url: '2648.svg' },
    { url: '2649.svg' },
    { url: '264A.svg' },
    { url: '264B.svg' },
    { url: '264C.svg' },
    { url: '264D.svg' },
    { url: '264E.svg' },
    { url: '264F.svg' },
    { url: '2650.svg' },
    { url: '2651.svg' },
    { url: '2652.svg' },
    { url: '2653.svg' },
    { url: '265F.svg' },
    { url: '2660.svg' },
    { url: '2663.svg' },
    { url: '2665.svg' },
    { url: '2666.svg' },
    { url: '2668.svg' },
    { url: '267B.svg' },
    { url: '267E.svg' },
    { url: '267F.svg' },
    { url: '2691-FE0F-200D-1F7E5.svg' },
    { url: '2691-FE0F-200D-1F7E6.svg' },
    { url: '2691-FE0F-200D-1F7E7.svg' },
    { url: '2691-FE0F-200D-1F7E8.svg' },
    { url: '2691-FE0F-200D-1F7E9-200D-2605-FE0F.svg' },
    { url: '2691-FE0F-200D-1F7E9.svg' },
    { url: '2691-FE0F-200D-1F7EA.svg' },
    { url: '2691-FE0F-200D-1F7EB.svg' },
    { url: '2692.svg' },
    { url: '2693.svg' },
    { url: '2694.svg' },
    { url: '2695.svg' },
    { url: '2696.svg' },
    { url: '2697.svg' },
    { url: '2699.svg' },
    { url: '269B.svg' },
    { url: '269C.svg' },
    { url: '26A0.svg' },
    { url: '26A1.svg' },
    { url: '26A7.svg' },
    { url: '26AA.svg' },
    { url: '26AB.svg' },
    { url: '26B0.svg' },
    { url: '26B1.svg' },
    { url: '26BD.svg' },
    { url: '26BE.svg' },
    { url: '26C4.svg' },
    { url: '26C5.svg' },
    { url: '26C8.svg' },
    { url: '26CE.svg' },
    { url: '26CF.svg' },
    { url: '26D1.svg' },
    { url: '26D3.svg' },
    { url: '26D4.svg' },
    { url: '26E9.svg' },
    { url: '26EA.svg' },
    { url: '26F0.svg' },
    { url: '26F1.svg' },
    { url: '26F2.svg' },
    { url: '26F3.svg' },
    { url: '26F4.svg' },
    { url: '26F5.svg' },
    { url: '26F7.svg' },
    { url: '26F8.svg' },
    { url: '26F9-1F3FB-200D-2640-FE0F.svg' },
    { url: '26F9-1F3FB-200D-2642-FE0F.svg' },
    { url: '26F9-1F3FB.svg' },
    { url: '26F9-1F3FC-200D-2640-FE0F.svg' },
    { url: '26F9-1F3FC-200D-2642-FE0F.svg' },
    { url: '26F9-1F3FC.svg' },
    { url: '26F9-1F3FD-200D-2640-FE0F.svg' },
    { url: '26F9-1F3FD-200D-2642-FE0F.svg' },
    { url: '26F9-1F3FD.svg' },
    { url: '26F9-1F3FE-200D-2640-FE0F.svg' },
    { url: '26F9-1F3FE-200D-2642-FE0F.svg' },
    { url: '26F9-1F3FE.svg' },
    { url: '26F9-1F3FF-200D-2640-FE0F.svg' },
    { url: '26F9-1F3FF-200D-2642-FE0F.svg' },
    { url: '26F9-1F3FF.svg' },
    { url: '26F9-FE0F-200D-2640-FE0F.svg' },
    { url: '26F9-FE0F-200D-2642-FE0F.svg' },
    { url: '26F9.svg' },
    { url: '26FA.svg' },
    { url: '26FD.svg' },
    { url: '2702.svg' },
    { url: '2705.svg' },
    { url: '2708.svg' },
    { url: '2709.svg' },
    { url: '270A-1F3FB.svg' },
    { url: '270A-1F3FC.svg' },
    { url: '270A-1F3FD.svg' },
    { url: '270A-1F3FE.svg' },
    { url: '270A-1F3FF.svg' },
    { url: '270A.svg' },
    { url: '270B-1F3FB.svg' },
    { url: '270B-1F3FC.svg' },
    { url: '270B-1F3FD.svg' },
    { url: '270B-1F3FE.svg' },
    { url: '270B-1F3FF.svg' },
    { url: '270B.svg' },
    { url: '270C-1F3FB.svg' },
    { url: '270C-1F3FC.svg' },
    { url: '270C-1F3FD.svg' },
    { url: '270C-1F3FE.svg' },
    { url: '270C-1F3FF.svg' },
    { url: '270C.svg' },
    { url: '270D-1F3FB.svg' },
    { url: '270D-1F3FC.svg' },
    { url: '270D-1F3FD.svg' },
    { url: '270D-1F3FE.svg' },
    { url: '270D-1F3FF.svg' },
    { url: '270D.svg' },
    { url: '270F.svg' },
    { url: '2712.svg' },
    { url: '2714.svg' },
    { url: '2716.svg' },
    { url: '271D.svg' },
    { url: '2721.svg' },
    { url: '2728.svg' },
    { url: '2733.svg' },
    { url: '2734.svg' },
    { url: '2744.svg' },
    { url: '2747.svg' },
    { url: '274C.svg' },
    { url: '274E.svg' },
    { url: '2753.svg' },
    { url: '2754.svg' },
    { url: '2755.svg' },
    { url: '2757.svg' },
    { url: '2763.svg' },
    { url: '2764-FE0F-200D-1F525.svg' },
    { url: '2764-FE0F-200D-1FA79.svg' },
    { url: '2764.svg' },
    { url: '2795.svg' },
    { url: '2796.svg' },
    { url: '2797.svg' },
    { url: '27A1.svg' },
    { url: '27B0.svg' },
    { url: '27BF.svg' },
    { url: '2934.svg' },
    { url: '2935.svg' },
    { url: '2B05.svg' },
    { url: '2B06.svg' },
    { url: '2B07.svg' },
    { url: '2B0C.svg' },
    { url: '2B0D.svg' },
    { url: '2B1B.svg' },
    { url: '2B1C.svg' },
    { url: '2B1F.svg' },
    { url: '2B20.svg' },
    { url: '2B21-FE0F-200D-1F308.svg' },
    { url: '2B21-FE0F-200D-1F7E5.svg' },
    { url: '2B21-FE0F-200D-1F7E6.svg' },
    { url: '2B21-FE0F-200D-1F7E7.svg' },
    { url: '2B21-FE0F-200D-1F7E8.svg' },
    { url: '2B21-FE0F-200D-1F7E9.svg' },
    { url: '2B21-FE0F-200D-1F7EA.svg' },
    { url: '2B21-FE0F-200D-1F7EB.svg' },
    { url: '2B21.svg' },
    { url: '2B22.svg' },
    { url: '2B23.svg' },
    { url: '2B24.svg' },
    { url: '2B2E.svg' },
    { url: '2B2F.svg' },
    { url: '2B50.svg' },
    { url: '2B55.svg' },
    { url: '2B58.svg' },
    { url: '2B8F.svg' },
    { url: '2BBA.svg' },
    { url: '2BBB.svg' },
    { url: '2BBC.svg' },
    { url: '2BC3.svg' },
    { url: '2BC4.svg' },
    { url: '2BEA.svg' },
    { url: '2BEB.svg' },
    { url: '3030.svg' },
    { url: '303D.svg' },
    { url: '3297.svg' },
    { url: '3299.svg' },
    { url: 'E000.svg' },
    { url: 'E001.svg' },
    { url: 'E002.svg' },
    { url: 'E003.svg' },
    { url: 'E004.svg' },
    { url: 'E005.svg' },
    { url: 'E006.svg' },
    { url: 'E007.svg' },
    { url: 'E008.svg' },
    { url: 'E009.svg' },
    { url: 'E010.svg' },
    { url: 'E011.svg' },
    { url: 'E040.svg' },
    { url: 'E041.svg' },
    { url: 'E042.svg' },
    { url: 'E043.svg' },
    { url: 'E044.svg' },
    { url: 'E045.svg' },
    { url: 'E046.svg' },
    { url: 'E047.svg' },
    { url: 'E048.svg' },
    { url: 'E049.svg' },
    { url: 'E04A.svg' },
    { url: 'E04B.svg' },
    { url: 'E050.svg' },
    { url: 'E051.svg' },
    { url: 'E052.svg' },
    { url: 'E053.svg' },
    { url: 'E054.svg' },
    { url: 'E055.svg' },
    { url: 'E056.svg' },
    { url: 'E057.svg' },
    { url: 'E058.svg' },
    { url: 'E059.svg' },
    { url: 'E05A.svg' },
    { url: 'E05B.svg' },
    { url: 'E05C.svg' },
    { url: 'E05D.svg' },
    { url: 'E080.svg' },
    { url: 'E081.svg' },
    { url: 'E082.svg' },
    { url: 'E083.svg' },
    { url: 'E084.svg' },
    { url: 'E085.svg' },
    { url: 'E086.svg' },
    { url: 'E087.svg' },
    { url: 'E088.svg' },
    { url: 'E089.svg' },
    { url: 'E08A.svg' },
    { url: 'E08B.svg' },
    { url: 'E08C.svg' },
    { url: 'E08D.svg' },
    { url: 'E08E.svg' },
    { url: 'E08F.svg' },
    { url: 'E090.svg' },
    { url: 'E091.svg' },
    { url: 'E092.svg' },
    { url: 'E093.svg' },
    { url: 'E094.svg' },
    { url: 'E095.svg' },
    { url: 'E096.svg' },
    { url: 'E097.svg' },
    { url: 'E098.svg' },
    { url: 'E099.svg' },
    { url: 'E09A.svg' },
    { url: 'E09B.svg' },
    { url: 'E09C.svg' },
    { url: 'E09D.svg' },
    { url: 'E09E.svg' },
    { url: 'E09F.svg' },
    { url: 'E0A0.svg' },
    { url: 'E0A1.svg' },
    { url: 'E0A2.svg' },
    { url: 'E0A3.svg' },
    { url: 'E0A4.svg' },
    { url: 'E0A5.svg' },
    { url: 'E0A6.svg' },
    { url: 'E0A7.svg' },
    { url: 'E0A8.svg' },
    { url: 'E0A9.svg' },
    { url: 'E0AA.svg' },
    { url: 'E0AB.svg' },
    { url: 'E0AC-200D-2640-FE0F.svg' },
    { url: 'E0AC-200D-2642-FE0F.svg' },
    { url: 'E0AC.svg' },
    { url: 'E0AD-200D-2640-FE0F.svg' },
    { url: 'E0AD-200D-2642-FE0F.svg' },
    { url: 'E0AD.svg' },
    { url: 'E0AE.svg' },
    { url: 'E0AF.svg' },
    { url: 'E0B0.svg' },
    { url: 'E0B1.svg' },
    { url: 'E0B2.svg' },
    { url: 'E0B3.svg' },
    { url: 'E0B4.svg' },
    { url: 'E0C0.svg' },
    { url: 'E0C1.svg' },
    { url: 'E0C2.svg' },
    { url: 'E0C3.svg' },
    { url: 'E0C4.svg' },
    { url: 'E0C5.svg' },
    { url: 'E0C6.svg' },
    { url: 'E0C7.svg' },
    { url: 'E0C8.svg' },
    { url: 'E0C9.svg' },
    { url: 'E0CA.svg' },
    { url: 'E0FF.svg' },
    { url: 'E100.svg' },
    { url: 'E101.svg' },
    { url: 'E102.svg' },
    { url: 'E103.svg' },
    { url: 'E104.svg' },
    { url: 'E105.svg' },
    { url: 'E106.svg' },
    { url: 'E107.svg' },
    { url: 'E108.svg' },
    { url: 'E109.svg' },
    { url: 'E10A.svg' },
    { url: 'E10B.svg' },
    { url: 'E10C.svg' },
    { url: 'E10D.svg' },
    { url: 'E140.svg' },
    { url: 'E141.svg' },
    { url: 'E142.svg' },
    { url: 'E143.svg' },
    { url: 'E144.svg' },
    { url: 'E146.svg' },
    { url: 'E147.svg' },
    { url: 'E148.svg' },
    { url: 'E149.svg' },
    { url: 'E150.svg' },
    { url: 'E151.svg' },
    { url: 'E152.svg' },
    { url: 'E153.svg' },
    { url: 'E154.svg' },
    { url: 'E155.svg' },
    { url: 'E156.svg' },
    { url: 'E157.svg' },
    { url: 'E181.svg' },
    { url: 'E182.svg' },
    { url: 'E183.svg' },
    { url: 'E184.svg' },
    { url: 'E185.svg' },
    { url: 'E186.svg' },
    { url: 'E187.svg' },
    { url: 'E188.svg' },
    { url: 'E1C0.svg' },
    { url: 'E1C1.svg' },
    { url: 'E1C2.svg' },
    { url: 'E1C3.svg' },
    { url: 'E1C4.svg' },
    { url: 'E1C6.svg' },
    { url: 'E1C7.svg' },
    { url: 'E1C8.svg' },
    { url: 'E1C9.svg' },
    { url: 'E1CA.svg' },
    { url: 'E1CB.svg' },
    { url: 'E1CC.svg' },
    { url: 'E1CD.svg' },
    { url: 'E1CE.svg' },
    { url: 'E1CF.svg' },
    { url: 'E1D0.svg' },
    { url: 'E1D1.svg' },
    { url: 'E1D2.svg' },
    { url: 'E1D3.svg' },
    { url: 'E1D4.svg' },
    { url: 'E1D5.svg' },
    { url: 'E1D6.svg' },
    { url: 'E1D7.svg' },
    { url: 'E1D8.svg' },
    { url: 'E1D9.svg' },
    { url: 'E200.svg' },
    { url: 'E201.svg' },
    { url: 'E202.svg' },
    { url: 'E203.svg' },
    { url: 'E204.svg' },
    { url: 'E205.svg' },
    { url: 'E206.svg' },
    { url: 'E207.svg' },
    { url: 'E208.svg' },
    { url: 'E209.svg' },
    { url: 'E20A.svg' },
    { url: 'E20B.svg' },
    { url: 'E20C.svg' },
    { url: 'E20D.svg' },
    { url: 'E20E.svg' },
    { url: 'E20F.svg' },
    { url: 'E210.svg' },
    { url: 'E211.svg' },
    { url: 'E212.svg' },
    { url: 'E213.svg' },
    { url: 'E214.svg' },
    { url: 'E215.svg' },
    { url: 'E216.svg' },
    { url: 'E240.svg' },
    { url: 'E241.svg' },
    { url: 'E242.svg' },
    { url: 'E243.svg' },
    { url: 'E244.svg' },
    { url: 'E245.svg' },
    { url: 'E246.svg' },
    { url: 'E247.svg' },
    { url: 'E248.svg' },
    { url: 'E249.svg' },
    { url: 'E24A.svg' },
    { url: 'E24B.svg' },
    { url: 'E24C.svg' },
    { url: 'E24D.svg' },
    { url: 'E24E.svg' },
    { url: 'E24F.svg' },
    { url: 'E250.svg' },
    { url: 'E251.svg' },
    { url: 'E252.svg' },
    { url: 'E253.svg' },
    { url: 'E254.svg' },
    { url: 'E255.svg' },
    { url: 'E256.svg' },
    { url: 'E257.svg' },
    { url: 'E258.svg' },
    { url: 'E259.svg' },
    { url: 'E25A.svg' },
    { url: 'E25B.svg' },
    { url: 'E25C.svg' },
    { url: 'E25D.svg' },
    { url: 'E25E.svg' },
    { url: 'E25F.svg' },
    { url: 'E260.svg' },
    { url: 'E261.svg' },
    { url: 'E262.svg' },
    { url: 'E263.svg' },
    { url: 'E264.svg' },
    { url: 'E265.svg' },
    { url: 'E266.svg' },
    { url: 'E267.svg' },
    { url: 'E268.svg' },
    { url: 'E269.svg' },
    { url: 'E280.svg' },
    { url: 'E281.svg' },
    { url: 'E282.svg' },
    { url: 'E283.svg' },
    { url: 'E2C0.svg' },
    { url: 'E2C1.svg' },
    { url: 'E2C2.svg' },
    { url: 'E2C3.svg' },
    { url: 'E2C4.svg' },
    { url: 'E2C6.svg' },
    { url: 'E2C7.svg' },
    { url: 'E2C8.svg' },
    { url: 'E2C9.svg' },
    { url: 'E2CA.svg' },
    { url: 'E2CB.svg' },
    { url: 'E2CC.svg' },
    { url: 'E2CD.svg' },
    { url: 'E2CE.svg' },
    { url: 'E2CF.svg' },
    { url: 'E2D0.svg' },
    { url: 'E2D1.svg' },
    { url: 'E2D2.svg' },
    { url: 'E2D3.svg' },
    { url: 'E2D4.svg' },
    { url: 'E2D5.svg' },
    { url: 'E2D6.svg' },
    { url: 'E2D7.svg' },
    { url: 'E2D8.svg' },
    { url: 'E2D9.svg' },
    { url: 'E2DA.svg' },
    { url: 'E300.svg' },
    { url: 'E301.svg' },
    { url: 'E302.svg' },
    { url: 'E303.svg' },
    { url: 'E305.svg' },
    { url: 'E306.svg' },
    { url: 'E307.svg' },
    { url: 'E308.svg' },
    { url: 'E309.svg' },
    { url: 'E30A.svg' },
    { url: 'E30B.svg' },
    { url: 'E30C.svg' },
    { url: 'E30D.svg' },
    { url: 'E30E.svg' },
    { url: 'E30F.svg' },
    { url: 'E312.svg' },
    { url: 'E313.svg' },
    { url: 'E314.svg' },
    { url: 'E315.svg' },
    { url: 'E316.svg' },
    { url: 'E318.svg' },
    { url: 'E319.svg' },
    { url: 'E31A.svg' },
    { url: 'E31B.svg' },
    { url: 'E31C.svg' },
    { url: 'E31D.svg' },
    { url: 'E31E.svg' },
    { url: 'E31F.svg' },
    { url: 'E320.svg' },
    { url: 'E321.svg' },
    { url: 'E322.svg' },
    { url: 'E324.svg' },
    { url: 'E325.svg' },
    { url: 'E326.svg' },
    { url: 'E327.svg' },
    { url: 'E328.svg' },
    { url: 'E329.svg' },
    { url: 'E32B.svg' },
    { url: 'E340.svg' },
    { url: 'E341.svg' },
    { url: 'E342.svg' },
    { url: 'E343.svg' },
    { url: 'E344.svg' },
    { url: 'E345.svg' },
    { url: 'E346.svg' },
    { url: 'E347.svg' },
    { url: 'E348.svg' },
    { url: 'E380.svg' },
    { url: 'E381.svg' },
    { url: 'F000.svg' },
    { url: 'F8FF.svg' }
  ]
}

export const transitionsList = [
  {
    type: 'none',
    title: 'none',
    langKey: 'tsl_none',
    icon: <NoneIcon />,
    img: TFadetoBlack,
  }, {
    type: 'fade',
    title: 'Fade',
    langKey: 'tsl_fade',
    img: TFadetoBlack,
    preview: FadePreview,
  }, {
    type: 'book-flip',
    title: 'Book Flip',
    langKey: 'tsl_book_flip',
    img: TFadetoBlack,
    preview: FadeToBlackPreview,
  }, {
    type: 'disolve',
    title: 'Disolve',
    langKey: 'tsl_disolve',
    img: TFadetoBlack,
    preview: FadeToWhitePreview,
  }, {
    type: 'doorway',
    title: 'Doorway',
    langKey: 'tsl_doorway',
    icon: <NoneIcon />,
    img: TFadetoBlack,
  }, {
    type: 'blur',
    title: 'Blur',
    langKey: 'tsl_blur',
    img: TFadetoBlack,
    preview: WipePreview
  }, {
    type: 'push',
    title: 'Push',
    langKey: 'tsl_push',
    img: TFadetoBlack,
    preview: SlidePreview
  }, {
    type: 'slide',
    title: 'Slide',
    langKey: 'tsl_slide',
    img: TFadetoBlack,
    preview: BlurPreview
  }, {
    type: 'wipe',
    title: 'Wipe',
    langKey: 'tsl_wipe',
    img: TFadetoBlack,
    preview: PushPreview
  }, {
    type: 'zoom-in',
    title: 'Zoom In',
    langKey: 'tsl_zoom_in',
    img: TFadetoBlack,
    preview: ZoomInPreview
  }, {
    type: 'zoom-out',
    title: 'Zoom Out',
    langKey: 'tsl_zoom_out',
    img: TFadetoBlack,
    preview: ZoomOutPreview
  },
]

export const filtersList = [
  {
    type: 'None',
    title: 'None',
    img: null,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, 0, /* red */ 0, 0, 1, 0, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#ffffffff',
    dark: 0,
  }, {
    type: 'Darker',
    title: 'Darker',
    img: FBlackWhite,
    mat4: [0, 0, 1, 0, /* white */ 0, 0, 1, 0, /* red */ 0, 0, 1, 0, /* green */ 1, 0, 0, 1 /* blue */],
    color: '#444444ff',
    dark: 0.3,
  }, {
    type: 'BlacknWhite',
    title: 'BlacknWhite',
    img: FBlackWhite,
    mat4: [0, 0, 1, 0, /* white */ 0, 0, 1, 0, /* red */ 0, 0, 1, 0, /* green */ 1, 0, 0, 1 /* blue */],
    color: '#f7f7f7ff',
    dark: 0.6,
  }, {
    type: 'Daphne',
    title: 'Daphne',
    img: FDaphne,
    mat4: [1, 0.2, -0.1, 0, /* white */ 0, 1, -0.2, 0, /* red */ 0, 0, 1, 0, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#ffdbffff',
    dark: 0,
  }, {
    type: 'Penelope',
    title: 'Penelope',
    img: FPenelope,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, 0, /* red */ 0.5, 0, 1, -0.4, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#fdffbeff',
    dark: 0,
  }, {
    type: 'Phoebe',
    title: 'Phoebe',
    img: FPhoebe,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, 0, /* red */ 0.4, 0, 1, -0.4, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#feffacff',
    dark: 0,
  }, {
    type: 'Thalia',
    title: 'Thalia',
    img: FThalia,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, 0, /* red */ 0.3, 0, 1, -0.4, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#fdff9aff',
    dark: 0,
  }, {
    type: 'Chloe',
    title: 'Chloe',
    img: FChloe,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, 0, /* red */ 0.3, 0, 1, 0, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#fdffffff',
    dark: 0,
  }, {
    type: 'Hermione',
    title: 'Hermione',
    img: FHermione,
    mat4: [1, -0.2, 0, 0, /* white */ 0, 1, 0, 0, /* red */ -0.2, 0, 1, 0, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#d7ffd7ff',
    dark: 0,
  }, {
    type: 'Hera',
    title: 'Hera',
    img: FHera,
    mat4: [1, -0.4, 0, 0, /* white */ 0, 1, 0, 0, /* red */ -0.3, 0, 1, 0, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#b3ffbbff',
    dark: 0,
  }, {
    type: 'Rhea',
    title: 'Rhea',
    img: FRhea,
    mat4: [1, -0.6, 0, 0, /* white */ 0, 1, 0, 0, /* red */ -0.5, 0, 1, 0, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#93ff8bff',
    dark: 0,
  }, {
    type: 'Donna',
    title: 'Donna',
    img: FDonna,
    mat4: [1, 0.5, -0.3, 0, /* white */ 0, 1, 0, 0, /* red */ 0, 0.3, 1, -0.5, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#ffff87ff',
    dark: 0,
  }, {
    type: 'Martina',
    title: 'Martina',
    img: FMartina,
    mat4: [1, 0.3, -0.1, 0, /* white */ 0, 1, 0, 0, /* red */ 0, 0, 1, 0, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#ffffffff',
    dark: 0,
  }, {
    type: 'Gabriella',
    title: 'Gabriella',
    img: FGabriella,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, 0, /* red */ 0, -0.3, 1, 0, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#fdffc7ff',
    dark: 0,
  }, {
    type: 'Elisabetta',
    title: 'Elisabetta',
    img: FElisabetta,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, 0, /* red */ 0.7, -0.5, 1, 0, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#fdffffff',
    dark: 0,
  }, {
    type: 'Isabella',
    title: 'Isabella',
    img: FBlackWhite,
    mat4: [0, 0, 1, 0, /* white */ 0, 0, 1, 0, /* red */ 0, 0, 1, 0, /* green */ 1, 0, 0, 1 /* blue */],
    color: '#daf1ffff',
    dark: 0,
  }, {
    type: 'Luna',
    title: 'Luna',
    img: FLuna,
    mat4: [1, 0, 0, 0.2, /* white */ 0, 1, 0, 0, /* red */ 0, 0, 1, -0.1, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#ffffe4ff',
    dark: 0,
  }, {
    type: 'Viola',
    title: 'Viola',
    img: FViola,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, 0, /* red */ 0.5, -0.5, 1, 0, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#fdffffff',
    dark: 0,
  }, {
    type: 'Zita',
    title: 'Zita',
    img: FZita,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, -0.2, /* red */ 0, 0, 1, -0.2, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#f09290ff',
    dark: 0,
  }, {
    type: 'Donata',
    title: 'Donata',
    img: FDonata,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, 0, /* red */ 0, -0.2, 1, 0, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#fdffdfff',
    dark: 0,
  }, {
    type: 'Maddalena',
    title: 'Maddalena',
    img: FMaddalena,
    mat4: [1, 0, 0, 0.1, /* white */ 0, 1, 0, 0.1, /* red */ 0, 0, 1, 0, /* green */ 0.5, 0, 0, 1 /* blue */],
    color: '#ffffffff',
    dark: 0,
  }, {
    type: 'Tecla',
    title: 'Tecla',
    img: FTecla,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, 0.1, /* red */ 0, 0, 1, 0, /* green */ 0.5, 0, 0, 1 /* blue */],
    color: '#ffffffff',
    dark: 0,
  }, {
    type: 'Narcisa',
    title: 'Narcisa',
    img: FNarcisa,
    mat4: [1, 0, 0, 0, /* white */ 0, 1, 0, 0, /* red */ 0, 0.1, 1, -0.2, /* green */ 0, 0, 0, 1 /* blue */],
    color: '#fdffb7ff',
    dark: 0,
  }
]

export const textConfig = [
  // 1
  {
    context: 'Joy',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Aboreto',
    },
  }, {
    context: 'Smile',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'AkayaKanadaka',
    },
  }, {
    context: 'Inspire',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'AlfaSlabOne',
    },
  }, {
    context: 'Dream',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'ArchitectsDaughter',
    },
  }, {
    context: 'Happiness',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Audiowide',
    },
  }, {
    context: 'Hope',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'BhuTukaExpandedOne',
    },
  },
  // 2
  {
    context: 'Charming',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'BioRhyme',
    },
  }, {
    context: 'Adorable',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'BrunoAceSC',
    },
  }, {
    context: 'Fantastic',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'BungeeInline',
    },
  }, {
    context: 'Wonderful',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'BungeeShade',
    },
  }, {
    context: 'Brilliant',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'ChakraPetch',
    },
  }, {
    context: 'Spectacular',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Changa',
    },
  },
  // 3
  {
    context: 'Magical',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'CherryBombOne',
    },
  }, {
    context: 'Enchanting',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'Chokokutai',
    },
  }, {
    context: 'Lovely',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'Diplomata',
    },
  }, {
    context: 'Elegant',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Eater',
    },
  }, 
  
  // 1
  {
    context: 'Graceful',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'EmblemaOne',
    },
  }, {
    context: 'Radiant',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'Estonia',
    },
  }, {
    context: 'Glorious',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'FascinateInline',
    },
  }, {
    context: 'Blissful',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'FasterOne',
    },
  }, {
    context: 'Harmony',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'FreckleFace',
    },
  }, {
    context: 'Serene',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'GideonRoman',
    },
  },
  // 2
  {
    context: 'Breathtaking',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Gidugu',
    },
  }, {
    context: 'Gorgeous',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'Goldman',
    },
  }, {
    context: 'Exquisite',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'GrenzeGotisch',
    },
  }, {
    context: 'Captivating',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Hubballi',
    },
  }, {
    context: 'Alluring',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Hurricane',
    },
  }, {
    context: 'Mesmerizing',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Joan',
    },
  },
  // 3
  {
    context: 'Cherish',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'KronaOne',
    },
  }, {
    context: 'Celebrate',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'Limelight',
    },
  }, {
    context: 'Cheerful',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'Mansalva',
    },
  }, {
    context: 'Fascinating',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'MarcellusSC',
    },
  },



  // 1
  {
    context: 'Whimsical',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Marhey',
    },
  }, {
    context: 'Sparkling',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'Megrim',
    },
  }, {
    context: 'Splendid',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'Mirza',
    },
  }, {
    context: 'Euphoric',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Monofett',
    },
  }, {
    context: 'Thrilling',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Monoton',
    },
  }, {
    context: 'Enthusiasm',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'NewRocker',
    },
  },
  // 2
  {
    context: 'Remarkable',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'OoohBaby',
    },
  }, {
    context: 'Miraculous',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'Pacifico',
    },
  }, {
    context: 'Stunning',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'Padyakke',
    },
  }, {
    context: 'Incredible',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Peralta',
    },
  }, {
    context: 'Luminous',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Plaster',
    },
  }, {
    context: 'Vibrant',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'PoiretOne',
    },
  },
  // 3
  {
    context: 'Delicious',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'RammettoOne',
    },
  }, {
    context: 'Flourish',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'RhodiumLibre',
    },
  }, {
    context: 'Sunny',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'RibeyeMarrow',
    },
  }, {
    context: 'Precious',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Righteous',
    },
  }, 
  
  // 1
  {
    context: 'Energetic',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'RubikRegular',
    },
  }, {
    context: 'Tranquil',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'RubikGemstones',
    },
  }, {
    context: 'Admire',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'RubikGlitch',
    },
  }, {
    context: 'Vivid',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'RubikMaze',
    },
  }, {
    context: 'Majestic',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'RubikMoonrocks',
    },
  }, {
    context: 'Pure',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'RubikVinyl',
    },
  }, {
    context: 'Exuberant',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'RubikWetPaint',
    },
  },
  // 2
  {
    context: 'Tender',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'RussoOne',
    },
  }, {
    context: 'Nurturing',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'SairaStencilOne',
    },
  }, {
    context: 'Radiate',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'SeymourOne',
    },
  }, {
    context: 'Peaceful',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Shojumaru',
    },
  }, {
    context: 'Bliss',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'SortsMillGoudy',
    },
  }, {
    context: 'Gentle',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'SuezOne',
    },
  },
  // 3
  {
    context: 'Blossom',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'Thasadith',
    },
  }, {
    context: 'Immerse',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'TurretRoad',
    },
  }, {
    context: 'Elevate',
    properties: {
      bold: '',
      italic: "",
      font_size: 40,
      font_family: 'Underdog',
    },
  }, {
    context: 'Enliven',
    properties: {
      italic: "",
      bold: '',
      font_size: 40,
      font_family: 'WaitingfortheSunrise',
    },
  },



  {
    context: 'Text',
    properties: {},
  }
]

export const opacityMarks = [
  { value: 0.0, label: '0' },
  { value: 0.1, label: '' },
  { value: 0.2, label: '2' },
  { value: 0.3, label: '' },
  { value: 0.4, label: '4' },
  { value: 0.5, label: '' },
  { value: 0.6, label: '6' },
  { value: 0.7, label: '' },
  { value: 0.8, label: '8' },
  { value: 0.9, label: '' },
  { value: 1.0, label: '10' },
]


export const defaultPreviewStyle = {
  bold: "",
  font_size: 25,
  italic: "",
  text_color: "#ffffffff",
  alignment: "center",
  background_color: "#000000b0",
  font_family: "RubikRegular",
}

export const speechVoices = [
  { "language": "Afrikaans (South Africa)", "code": "af-ZA", "gender": "FEMALE", "name": "Afrikaans (South Africa) - Female" },
  { "language": "Arabic", "code": "ar-XA", "gender": "FEMALE", "name": "Arabic - Female" },
  { "language": "Arabic", "code": "ar-XA", "gender": "MALE", "name": "Arabic - Male" },
  { "language": "Bengali (Bangladesh)", "code": "bn-BD", "gender": "FEMALE", "name": "Bengali (Bangladesh) - Female" },
  { "language": "Bengali (India)", "code": "bn-IN", "gender": "FEMALE", "name": "Bengali (India) - Female" },
  { "language": "Czech (Czech Republic)", "code": "cs-CZ", "gender": "FEMALE", "name": "Czech (Czech Republic) - Female" },
  { "language": "Czech (Czech Republic)", "code": "cs-CZ", "gender": "MALE", "name": "Czech (Czech Republic) - Male" },
  { "language": "Welsh (United Kingdom)", "code": "cy-GB", "gender": "FEMALE", "name": "Welsh (United Kingdom) - Female" },
  { "language": "Welsh (United Kingdom)", "code": "cy-GB", "gender": "MALE", "name": "Welsh (United Kingdom) - Male" },
  { "language": "Danish (Denmark)", "code": "da-DK", "gender": "FEMALE", "name": "Danish (Denmark) - Female" },
  { "language": "Danish (Denmark)", "code": "da-DK", "gender": "MALE", "name": "Danish (Denmark) - Male" },
  { "language": "German", "code": "de-DE", "gender": "FEMALE", "name": "German - Female" },
  { "language": "German", "code": "de-DE", "gender": "MALE", "name": "German - Male" },
  { "language": "Greek (Greece)", "code": "el-GR", "gender": "FEMALE", "name": "Greek (Greece) - Female" },
  { "language": "Greek (Greece)", "code": "el-GR", "gender": "MALE", "name": "Greek (Greece) - Male" },
  { "language": "English (Australia)", "code": "en-AU", "gender": "FEMALE", "name": "English (Australia) - Female" },
  { "language": "English (Australia)", "code": "en-AU", "gender": "MALE", "name": "English (Australia) - Male" },
  { "language": "English (United Kingdom)", "code": "en-GB", "gender": "FEMALE", "name": "English (United Kingdom) - Female" },
  { "language": "English (United Kingdom)", "code": "en-GB", "gender": "MALE", "name": "English (United Kingdom) - Male" },
  { "language": "English (India)", "code": "en-IN", "gender": "FEMALE", "name": "English (India) - Female" },
  { "language": "English (India)", "code": "en-IN", "gender": "MALE", "name": "English (India) - Male" },
  { "language": "English (United States)", "code": "en-US", "gender": "FEMALE", "name": "English (United States) - Female" },
  { "language": "English (United States)", "code": "en-US", "gender": "MALE", "name": "English (United States) - Male" },
  { "language": "Spanish", "code": "es-ES", "gender": "FEMALE", "name": "Spanish - Female" },
  { "language": "Spanish", "code": "es-ES", "gender": "MALE", "name": "Spanish - Male" },
  { "language": "Spanish (Mexico)", "code": "es-MX", "gender": "FEMALE", "name": "Spanish (Mexico) - Female" },
  { "language": "Spanish (Mexico)", "code": "es-MX", "gender": "MALE", "name": "Spanish (Mexico) - Male" },
  { "language": "Spanish (United States)", "code": "es-US", "gender": "FEMALE", "name": "Spanish (United States) - Female" },
  { "language": "Spanish (United States)", "code": "es-US", "gender": "MALE", "name": "Spanish (United States) - Male" },
  { "language": "Estonian (Estonia)", "code": "et-EE", "gender": "FEMALE", "name": "Estonian (Estonia) - Female" },
  { "language": "Basque (Spain)", "code": "eu-ES", "gender": "FEMALE", "name": "Basque (Spain) - Female" },
  { "language": "Persian", "code": "fa-IR", "gender": "FEMALE", "name": "Persian - Female" },
  { "language": "Persian", "code": "fa-IR", "gender": "MALE", "name": "Persian - Male" },
  { "language": "Filipino (Philippines)", "code": "fil-PH", "gender": "FEMALE", "name": "Filipino (Philippines) - Female" },
  { "language": "Finnish (Finland)", "code": "fi-FI", "gender": "FEMALE", "name": "Finnish (Finland) - Female" },
  { "language": "Finnish (Finland)", "code": "fi-FI", "gender": "MALE", "name": "Finnish (Finland) - Male" },
  { "language": "French", "code": "fr-FR", "gender": "FEMALE", "name": "French - Female" },
  { "language": "French", "code": "fr-FR", "gender": "MALE", "name": "French - Male" },
  { "language": "Galician (Spain)", "code": "gl-ES", "gender": "FEMALE", "name": "Galician (Spain) - Female" },
  { "language": "Gujarati (India)", "code": "gu-IN", "gender": "FEMALE", "name": "Gujarati (India) - Female" },
  { "language": "Hebrew (Israel)", "code": "he-IL", "gender": "FEMALE", "name": "Hebrew (Israel) - Female" },
  { "language": "Hindi (India)", "code": "hi-IN", "gender": "FEMALE", "name": "Hindi (India) - Female" },
  { "language": "Hindi (India)", "code": "hi-IN", "gender": "MALE", "name": "Hindi (India) - Male" },
  { "language": "Croatian (Croatia)", "code": "hr-HR", "gender": "FEMALE", "name": "Croatian (Croatia) - Female" },
  { "language": "Hungarian (Hungary)", "code": "hu-HU", "gender": "FEMALE", "name": "Hungarian (Hungary) - Female" },
  { "language": "Hungarian (Hungary)", "code": "hu-HU", "gender": "MALE", "name": "Hungarian (Hungary) - Male" },
  { "language": "Armenian (Armenia)", "code": "hy-AM", "gender": "FEMALE", "name": "Armenian (Armenia) - Female" },
  { "language": "Indonesian (Indonesia)", "code": "id-ID", "gender": "FEMALE", "name": "Indonesian (Indonesia) - Female" },
  { "language": "Indonesian (Indonesia)", "code": "id-ID", "gender": "MALE", "name": "Indonesian (Indonesia) - Male" },
  { "language": "Icelandic (Iceland)", "code": "is-IS", "gender": "FEMALE", "name": "Icelandic (Iceland) - Female" },
  { "language": "Icelandic (Iceland)", "code": "is-IS", "gender": "MALE", "name": "Icelandic (Iceland) - Male" },
  { "language": "Italian (Italy)", "code": "it-IT", "gender": "FEMALE", "name": "Italian (Italy) - Female" },
  { "language": "Italian (Italy)", "code": "it-IT", "gender": "MALE", "name": "Italian (Italy) - Male" },
  { "language": "Japanese (Japan)", "code": "ja-JP", "gender": "FEMALE", "name": "Japanese (Japan) - Female" },
  { "language": "Japanese (Japan)", "code": "ja-JP", "gender": "MALE", "name": "Japanese (Japan) - Male" },
  { "language": "Georgian (Georgia)", "code": "ka-GE", "gender": "FEMALE", "name": "Georgian (Georgia) - Female" },
  { "language": "Kazakh (Kazakhstan)", "code": "kk-KZ", "gender": "FEMALE", "name": "Kazakh (Kazakhstan) - Female" },
  { "language": "Kazakh (Kazakhstan)", "code": "kk-KZ", "gender": "MALE", "name": "Kazakh (Kazakhstan) - Male" },
  { "language": "Khmer (Cambodia)", "code": "km-KH", "gender": "FEMALE", "name": "Khmer (Cambodia) - Female" },
  { "language": "Kannada (India)", "code": "kn-IN", "gender": "FEMALE", "name": "Kannada (India) - Female" },
  { "language": "Korean (South Korea)", "code": "ko-KR", "gender": "FEMALE", "name": "Korean (South Korea) - Female" },
  { "language": "Korean (South Korea)", "code": "ko-KR", "gender": "MALE", "name": "Korean (South Korea) - Male" },
  { "language": "Lithuanian (Lithuania)", "code": "lt-LT", "gender": "FEMALE", "name": "Lithuanian (Lithuania) - Female" },
  { "language": "Latvian (Latvia)", "code": "lv-LV", "gender": "FEMALE", "name": "Latvian (Latvia) - Female" },
  { "language": "Latvian (Latvia)", "code": "lv-LV", "gender": "MALE", "name": "Latvian (Latvia) - Male" },{ "language": "Malayalam (India)", "code": "ml-IN", "gender": "FEMALE", "name": "Malayalam (India) - Female" },
  { "language": "Marathi (India)", "code": "mr-IN", "gender": "FEMALE", "name": "Marathi (India) - Female" },
  { "language": "Marathi (India)", "code": "mr-IN", "gender": "MALE", "name": "Marathi (India) - Male" },
  { "language": "Malay (Malaysia)", "code": "ms-MY", "gender": "FEMALE", "name": "Malay (Malaysia) - Female" },
  { "language": "Malay (Malaysia)", "code": "ms-MY", "gender": "MALE", "name": "Malay (Malaysia) - Male" },
  { "language": "Burmese (Myanmar)", "code": "my-MM", "gender": "FEMALE", "name": "Burmese (Myanmar) - Female" },
  { "language": "Dutch (Netherlands)", "code": "nl-NL", "gender": "FEMALE", "name": "Dutch (Netherlands) - Female" },
  { "language": "Dutch (Netherlands)", "code": "nl-NL", "gender": "MALE", "name": "Dutch (Netherlands) - Male" },
  { "language": "Norwegian Bokmål (Norway)", "code": "nb-NO", "gender": "FEMALE", "name": "Norwegian Bokmål (Norway) - Female" },
  { "language": "Norwegian Bokmål (Norway)", "code": "nb-NO", "gender": "MALE", "name": "Norwegian Bokmål (Norway) - Male" },
  { "language": "Nepali (Nepal)", "code": "ne-NP", "gender": "FEMALE", "name": "Nepali (Nepal) - Female" },
  { "language": "Polish (Poland)", "code": "pl-PL", "gender": "FEMALE", "name": "Polish (Poland) - Female" },
  { "language": "Polish (Poland)", "code": "pl-PL", "gender": "MALE", "name": "Polish (Poland) - Male" },
  { "language": "Portuguese (Brazil)", "code": "pt-BR", "gender": "FEMALE", "name": "Portuguese (Brazil) - Female" },
  { "language": "Portuguese (Brazil)", "code": "pt-BR", "gender": "MALE", "name": "Portuguese (Brazil) - Male" },
  { "language": "Portuguese (Portugal)", "code": "pt-PT", "gender": "FEMALE", "name": "Portuguese (Portugal) - Female" },
  { "language": "Portuguese (Portugal)", "code": "pt-PT", "gender": "MALE", "name": "Portuguese (Portugal) - Male" },
  { "language": "Romanian (Romania)", "code": "ro-RO", "gender": "FEMALE", "name": "Romanian (Romania) - Female" },
  { "language": "Romanian (Romania)", "code": "ro-RO", "gender": "MALE", "name": "Romanian (Romania) - Male" },
  { "language": "Russian (Russia)", "code": "ru-RU", "gender": "FEMALE", "name": "Russian (Russia) - Female" },
  { "language": "Russian (Russia)", "code": "ru-RU", "gender": "MALE", "name": "Russian (Russia) - Male" },
  { "language": "Slovak (Slovakia)", "code": "sk-SK", "gender": "FEMALE", "name": "Slovak (Slovakia) - Female" },
  { "language": "Slovak (Slovakia)", "code": "sk-SK", "gender": "MALE", "name": "Slovak (Slovakia) - Male" },
  { "language": "Slovenian (Slovenia)", "code": "sl-SI", "gender": "FEMALE", "name": "Slovenian (Slovenia) - Female" },
  { "language": "Albanian (Albania)", "code": "sq-AL", "gender": "FEMALE", "name": "Albanian (Albania) - Female" },
  { "language": "Serbian (Serbia)", "code": "sr-RS", "gender": "FEMALE", "name": "Serbian (Serbia) - Female" },
  { "language": "Serbian (Serbia)", "code": "sr-RS", "gender": "MALE", "name": "Serbian (Serbia) - Male" },
  { "language": "Swedish (Sweden)", "code": "sv-SE", "gender": "FEMALE", "name": "Swedish (Sweden) - Female" },
  { "language": "Swedish (Sweden)", "code": "sv-SE", "gender": "MALE", "name": "Swedish (Sweden) - Male" },
  { "language": "Swahili (Kenya)", "code": "sw-KE", "gender": "FEMALE", "name": "Swahili (Kenya) - Female" },
  { "language": "Swahili (Kenya)", "code": "sw-KE", "gender": "MALE", "name": "Swahili (Kenya) - Male" },
  { "language": "Tamil (India)", "code": "ta-IN", "gender": "FEMALE", "name": "Tamil (India) - Female" },
  { "language": "Telugu (India)", "code": "te-IN", "gender": "FEMALE", "name": "Telugu (India) - Female" },
  { "language": "Thai (Thailand)", "code": "th-TH", "gender": "FEMALE", "name": "Thai (Thailand) - Female" },
  { "language": "Thai (Thailand)", "code": "th-TH", "gender": "MALE", "name": "Thai (Thailand) - Male" },
  { "language": "Tagalog (Philippines)", "code": "tl-PH", "gender": "FEMALE", "name": "Tagalog (Philippines) - Female" },
  { "language": "Turkish (Turkey)", "code": "tr-TR", "gender": "FEMALE", "name": "Turkish (Turkey) - Female" },
  { "language": "Turkish (Turkey)", "code": "tr-TR", "gender": "MALE", "name": "Turkish (Turkey) - Male" },
  { "language": "Ukrainian (Ukraine)", "code": "uk-UA", "gender": "FEMALE", "name": "Ukrainian (Ukraine) - Female" },
  { "language": "Ukrainian (Ukraine)", "code": "uk-UA", "gender": "MALE", "name": "Ukrainian (Ukraine) - Male" },
  { "language": "Urdu (Pakistan)", "code": "ur-PK", "gender": "FEMALE", "name": "Urdu (Pakistan) - Female" },
  { "language": "Vietnamese (Vietnam)", "code": "vi-VN", "gender": "FEMALE", "name": "Vietnamese (Vietnam) - Female" },
  { "language": "Vietnamese (Vietnam)", "code": "vi-VN", "gender": "MALE", "name": "Vietnamese (Vietnam) - Male" },
  { "language": "Xhosa (South Africa)", "code": "xh-ZA", "gender": "FEMALE", "name": "Xhosa (South Africa) - Female" },
  { "language": "Zulu (South Africa)", "code": "zu-ZA", "gender": "FEMALE", "name": "Zulu (South Africa) - Female" },
  ];

export const localeForPexles = ['en-US', 'pt-BR', 'es-ES', 'ca-ES', 'de-DE', 'it-IT', 'fr-FR', 'sv-SE', 'id-ID', 'pl-PL', 'ja-JP', 'zh-TW', 'zh-CN', 'ko-KR', 'th-TH', 'nl-NL', 'hu-HU', 'vi-VN', 'cs-CZ', 'da-DK', 'fi-FI', 'uk-UA', 'el-GR', 'ro-RO', 'nb-NO', 'sk-SK', 'tr-TR', 'ru-RU']

export const mimeTypes = {
  //   File Extension   MIME Type
    'abs':           'audio/x-mpeg',
    'ai':            'application/postscript',
    'aif':           'audio/x-aiff',
    'aifc':          'audio/x-aiff',
    'aiff':          'audio/x-aiff',
    'aim':           'application/x-aim',
    'art':           'image/x-jg',
    'asf':           'video/x-ms-asf',
    'asx':           'video/x-ms-asf',
    'au':            'audio/basic',
    'avi':           'video/x-msvideo',
    'avx':           'video/x-rad-screenplay',
    'bcpio':         'application/x-bcpio',
    'bin':           'application/octet-stream',
    'bmp':           'image/bmp',
    'body':          'text/html',
    'cdf':           'application/x-cdf',
    'cer':           'application/pkix-cert',
    'class':         'application/java',
    'cpio':          'application/x-cpio',
    'csh':           'application/x-csh',
    'css':           'text/css',
    'dib':           'image/bmp',
    'doc':           'application/msword',
    'dtd':           'application/xml-dtd',
    'dv':            'video/x-dv',
    'dvi':           'application/x-dvi',
    'eot':           'application/vnd.ms-fontobject',
    'eps':           'application/postscript',
    'etx':           'text/x-setext',
    'exe':           'application/octet-stream',
    'gif':           'image/gif',
    'gtar':          'application/x-gtar',
    'gz':            'application/x-gzip',
    'hdf':           'application/x-hdf',
    'hqx':           'application/mac-binhex40',
    'htc':           'text/x-component',
    'htm':           'text/html',
    'html':          'text/html',
    'ief':           'image/ief',
    'jad':           'text/vnd.sun.j2me.app-descriptor',
    'jar':           'application/java-archive',
    'java':          'text/x-java-source',
    'jnlp':          'application/x-java-jnlp-file',
    'jpe':           'image/jpeg',
    'jpeg':          'image/jpeg',
    'jpg':           'image/jpeg',
    'js':            'application/javascript',
    'jsf':           'text/plain',
    'json':          'application/json',
    'jspf':          'text/plain',
    'kar':           'audio/midi',
    'latex':         'application/x-latex',
    'm3u':           'audio/x-mpegurl',
    'mac':           'image/x-macpaint',
    'man':           'text/troff',
    'mathml':        'application/mathml+xml',
    'me':            'text/troff',
    'mid':           'audio/midi',
    'midi':          'audio/midi',
    'mif':           'application/x-mif',
    'mov':           'video/quicktime',
    'movie':         'video/x-sgi-movie',
    'mp1':           'audio/mpeg',
    'mp2':           'audio/mpeg',
    'mp3':           'audio/mpeg',
    'mp4':           'video/mp4',
    'mpa':           'audio/mpeg',
    'mpe':           'video/mpeg',
    'mpeg':          'video/mpeg',
    'mpega':         'audio/x-mpeg',
    'mpg':           'video/mpeg',
    'mpv2':          'video/mpeg2',
    'ms':            'application/x-wais-source',
    'nc':            'application/x-netcdf',
    'oda':           'application/oda',
    'odb':           'application/vnd.oasis.opendocument.database',
    'odc':           'application/vnd.oasis.opendocument.chart',
    'odf':           'application/vnd.oasis.opendocument.formula',
    'odg':           'application/vnd.oasis.opendocument.graphics',
    'odi':           'application/vnd.oasis.opendocument.image',
    'odm':           'application/vnd.oasis.opendocument.text-master',
    'odp':           'application/vnd.oasis.opendocument.presentation',
    'ods':           'application/vnd.oasis.opendocument.spreadsheet',
    'odt':           'application/vnd.oasis.opendocument.text',
    'otg':           'application/vnd.oasis.opendocument.graphics-template',
    'oth':           'application/vnd.oasis.opendocument.text-web',
    'otp':           'application/vnd.oasis.opendocument.presentation-template',
    'ots':           'application/vnd.oasis.opendocument.spreadsheet-template',
    'ott':           'application/vnd.oasis.opendocument.text-template',
    'ogx':           'application/ogg',
    'ogv':           'video/ogg',
    'oga':           'audio/ogg',
    'ogg':           'audio/ogg',
    'otf':           'application/x-font-opentype',
    'spx':           'audio/ogg',
    'flac':          'audio/flac',
    'anx':           'application/annodex',
    'axa':           'audio/annodex',
    'axv':           'video/annodex',
    'xspf':          'application/xspf+xml',
    'pbm':           'image/x-portable-bitmap',
    'pct':           'image/pict',
    'pdf':           'application/pdf',
    'pgm':           'image/x-portable-graymap',
    'pic':           'image/pict',
    'pict':          'image/pict',
    'pls':           'audio/x-scpls',
    'png':           'image/png',
    'pnm':           'image/x-portable-anymap',
    'pnt':           'image/x-macpaint',
    'ppm':           'image/x-portable-pixmap',
    'ppt':           'application/vnd.ms-powerpoint',
    'pps':           'application/vnd.ms-powerpoint',
    'ps':            'application/postscript',
    'psd':           'image/vnd.adobe.photoshop',
    'qt':            'video/quicktime',
    'qti':           'image/x-quicktime',
    'qtif':          'image/x-quicktime',
    'ras':           'image/x-cmu-raster',
    'rdf':           'application/rdf+xml',
    'rgb':           'image/x-rgb',
    'rm':            'application/vnd.rn-realmedia',
    'roff':          'text/troff',
    'rtf':           'application/rtf',
    'rtx':           'text/richtext',
    'sfnt':          'application/font-sfnt',
    'sh':            'application/x-sh',
    'shar':          'application/x-shar',
    'sit':           'application/x-stuffit',
    'snd':           'audio/basic',
    'src':           'application/x-wais-source',
    'sv4cpio':       'application/x-sv4cpio',
    'sv4crc':        'application/x-sv4crc',
    'svg':           'image/svg+xml',
    'svgz':          'image/svg+xml',
    'swf':           'application/x-shockwave-flash',
    't':             'text/troff',
    'tar':           'application/x-tar',
    'tcl':           'application/x-tcl',
    'tex':           'application/x-tex',
    'texi':          'application/x-texinfo',
    'texinfo':       'application/x-texinfo',
    'tif':           'image/tiff',
    'tiff':          'image/tiff',
    'tr':            'text/troff',
    'tsv':           'text/tab-separated-values',
    'ttf':           'application/x-font-ttf',
    'txt':           'text/plain',
    'ulw':           'audio/basic',
    'ustar':         'application/x-ustar',
    'vxml':          'application/voicexml+xml',
    'xbm':           'image/x-xbitmap',
    'xht':           'application/xhtml+xml',
    'xhtml':         'application/xhtml+xml',
    'xls':           'application/vnd.ms-excel',
    'xml':           'application/xml',
    'xpm':           'image/x-xpixmap',
    'xsl':           'application/xml',
    'xslt':          'application/xslt+xml',
    'xul':           'application/vnd.mozilla.xul+xml',
    'xwd':           'image/x-xwindowdump',
    'vsd':           'application/vnd.visio',
    'wav':           'audio/x-wav',
    'wbmp':          'image/vnd.wap.wbmp',
    'wml':           'text/vnd.wap.wml',
    'wmlc':          'application/vnd.wap.wmlc',
    'wmls':          'text/vnd.wap.wmlsc',
    'wmlscriptc':    'application/vnd.wap.wmlscriptc',
    'wmv':           'video/x-ms-wmv',
    'woff':          'application/font-woff',
    'woff2':         'application/font-woff2',
    'wrl':           'model/vrml',
    'wspolicy':      'application/wspolicy+xml',
    'z':             'application/x-compress',
    'zip':           'application/zip'
  };

export const voiserSpeeches = [
  ["Abbi", "English (British)", "en-GB", "540.png", "2.svg", "540-Abbi.mp3"],
  ["Bella", "English (British)", "en-GB", "541.png", "2.svg", "541-Bella.mp3"],
  ["Hollie", "English (British)", "en-GB", "542.png", "2.svg", "542-Hollie.mp3"],
  ["Maisie", "English (British)", "en-GB", "543.png", "2.svg", "543-Maisie.mp3"],
  ["Mia", "English (British)", "en-GB", "544.png", "2.svg", "544-Mia.mp3"],
  ["Olivia", "English (British)", "en-GB", "545.png", "2.svg", "545-Olivia.mp3"],
  ["Alfie", "English (British)", "en-GB", "546.png", "2.svg", "546-Alfie.mp3"],
  ["Elliot", "English (British)", "en-GB", "547.png", "2.svg", "547-Elliot.mp3"],
  ["Ethan", "English (British)", "en-GB", "548.png", "2.svg", "548-Ethan.mp3"],
  ["Noah", "English (British)", "en-GB", "549.png", "2.svg", "549-Noah.mp3"],
  ["Oliver", "English (British)", "en-GB", "550.png", "2.svg", "550-Oliver.mp3"],
  ["Thomas", "English (British)", "en-GB", "551.png", "2.svg", "551-Thomas.mp3"],
  ["Libby", "English (British)", "en-GB", "144.png", "2.svg", "libby.mp3"],
  ["Sonia", "English (British)", "en-GB", "145.png", "2.svg", "145-Sonia.mp3"],
  ["Ryan", "English (British)", "en-GB", "146.png", "2.svg", "en-GB-ryan.mp3"],
  ["Joseph", "English (American)", "en-US", "13.png", "3.svg", "13-joseph.mp3"],
  ["Jacob", "English (American)", "en-US", "15.png", "3.svg", "15-Jacob.mp3"],
  ["Olivia", "English (American)", "en-US", "16.png", "3.svg", "en-US-olivia.mp3"],
  ["Emily", "English (American)", "en-US", "17.png", "3.svg", "en-US-emily.mp3"],
  ["Jane", "English (American)", "en-US", "609.png", "3.svg", "609-Jane.mp3"],
  ["Nancy", "English (American)", "en-US", "610.png", "3.svg", "610-Nancy.mp3"],
  ["Davis", "English (American)", "en-US", "611.png", "3.svg", "611-Davis.mp3"],
  ["Jason", "English (American)", "en-US", "612.png", "3.svg", "612-Jason.mp3"],
  ["Tony", "English (American)", "en-US", "613.png", "3.svg", "613-Tony.mp3"],
  ["Aria", "English (American)", "en-US", "147.png", "3.svg", "en-US-aria.mp3"],
  ["Jenny", "English (American)", "en-US", "148.png", "3.svg", "en-US-jenny.mp3"],
  ["Guy", "English (American)", "en-US", "149.png", "3.svg", "en-US-guy.mp3"],
  ["Joseph", "English (American)", "en-US", "417.png", "3.svg", "Joseph.mp3"],
  ["Amber", "English (American)", "en-US", "222.png", "3.svg", "222-Amber.mp3"],
  ["Ashley", "English (American)", "en-US", "224.png", "3.svg", "224-Ashley.mp3"],
  ["Cora", "English (American)", "en-US", "225.png", "3.svg", "225-Cora.mp3"],
  ["Elizabeth", "English (American)", "en-US", "226.png", "3.svg", "226-Elizabeth.mp3"],
  ["Michelle", "English (American)", "en-US", "228.png", "3.svg", "228-Michelle.mp3"],
  ["Monica", "English (American)", "en-US", "229.png", "3.svg", "229-Monica.mp3"],
  ["Sara", "English (American)", "en-US", "230.png", "3.svg", "230-Sara.mp3"],
  ["Brandon", "English (American)", "en-US", "232.png", "3.svg", "232-Brandon.mp3"],
  ["Christopher", "English (American)", "en-US", "233.png", "3.svg", "233-Christopher.mp3"],
  ["Eric", "English (American)", "en-US", "234.png", "3.svg", "234-Eric.mp3"],
  ["Jacob", "English (American)", "en-US", "236.png", "3.svg", "jacob.mp3"],
  ["Ana [Kid]", "English (American)", "en-US", "237.png", "3.svg", "237-Ana.mp3"],
  ["Natasha", "English (Australia)", "en-AU", "136.png", "4.svg", "natasha.mp3"],
  ["William", "English (Australia)", "en-AU", "137.png", "4.svg", "william.mp3"],
  ["Meryem", "Turkish", "tr-TR", "218.png", "1.svg", "meryem-demo.mp3"],
  ["İbrahim", "Turkish", "tr-TR", "219.png", "1.svg", "ibrahim-demo.mp3"],
  ["خدیجه", "Arabic (Saudi Arabia)", "ar-SA", "111.png", "5.svg", "arabic4.mp3"],
  ["عمر", "Arabic (Saudi Arabia)", "ar-SA", "112.png", "5.svg", "arabic5.mp3"],
  ["Noah", "German (Germany)", "de-DE", "24.png", "6.svg", "Noah.mp3"],
  ["Amala", "German (Germany)", "de-DE", "567.png", "6.svg", "567-Amala.mp3"],
  ["Elke", "German (Germany)", "de-DE", "568.png", "6.svg", "568-Elke.mp3"],
  ["Gisela [Kid]", "German (Germany)", "de-DE", "569.png", "6.svg", "569-Gisela.mp3"],
  ["Klarissa", "German (Germany)", "de-DE", "570.png", "6.svg", "570-Klarissa.mp3"],
  ["Louisa", "German (Germany)", "de-DE", "571.png", "6.svg", "571-Louisa.mp3"],
  ["Maja", "German (Germany)", "de-DE", "572.png", "6.svg", "572-Maja.mp3"],
  ["Tanja", "German (Germany)", "de-DE", "573.png", "6.svg", "573-Tanja.mp3"],
  ["Bernd", "German (Germany)", "de-DE", "574.png", "6.svg", "574-Bernd.mp3"],
  ["Christoph", "German (Germany)", "de-DE", "575.png", "6.svg", "575-Christoph.mp3"],
  ["Kasper", "German (Germany)", "de-DE", "576.png", "6.svg", "576-Kasper.mp3"],
  ["Killian", "German (Germany)", "de-DE", "577.png", "6.svg", "577-Killian.mp3"],
  ["Klaus", "German (Germany)", "de-DE", "578.png", "6.svg", "578-Klaus.mp3"],
  ["Ralf", "German (Germany)", "de-DE", "579.png", "6.svg", "579-Ralf.mp3"],
  ["Katja", "German (Germany)", "de-DE", "161.png", "6.svg", "de-DE-katja.mp3"],
  ["Conrad", "German (Germany)", "de-DE", "162.png", "6.svg", "de-DE-conrad.mp3"],
  ["Fabiola", "Italian", "it-IT", "614.png", "7.svg", "614-Fabiola.mp3"],
  ["Fiamma", "Italian", "it-IT", "615.png", "7.svg", "615-Fiamma.mp3"],
  ["Imelda", "Italian", "it-IT", "616.png", "7.svg", "616-Imelda.mp3"],
  ["Irma", "Italian", "it-IT", "617.png", "7.svg", "617-Irma.mp3"],
  ["Palmira", "Italian", "it-IT", "618.png", "7.svg", "618-Palmira.mp3"],
  ["Pierina", "Italian", "it-IT", "619.png", "7.svg", "619-Pierina.mp3"],
  ["Benigno", "Italian", "it-IT", "620.png", "7.svg", "620-Benigno.mp3"],
  ["Calimero", "Italian", "it-IT", "621.png", "7.svg", "621-Calimero.mp3"],
  ["Cataldo", "Italian", "it-IT", "622.png", "7.svg", "622-Cataldo.mp3"],
  ["Gianni", "Italian", "it-IT", "623.png", "7.svg", "623-Gianni.mp3"],
  ["Lisandro", "Italian", "it-IT", "624.png", "7.svg", "624-Lisandro.mp3"],
  ["Rinaldo", "Italian", "it-IT", "625.png", "7.svg", "625-Rinaldo.mp3"],
  ["Elsa", "Italian", "it-IT", "175.png", "7.svg", "Elsa.mp3"],
  ["Isabella", "Italian", "it-IT", "176.png", "7.svg", "Isabella.mp3"],
  ["Diego", "Italian", "it-IT", "177.png", "7.svg", "italyanca-Diago.mp3"],
  ["Isabella", "Spanish (Spain)", "es-ES", "687.png", "8.svg", "687-Isabella.mp3"],
  ["Elvira", "Spanish (Spain)", "es-ES", "206.png", "8.svg", "Elvira.mp3"],
  ["Alvaro", "Spanish (Spain)", "es-ES", "207.png", "8.svg", "Alvaro.mp3"],
  ["Dariya", "Russian", "ru-RU", "197.png", "9.svg", "Rusca-Dariya.mp3"],
  ["Svetlana", "Russian", "ru-RU", "198.png", "9.svg", "Rusca-Svetlana.mp3"],
  ["Dmitry", "Russian", "ru-RU", "199.png", "9.svg", "4russia-Dimitri.mp3"],
  ["Xiaochen", "Chinese (Mandarin)", "zh-CN", "536.png", "10.svg", "536-Xiaochen.mp3"],
  ["Xiaoqiu", "Chinese (Mandarin)", "zh-CN", "537.png", "10.svg", "537-Xiaoqiu.mp3"],
  ["Xiaoshuang", "Chinese (Mandarin)", "zh-CN", "538.png", "10.svg", "538-Xiaoshuang.mp3"],
  ["Xiaoyan", "Chinese (Mandarin)", "zh-CN", "539.png", "10.svg", "539-Xiaoyan.mp3"],
  ["Xiaohan", "Chinese (Mandarin)", "zh-CN", "294.png", "10.svg", "294-Xiaohan.mp3"],
  ["Xiaomo", "Chinese (Mandarin)", "zh-CN", "295.png", "10.svg", "295-Xiaomo.mp3"],
  ["Xiaorui", "Chinese (Mandarin)", "zh-CN", "296.png", "10.svg", "296-Xiaorui.mp3"],
  ["Xiaoxuan", "Chinese (Mandarin)", "zh-CN", "298.png", "10.svg", "298-Xiaoxuan.mp3"],
  ["Yunxi", "Chinese (Mandarin)", "zh-CN", "300.png", "10.svg", "300-Yunxi.mp3"],
  ["Yunyang", "Chinese (Mandarin)", "zh-CN", "301.png", "10.svg", "301-Yunyang.mp3"],
  ["Yunfeng", "Chinese (Mandarin)", "zh-CN", "604.png", "10.svg", "604-Yunfeng.mp3"],
  ["Yunhao", "Chinese (Mandarin)", "zh-CN", "605.png", "10.svg", "605-Yunhao.mp3"],
  ["Yunjian", "Chinese (Mandarin)", "zh-CN", "606.png", "10.svg", "606-Yunjian.mp3"],
  ["Xiaoxiao", "Chinese (Mandarin)", "zh-CN", "121.png", "10.svg", "Xiaoxiao.mp3"],
  ["Xiaoyou", "Chinese (Mandarin)", "zh-CN", "122.png", "10.svg", "xiaoyou.mp3"],
  ["Yunye", "Chinese (Mandarin)", "zh-CN", "123.png", "10.svg", "yunye.mp3"],
  ["HsiaoChen", "Taiwan", "zh-TW", "124.png", "11.svg", "HsiaoChen.mp3"],
  ["HsiaoYu", "Taiwan", "zh-TW", "125.png", "11.svg", "HsiaoYu.mp3"],
  ["YunJhe", "Taiwan", "zh-TW", "126.png", "11.svg", "YunJhe.mp3"],
  ["Vlasta", "Czech", "cs-CZ", "129.png", "12.svg", "Vlasta.mp3"],
  ["Antonin", "Czech", "cs-CZ", "130.png", "12.svg", "Antonin.mp3"],
  ["Liam", "Danish", "da-DK", "62.png", "13.svg", "62-Liam.mp3"],
  ["Olivia", "Danish", "da-DK", "64.png", "13.svg", "Olivia.mp3"],
  ["Christel", "Danish", "da-DK", "131.png", "13.svg", "christel.mp3"],
  ["Jeppe", "Danish", "da-DK", "132.png", "13.svg", "Jeppe.mp3"],
  ["Liam", "Danish", "da-DK", "139.png", "13.svg", "Liam.mp3"],
  ["Siti", "Indonesian", "id-ID", "84.png", "14.svg", "Siti.mp3"],
  ["Gadis", "Indonesian", "id-ID", "173.png", "14.svg", "Gadis.mp3"],
  ["Ardi", "Indonesian", "id-ID", "174.png", "14.svg", "Ardi.mp3"],
  ["Hillevi", "Swedish", "sv-SE", "208.png", "15.svg", "hillevi.mp3"],
  ["Sofie", "Swedish", "sv-SE", "209.png", "15.svg", "sofie.mp3"],
  ["Mattias", "Swedish", "sv-SE", "210.png", "15.svg", "Mattias.mp3"],
  ["Swara", "Hindi", "hi-IN", "169.png", "16.svg", "Swara.mp3"],
  ["Madhur", "Hindi", "hi-IN", "170.png", "16.svg", "Madhur.mp3"],
  ["Colette", "Dutch (Netherlands)", "nl-NL", "133.png", "17.svg", "Colette.mp3"],
  ["Fenna", "Dutch (Netherlands)", "nl-NL", "134.png", "17.svg", "Fenna.mp3"],
  ["Maarten", "Dutch (Netherlands)", "nl-NL", "135.png", "17.svg", "135-Maarten.mp3"],
  ["Blessica", "Filipino", "fil-PH", "363.png", "18.svg", "363-Blessica.mp3"],
  ["Angelo", "Filipino", "fil-PH", "364.png", "18.svg", "364-Angelo.mp3"],
  ["Noora", "Finnish (Finland)", "fi-FI", "150.png", "19.svg", "Noora.mp3"],
  ["Selma", "Finnish (Finland)", "fi-FI", "151.png", "19.svg", "Selma.mp3"],
  ["Harri", "Finnish (Finland)", "fi-FI", "152.png", "19.svg", "Harri.mp3"],
  ["Nanami", "Japanese", "ja-JP", "178.png", "20.svg", "nanami.mp3"],
  ["Keita", "Japanese", "ja-JP", "179.png", "20.svg", "keita.mp3"],
  ["SunHi", "Korean", "ko-KR", "180.png", "21.svg", "Sunhi.mp3"],
  ["InJoon", "Korean", "ko-KR", "181.png", "21.svg", "InJoon.mp3"],
  ["Noemi", "Hungarian", "hu-HU", "171.png", "22.svg", "noemi.mp3"],
  ["Tamas", "Hungarian", "hu-HU", "172.png", "22.svg", "tamas.mp3"],
  ["Sofie", "Norwegian", "nb-NO", "88.png", "23.svg", "88-Sofia.mp3"],
  ["Maja", "Norwegian", "nb-NO", "90.png", "23.svg", "Maja.mp3"],
  ["Iselin", "Norwegian", "nb-NO", "184.png", "23.svg", "Iselin.mp3"],
  ["Pernille", "Norwegian", "nb-NO", "185.png", "23.svg", "Pernille.mp3"],
  ["Finn", "Norwegian", "nb-NO", "186.png", "23.svg", "Norvecce-Finn.mp3"],
  ["Polina", "Ukrainian", "uk-UA", "510.png", "24.svg", "510-Polina.mp3"],
  ["Ostap", "Ukrainian", "uk-UA", "511.png", "24.svg", "511-Ostap.mp3"],
  ["Zofia", "Polish (Poland)", "pl-PL", "92.png", "25.svg", "92-Zofia.mp3"],
  ["Agnieszka", "Polish (Poland)", "pl-PL", "187.png", "25.svg", "Agnieszka.mp3"],
  ["Zofia", "Polish (Poland)", "pl-PL", "188.png", "25.svg", "Zofia.mp3"],
  ["Marek", "Polish (Poland)", "pl-PL", "189.png", "25.svg", "Lehce-(Polonya)-Marek.mp3"],
  ["Fernanda", "Portuguese", "pt-PT", "192.png", "26.svg", "Fernanda.mp3"],
  ["Raquel", "Portuguese", "pt-PT", "193.png", "26.svg", "Raquel.mp3"],
  ["Duarte", "Portuguese", "pt-PT", "194.png", "26.svg", "Portekizce-Duarte.mp3"],
  ["Viktoria", "Slovak (Slovakia)", "sk-SK", "200.png", "27.svg", "2slovakca-Viktoria.mp3"],
  ["Lukas", "Slovak (Slovakia)", "sk-SK", "201.png", "27.svg", "3slovakca-Lukas.mp3"],
  ["HoaiMy", "Vietnamese", "vi-VN", "220.png", "28.svg", "HoaiMy.mp3"],
  ["NamMinh", "Vietnamese", "vi-VN", "221.png", "28.svg", "NamMinh.mp3"],
  ["Athina", "Greek", "el-GR", "165.png", "29.svg", "Athina.mp3"],
  ["Nestoras", "Greek", "el-GR", "166.png", "29.svg", "Nestoras.mp3"],
  ["Clara", "French (France)", "fr-FR", "35.png", "30.svg", "35-Clara.mp3"],
  ["Brigitte", "French (France)", "fr-FR", "553.png", "30.svg", "553-Brigitte.mp3"],
  ["Celeste", "French (France)", "fr-FR", "554.png", "30.svg", "554-Celeste.mp3"],
  ["Coralie", "French (France)", "fr-FR", "555.png", "30.svg", "555-Coralie.mp3"],
  ["Eloise [Kid]", "French (France)", "fr-FR", "556.png", "30.svg", "556-Eloise.mp3"],
  ["Jacqueline", "French (France)", "fr-FR", "557.png", "30.svg", "557-Jacqueline.mp3"],
  ["Josephine", "French (France)", "fr-FR", "558.png", "30.svg", "558-Josephine.mp3"],
  ["Yvette", "French (France)", "fr-FR", "559.png", "30.svg", "559-Yvette.mp3"],
  ["Alain", "French (France)", "fr-FR", "560.png", "30.svg", "560-Alain.mp3"],
  ["Claude", "French (France)", "fr-FR", "561.png", "30.svg", "561-Claude.mp3"],
  ["Jerome", "French (France)", "fr-FR", "562.png", "30.svg", "562-Jerome.mp3"],
  ["Maurice", "French (France)", "fr-FR", "563.png", "30.svg", "563-Maurice.mp3"],
  ["Yves", "French (France)", "fr-FR", "564.png", "30.svg", "564-Yves.mp3"],
  ["Denise", "French (France)", "fr-FR", "155.png", "30.svg", "Denise.mp3"],
  ["Henri", "French (France)", "fr-FR", "156.png", "30.svg", "fr-FR-henri.mp3"],
  ["سماء", "Arabic (Egypt)", "ar-EG", "109.png", "31.svg", "arabic-egypt2.mp3"],
  ["يوسف", "Arabic (Egypt)", "ar-EG", "110.png", "31.svg", "arabic-egypt1.mp3"],
  ["Kalina", "Bulgarian", "bg-BG", "113.png", "32.svg", "kalina.mp3"],
  ["Borislav", "Bulgarian", "bg-BG", "114.png", "32.svg", "borislav.mp3"],
  ["Alba", "Catalan (Spanish)", "ca-ES", "115.png", "33.svg", "Alba.mp3"],
  ["Joana", "Catalan (Spanish)", "ca-ES", "116.png", "33.svg", "Joana.mp3"],
  ["Enric", "Catalan (Spanish)", "ca-ES", "117.png", "33.svg", "Enric.mp3"],
  ["HiuGaai", "Chinese (Cantoneo)", "zh-HK", "118.png", "34.svg", "HiuGaai.mp3"],
  ["HiuMaan", "Chinese (Cantoneo)", "zh-HK", "119.png", "34.svg", "HiuMaan.mp3"],
  ["WanLung", "Chinese (Cantoneo)", "zh-HK", "120.png", "34.svg", "WanLung.mp3"],
  ["Gabrijela", "Croatian", "hr-HR", "127.png", "35.svg", "Gabrijela.mp3"],
  ["Srecko", "Croatian", "hr-HR", "128.png", "35.svg", "Srecko.mp3"],
  ["Clara", "English (Canada)", "en-CA", "138.png", "36.svg", "clara.mp3"],
  ["Neerja", "English (India)", "en-IN", "140.png", "37.svg", "Neerja.mp3"],
  ["Prabhat", "English (India)", "en-IN", "141.png", "37.svg", "prabhat.mp3"],
  ["Emily", "English (Ireland)", "en-IE", "142.png", "38.svg", "emily.mp3"],
  ["Connor", "English (Ireland)", "en-IE", "143.png", "38.svg", "connor.mp3"],
  ["Antoine", "French (Canada)", "fr-CA", "371.png", "39.svg", "371-Antoine.mp3"],
  ["Sylvie", "French (Canada)", "fr-CA", "153.png", "39.svg", "Sylvie.mp3"],
  ["Jean", "French (Canada)", "fr-CA", "154.png", "39.svg", "Jean.mp3"],
  ["Ariane", "French (Switzerland)", "fr-CH", "157.png", "40.svg", "ariane.mp3"],
  ["Fabrice", "French (Switzerland)", "fr-CH", "158.png", "40.svg", "fabrice.mp3"],
  ["Ingrid", "German (Austria)", "de-AT", "159.png", "41.svg", "Ingrid.mp3"],
  ["Jonas", "German (Austria)", "de-AT", "160.png", "41.svg", "jonas.mp3"],
  ["Leni", "German (Switzerland)", "de-CH", "163.png", "42.svg", "Leni.mp3"],
  ["Jan", "German (Switzerland)", "de-CH", "164.png", "42.svg", "Jan.mp3"],
  ["Hila", "Hebrew (Israel)", "he-IL", "167.png", "43.svg", "Avri.mp3"],
  ["Avri", "Hebrew (Israel)", "he-IL", "168.png", "43.svg", "Hila.mp3"],
  ["Siti", "Malay (Malaysia)", "ms-MY", "675.png", "44.svg", "675-Siti.mp3"],
  ["Yasmin", "Malay (Malaysia)", "ms-MY", "182.png", "44.svg", "Yasmin.mp3"],
  ["Osman", "Malay (Malaysia)", "ms-MY", "183.png", "44.svg", "Osman.mp3"],
  ["Brenda", "Portuguese (Brazil)", "pt-BR", "626.png", "45.svg", "626-Brenda.mp3"],
  ["Elza", "Portuguese (Brazil)", "pt-BR", "627.png", "45.svg", "627-Elza.mp3"],
  ["Giovanna", "Portuguese (Brazil)", "pt-BR", "628.png", "45.svg", "628-Giovanna.mp3"],
  ["Leila", "Portuguese (Brazil)", "pt-BR", "629.png", "45.svg", "629-Leila.mp3"],
  ["Leticia", "Portuguese (Brazil)", "pt-BR", "630.png", "45.svg", "630-Leticia.mp3"],
  ["Manuela", "Portuguese (Brazil)", "pt-BR", "631.png", "45.svg", "631-Manuela.mp3"],
  ["Yara", "Portuguese (Brazil)", "pt-BR", "632.png", "45.svg", "632-Yara.mp3"],
  ["Donato", "Portuguese (Brazil)", "pt-BR", "633.png", "45.svg", "633-Donato.mp3"],
  ["Fabio", "Portuguese (Brazil)", "pt-BR", "634.png", "45.svg", "634-Fabio.mp3"],
  ["Humberto", "Portuguese (Brazil)", "pt-BR", "635.png", "45.svg", "635-Humberto.mp3"],
  ["Julio", "Portuguese (Brazil)", "pt-BR", "636.png", "45.svg", "636-Julio.mp3"],
  ["Nicolau", "Portuguese (Brazil)", "pt-BR", "637.png", "45.svg", "637-Nicolau.mp3"],
  ["Valerio", "Portuguese (Brazil)", "pt-BR", "638.png", "45.svg", "638-Valerio.mp3"],
  ["Maria", "Portuguese (Brazil)", "pt-BR", "680.png", "45.svg", "680-Maria.mp3"],
  ["Francisca", "Portuguese (Brazil)", "pt-BR", "190.png", "45.svg", "1prtkz-Francisca.mp3"],
  ["Antonio", "Portuguese (Brazil)", "pt-BR", "191.png", "45.svg", "2prtkz-Antonio.mp3"],
  ["Alina", "Romanian (Romania)", "ro-RO", "195.png", "46.svg", "1rumence-Alina.mp3"],
  ["Emil", "Romanian (Romania)", "ro-RO", "196.png", "46.svg", "2rumence-Emil.mp3"],
  ["Petra", "Slovenian", "sl-SI", "202.png", "47.svg", "1slovence-petra.mp3"],
  ["Rok", "Slovenian", "sl-SI", "203.png", "47.svg", "2slovence-rok.mp3"],
  ["Beatriz", "Spanish (Mexico)", "es-MX", "639.png", "48.svg", "639-Beatriz.mp3"],
  ["Candela", "Spanish (Mexico)", "es-MX", "640.png", "48.svg", "640-Candela.mp3"],
  ["Carlota", "Spanish (Mexico)", "es-MX", "641.png", "48.svg", "641-Carlota.mp3"],
  ["Larissa", "Spanish (Mexico)", "es-MX", "642.png", "48.svg", "642-Larissa.mp3"],
  ["Marina", "Spanish (Mexico)", "es-MX", "643.png", "48.svg", "643-Marina.mp3"],
  ["Nuria", "Spanish (Mexico)", "es-MX", "644.png", "48.svg", "644-Nuria.mp3"],
  ["Renata", "Spanish (Mexico)", "es-MX", "645.png", "48.svg", "645-Renata.mp3"],
  ["Cecilio", "Spanish (Mexico)", "es-MX", "646.png", "48.svg", "646-Cecilio.mp3"],
  ["Gerardo", "Spanish (Mexico)", "es-MX", "647.png", "48.svg", "647-Gerardo.mp3"],
  ["Liberto", "Spanish (Mexico)", "es-MX", "648.png", "48.svg", "648-Liberto.mp3"],
  ["Luciano", "Spanish (Mexico)", "es-MX", "649.png", "48.svg", "649-Luciano.mp3"],
  ["Pelayo", "Spanish (Mexico)", "es-MX", "650.png", "48.svg", "650-Pelayo.mp3"],
  ["Yago", "Spanish (Mexico)", "es-MX", "651.png", "48.svg", "651-Yago.mp3"],
  ["Dalia", "Spanish (Mexico)", "es-MX", "204.png", "48.svg", "dalia.mp3"],
  ["Jorge", "Spanish (Mexico)", "es-MX", "205.png", "48.svg", "jorge.mp3"],
  ["Achara", "Thai (Thailand)", "th-TH", "215.png", "49.svg", "Anchara-tailand.mp3"],
  ["Premwadee", "Thai (Thailand)", "th-TH", "216.png", "49.svg", "Premwadee-tailand.mp3"],
  ["Niwat", "Thai (Thailand)", "th-TH", "217.png", "49.svg", "Niwat-tailand.mp3"],
  ["Pallavi", "Tamil (India)", "ta-IN", "211.png", "50.svg", "pallavi-tamil.mp3"],
  ["Valluvar", "Tamil (India)", "ta-IN", "212.png", "50.svg", "vulluvar-tamil.mp3"],
  ["Shruti", "Telugu (India)", "te-IN", "213.png", "51.svg", "Shruti-telugu.mp3"],
  ["Mohan", "Telugu (India)", "te-IN", "214.png", "51.svg", "Mohan-Telugu.mp3"],
  ["Dena", "Dutch (Belgium)", "nl-BE", "313.png", "52.svg", "313-Dena.mp3"],
  ["Arnaud", "Dutch (Belgium)", "nl-BE", "314.png", "52.svg", "314-Arnaud.mp3"],
  ["Yan", "English (Hongkong)", "en-HK", "322.png", "53.svg", "322-Yan.mp3"],
  ["Sam", "English (Hongkong)", "en-HK", "323.png", "53.svg", "323-Sam.mp3"],
  ["Molly", "English (New Zealand)", "en-NZ", "330.png", "54.svg", "330-Molly.mp3"],
  ["Mitchell", "English (New Zealand)", "en-NZ", "331.png", "54.svg", "331-Mitchell.mp3"],
  ["Rosa", "English (Philippines)", "en-PH", "334.png", "55.svg", "334-Rosa.mp3"],
  ["James", "English (Philippines)", "en-PH", "335.png", "55.svg", "335-James.mp3"],
  ["Luna", "English (Singapore)", "en-SG", "336.png", "56.svg", "336-Luna.mp3"],
  ["Wayne", "English (Singapore)", "en-SG", "337.png", "56.svg", "337-Wayne.mp3"],
  ["Leah", "English (South Africa)", "en-ZA", "338.png", "57.svg", "338-Leah.mp3"],
  ["Luke", "English (South Africa)", "en-ZA", "339.png", "57.svg", "339-Luke.mp3"],
  ["Anu", "Estonian (Estonia)", "et-EE", "361.png", "58.svg", "361-Anu.mp3"],
  ["Kert", "Estonian (Estonia)", "et-EE", "362.png", "58.svg", "362-Kert.mp3"],
  ["Charline", "French (Belgium)", "fr-BE", "368.png", "59.svg", "368-Charline.mp3"],
  ["Gerard", "French (Belgium)", "fr-BE", "369.png", "59.svg", "369-Gerard.mp3"],
  ["Dhwani", "Gujarati (India)", "gu-IN", "387.png", "60.svg", "387-Dhwani.mp3"],
  ["Niranjan", "Gujarati (India)", "gu-IN", "388.png", "60.svg", "388-Niranjan.mp3"],
  ["Orla", "Irish (Ireland)", "ga-IE", "397.png", "61.svg", "397-Orla.mp3"],
  ["Colm", "Irish (Ireland)", "ga-IE", "398.png", "61.svg", "398-Colm.mp3"],
  ["Everita", "Latvian (Latvia)", "lv-LV", "410.png", "62.svg", "410-Everita.mp3"],
  ["Nils", "Latvian (Latvia)", "lv-LV", "411.png", "62.svg", "411-Nils.mp3"],
  ["Ona", "Lithuanian (Lithuania)", "lt-LT", "412.png", "63.svg", "412-Ona.mp3"],
  ["Leonas", "Lithuanian (Lithuania)", "lt-LT", "413.png", "63.svg", "413-Leonas.mp3"],
  ["Grace", "Maltese (Malta)", "mt-MT", "416.png", "64.svg", "416-Grace.mp3"],
  ["Aarohi", "Marathi (India)", "mr-IN", "418.png", "65.svg", "418-Aarohi.mp3"],
  ["Manohar", "Marathi (India)", "mr-IN", "419.png", "65.svg", "419-Manohar.mp3"],
  ["Elena", "Spanish (Argentina)", "es-AR", "444.png", "66.svg", "444-Elena.mp3"],
  ["Tomas", "Spanish (Argentina)", "es-AR", "445.png", "66.svg", "445-Tomas.mp3"],
  ["Salome", "Spanish (Colombia)", "es-CO", "450.png", "67.svg", "450-Salome.mp3"],
  ["Gonzalo", "Spanish (Colombia)", "es-CO", "451.png", "67.svg", "451-Gonzalo.mp3"],
  ["Luis", "Spanish (United States)", "es-US", "690.png", "68.svg", "690-Luis.mp3"],
  ["Manuel", "Spanish (United States)", "es-US", "691.png", "68.svg", "691-Manuel.mp3"],
  ["Paloma", "Spanish (United States)", "es-US", "484.png", "68.svg", "484-Paloma.mp3"],
  ["Alonso", "Spanish (United States)", "es-US", "485.png", "68.svg", "485-Alonso.mp3"],
  ["Zuri", "Swahili (Kenya)", "sw-KE", "490.png", "69.svg", "490-Zuri.mp3"],
  ["Rafiki", "Swahili (Kenya)", "sw-KE", "491.png", "69.svg", "491-Rafiki.mp3"],
  ["Uzma", "Urdu (Pakistan)", "ur-PK", "514.png", "70.svg", "514-Uzma.mp3"],
  ["Asad", "Urdu (Pakistan)", "ur-PK", "515.png", "70.svg", "515-Asad.mp3"],
  ["Nia", "Welsh (United Kingdom)", "cy-GB", "520.png", "71.svg", "520-Nia.mp3"],
  ["Aled", "Welsh (United Kingdom)", "cy-GB", "521.png", "71.svg", "521-Aled.mp3"],
  ["Tanishaa", "Bengali (India)", "bn-IN", "532.png", "72.svg", "532-Tanishaa.mp3"],
  ["Bashkar", "Bengali (India)", "bn-IN", "533.png", "72.svg", "533-Bashkar.mp3"],
  ["Sapna", "Kannada (India)", "kn-IN", "582.png", "73.svg", "582-Sapna.mp3"],
  ["Gagan", "Kannada (India)", "kn-IN", "583.png", "73.svg", "583-Gagan.mp3"],
  ["Sobhana", "Malayalam (India)", "ml-IN", "590.png", "74.svg", "590-Sobhana.mp3"],
  ["Midhun", "Malayalam (India)", "ml-IN", "591.png", "74.svg", "591-Midhun.mp3"],
  ["Laila", "Arabic (Bahrain)", "ar-BH", "256.png", "76.svg", "256-Laila.mp3"],
  ["Ali", "Arabic (Bahrain)", "ar-BH", "257.png", "76.svg", "257-Ali.mp3"],
  ["Rana", "Arabic (Iraq)", "ar-IQ", "260.png", "77.svg", "260-Rana.mp3"],
  ["Bassel", "Arabic (Iraq)", "ar-IQ", "261.png", "77.svg", "261-Bassel.mp3"],
  ["Sana", "Arabic (Jordan)", "ar-JO", "262.png", "78.svg", "262-Sana.mp3"],
  ["Taim", "Arabic (Jordan)", "ar-JO", "263.png", "78.svg", "263-Taim.mp3"],
  ["Noura", "Arabic (Kuwait)", "ar-KW", "264.png", "79.svg", "264-Noura.mp3"],
  ["Fahed", "Arabic (Kuwait)", "ar-KW", "265.png", "79.svg", "265-Fahed.mp3"],
  ["Iman", "Arabic (Libya)", "ar-LY", "266.png", "80.svg", "266-Iman.mp3"],
  ["Omar", "Arabic (Libya)", "ar-LY", "267.png", "80.svg", "267-Omar.mp3"],
  ["Mouna", "Arabic (Morocco)", "ar-MA", "268.png", "81.svg", "268-Mouna.mp3"],
  ["Jamal", "Arabic (Morocco)", "ar-MA", "269.png", "81.svg", "269-Jamal.mp3"],
  ["Amal", "Arabic (Qatar)", "ar-QA", "270.png", "82.svg", "270-Amal.mp3"],
  ["Moaz", "Arabic (Qatar)", "ar-QA", "271.png", "82.svg", "271-Moaz.mp3"],
  ["Amany", "Arabic (Syria)", "ar-SY", "274.png", "83.svg", "274-Amany.mp3"],
  ["Laith", "Arabic (Syria)", "ar-SY", "275.png", "83.svg", "275-Laith.mp3"],
  ["Reem", "Arabic (Tunisia)", "ar-TN", "276.png", "84.svg", "276-Reem.mp3"],
  ["Hedi", "Arabic (Tunisia)", "ar-TN", "277.png", "84.svg", "277-Hedi.mp3"],
  ["Fatima", "Arabic (United Arab Emirates)", "ar-AE", "278.png", "85.svg", "278-Fatima.mp3"],
  ["Hamdan", "Arabic (United Arab Emirates)", "ar-AE", "279.png", "85.svg", "279-Hamdan.mp3"],
  ["Maryam", "Arabic (Yemen)", "ar-YE", "280.png", "86.svg", "280-Maryam.mp3"],
  ["Saleh", "Arabic (Yemen)", "ar-YE", "281.png", "86.svg", "281-Saleh.mp3"],
  ["Nabanita", "Bengali (Bangladesh)", "bn-BD", "282.png", "87.svg", "282-Nabanita.mp3"],
  ["Pradeep", "Bengali (Bangladesh)", "bn-BD", "283.png", "87.svg", "283-Pradeep.mp3"],
  ["Nilar", "Burmese (Myanmar)", "my-MM", "286.png", "88.svg", "286-Nilar.mp3"],
  ["Thiha", "Burmese (Myanmar)", "my-MM", "287.png", "88.svg", "287-Thiha.mp3"],
  ["Asilia", "English (Kenya)", "en-KE", "328.png", "89.svg", "328-Asilia.mp3"],
  ["Chilemba", "English (Kenya)", "en-KE", "329.png", "89.svg", "329-Chilemba.mp3"],
  ["Ezinne", "English (Nigeria)", "en-NG", "332.png", "90.svg", "332-Ezinne.mp3"],
  ["Imani", "English (Tanzania)", "en-TZ", "340.png", "91.svg", "340-Imani.mp3"],
  ["Elimu", "English (Tanzania)", "en-TZ", "341.png", "91.svg", "341-Elimu.mp3"],
  ["Sabela", "Galician (Spain)", "gl-ES", "377.png", "92.svg", "377-Sabela.mp3"],
  ["Roi", "Galician (Spain)", "gl-ES", "378.png", "92.svg", "378-Roi.mp3"],
  ["Siti", "Javanese (Indonesia)", "jv-ID", "404.png", "93.svg", "404-Siti.mp3"],
  ["Dimas", "Javanese (Indonesia)", "jv-ID", "405.png", "93.svg", "405-Dimas.mp3"],
  ["Sreymom", "Khmer (Cambodia)", "km-KH", "406.png", "94.svg", "406-Sreymom.mp3"],
  ["Piseth", "Khmer (Cambodia)", "km-KH", "407.png", "94.svg", "407-Piseth.mp3"],
  ["Dilara", "Persian (Iran)", "fa-IR", "423.png", "95.svg", "423-Dilara.mp3"],
  ["Farid", "Persian (Iran)", "fa-IR", "424.png", "95.svg", "424-Farid.mp3"],
  ["Ubax", "Somali (Somalia)", "so-SO", "442.png", "96.svg", "442-Ubax.mp3"],
  ["Muuse", "Somali (Somalia)", "so-SO", "443.png", "96.svg", "443-Muuse.mp3"],
  ["Sofia", "Spanish (Bolivia)", "es-BO", "446.png", "97.svg", "446-Sofia.mp3"],
  ["Marcelo", "Spanish (Bolivia)", "es-BO", "447.png", "97.svg", "447-Marcelo.mp3"],
  ["Catalina", "Spanish (Chile)", "es-CL", "448.png", "98.svg", "448-Catalina.mp3"],
  ["Lorenzo", "Spanish (Chile)", "es-CL", "449.png", "98.svg", "449-Lorenzo.mp3"],
  ["Maria", "Spanish (Costa Rica)", "es-CR", "452.png", "99.svg", "452-Maria.mp3"],
  ["Juan", "Spanish (Costa Rica)", "es-CR", "453.png", "99.svg", "453-Juan.mp3"],
  ["Belkys", "Spanish (Cuba)", "es-CU", "454.png", "100.svg", "454-Belkys.mp3"],
  ["Manuel", "Spanish (Cuba)", "es-CU", "455.png", "100.svg", "455-Manuel.mp3"],
  ["Ramona", "Spanish (Dominican Republic)", "es-DO", "456.png", "101.svg", "456-Ramona.mp3"],
  ["Emilio", "Spanish (Dominican Republic)", "es-DO", "457.png", "101.svg", "457-Emilio.mp3"],
  ["Andrea", "Spanish (Ecuador)", "es-EC", "458.png", "102.svg", "458-Andrea.mp3"],
  ["Luis", "Spanish (Ecuador)", "es-EC", "459.png", "102.svg", "459-Luis.mp3"],
  ["Lorena", "Spanish (El Salvador)", "es-SV", "460.png", "103.svg", "460-Lorena.mp3"],
  ["Rodrigo", "Spanish (El Salvador)", "es-SV", "461.png", "103.svg", "461-Rodrigo.mp3"],
  ["Teresa", "Spanish (Equatorial Guinea)", "es-GQ", "462.png", "104.svg", "462-Teresa.mp3"],
  ["Javier", "Spanish (Equatorial Guinea)", "es-GQ", "463.png", "104.svg", "463-Javier.mp3"],
  ["Marta", "Spanish (Guatemala)", "es-GT", "464.png", "105.svg", "464-Marta.mp3"],
  ["Andres", "Spanish (Guatemala)", "es-GT", "465.png", "105.svg", "465-Andres.mp3"],
  ["Karla", "Spanish (Honduras)", "es-HN", "466.png", "106.svg", "466-Karla.mp3"],
  ["Carlos", "Spanish (Honduras)", "es-HN", "467.png", "106.svg", "467-Carlos.mp3"],
  ["Yolanda", "Spanish (Nicaragua)", "es-NI", "470.png", "107.svg", "470-Yolanda.mp3"],
  ["Federico", "Spanish (Nicaragua)", "es-NI", "471.png", "107.svg", "471-Federico.mp3"],
  ["Margarita", "Spanish (Panama)", "es-PA", "472.png", "108.svg", "472-Margarita.mp3"],
  ["Roberto", "Spanish (Panama)", "es-PA", "473.png", "108.svg", "473-Roberto.mp3"],
  ["Tania", "Spanish (Paraguay)", "es-PY", "474.png", "109.svg", "474-Tania.mp3"],
  ["Mario", "Spanish (Paraguay)", "es-PY", "475.png", "109.svg", "475-Mario.mp3"],
  ["Camila", "Spanish (Peru)", "es-PE", "476.png", "110.svg", "476-Camila.mp3"],
  ["Alex", "Spanish (Peru)", "es-PE", "477.png", "110.svg", "477-Alex.mp3"],
  ["Karina", "Spanish (Puerto Rico)", "es-PR", "478.png", "111.svg", "478-Karina.mp3"],
  ["Victor", "Spanish (Puerto Rico)", "es-PR", "479.png", "111.svg", "479-Victor.mp3"],
  ["Valentina", "Spanish (Uruguay)", "es-UY", "482.png", "112.svg", "482-Valentina.mp3"],
  ["Mateo", "Spanish (Uruguay)", "es-UY", "483.png", "112.svg", "483-Mateo.mp3"],
  ["Paola", "Spanish (Venezuela)", "es-VE", "486.png", "113.svg", "486-Paola.mp3"],
  ["Sebastian", "Spanish (Venezuela)", "es-VE", "487.png", "113.svg", "487-Sebastian.mp3"],
  ["Tuti", "Sundanese (Indonesia)", "su-ID", "488.png", "114.svg", "488-Tuti.mp3"],
  ["Jajang", "Sundanese (Indonesia)", "su-ID", "489.png", "114.svg", "489-Jajang.mp3"],
  ["Rehema", "Swahili (Tanzania)", "sw-TZ", "492.png", "115.svg", "492-Rehema.mp3"],
  ["Daudi", "Swahili (Tanzania)", "sw-TZ", "493.png", "115.svg", "493-Daudi.mp3"],
  ["Venba", "Tamil (Singapore)", "ta-SG", "499.png", "116.svg", "499-Venba.mp3"],
  ["Anbu", "Tamil (Singapore)", "ta-SG", "500.png", "116.svg", "500-Anbu.mp3"],
  ["Saranya", "Tamil (Sri Lanka)", "ta-LK", "501.png", "117.svg", "501-Saranya.mp3"],
  ["Kumar", "Tamil (Sri Lanka)", "ta-LK", "502.png", "117.svg", "502-Kumar.mp3"],
  ["Gul", "Urdu (India)", "ur-IN", "512.png", "118.svg", "512-Gul.mp3"],
  ["Salman", "Urdu (India)", "ur-IN", "513.png", "118.svg", "513-Salman.mp3"],
  ["Madina", "Uzbek (Uzbekistan)", "uz-UZ", "516.png", "119.svg", "516-Madina.mp3"],
  ["Sardor", "Uzbek (Uzbekistan)", "uz-UZ", "517.png", "119.svg", "517-Sardor.mp3"],
  ["Thando", "Zulu (South Africa)", "zu-ZA", "522.png", "120.svg", "522-Thando.mp3"],
  ["Themba", "Zulu (South Africa)", "zu-ZA", "523.png", "120.svg", "523-Themba.mp3"],
  ["Anila", "Albanian (Albania)", "sq-AL", "524.png", "121.svg", "524-Anila.mp3"],
  ["Ilir", "Albanian (Albania)", "sq-AL", "525.png", "121.svg", "525-Ilir.mp3"],
  ["Layla", "Arabic (Lebanon)", "ar-LB", "526.png", "122.svg", "526-Layla.mp3"],
  ["Rami", "Arabic (Lebanon)", "ar-LB", "527.png", "122.svg", "527-Rami.mp3"],
  ["Aysha", "Arabic (Oman)", "ar-OM", "528.png", "123.svg", "528-Aysha.mp3"],
  ["Abdullah", "Arabic (Oman)", "ar-OM", "529.png", "123.svg", "529-Abdullah.mp3"],
  ["Babek", "Azerbaijani (Azerbaijan)", "az-AZ", "530.png", "124.svg", "531-Babek.mp3"],
  ["Banu", "Azerbaijani (Azerbaijan)", "az-AZ", "531.png", "124.svg", "530-Banu.mp3"],
  ["Adri", "Afrikaans (South Africa)", "af-ZA", "250.png", "125.svg", "250-Adri.mp3"],
  ["Willem", "Afrikaans (South Africa)", "af-ZA", "251.png", "125.svg", "251-Willem.mp3"],
  ["Vesna", "Bosnian (Bosnia and Herzegovina)", "bs-BA", "534.png", "126.svg", "534-Vesna.mp3"],
  ["Goran", "Bosnian (Bosnia and Herzegovina)", "bs-BA", "535.png", "126.svg", "535-Goran.mp3"],
  ["Eka", "Georgian (Georgia)", "ka-GE", "565.png", "127.svg", "565-Eka.mp3"],
  ["Gudrun", "Icelandic (Iceland)", "is-IS", "580.png", "128.svg", "580-Gudrun.mp3"],
  ["Gunnar", "Icelandic (Iceland)", "is-IS", "581.png", "128.svg", "581-Gunnar.mp3"],
  ["Mekdes", "Amharic (Ethiopia)", "am-ET", "252.png", "129.svg", "252-Mekdes.mp3"],
  ["Ameha", "Amharic (Ethiopia)", "am-ET", "253.png", "129.svg", "253-Ameha.mp3"],
  ["Aigul", "Kazakh (Kazakhstan)", "kk-KZ", "584.png", "130.svg", "584-Aigul.mp3"],
  ["Daulet", "Kazakh (Kazakhstan)", "kk-KZ", "585.png", "130.svg", "585-Daulet.mp3"],
  ["Keomany", "Lao (Laos)", "lo-LA", "586.png", "131.svg", "586-Keomany.mp3"],
  ["Chanthavong", "Lao (Laos)", "lo-LA", "587.png", "131.svg", "587-Chanthavong.mp3"],
  ["Marija", "Macedonian (Republic of North Macedonia)", "mk-MK", "588.png", "132.svg", "588-Marija.mp3"],
  ["Aleksandar", "Macedonian (Republic of North Macedonia)", "mk-MK", "589.png", "132.svg", "589-Aleksandar.mp3"],
  ["Amina", "Arabic (Algeria)", "ar-DZ", "254.png", "133.svg", "254-Amina.mp3"],
  ["Ismael", "Arabic (Algeria)", "ar-DZ", "255.png", "133.svg", "255-ismael.mp3"],
  ["Yesui", "Mongolian (Mongolia)", "mn-MN", "592.png", "134.svg", "592-Yesui.mp3"],
  ["Bataa", "Mongolian (Mongolia)", "mn-MN", "593.png", "134.svg", "593-Bataa.mp3"],
  ["Hemkala", "Nepali (Nepal)", "ne-NP", "594.png", "135.svg", "594-Hemkala.mp3"],
  ["Sagar", "Nepali (Nepal)", "ne-NP", "595.png", "135.svg", "595-Sagar.mp3"],
  ["Latifa", "Pashto (Afghanistan)", "ps-AF", "596.png", "136.svg", "596-Latife.mp3"],
  ["GulNawaz", "Pashto (Afghanistan)", "ps-AF", "597.png", "136.svg", "597-GulNawaz.mp3"],
  ["Sophie", "Serbian (Serbia, Cyrillic)", "sr-RS", "598.png", "137.svg", "598-Sophie.mp3"],
  ["Nicholas", "Serbian (Serbia, Cyrillic)", "sr-RS", "599.png", "137.svg", "599-Nicholas.mp3"],
  ["Thilini", "Sinhala (Sri Lanka)", "si-LK", "600.png", "138.svg", "600-Thilini.mp3"],
  ["Sameera", "Sinhala (Sri Lanka)", "si-LK", "601.png", "138.svg", "601-Sameera.mp3"],
  ["Kani", "Tamil (Malaysia)", "ta-MY", "602.png", "139.svg", "602-Kani.mp3"],
  ["Surya", "Tamil (Malaysia)", "ta-MY", "603.png", "139.svg", "603-Surya.mp3"],
]