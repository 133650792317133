import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";

import { GetFieldWidth } from "./commonFunction";
import { TimeLineContext } from "../timeline.config";
import { TimeCount_Store } from "../../../../store/actions/videoPlayAction";
import { SaveVideoPlaying_Store, SaveVideoBuffering_Store } from "../../../../store/actions/videoPlayAction"
import { loading_Store } from '../../../../store/actions/mainAction';
import {switchVideo_Store} from "../../../../store/actions/webgldata";

export const Legend = () => {
  const dispatch = useDispatch();
  const [timelineData] = useContext(TimeLineContext);
  const VideoPlaying = useSelector((store) => store.videodata.videoPlaying)
  const videoElements = useSelector((store) => store.webgldata.videoElements)
  const activeVideoUUID = useSelector(store => store.projectdata.activeVideoUUID);
  const legendInfo = timelineData.legendInfo;
  const [griddata, setGriddata] = useState({});
  useEffect(() => {
    const ItemsArr = ItemsArrCreate(legendInfo.diffCount);
    const { fieldW, lineW, startL } = GetFieldWidth(legendInfo);
    const newChildArr = Array.from({ length: legendInfo.sRuller - 1 }, (v, i) => i);
    const childWidth = fieldW / legendInfo.sChild;
    
    const newGridData = {
      fieldW: fieldW,
      itemArr: ItemsArr,
      childW: childWidth,
      childArr: newChildArr,
      style: { lineW: lineW, startL: startL, },
    }

    setGriddata(newGridData)
  }, [legendInfo])

  const ItemsArrCreate = (diff) => {
    let ArrCount = Math.ceil(timelineData.maxTime / diff);
    if (ArrCount < 0) ArrCount = 14;
    const ItemsArr = Array.from({ length: ArrCount }, (v, i) => (i + 1) * diff);
    return ItemsArr;
  }

  const onClick = (e, second) => {
    if (typeof second !== 'number') return;

    let offestVal = 0;
    const clientWidth = e.target.clientWidth;
    const centerPos = e.target.clientWidth / 2;
    //console.log('eventss', e, e.clientX, e.nativeEvent.offsetX, e.target.clientWidth, e.clientX/e.target.clientWidth);
    if(second > 0){
      if(centerPos == e.nativeEvent.offsetX)
      {
        offestVal = 0;
      }else if(centerPos > e.nativeEvent.offsetX){
        offestVal = (e.nativeEvent.offsetX - centerPos) / clientWidth;
      }else if(centerPos < e.nativeEvent.offsetX){
        offestVal = ((centerPos - e.nativeEvent.offsetX) / clientWidth) * -1;
      }
    }else{
      offestVal = (e.nativeEvent.offsetX / clientWidth) * 0.5;
    }
    
   // console.log('paint pos', legendInfo, offestVal, (second + offestVal), second, second + (offestVal*(legendInfo.diffCount / 2)))
    const offsetSec = legendInfo.zoom < 0? second + (offestVal*((legendInfo.diffCount-1) / legendInfo.sRuller)):second + offestVal;
    
    checkVideoPlay(offsetSec)
    
    dispatch(switchVideo_Store({
      isSwitching: false,
      videoID: ''
    }));
  }

  const checkVideoPlay = (sec) => {
    //console.log('vddd', VideoPlaying);
    if (VideoPlaying) {
      
      //dispatch(loading_Store(true))
      dispatch(SaveVideoBuffering_Store(true))
      dispatch(SaveVideoPlaying_Store(false))
      
      setTimeout(() => {
        dispatch(TimeCount_Store(new Date((sec) * 1000)));  
      }, 10);
      
      setTimeout(() => {
        //dispatch(loading_Store(false))
        dispatch(SaveVideoBuffering_Store(false))
        dispatch(SaveVideoPlaying_Store(true))
      }, 30)
    }else{
      dispatch(TimeCount_Store(new Date((sec) * 1000)));
      //dispatch(loading_Store(true));
      const interval = setInterval(function (){
        var playstates = []
        var getVkeys = Object.keys(videoElements);
        getVkeys.map(key => {
          if(videoElements[key].readyState === 3 || videoElements[key].readyState === 2 || videoElements[key].readyState === 1) {
            playstates.push(videoElements[key].readyState);
          }
        });
        if (playstates.length > 1) {
          dispatch(SaveVideoBuffering_Store(true))
        }
     //   console.log('videolog', playstates);
        if(playstates.length < 1){
          //dispatch(loading_Store(false));
          dispatch(SaveVideoBuffering_Store(false))
          clearInterval(interval);
        }
      }, 30);
    }
  }

  return (
    <>
      {Object.keys(griddata).length > 0 && (
        <div className="v-timeline-legend">
          <div className="v-legend-ruler">
            <div onClick={(e) => {onClick(e, 0) }}
              className="v-legend-ruler-first-bigger cursor-pointer"
              style={{ width: griddata.fieldW / 2, marginLeft: griddata.style.startL }}
            >
              <div className="v-legend-values">0</div>
            </div>

            {griddata.itemArr.map((item, index) => (
              <div className="v-legend-ruler-iterations" key={index}>
                <SmallRulers pointNumber={item}
                  legendInfo={legendInfo}
                  griddata={griddata}
                  onClick={onClick}
                />

                <div className="v-legend-ruler-last-bigger cursor-pointer"
                  onClick={(e) => { onClick(e, item) }}
                  style={{ width: griddata.fieldW }}
                >
                  <div className="v-legend-values">{item}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const SmallRulers = (props) => {
  const { griddata, legendInfo, pointNumber, onClick } = props;
  
  return (
    griddata.childArr.map((item, i) => (
      <div style={{ width: griddata.fieldW }} key={i}
        className="v-legend-ruler-smaller cursor-pointer"
        onClick={(e) => { onClick(e, legendInfo.zoom < 0 ? pointNumber - Math.ceil((legendInfo.diffCount-1) * (legendInfo.sRuller - i - 1) / legendInfo.sRuller) : pointNumber + i - 4); }}
      >
        {legendInfo.zoom > 1 ? <div className='smaller-value'>{pointNumber + i - 4}</div> : ''}
      </div >
    ))
  )
};
