export const Menu_options = [
	{
		title: 'Features',
		items: [
			{
				header: 'Video Editor',
				child: [
					{
						title: 'Add Image To Video',
						link: '../../videoeditor/add-image-to-video'
					}, {
						title: 'Add Music To Video',
						link: '../../videoeditor/add-music-to-video'
					}, {
						title: 'Add Text To Video',
						link: '../../videoeditor/add-text-to-video'
					}, {
						title: 'Filter Video',
						link: '../../videoeditor/filter-to-video'
					}, {
						title: 'Add Transitions to Video',
						link: '../../videoeditor/add-transitions-to-video'
					},]
			}, {
				header: 'Subtitles & Transcription',
				child: [
					{
						title: 'Add Subtitles To Video',
						link: '../../subtitlestranscription/add-subtitles'
					}, {
						title: 'Audio To Text',
						link: '../../subtitlestranscription/audio-to-text'
					}, {
						title: 'Auto Subtitles',
						link: '../../subtitlestranscription/auto-subtitles'
					}, {
						title: 'Transcribe Video',
						link: '../../subtitlestranscription/transcribe-video'
					}, {
						title: 'Translate Video',
						link: '../../subtitlestranscription/translate-video'
					},]
			}, {
				header: 'Toolkit',
				child: [
					{
						title: 'Crop',
						link: '../../tools/crop-video'
					}, {
						title: 'Cut',
						link: '../../tools/cut-video'
					}, {
						title: 'Join/Merge',
						link: '../../tools/joinmerge-video'
					}, {
						title: 'Loop Video',
						link: '../../tools/loop-video'
					}, {
						title: 'Resize',
						link: '../../tools/resize-video'
					}, {
						title: 'Rotate',
						link: '../../tools/rotate-video'
					},
					// {
					// 	title: 'Convert',
					// 	link: '../../tools/convert-video'
					// },
					// {
					// 	title: 'Compress',
					// 	link: '../../tools/compress-video'
					// }
				]
			},
			// {
			// 	header: 'Record',
			// 	child: [
			// 		{
			// 			title: 'Screen Recorder',
			// 			link: ''
			// 		}, {
			// 			title: 'Telprompter',
			// 			link: ''
			// 		}, {
			// 			title: 'Voice Recorder',
			// 			link: ''
			// 		}, {
			// 			title: 'Webcam Recorder',
			// 			link: ''
			// 		},]
			// }, 
			{
				header: 'More',
				child: [
			// 		{
			// 			title: 'GIF Editor',
			// 			link: ''
			// 		}, 
					{
						title: 'Video Compressor',
						link: '../../more/compress-video'
					}, {
						title: 'Video Converter',
						link: '../../more/convert-video'
					}, 
			// {
			// 			title: 'YouTube Cutter',
			// 			link: ''
			// 		}, {
			// 			title: 'TyouTube To GIF',
			// 			link: ''
			// 		}, {
			// 			title: 'send Video',
			// 			link: ''
			// 		}, {
			// 			title: 'Webcam Test',
			// 			link: ''
			// 		},
				]
			}
		]
	},
	// {
	// 	title: 'Produce',
	// 	items: [
	// 		{
	// 			header: 'Marketing',
	// 			child: [
	// 				{
	// 					title: 'Intro Maker',
	// 					link: ''
	// 				}, {
	// 					title: 'Outro Maker',
	// 					link: ''
	// 				}, {
	// 					title: 'Video Maker',
	// 					link: ''
	// 				}, {
	// 					title: 'Meme Maker',
	// 					link: ''
	// 				}, {
	// 					title: 'Promo Videos',
	// 					link: ''
	// 				}, {
	// 					title: 'Video Ad Maker',
	// 					link: ''
	// 				},]
	// 		}
	// 	]
	// }, 
	{
		title: 'Blog',
		items: [
			{
				header: '',
				child: [
					{
						title: 'VIDEOO Blog',
						link: '/videooblog'
					}, {
						title: 'VIDEOO How to',
						link: '/videoohowto'
					}, {
						title: 'VIDEOO Careers',
						link: '/videoocareers'
					},]
			}
		]
	}
];

export const Pricing_options = [
	{ title: "Pricing", link: "pricing" },
	{ title: "Login", link: "signin" },
	{ title: "Signup", link: "signup" }
];