import { useContext, useState } from 'react';
import { WebGLContext, WebGLDefaultData } from './videoEdit.config';

export const WebGLContextProvider = ({ children }) => {
  const [webGLContext, setWebGLContext] = useState(WebGLDefaultData);

  return (
    <WebGLContext.Provider value={[webGLContext, setWebGLContext]}>
      {children}
    </WebGLContext.Provider>
  )
}

export const useWebGL = () => useContext(WebGLContext);