import React, {useState, useEffect, useCallback, memo ,useMemo} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import {
  Box,
  FormControl,
  Button,
  InputLabel,
  Grid,
  Select ,
  MenuItem,
  Slider,
  Typography,
  TextField,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItem,
  IconButton,
  Badge,
} 
from "@mui/material"
import { styled } from '@mui/material/styles';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { useDebounce } from "@uidotdev/usehooks";
import { SectionHeader } from './common/section.header';
import { createTextToSpeech,
   updateTextToSpeech, UploadAssets } from '../../../axios/ApiProvider';
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import {speechVoices, voiserSpeeches} from "./tooltap.config"
import { changedJSON_Store, ProjectJSON_Store } from '../../../store/actions/projectdata';
import { audioElements_Store } from '../../../store/actions/webgldata';
import { defaultAudioData } from '../videoEdit.config';
import GoogleSpeechLogo from "../../../assets/image/Logos/google-speech.jpg"
import VoiserSpeechLogo from "../../../assets/image/Logos/voiser-speech.jpg"
import PricingModal from '../../../pages/Pricing/pricingModal';

export const BASE_STATIC_STORAGE_URL = process.env.REACT_APP_STATIC_STORAGE_URL;

const HeaderInfo = { title: 'Text To Speech', className: 'pl-3' };

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 18,
  height: 18,
  border: `2px solid ${theme.palette.background.paper}`,
}));

export const TooltapTextToSpeech = () => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)

  const [speechMenu, setSpeechMenu] = useState("voiser")
  
  const handleClickVoiserMenu = () => {
    setSpeechMenu("voiser")
  }

  const handleClickGoogleMenu = () => {
    setSpeechMenu("google")
  }

  return (
    <>
      <Box className={`v-toolbar-header pl-3`}>
        <Box display="flex">
          <Typography sx={{lineHeight: "50px"}} className='v-header-right first-letter:uppercase'>{languageStrs.text_to_speech}</Typography>
          <Box paddingX={2} paddingY={1} onClick={handleClickVoiserMenu}>
              <img src={VoiserSpeechLogo} width={100} alt="voiser speech" />
          </Box>
          <Box paddingX={2} paddingY={1} onClick={handleClickGoogleMenu}>
              <img src={GoogleSpeechLogo} width={100} alt="google speech" />
          </Box>
        </Box>
      </Box>
      {speechMenu === "voiser" ? <VoiserSpeech /> : <GoogleSpeech />}
    </>
  )
}


export const GoogleSpeech = () => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const Time_Count = useSelector((store) => store.videodata.Time_Count);
  const audioElements = useSelector(store => store.webgldata.audioElements);
  const projectJSON = useSelector(store => store.projectdata.projectJSON);
  const projectUUID = GetProjectUUID();
  const [languages, setLanguages] = useState([]);
  const [config, setConfig] = useState({
    language: "English (United Kingdom) - Female",
    gender: "Female",
    pitch: 0,
    speakingRate: 1,
    volumeGain: 0
  })
  const [text, setText] = useState("")

  const [showPricingPageModal, setShowPricingPageModal] = useState(false);

  const loginState = useSelector((store) => store.maindata.loginState)
  const [userData, setUserData] = useState({})
  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') {
      tempData = JSON.parse(item)
    }

    setUserData(tempData)
    console.log('userData', tempData);
  }, [loginState])

  const dispatch = useDispatch()
  useEffect(() => {
    const laguageArr = speechVoices.map(voice => voice.name);
    setLanguages(laguageArr);
  }, [])
  
  const handleChangeLanguage = (e) => {
    const voice = speechVoices.find(v => v.name === e.target.value)
    if(voice) {
      setConfig({
        ...config,
        language: voice.name,
        gender: voice.gender,
        code: voice.code
      })
    }
  }

  const handleChangeConfig = e => {
    setConfig({
      ...config,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeText = (e) => {
    setText(e.target.value)
  }

  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }

  const handleCreateTextToSpeech = async () => {
    if(userData?.state == "anonymous" || !userData?.state){
      setShowPricingPageModal(true);
      return;
    }
    
    const resp = await createTextToSpeech("google");
    await UploadAssets(projectUUID, resp.data.asset_uuid);
    const uuid = resp.data.uuid;
    const uResp = await updateTextToSpeech("google", uuid, config, text);

    console.log('uResp', uResp);

    if (uResp?.Code.includes("TextToSpeech Voiser Some expected data was missing from the response")) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Text to Speech has failed:" + resp.data.asset_uuid + " Please contact info@videoo.io for further assistance.",
        showConfirmButton: true,
        confirmButtonText: "Ok",
      }).then((result) => {
      });
      return;
    }

    const speech = uResp.data

    let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
    let startTime = Time_Count.getTime()
    let audioInfo = {...defaultAudioData}

    audioInfo.duration = parseInt(speech.duration) // convert to milliseconds
    audioInfo.timeline_end = parseInt(speech.duration)
    audioInfo.segment_end = parseInt(speech.duration)
    audioInfo.signed_url = speech.url;
    audioInfo.groups = speech.uuid;
    audioInfo.timeline_start = startTime
    audioInfo.timeline_end = audioInfo.duration + startTime
    audioInfo.asset_uuid = speech.asset_uuid;
    audioInfo.asset_filename =  speech.asset_filename
    audioInfo.asset_extension = speech.asset_filename.split(".").reverse()[0]

    changedJSON.audios = { [speech.uuid]: audioInfo }
    const audioPlayer = document.createElement('audio');
    audioPlayer.crossOrigin = "anonymous";
    audioPlayer.id = speech.uuid;
    audioPlayer.preload = "auto";
    audioPlayer.playsInline = true;
    audioPlayer.loop = true;
    audioPlayer.src = speech.url;
    dispatch(audioElements_Store({ ...audioElements, [speech.uuid]: audioPlayer }));
    dispatch(ProjectJSON_Store({
      ...projectJSON,
      audios: {
        ...projectJSON.audios,
        [speech.uuid]: audioInfo
      }
    }))
  }

  return (
    <>
      <Grid container sx={{px: 4}} spacing={1}>
        <Grid item xs={12}>
          <TextField multiline variant='outlined' label={languageStrs.text} onChange={handleChangeText} value={text} rows={3} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{languageStrs.language}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={config.language}
              label={languageStrs.language}
              onChange={handleChangeLanguage}
            >
              {languages.map(lang => <MenuItem value={lang} key={lang}>{lang}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography id="input-slider" gutterBottom>
          {languageStrs.speaking_rate_defaul}
          </Typography>
          <Slider
            aria-label="Speaking Rate"
            value={config.speakingRate}
            valueLabelDisplay="auto"
            step={0.25}
            min={0.25}
            max={4}
            name='speakingRate'
            onChange={handleChangeConfig}
            marks={[
              {
                value: 0.25,
                label: '0.25',
              },
              {
                value: 4,
                label: '4',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography id="input-slider" gutterBottom>
          {languageStrs.pitch_defaul}
          </Typography>
          <Slider
            aria-label="Pitch"
            value={config.pitch}
            valueLabelDisplay="auto"
            step={1}
            min={-20}
            max={20}
            marks={[
              {
                value: -20,
                label: '-20',
              },
              {
                value: 20,
                label: '20',
              },
            ]}
            name='pitch'
            onChange={handleChangeConfig}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography id="input-slider" gutterBottom>
          {languageStrs.volume_gain_default}
          </Typography>
          <Slider
            aria-label="Volume Gain"
            value={config.volumeGain}
            valueLabelDisplay="auto"
            step={1}
            min={-96}
            max={16}
            marks={[
              {
                value: -96,
                label: '-96',
              },
              {
                value: 16,
                label: '16',
              },
            ]}
            name='volumeGain'
            onChange={handleChangeConfig}
          />
        </Grid>
        
        <Grid item xs={12}>
          <Button variant="outlined" fullWidth size='large' onClick={handleCreateTextToSpeech}>{languageStrs.create_text_speech}</Button>
        </Grid>
      </Grid>
      <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />
    </>
  )
}


export const VoiserSpeech = () => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState({
    language: "English (American)",
    configVoice : "Jenny Multilingual",
    pitch: 0,
    speakingRate: 1,
    code: "en-US"
  });
  const [selectedModel, setSelectedModel] = useState(null)
  const [speechFilter, setSpeechFilter] = useState("");
  const debounceSpeechFilter = useDebounce(speechFilter, 300);
  const Time_Count = useSelector((store) => store.videodata.Time_Count);
  const audioElements = useSelector(store => store.webgldata.audioElements);
  const projectJSON = useSelector(store => store.projectdata.projectJSON);
  const projectUUID = GetProjectUUID();
  const dispatch = useDispatch()
  const [showPricingPageModal, setShowPricingPageModal] = useState(false);

  const loginState = useSelector((store) => store.maindata.loginState)
  const [userData, setUserData] = useState({})
  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') {
      tempData = JSON.parse(item)
    }

    setUserData(tempData)
    console.log('userData', tempData);
  }, [loginState])

  const handleChangeText = e => {
    setText(e.target.value)
  }

  const handleOpenSettings = () => {
    setOpen(!open)
  }

  const handleChangePitch = (e) => {
    setConfig({
      ...config,
      pitch: e.target.value
    })
  }

  const handleChangeSpeakingRate = e => {
    setConfig({
      ...config,
      speakingRate: e.target.value
    })
  }

  const handleReviewVoice = useCallback(
    (voice) => {
      const audio = document.createElement("audio")
      audio.src = `${BASE_STATIC_STORAGE_URL}/tts/voices/${voice}`;
      audio.play();
    },
    [],
  )

  const handleClickModel = useCallback(
    (index, name, code, country) => {
      setSelectedModel(index);
      setConfig({
        ...config,
        language: country,
        configVoice : name,
        code: code
      })
    },
    [],
  )

  const handleChangeSpeechFilter = e => {
    setSpeechFilter(e.target.value)
  }

  const speeches = useMemo(() => {
    if(speechFilter === "") return voiserSpeeches
    return voiserSpeeches.filter(sp => sp[0].toLocaleLowerCase().search(speechFilter.toLocaleLowerCase()) != -1 || sp[1].toLocaleLowerCase().search(speechFilter.toLocaleLowerCase()) != -1) 
  }, [debounceSpeechFilter])


  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }

  const handleCreateTextToSpeech = async () => {
    if(userData?.state == "anonymous" || !userData?.state){
      setShowPricingPageModal(true);
      return;
    }
    
    const resp = await createTextToSpeech("voiser");
    await UploadAssets(projectUUID, resp.data.asset_uuid);
    const uuid = resp.data.uuid;
    const uResp = await updateTextToSpeech("voiser", uuid, config, text);

    console.log('uResp', uResp);

    if (uResp?.data?.Code?.includes("TextToSpeech Voiser Some expected data was missing from the response") ||
        uResp?.data?.Code?.includes("'status': 'error'")) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Text to Speech has failed:" + resp.data.asset_uuid + "Please contact info@videoo.io for further assistance.",
        showConfirmButton: true,
        confirmButtonText: "Ok",
      }).then((result) => {
      });
      return;
    }

    const speech = uResp.data

    let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
    let startTime = Time_Count.getTime()
    let groupsId = speech.uuid;
    let audioInfo = {...defaultAudioData}
    const audioKeys = Object.keys(projectJSON.audios);
    if(audioKeys){
      audioKeys.map(keyItem => {
        if(startTime < projectJSON.audios[keyItem].timeline_end){
          startTime = projectJSON.audios[keyItem].timeline_end;
          groupsId = projectJSON.audios[keyItem].groups;
        }
      })
    }
    
    audioInfo.duration = parseInt(speech.duration) // convert to milliseconds
    audioInfo.timeline_end = parseInt(speech.duration)
    audioInfo.segment_end = parseInt(speech.duration)
    audioInfo.signed_url = speech.url;
    audioInfo.groups = audioKeys? groupsId : speech.uuid;
    audioInfo.timeline_start = startTime
    audioInfo.timeline_end = audioInfo.duration + startTime
    audioInfo.asset_uuid = speech.asset_uuid;
    audioInfo.asset_filename =  speech.asset_filename
    audioInfo.asset_extension = speech.asset_filename.split(".").reverse()[0]

    changedJSON.audios = { [speech.uuid]: audioInfo }
    const audioPlayer = document.createElement('audio');
    audioPlayer.crossOrigin = "anonymous";
    audioPlayer.id = speech.uuid;
    audioPlayer.preload = "auto";
    audioPlayer.playsInline = true;
    audioPlayer.loop = true;
    audioPlayer.src = speech.url;
    dispatch(audioElements_Store({ ...audioElements, [speech.uuid]: audioPlayer }));
    dispatch(ProjectJSON_Store({
      ...projectJSON,
      audios: {
        ...projectJSON.audios,
        [speech.uuid]: audioInfo
      }
    }))
  }



  return(
  <>
    <Grid container sx={{px: 4}} spacing={1}>
      <Grid item xs={12}>
        <TextField multiline variant='outlined' label={languageStrs.text} onChange={handleChangeText} value={text} rows={3} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" fullWidth size='large' onClick={handleOpenSettings}>{languageStrs.settings}</Button>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid container>
            <Grid item xs={12}>
              <Typography id="input-slider" gutterBottom>
              {languageStrs.speaking_rate_defaul}
              </Typography>
              <Slider
                aria-label="Speaking Rate"
                value={config.speakingRate}
                valueLabelDisplay="auto"
                step={0.25}
                min={0.25}
                max={4}
                name='speakingRate'
                onChange={handleChangeSpeakingRate}
                marks={[
                  {
                    value: 0.25,
                    label: '0.25',
                  },
                  {
                    value: 4,
                    label: '4',
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography id="input-slider" gutterBottom>
              {languageStrs.pitch_defaul}
              </Typography>
              <Slider
                aria-label="Pitch"
                value={config.pitch}
                valueLabelDisplay="auto"
                step={1}
                min={-20}
                max={20}
                marks={[
                  {
                    value: -20,
                    label: '-20',
                  },
                  {
                    value: 20,
                    label: '20',
                  },
                ]}
                name='pitch'
                onChange={handleChangePitch}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid item xs={12} >
        <Button variant="contained" fullWidth size='large' onClick={handleCreateTextToSpeech}>{languageStrs.create_text_speech}</Button>
      </Grid>
      <Grid item xs={12}>
        <TextField variant='outlined' label={languageStrs.search} size='small'  onChange={handleChangeSpeechFilter} value={speechFilter} fullWidth />
      </Grid>
    </Grid>
    <Grid container sx={{overflow: "auto", px: 4, mt: 2,}}>
      {speeches.map((speech, index) => {
        return (
        <Grid item xs={12} md={6} lg={12} key={index}>
          <VoiserModel
            index={index}
            selected={selectedModel}
            name={speech[0]}
            country={speech[1]}
            code={speech[2]}
            avatar={speech[3]}
            cflag={speech[4]}
            voice={speech[5]}
            onClickModel={handleClickModel}
            onReviewVoice={handleReviewVoice}
          />
        </Grid>)
      })}
    </Grid>
    <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />
  </>)
}

const VoiserModel = memo(({index, selected, avatar, cflag, code, name, country, voice, onClickModel, onReviewVoice}) => {
  
  return (
    <ListItem
      sx={{cursor: "pointer"}}
      selected={selected === index}
      onClick={(e) => {
        onClickModel(index, name, code, country)
      }}
      secondaryAction={
      <IconButton color='secondary' 
      onClick={(e) => {
        e.stopPropagation();
        onReviewVoice(voice)
      }}>
        <PlayCircleFilledIcon />
      </IconButton>
    } >
      <ListItemAvatar>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <SmallAvatar alt="Remy Sharp" src={`${BASE_STATIC_STORAGE_URL}/tts/flags/${cflag}`} />
        }
      >
        <Avatar alt="Travis Howard" src={`${BASE_STATIC_STORAGE_URL}/tts/images/${avatar}`} />
      </Badge>
      </ListItemAvatar>
      <ListItemText primary={name} secondary={country} />
    </ListItem>
  )
})