const zoominGl = {
    type: 'zoomin',
    glsl: `uniform float zoom_quickness; // = 0.8
    float nQuick = clamp(zoom_quickness,0.2,1.0);
    
    vec2 zoom(vec2 uv, float amount) {
      return 0.5 + ((uv - 0.5) * (1.0-amount));	
    }
    
    vec4 transition (vec2 uv) {
      return mix(
        getFromColor(zoom(uv, smoothstep(0.0, nQuick, progress))),
        getToColor(uv),
       smoothstep(nQuick-0.2, 1.0, progress)
      );
    }`,
    defaultParams: {zoom_quickness: 0.8},
    paramsTypes: {zoom_quickness: 'float'}
  }

  export default zoominGl