import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { v4 as uuidv4 } from 'uuid';

import { CaptureDialog_Store } from "../../../store/actions/editAction"
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import { getProjectJSON, SERVER_STATUS } from '../../../axios/ApiProvider';
import { getMaxZIndex, changeZeroSecond } from '../../../component/video-edit/commonFunction';
import { changedJSON_Store, thumbNails_Store, uploadingFiles_Store, ProjectJSON_Store } from '../../../store/actions/projectdata';
import { getNewVideoInformation, getThumbnails, getDuration, getVideoAssetFps, getVideoLoadedData } from '../../../component/video-edit/webGL/commonFunc';
import { dataLoaded_Store, VideoElements_Store } from '../../../store/actions/webgldata';
import {webGlSizeOptions} from "../../../component/video-edit/videoEdit.config"

import videopost from "../../../assets/image/videoplaceholder.jpg"

let tempVideoEls = null;
let tempProjectJSON = null;
let tempLoadingData = null;
let mimeTypes = [
  "video/webm",
  "video/webm;codecs=vp8",
  "video/webm;codecs=daala",
  "video/webm;codecs=h264",
  "video/mpeg",
]
const ScreenCapture = () => {
  const dispatch = useDispatch();
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const captureDialog = useSelector(store => store.editdata.captureDialog);
  const videoRef = useRef(null)
  const [constraints, setConstraints] = useState({
    video: {
      displaySurface: "default",
      bitrate: 2500000,
      frameRate: {
        ideal: 60,
        max: 60,
      },
    }
  })
  const [entry, setEntry] = useState("default")
  const [stream, setStream] = useState(null)
  const [recording, setRecording] = useState(false);
  const [recorder, setRecorder] = useState()
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [trackingInterval, setTrackingInterval] = useState(null)
  const projectUUID = GetProjectUUID();
  const loginState = useSelector((store) => store.maindata.loginState);
  const thumbNails = useSelector(store => store.projectdata.thumbNails);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const uploadingFiles = useSelector((store) => store.projectdata.uploadingFiles);
  const Time_Count = useSelector((store) => store.videodata.Time_Count);

  useEffect(() => { tempVideoEls = { ...videoElements } }, [videoElements]);
  useEffect(() => { tempProjectJSON = { ...ProjectJSON } }, [ProjectJSON]);
  useEffect(() => { tempLoadingData = { ...uploadingFiles } }, [uploadingFiles]);

  const [userData, setUserData] = useState({})
  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') tempData = JSON.parse(item)
    setUserData(tempData)
  }, [loginState])

  useEffect(() => {
    (async function () {
      if (captureDialog.screen) {
        setRecordingTime(0);
        if (stream) {
          stream.getTracks().forEach(track => {
            track.stop();
          });
        }
        try{
          const s = await navigator.mediaDevices.getDisplayMedia(constraints)
          window.stream = s;
          s.getTracks().forEach(async (track) => {
            await track.applyConstraints({
              frameRate: 120,
            });  
          });
          
          setStream(s)
          if (videoRef.current) {
            videoRef.current.srcObject = s;
          }
        }catch(e){
          console.log(e)
        }
      }
    })();
  }, [captureDialog, constraints])

  useEffect(() => {
    if (recorder) {
      recorder.ondataavailable = e => {
        setRecordedBlob(e.data)
      }
    }
  }, [recorder])

  useEffect(() => {
    setConstraints({
      video: {
        ...constraints.video,
        displaySurface: entry,
      }
    })
  }, [entry])

  useEffect(() => {
    if (recordedBlob) {
      handleUpload()
    }
  }, [recordedBlob])

  const handleClose = () => {
    if (stream) {
      stream.getTracks().forEach(track => {
        track.stop();
      });
      setRecordedBlob(null)
    }
    dispatch(CaptureDialog_Store({
      screen: false
    }))
  }

  const handleRecord = () => {
    if (recording) {
      setRecording(false)
      recorder.stop()
    } else {
      setRecording(true);
      let rightMimType = 'video/webm';
      for (const mtype of mimeTypes) {
        if(MediaRecorder.isTypeSupported(mtype)){
          rightMimType = mtype;
          break;
        }
      }
      
      const options = {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000,
        mimeType: rightMimType,
      };
      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorder.start()
      setRecorder(mediaRecorder)
    }
  }

  const handleUpload = async () => {
    const newUUID = uuidv4();
    let videofile = new File([recordedBlob], "webcamrecord.webm", {
      type: "video/webm",
    })
    const videoLength = Object.keys(tempVideoEls).length;

    let duration = await getDuration(videofile)
    if (duration === Infinity) {
      window.toastr.info('video type error', 'can`t load this video')
      return
    }

    let fps = await getVideoAssetFps(videofile)
    console.log("THIS IS FPS : ", fps);

    if (!loginState) {
      if (videofile.size > 250 * 1024 * 1024 || duration > 5 * 60) {
        let title = 'Please sign in or sign up to upload videos'
        let content = 'larger than 250 Mbytes or longer than 5 minutes'
        window.toastr.info(title, content)
        return
      }
    } else {
      let flag = userData?.storage_usage_state
      if (!flag) {
        window.toastr.error('You have run out of Cloud Storage Space with your current plan, you need to upgrade your account to upload files to a project')
        return
      }
    }

    handleClose()

    let newProjectJSON = {...ProjectJSON}
    if(Object.keys(videoElements).length === 0) {
      const objURL = URL.createObjectURL(videofile);
      getVideoLoadedData(objURL, uuidv4(), {segment_start: 10}, null, (res, videoPlayer) => {
        const videoPlayerW = videoPlayer.videoWidth;
        const videoPlayerH = videoPlayer.videoHeight;
        if(videoPlayerH / videoPlayerW > 1) {
          newProjectJSON.width = webGlSizeOptions[0].wSize.width;
          newProjectJSON.height = webGlSizeOptions[0].wSize.height;
          dispatch(ProjectJSON_Store(newProjectJSON));
        }
      })
    }

    let fileData = { pc: 0, type: 'video', name: videofile.name, state: false }
    dispatch(uploadingFiles_Store({ ...uploadingFiles, [newUUID]: fileData }));

    let tempThumbNails = [];
    let _thumbNails = await getThumbnails(videofile, 1);

    _thumbNails.forEach((thumbNail) => {
      let tempData = {
        uuid: uuidv4(),
        content_type: 'blob',
        timestamp: thumbNail.time,
        signed_url: URL.createObjectURL(thumbNail.file)
      }

      tempThumbNails.push(tempData);
    });

    let maxZIndex = getMaxZIndex(ProjectJSON);

    let props = [newProjectJSON, videofile, "record", duration, fps, newUUID, projectUUID, videoLength, maxZIndex]
    getNewVideoInformation(...props, 
      async (videoPlayer, videoInfo) => {
      dispatch(thumbNails_Store({ ...thumbNails, [newUUID]: tempThumbNails }));
      let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      let startTime = Time_Count.getTime()

      videoInfo = {
        ...videoInfo,
        timeline_start: startTime,
        timeline_end: videoInfo.duration + startTime
      }

      changedJSON.flag = true
      changedJSON.videos = { [newUUID]: videoInfo }
      dispatch(VideoElements_Store({ ...tempVideoEls, [newUUID]: videoPlayer }));
      dispatch(changedJSON_Store(changedJSON));
      changeZeroSecond(videoInfo, dispatch)
    },
    (tempAssets) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      changedData = { ...changedData, asset: { ...tempAssets } };
      dispatch(changedJSON_Store(changedData));
    },
    async (newAssets, assetUUID) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      if (!tempProjectJSON.videos[newUUID]) return;
      changedData.videos[newUUID] = tempProjectJSON.videos[newUUID];
      // changedData.videos[newUUID].asset_uuid = assetUUID;

      const JSONdata = await getProjectJSON(projectUUID);
      const projectData = JSONdata?.data[projectUUID];

      if (JSONdata.state !== 'success' || SERVER_STATUS === 'localhost')
        changedData = { ...changedData, asset: { ...newAssets } };
      else changedData = { ...changedData, asset: projectData.asset };

      // let fileItem = { ...tempLoadingData[newUUID], state: true };
      // dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
      dispatch(changedJSON_Store(changedData));
    },
    () => { dispatch(dataLoaded_Store(true)); }, 
    (e) => {
      let pc = parseFloat(e.loaded / e.total * 100).toFixed(2);
      let fileItem = { ...tempLoadingData[newUUID], pc: Number(pc) };
      dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
      if(Number(pc) === 100) {
        setTimeout(() => {
          let fileItem = { ...tempLoadingData[newUUID], pc: Number(pc), state: true };
          dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
        }, 1000)
      }
    })
  }

  const handleChangeEntry = (e) => {
    setEntry(e.target.value)
  }

  useEffect(() => {
    if(recording) {
      const interval = setInterval(() => {
        setRecordingTime((time) => time + 100);
      }, 100);
      setTrackingInterval(interval)
    } else {
      clearInterval(trackingInterval)
    }
  }, [recording])
  const timeCounRender = (timeCountNum) => {
    const secNum = parseInt(timeCountNum / 1000);
    const minNum = parseInt(secNum / 60);
    //const mileSec = parseInt((timeCountNum % 1000));
    return ''+(minNum.toString().padStart(2, '0')) + ':' +((secNum % 60).toString().padStart(2, '0'));
  }
  return (
    <Dialog
      open={captureDialog.screen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {languageStrs.record_screen_and_upload}
        {(recording || recordingTime !== 0) && 
         <>
          <div
            style={{
              marginLeft: "20px",
              display: "inline-block",
              backgroundColor: "red",
              width: "15px",
              height: "15px",
              borderRadius: "50%"
            }}
          />
          <span style={{
            marginLeft: "10px"
          }}>{timeCounRender(recordingTime)}</span>
        </>
        }
      </DialogTitle>
      <DialogContent>
        <video poster={videopost} ref={videoRef} autoPlay />
        <Box marginY={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Screen Capture Preferences</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Screen Capture Preferences"
              value={entry}
              onChange={handleChangeEntry}
            >
              <MenuItem value="default">{languageStrs.show_defaul_sharingMenu}</MenuItem>
              <MenuItem value="browser">{languageStrs.prefer_share_browser}</MenuItem>
              <MenuItem value="window">{languageStrs.prefer_share_window}</MenuItem>
              <MenuItem value="monitor">{languageStrs.prefer_share_entireScreen}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRecord} autoFocus variant='contained' color='error'>{recording ? languageStrs.stop : languageStrs.record}</Button>
        {/* <Button variant='contained' color="success" disabled={!recordedBlob || recording} onClick={handleUpload}>Upload</Button> */}
        <Button onClick={handleClose}>
          {languageStrs.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ScreenCapture