const N = (v) => {
  return Number(v.slice(0, -2));
}
export const WebGLMovementEnd = async (target, canvas, projectJSON, elementOb) => {
  const canvasW = canvas.offsetWidth;
  const ratio = projectJSON.width / canvasW;
  const ratioWH = elementOb.ratioWH;
  const ratioW = ratioWH.w / projectJSON.width;
  const ratioH = ratioWH.h / projectJSON.height;

  const transForm = target.style.transform;
  const rotation = Number((transForm).slice(7, transForm.indexOf('deg')));
  let Params = [
    N(target.style.left) * ratio * ratioW,
    N(target.style.top) * ratio * ratioH,
    N(target.style.width) * ratio * ratioW,
    N(target.style.height) * ratio * ratioH,
    rotation
  ];

  const positionData = {
    coordinate: { ...elementOb.coordinate, x: Params[0], y: Params[1] },
    dimension: { ...elementOb.dimension, w: Params[2], h: Params[3] },
    rotation: rotation
  };

  return positionData;
}

export const WebGLStetchEnd = async (target, canvas, projectJSON, elementOb, elementRatio) => {
  
  const canvasW = canvas.offsetWidth;
  const ratio = projectJSON.width / canvasW;
  const ratioWH = elementOb.ratioWH;
  
  const ratioW = projectJSON.width;
  const ratioH = projectJSON.height;
  const projectRatio = projectJSON.width / projectJSON.height;

  let coordinateX = elementOb.coordinate.x;
  let coordinateY = elementOb.coordinate.y;
  let dimensionW = elementOb.dimension.w;
  let dimensionH = elementOb.dimension.h;

  if(projectRatio > elementRatio){
    dimensionW = projectJSON.width;
    dimensionH = projectJSON.width * elementRatio;
    coordinateX = 0;
    coordinateY = (projectJSON.height - dimensionH)/2;
  }else{
    dimensionH = projectJSON.height;
    dimensionW = projectJSON.height / elementRatio;
    coordinateX = (projectJSON.width - dimensionW)/2;
    coordinateY = 0;
  }

  //const transForm = target.transform;
  const rotation = 0;
  let Params = [
    Number(target.left) * ratio * ratioW,
    Number(target.top) * ratio * ratioH,
    Number(target.width) * ratio * ratioW,
    Number(target.height) * ratio * ratioH,
    rotation
  ];

  const positionData = {
    coordinate: { ...elementOb.coordinate, x: coordinateX, y: coordinateY },
    dimension: { ...elementOb.dimension, w: dimensionW, h: dimensionH },
    rotation: rotation,
    ratioWH: {w: ratioW, h: ratioH}
  };

  return positionData;
}