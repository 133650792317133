import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, MenuItem, Divider } from '@mui/material';
import { Box, Typography, Grid, Button } from '@mui/material';

import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

import { textConfig } from './tooltap.config';
import { changeZeroSecond, convertStringtoWStringUTF16 } from '../commonFunction';
import { copyObject } from '../commonFunction';
import { opacityMarks } from './tooltap.config';
import { getMaxZIndex } from '../commonFunction';
import { useWebGL } from '../videoEdit.provider';
import { ColorPicker } from './common/colorPicker';
import { SectionHeader } from './common/section.header';
import { getNewTextInformation } from '../webGL/commonFunc';
import { getContentWidthHeight } from '../webGL/Setups/SetupClass';
import { ProjectJSON_Store } from '../../../store/actions/projectdata';
import { SelectedItemID_Store } from '../../../store/actions/webgldata';
import { fontFamilyOptions, fontSizeOptions } from './common/TextCommonFunc';
import { DefaultTextData } from '../videoEdit.config';


export const TooltapText = () => {
  const dispatch = useDispatch()
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const [webGLContext] = useWebGL()
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON)
  const selectedItemID = useSelector(store => store.webgldata.selectedItemID)
  const Time_Count = useSelector((store) => store.videodata.Time_Count);

  const [selectedText, setSelectedText] = useState(null)
  let fontSize = selectedText?.font_size
  let fontType = typeof fontSize

  const HeaderInfo = { title: languageStrs.add_text, className: 'pl-3' }
  const HeaderInfo1 = { title: languageStrs.text, className: 'pl-3' }

  useEffect(() => {
    if (!ProjectJSON?.text) return

    const textOb = JSON.parse(JSON.stringify(ProjectJSON.text));
    if (!textOb || typeof textOb !== 'object') return;
    const textKeys = Object.keys(textOb);
    const selectedKey = textKeys.find((textKey) => textKey === selectedItemID);
    if (!!selectedKey) {
      (async () => {
        let textItem = textOb[selectedKey];
        const newSelectItem = {
          alpha: textItem.alpha,
          context: textItem.context,
          contextutf16: convertStringtoWStringUTF16(textItem.context),
          timeStart: await getTimefromNum(textItem.timeline_start),
          timeEnd: await getTimefromNum(textItem.timeline_end),
          ...textItem.properties
        }
        setSelectedText({ ...newSelectItem });
      })()
    } else setSelectedText(null);

    if (!!selectedKey && !!ProjectJSON) {
      let textItem = textOb[selectedKey];
      setTextColor(textItem.properties.text_color.substring(1))
      SetBackgroundColor(textItem.properties.background_color.substring(1))
    } else { setTextColor('00000000'); SetBackgroundColor('00000000'); }
  }, [selectedItemID, ProjectJSON]);

  const getTimefromNum = async (num) => {
    const tempDate = new Date(num);
    const tempTime = await tempDate.MMSSMM();
    return tempTime;
  }

  const addNewTextFunc = async (property) => {
    let newUUID = uuidv4();
    let groupID = newUUID;
    let startTime = Time_Count.getTime()
    let maxZIndex = getMaxZIndex(ProjectJSON);
    // vKeys.length
    const textKeys = Object.keys(ProjectJSON.text);
    if(textKeys.length > 0){
      groupID = textKeys[0];
      textKeys.map(tKeyItem => {
        if(startTime < ProjectJSON.text[tKeyItem].timeline_end){
          startTime = ProjectJSON.text[tKeyItem].timeline_end;
        }
      })
    }
    let textInfo = await getNewTextInformation(property, ProjectJSON, groupID, maxZIndex);

    textInfo = {
      ...textInfo,
      timeline_start: startTime,
      timeline_end: textInfo.duration + startTime
    }

    saveChangedJSON(textInfo, newUUID);
    dispatch(SelectedItemID_Store(newUUID));
  }

  const textTypeChange = async (keyValue, value) => {
    const textInfo = await checkVariable()
    if (!textInfo || !value) return
    if (keyValue === 'bold' && selectedText.bold === 'bold') value = ''
    if (keyValue === 'italic' && selectedText.italic === 'italic') value = ''
    textInfo.properties = { ...textInfo.properties, [keyValue]: value }

    let mainCanvas = webGLContext.webGLRef
    let props = [textInfo.context, ProjectJSON, textInfo, mainCanvas]
    let [maxWidth, maxHeight, acceptRatio] = getContentWidthHeight(...props)
    textInfo.dimension.h = maxHeight / acceptRatio
    textInfo.dimension.w = maxWidth / acceptRatio

    saveChangedJSON(textInfo, selectedItemID)
  }

  const checkVariable = () => {
    if (!selectedText) return false;
    const textInfo = Object.assign({}, ProjectJSON.text[selectedItemID]);
    if (!textInfo) return false;
    return textInfo;
  }

  const saveChangedJSON = (textInfo, UUID) => {
    const newJSONdata = Object.assign({}, ProjectJSON);
    newJSONdata.text = { ...newJSONdata.text, [UUID]: textInfo };

    dispatch(ProjectJSON_Store(newJSONdata));
    changeZeroSecond(textInfo, dispatch)
  }

  const [textColor, setTextColor] = useState('00000000');
  const [backgroundColor, SetBackgroundColor] = useState('00000000');
  const backgroundChanged = (color) => {
    let textOb = JSON.parse(JSON.stringify(ProjectJSON.text));
    const selectedKey = Object.keys(textOb).find((textKey) => textKey === selectedItemID);
    if (!selectedKey) { SetBackgroundColor('00000000'); return; }
    let textItem = JSON.parse(JSON.stringify(textOb[selectedKey]));
    if (textItem.properties.background_color === color) return;

    textItem.properties.background_color = color;
    dispatch(ProjectJSON_Store({ ...ProjectJSON, text: { ...ProjectJSON.text, [selectedKey]: textItem } }))
  }

  const textColorChanged = (color) => {
    let textOb = JSON.parse(JSON.stringify(ProjectJSON.text));
    const selectedKey = Object.keys(textOb).find((textKey) => textKey === selectedItemID);
    if (!selectedKey) { SetBackgroundColor('00000000'); return; }
    let textItem = JSON.parse(JSON.stringify(textOb[selectedKey]));
    if (textItem.properties.text_color === color) return;

    textItem.properties.text_color = color;
    dispatch(ProjectJSON_Store({ ...ProjectJSON, text: { ...ProjectJSON.text, [selectedKey]: textItem } }))
    changeZeroSecond(textItem, dispatch)
  }

  const autoSizeSetting = () => {
    let tempTextJSON = JSON.parse(JSON.stringify(ProjectJSON.text))
    if (!selectedItemID) return;
    if (!tempTextJSON[selectedItemID]) return;
    let textItem = tempTextJSON[selectedItemID];
    textItem.properties = DefaultTextData.properties;

    let width = textItem.dimension.w;
    let height = textItem.dimension.h;

    let mainCanvas = webGLContext.webGLRef;
    let props = [textItem.context, ProjectJSON, textItem, mainCanvas];
    let [maxWidth, maxHeight, acceptRatio] = getContentWidthHeight(...props);
    maxHeight = maxHeight / acceptRatio;
    maxWidth = maxWidth / acceptRatio;

    if (width !== maxWidth) textItem.dimension.w = maxWidth;
    if (height !== maxHeight) textItem.dimension.h = maxHeight;
    let tempJSON = { ...ProjectJSON, text: tempTextJSON };
    dispatch(ProjectJSON_Store(tempJSON))
  }

  const [textValue, setTextValue] = useState('')

  useEffect(() => {
    if (selectedText?.context) {
      let tempText = selectedText?.context.replaceAll('?entered!*-', '\n')

      textchanged(tempText)
    }
  }, [selectedText])
  
  const textchanged = (value) => {
    setTextValue(value)
    let tempJSON = copyObject(ProjectJSON)
    if (!tempJSON || !selectedItemID) return

    let activeUUID = selectedItemID
    let activeText = tempJSON?.text[activeUUID]
    console.log('textChanged', value, activeText)
    let tempText = value.replaceAll('\n', '?entered!*-')
    if (!activeText || tempText === activeText.context) return

    let mainCanvas = webGLContext.webGLRef
    let params = [tempText, ProjectJSON, activeText, mainCanvas]
    let [width, height, acceptRatio] = getContentWidthHeight(...params)

    activeText.context = tempText
    activeText.contextutf16 = convertStringtoWStringUTF16(tempText)
    activeText.dimension.w = width / acceptRatio
    activeText.dimension.h = height / acceptRatio
    if(activeText.properties.alignment == "center"){
      activeText.coordinate.x = (tempJSON.width - activeText.dimension.w) / 2;
    }
    dispatch(ProjectJSON_Store(tempJSON))
    changeZeroSecond(activeText, dispatch)
  }

  return (
    <>
      <div className='hidden mm:flex mm:flex-col'>
        {!selectedText ? (
          <SectionHeader {...HeaderInfo} />
        ) : (
          <SectionHeader {...HeaderInfo1} />
        )}
      </div>

      {!selectedText ? (
        <Box md={12} className='px-10 pt-0'>
          <Grid container spacing={1}>
            {textConfig.map((config, key) => (
              <Grid item xs={6} key={key}>
                <Box onClick={() => { addNewTextFunc(config); }}
                  className={`v-commonboxheight1 v-pointer v-center v-hoverbackcolor1 text-25 ${config.properties?.font_family}`}
                >
                  {config.context}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box className='px-10 pt-0'>
          <Box className='v-hoverborder v-radius'>
            <Box className='v-inputbox px-10 py-5'>
              <textarea rows={3}
                value={textValue}
                className='default-input w-full'
                onChange={({ target }) => {
                  textchanged(target.value)
                 }}
              // onBlur={textchanged}
              />
            </Box>

            <Box className='v-border_t v-center'>
              <Grid container spacing={0} className='v-commonboxheight '>
                <Grid item xs={2} className='v-center'>
                  <AccessAlarmIcon className='v-font-5' />
                </Grid>
                <Grid item className='v-font-2-3 v-center' xs={2}> {languageStrs.start}</Grid>
                <Grid item className='v-font-2-3 v-center' xs={2}> {selectedText?.timeStart}</Grid>
                <Grid item className='v-font-2-3 v-center' xs={2}> {languageStrs.end}</Grid>
                <Grid item className='v-font-2-3 v-center' xs={2}> {selectedText?.timeEnd}</Grid>
                <Grid item className='v-center' xs={2}>
                  <AccessAlarmIcon className='v-font-5' />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className='p-2'>
            <div className='hidden mm:flex mm:flex-col'>
              <Box className='v-block-header pb-5 mt-10'>
                <Typography className='v-block-header-left'>{languageStrs.text_style}</Typography>
                {/* <Typography className='v-block-header-right pr-2'>Less</Typography> */}
              </Box>
            </div>

            <Box md={12} className='mb-2'>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Box className='v-autocomplate'>
                    <Select
                      className='v-hoverborder v-radius v-commonboxheight'
                      inputProps={{ 'aria-label': 'Without label' }}
                      value={selectedText?.font_family}
                      onChange={(e) => { textTypeChange('font_family', e.target.value) }}
                    >
                      {fontFamilyOptions.map((option, key) =>
                        <MenuItem value={option.label} key={key}>
                          <Typography component='span'
                            sx={{ fontFamily: option.label }}
                          >{option.label}</Typography>
                        </MenuItem>
                      )}
                    </Select>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box className="v-autocomplate">
                    <Select value={fontSize}
                      className='v-hoverborder v-radius v-commonboxheight'
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={(e) => { textTypeChange('font_size', parseFloat(e.target.value)) }}
                    >
                      {(fontType === 'number' && fontSize % 2.5 !== 0) && (
                        <MenuItem value={fontSize}>
                          <Typography component='span'>{fontSize}</Typography>
                        </MenuItem>
                      )}

                      {fontSizeOptions.map((option, key) =>
                        <MenuItem value={option.label} key={key}>
                          <Typography component='span'>{option.label}</Typography>
                        </MenuItem>
                      )}
                    </Select>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box md={12} className='mb-2'>
              <Grid container spacing={0}>
                <Grid item xs={4.8}>
                  <Box className='v-commonboxheight v-radius v-spacebetween mr-1'>
                    <Grid container className='v-spacebetween'>
                      <Grid item xs={6}>
                        <Button endIcon={<FormatBoldIcon />}
                          variant={selectedText?.bold === 'bold' ? 'contained' : 'outlined'}
                          onClick={() => { textTypeChange('bold', 'bold') }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Button endIcon={<FormatItalicIcon />}
                          variant={selectedText?.italic === 'italic' ? 'contained' : 'outlined'}
                          onClick={() => { textTypeChange('italic', 'italic') }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={7.2}>
                  <Box className=' v-commonboxheight v-radius v-spacebetween mr-1 ml-1'>
                    <Grid container >
                      <Grid item xs={4}   >
                        <Button endIcon={<FormatAlignLeftIcon />}
                          variant={selectedText?.alignment === 'left' ? 'contained' : 'outlined'}
                          onClick={() => { textTypeChange('alignment', 'left') }}
                        />
                      </Grid>

                      <Grid item xs={4} >
                        <Button endIcon={<FormatAlignCenterIcon />}
                          variant={selectedText?.alignment === 'center' ? 'contained' : 'outlined'}
                          onClick={() => { textTypeChange('alignment', 'center') }}
                        />
                      </Grid>

                      <Grid item xs={4} >
                        <Button endIcon={<FormatAlignRightIcon />}
                          variant={selectedText?.alignment === 'right' ? 'contained' : 'outlined'}
                          onClick={() => { textTypeChange('alignment', 'right') }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box md={12} className='mb-2'>
              <Box className='v-border v-radius'>
                <Box className='v-spacebetween p-10'>
                  <span>{languageStrs.background_color}</span>
                  <Box className='v-center mr-3' sx={{ position: 'relative' }}>
                    <input type="text"
                      className='v-input-text mr-2 v-font-2-2'
                      value={'#' + backgroundColor}
                      onChange={() => { }} disabled />

                    <ColorPicker
                      Icon={<FormatColorFillIcon className='v-font-3-2 v-colorpicker-icon' />}
                      color={'#' + backgroundColor}
                      change={backgroundChanged} />
                  </Box>
                </Box>

                <Divider />

                <Box className='v-spacebetween p-10'>
                  <span>Text Colour</span>
                  <Box className='v-center mr-3' sx={{ position: 'relative' }}>
                    <input type="text"
                      className='v-input-text mr-2 v-font-2-2'
                      value={'#' + textColor}
                      onChange={() => { }} disabled />

                    <ColorPicker
                      Icon={<FormatColorFillIcon className='v-font-3-2 v-colorpicker-icon' />}
                      color={'#' + textColor}
                      change={textColorChanged} />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box md={12} className='mb-2'>
              {/* <Box className='px-10 pt-0'>
                <Box onClick={autoSizeSetting}
                  className='v-addtextbutton v-border_t v-center v-commonboxheight v-pointer v-font-3-3 mt-4'
                >Auto Size Setting</Box>
              </Box> */}

              <Box className='px-10 pt-0'>
                <Box onClick={() => { setSelectedText(null) }}
                  className='v-addtextbutton v-border_t v-center v-commonboxheight v-pointer v-font-3-3 mt-15 mm:mt-30'
                >{languageStrs.add_new_text_field}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}