import { createContext } from "react";
import { useContext, useState } from 'react';
import { WebGLDefaultData } from '../../videoEdit.config';

const ThumbnailCtx = createContext();

const ThumbnailContextProvider = ({ children }) => {
  const [thumbnailCtx, setThumnailCtx] = useState(WebGLDefaultData)

  return (
    <ThumbnailCtx.Provider value={[thumbnailCtx, setThumnailCtx]}>
      {children}
    </ThumbnailCtx.Provider>
  )
}

const useThumbnail = () => useContext(ThumbnailCtx)

export {
  ThumbnailCtx,
  useThumbnail,
  ThumbnailContextProvider
}