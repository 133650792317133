import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import { getGLContext, getImageWebGLElements, getTextWebGLElements, getVideoWebGLElements } from '../webGL/commonFunc';
import { getGroupoptions } from '../commonFunction';
import { useThumbnail } from './provider/thumbnail.provider';
import { DrawScene, UpdateVideoTextures, initShaderProgram } from '../webGL/Setups/SetupClass';

export const ThumbnailGrid = ({ videos, images, texts }) => {
  const [canvasGL, setCanvasGL] = useState(null)
  const [mainCanvas, setMainCanvas] = useState(null)
  const [thumbnailCtx, setThumnailCtx] = useThumbnail()

  const [groupData, setGroupData] = useState({})
  const projectJSON = useSelector(store => store.projectdata.projectJSON);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const imageElements = useSelector(store => store.webgldata.imageElements);
  const timeCount = useSelector(store => store.videodata.Time_Count);

  useEffect(() => {
    let groupOptions = getGroupoptions(projectJSON)
    setGroupData(groupOptions)
  }, [projectJSON])

  useEffect(() => {
    if (!thumbnailCtx.webGLRef) return;
    const canvasEl = thumbnailCtx.webGLRef;
    const canvasGl = getGLContext(canvasEl);
    const shaderProgram = initShaderProgram(canvasGl);

    const programData = {
      program: shaderProgram,
      attribLocations: {
        vertexPosition: canvasGl.getAttribLocation(shaderProgram, 'aVertexPosition'),
        textureCoord: canvasGl.getAttribLocation(shaderProgram, 'aTextureCoord'),
      },
      uniformLocations: {
        projectionMatrix: canvasGl.getUniformLocation(shaderProgram, 'uProjectionMatrix'),
        modelViewMatrix: canvasGl.getUniformLocation(shaderProgram, 'uModelViewMatrix'),
        //filterPreset_m: canvasGl.getUniformLocation(shaderProgram, 'aFilterPreset_m'),
        //filterPreset_v: canvasGl.getUniformLocation(shaderProgram, 'aFilterPreset_v'),
        transparency: canvasGl.getUniformLocation(shaderProgram, 'transparency'),
        editValue: canvasGl.getUniformLocation(shaderProgram, 'edit_values'),
        blackWhiteFilter: canvasGl.getUniformLocation(shaderProgram, 'blackwhite'),
        uSampler: canvasGl.getUniformLocation(shaderProgram, 'uSampler'),
      },
    }

    setThumnailCtx({ ...thumbnailCtx, programInfo: programData });
    setMainCanvas(canvasEl);
    setCanvasGL(canvasGl);
  }, [thumbnailCtx.webGLRef])

  const [time, setTime] = useState(+new Date());
  useEffect(() => {
    let handler = setTimeout(async () => {
      await setTime(+new Date());
      if (!mainCanvas || !thumbnailCtx.activeTextures.size) return;

      videos.forEach(function (key) {
        if (!videoElements[key]) return;
        const currentTexture = thumbnailCtx.activeTextures.get(key);
        
        UpdateVideoTextures(canvasGL, currentTexture, videoElements[key]);
      })

      images.forEach(function (key) {
        if (!imageElements[key]) return;
        const currentTexture = thumbnailCtx.activeTextures.get(key);
        UpdateVideoTextures(canvasGL, currentTexture, imageElements[key]);
      })
     DrawScene(canvasGL, thumbnailCtx, projectJSON, groupData, timeCount);
    }, 50);
    return () => handler && clearTimeout(handler);
  }, [time])

  useEffect(() => {
    getWebGLElements(videos, images, texts)
  }, [videos, images, texts])

  const getWebGLElements = async (videouuids, imageuuids, textuuids) => {
    if (!thumbnailCtx.webGLRef || !thumbnailCtx.activeModelViewMatrices) return;

    const activeMatrices = thumbnailCtx.activeModelViewMatrices;
    const activeTextures = thumbnailCtx.activeTextures;
    const activeBuffers = thumbnailCtx.activeBuffers;
    const activeTextCanvases = thumbnailCtx.activeTextCanvases;
    const activeKeys = [...videouuids, ...textuuids, ...imageuuids];

    const matricesKeys = [...activeMatrices.keys()];
    matricesKeys.forEach((key) => {
      const checkActiveKey = activeKeys.find((activeKey) => activeKey === key);
      if (!checkActiveKey) {
        activeMatrices.delete(key);
        activeTextures.delete(key);
        activeBuffers.delete(key);
        activeTextCanvases.delete(key);
      }
    })
    
    await getVideoWebGLElements(videouuids, projectJSON, videoElements, thumbnailCtx);
    await getImageWebGLElements(imageuuids, projectJSON, imageElements, thumbnailCtx);
    await getTextWebGLElements(textuuids, projectJSON, thumbnailCtx);
  }

  return (
    <></>
  )
}