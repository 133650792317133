import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Menu, Slider } from "@mui/material";

import './mobile.controller.scss';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import VolumeUp from '@mui/icons-material/VolumeUp';
import OpacityIcon from '@mui/icons-material/Opacity';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import BorderColorIcon from '@mui/icons-material/BorderColor'
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';


import { volumeMarks } from './control.config';
import { changeZeroSecond, copyObject, linkactive } from '../commonFunction';
import { getSelectedType, getNextZIndex } from '../commonFunction';
import { ProjectJSON_Store, editSubtitle_Store, thumbNails_Store, StretchScreenFlag_store } from '../../../store/actions/projectdata';
import { SelectedItemID_Store, VideoElements_Store, audioElements_Store, imageElements_Store, selectedGroupID_Store } from '../../../store/actions/webgldata';
import { removeElement, duplicateElementCreate, splitElementCreate, checkVideoEL, fullVideoSize, checkVolumeValue } from './controlCommonFunc';

export const MobileControl = () => {
  const dispatch = useDispatch();
  const ProjectData = useSelector(store => store.projectdata.projectJSON);
  const selectedID = useSelector(store => store.webgldata.selectedItemID);
  const Time_Count = useSelector((store) => store.videodata.Time_Count);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const audioElements = useSelector(store => store.webgldata.audioElements);
  const imageElements = useSelector(store => store.webgldata.imageElements);
  const selectedGroupID = useSelector(store => store.webgldata.selectedGroupID);
  const editSubtitle = useSelector(store => store.projectdata.editSubtitle);
  const thumbNails = useSelector(store => store.projectdata.thumbNails);
  const stretchScreenFlag = useSelector((store) => store.projectdata.stretchScreenFlag);


  const [menuOpen, setMenuOpen] = useState(true);
  const [hoverItem, setHoverItem] = useState(-1);
  const onTouchStart = (index) => { setHoverItem(index); }
  const onTouchEnd = () => { setTimeout(() => { setHoverItem(-1); }, 100); }

  const levelUpClick = () => { levelUpDown(1); }
  const replitClick = () => { splitFunction(); }
  const volumeClick = (e) => { openVolumnMenu(e); }
  const levelDownClick = () => { levelUpDown(-1); }
  const removeClick = () => { removeElementFunc(); }
  const duplicateClick = () => { duplicateFunction(); }
  const opacityClick = (e) => { setOpacityMenu(e.currentTarget); }

  const editSubtitleClick = () => {
    if (subtitleFlag && !editSubtitle) {
      dispatch(editSubtitle_Store(true))
    }
  }

  const menuInfo = [
    {
      icon: <ContentCopyIcon />,
      title: 'duplicate',
      default: false,
      func: duplicateClick
    },{
      icon: <ContentCutIcon />,
      title: 'split',
      default: false,
      func: replitClick
    },  {
      icon: <DeleteOutlineIcon />,
      title: 'remove',
      default: false,
      func: removeClick
    }, {
      title: 'layer',
      default: false,
      icon:
        <ArrowUpwardOutlinedIcon
          onClick={levelUpClick}
          className='w-40 h-40 text-[#9094a3] p-[8px]'
        />,
      icon1:
        <ArrowDownwardOutlinedIcon
          onClick={levelDownClick}
          className='w-40 h-40 text-[#9094a3] p-[8px]'
        />,
    }, {
      icon: <VolumeUp />,
      title: 'volume',
      default: false,
      func: volumeClick,
    }, {
      icon: <OpacityIcon />,
      title: 'opacity',
      default: false,
      func: opacityClick,
    }, 
    // {
    //   icon: <AutoFixHighIcon />,
    //   title: 'EditSubtitle',
    //   default: false,
    //   func: editSubtitleClick,
    // },
  ];

  const [activeType, setActiveType] = useState('')
  const [activevideo, setActiveVideo] = useState(null)
  const [subtitleFlag, setSubtitleFlag] = useState(false)

  useEffect(() => {
    let tempFlag = false

    if (selectedID && ProjectData) {
      let tempType = getSelectedType(selectedID, ProjectData);
      if (tempType === 'text') {
        let textItem = ProjectData.text[selectedID]
        if (textItem.subtitle_uuid) {
          tempFlag = true
        }
      }
    }

    if (subtitleFlag !== tempFlag) {
      setSubtitleFlag(tempFlag)
    }
  }, [selectedID, ProjectData])

  useEffect(() => {
    let tempType = ''
    let activeVideo = null

    if (selectedID && ProjectData) {
      tempType = getSelectedType(selectedID, ProjectData);
    }

    if (tempType === 'videos') {
      activeVideo = ProjectData.videos[selectedID]
    }

    setActiveVideo(activeVideo)

    if (tempType !== activeType) {
      setActiveType(tempType)
    }
  }, [selectedID, ProjectData])

  const removeElementFunc = async () => {
    const [newJSONdata, deleteItem] = await removeElement(ProjectData, selectedID);

    if (!!newJSONdata) {
      dispatch(SelectedItemID_Store(null));
      dispatch(ProjectJSON_Store(newJSONdata));
      changeZeroSecond(deleteItem, dispatch)
    }
  }

  const splitFunction = async () => {
    const newUUID = uuidv4();
    const [newJSONdata, type] = await splitElementCreate(ProjectData, selectedID, newUUID, Time_Count);
    if (!newJSONdata) return;

    if (type === 'videos') {
      if (!videoElements[selectedID]) return;
      let newVideoEL = videoElements[selectedID]; //.cloneNode(true);
      newVideoEL.id = newUUID;

      dispatch(VideoElements_Store({
        ...videoElements,
        [newUUID]: newVideoEL
      }))

      if (thumbNails[selectedID]) {
        dispatch(thumbNails_Store({
          ...thumbNails,
          [newUUID]: thumbNails[selectedID]
        }))
      }
    }

    if (type === 'audios') {
      if (!audioElements[selectedID]) return;
      let newAudioEl = audioElements[selectedID].cloneNode(true);
      newAudioEl.id = newUUID;

      dispatch(audioElements_Store({ ...audioElements, [newUUID]: newAudioEl }))
    }

    if (type === 'images') {
      if (!imageElements[selectedID]) return;
      let newImageEL = imageElements[selectedID].cloneNode(true);
      newImageEL.id = newUUID;

      let tempImageEL = Object.assign({}, imageElements);
      tempImageEL[newUUID] = newImageEL;
      await dispatch(imageElements_Store(tempImageEL));
    }

    dispatch(ProjectJSON_Store(newJSONdata));
  }

  const duplicateFunction = async () => {
    const newUUID = uuidv4();
    const [newJSONdata, type] = await duplicateElementCreate(ProjectData, selectedID, newUUID);
    if (!newJSONdata) return;

    if (type === 'videos') {
      if (!videoElements[selectedID]) return;
      let newVideoEL = videoElements[selectedID]; //.cloneNode(true);
      newVideoEL.id = newUUID;

      dispatch(VideoElements_Store({
        ...videoElements,
        [newUUID]: newVideoEL
      }))

      if (thumbNails[selectedID]) {
        dispatch(thumbNails_Store({
          ...thumbNails,
          [newUUID]: thumbNails[selectedID]
        }))
      }
    }

    if (type === 'audios') {
      if (!audioElements[selectedID]) return;
      let newAudioEl = audioElements[selectedID].cloneNode(true);
      newAudioEl.id = newUUID;

      dispatch(audioElements_Store({ ...audioElements, [newUUID]: newAudioEl }))
    }

    if (type === 'images') {
      if (!imageElements[selectedID]) return;
      let newImageEL = imageElements[selectedID].cloneNode(true);
      newImageEL.id = newUUID;

      let tempImageEL = Object.assign({}, imageElements);
      tempImageEL[newUUID] = newImageEL;
      await dispatch(imageElements_Store(tempImageEL));
    }

    dispatch(ProjectJSON_Store(newJSONdata));
  }

  const levelUpDown = (flag) => {
    if (!selectedID) return;
    if (flag !== 1 && flag !== -1) return;

    let tempJSON = JSON.parse(JSON.stringify(ProjectData));
    let type = getSelectedType(selectedID, tempJSON);
    if (!type || !tempJSON[type][selectedID]) return;

    let elementOb = tempJSON[type][selectedID];
    let zIndex = getNextZIndex(tempJSON, selectedID, type, flag)
    let currentIndex = elementOb.coordinate.zIndex;
    if (currentIndex === zIndex) return;

    elementOb.coordinate.zIndex = zIndex;
    dispatch(ProjectJSON_Store(tempJSON));
    changeZeroSecond(elementOb, dispatch)
  }

  // video volume change func start
  const [volumeValue, setVolumeValue] = useState(0);
  const [volumeMenu, setVolumeMenu] = useState(null);
  const closeVolumnMenu = () => { setVolumeMenu(null); };

  useEffect(() => {
    if (selectedID && ProjectData) {
      let tempType = getSelectedType(selectedID, ProjectData);

      if (tempType === 'videos' || tempType === 'audios') {
        let tempObject = ProjectData[tempType][selectedID]
        let value = checkVolumeValue(tempObject.volume);
        setVolumeValue(value);
      } else setVolumeValue(0);
    }
  }, [selectedID, ProjectData])

  const openVolumnMenu = async ({ currentTarget }) => {
    if (activeType === 'videos' || activeType === 'audios') {
      setVolumeMenu(currentTarget)
    } else {
      window.toastr.error('could you please select Video Item?');
      closeVolumnMenu();
    }
  }

  const changedVolume = async () => {
    if (activeType === 'videos' || activeType === 'audios') {
      const newJSONdata = Object.assign({}, ProjectData);
      let tempObject = newJSONdata[activeType][selectedID]
      tempObject.volume = volumeValue;

      newJSONdata[activeType] = { ...newJSONdata[activeType], [selectedID]: tempObject };
      dispatch(ProjectJSON_Store(newJSONdata));
    } else setVolumeMenu(null);
  }
  // video volume change func end

  // opacity change func start
  const [opacity, setOpacity] = useState(0);
  const [selected, setSelected] = useState({})
  const [opacityMenu, setOpacityMenu] = useState(null);

  useEffect(() => {
    let tempSelected = {}
    if (!!selectedID) {
      let type = getSelectedType(selectedID, ProjectData);
      if(!ProjectData[type]) return;
      let tempItem = ProjectData[type][selectedID]

      if (!!type && !!tempItem) {
        tempSelected = tempItem
        setOpacity(tempItem.alpha)
      } else setOpacity(0)
    }

    setSelected(tempSelected)
  }, [selectedID, ProjectData])

  const changedOpacity = async () => {
    let tempJSON = copyObject(ProjectData);
    if (!selectedID) { setOpacity(0); return; }
    let tempType = getSelectedType(selectedID, tempJSON);

    let selectedOb = tempJSON[tempType];
    if (!selectedOb || !selectedOb[selectedID]) {
      setOpacity(1);
      return;
    }

    let elementOb = tempJSON[tempType][selectedID]
    if (elementOb.alpha !== opacity) {
      elementOb.alpha = opacity;
      dispatch(ProjectJSON_Store(tempJSON));
      changeZeroSecond(elementOb, dispatch)
    }
  }
  // opacity change func end

  const [selectedType, setSelectedType] = useState(false)
  useEffect(() => {
    let tempJSON = copyObject(ProjectData)
    let type = getSelectedType(selectedID, tempJSON)
    setSelectedType(type === 'text')
  }, [ProjectData, selectedID])

  const textEdit = () => {
    linkactive(9, dispatch, false)
  }

  const changeDisable = () => {
    if (activeType !== 'videos') {
      return
    }

    let tempJSON = copyObject(ProjectData)
    let activeVideo = tempJSON.videos[selectedID]

    if (!activeVideo) {
      return
    }

    activeVideo.disable_video = !activeVideo?.disable_video
    dispatch(ProjectJSON_Store(tempJSON))
    changeZeroSecond(activeVideo, dispatch)
  }

  const removeGroup = () => {
    if (!ProjectData?.videos || !selectedGroupID) {
      return
    }

    const tempJSON = copyObject(ProjectData)
    const videos = tempJSON.videos
    const audios = tempJSON.audios
    const images = tempJSON.images
    const texts = tempJSON.text

    const videoKeys = Object.keys(videos)
    const audiokeys = Object.keys(audios)
    const imageKeys = Object.keys(images)
    const textKeys = Object.keys(texts)

    const tempVideos = {}
    const tempAudios = {}
    const tempImages = {}
    const tempTexts = {}

    let removeObject = null

    videoKeys.forEach((key) => {
      const videoEl = videos[key]
      if (videoEl.groups !== selectedGroupID) {
        tempVideos[key] = videoEl
      } else if (videoEl.timeline_start === 0) {
        removeObject = videoEl
      }
    })

    audiokeys.forEach((key) => {
      const audioEl = audios[key]
      if (audioEl.groups !== selectedGroupID) {
        tempAudios[key] = audioEl
      } else if (audioEl.timeline_start === 0) {
        removeObject = audioEl
      }
    })

    imageKeys.forEach((key) => {
      const imageEl = images[key]
      if (imageEl.groups !== selectedGroupID) {
        tempImages[key] = imageEl
      } else if (imageEl.timeline_start === 0) {
        removeObject = imageEl
      }
    })

    textKeys.forEach((key) => {
      const textEl = texts[key]
      if (textEl.groups !== selectedGroupID) {
        tempTexts[key] = textEl
      } else if (textEl.timeline_start === 0) {
        removeObject = textEl
      }
    })

    let tempNewJson = {
      ...tempJSON,
      videos: tempVideos,
      audios: tempAudios,
      images: tempImages,
      text: tempTexts,
    }

    dispatch(SelectedItemID_Store(null))
    dispatch(selectedGroupID_Store(null))
    dispatch(ProjectJSON_Store(tempNewJson))

    if (!!removeObject) {
      changeZeroSecond(removeObject, dispatch)
    }
  }

  const stretchScreenVideo = async () => {
    const [newJSONdata, type] = await fullVideoSize(ProjectData, selectedID);
    if (type === 'videos' || type === 'images'){
      dispatch(StretchScreenFlag_store(!stretchScreenFlag))
    }
  }

  return (
    <Box className={`mobile-controller-container ${!menuOpen ? 'desable-controller' : ''}`}>
      <Box className="menu-icon-btn" onClick={() => { setMenuOpen(!menuOpen) }}>
        <KeyboardArrowRightIcon className={menuOpen ? 'open-menu-btn' : ''} />
      </Box>

      <Box className={`mobile-contraller-body`}>
        {!!selectedType && (
          <div className="mobile-menu-item-contain">
            <div
              className={`mobile-menu-item ${hoverItem === 12 && 'active-item'}`}
              onTouchStart={() => { onTouchStart(12) }}
              onTouchEnd={onTouchEnd}
              onClick={textEdit}
            >
              <BorderColorIcon />
              <Typography component="h2">Edit</Typography>
            </div>
          </div>
        )}

        {menuInfo.map((item, index) =>
          <Box className="mobile-menu-item-contain" key={index}>
            {item.title !== 'layer' && (
              <Box className={`mobile-menu-item ${hoverItem === index && 'active-item'}`}
                onTouchStart={() => { onTouchStart(index); }}
                onTouchEnd={onTouchEnd}
                onClick={item.func}
              >
                {item.icon}
                <Typography component="h2">{item.title}</Typography>
              </Box>
            )}

            {item.title === 'layer' && (
              <div className='w-full h-full flex flex-col justify-around items-center'>
                <div className='flex flex-row'>
                  <div className={hoverItem === 10 ? 'active-item' : ''}
                    onTouchStart={() => { onTouchStart(10) }}
                    onTouchEnd={onTouchEnd}
                  >{item.icon}</div>

                  <div className={hoverItem === 11 ? 'active-item' : ''}
                    onTouchStart={() => { onTouchStart(11) }}
                    onTouchEnd={onTouchEnd}
                  >{item.icon1}</div>
                </div>

                <div className='text-[0.7rem] font-[100] font-[Roboto] text-[#282930]'>
                  Layer {selected?.coordinate?.zIndex + 9}
                </div>
              </div>
            )}
          </Box>
        )}

        {activeType === 'videos' && (
          <div className="mobile-menu-item-contain">
            <div onTouchEnd={onTouchEnd}
              onTouchStart={() => { onTouchStart(12) }}
              className={`mobile-menu-item ${hoverItem === 12 && 'active-item'}`}
            >
              <input type='checkbox'
                onChange={changeDisable}
                checked={!!activevideo?.disable_video}
                className='default-input flex-1'
              />

              <Typography component='h2'>Disable</Typography>
            </div>
          </div>
        )}

        {selectedGroupID && (
          <>
          <div className="mobile-menu-item-contain">
            <div onTouchEnd={onTouchEnd}
              onTouchStart={() => { onTouchStart(12) }}
              className={`mobile-menu-item ${hoverItem === 12 && 'active-item'}`}
              onChange={changeDisable}
            >
              <FolderDeleteIcon onClick={removeGroup} />
              <Typography component='h2'>DeleteRow</Typography>
            </div>
          </div>
          <div className="mobile-menu-item-contain">
            <div onTouchEnd={onTouchEnd}
              onTouchStart={() => { onTouchStart(13) }}
              className={`mobile-menu-item ${hoverItem === 13 && 'active-item'}`}
              onChange={changeDisable}
            >
              <ZoomOutMapOutlinedIcon onClick={stretchScreenVideo} />
              <Typography component='h2'>Fullscreen</Typography>
            </div>
          </div>
          </>
          
        )}

        <Menu
          anchorEl={volumeMenu}
          open={Boolean(volumeMenu)}
          onClose={() => { setVolumeMenu(null); }}
          transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          className='volume-menu-wrapper'
        >
          <Box className='volume-menu-contain'>
            <VolumeUp className='menu-icon-top' />

            <Slider
              sx={{ '& input[type="range"]': { WebkitAppearance: 'slider-vertical' } }}
              orientation="vertical"
              value={volumeValue}
              step={0.1} min={0} max={1}
              marks={volumeMarks}
              onChange={(e) => { setVolumeValue(e.target.value) }}
              onChangeCommitted={changedVolume}
            />

            <VolumeOffIcon className='menu-icon-bottom' />
          </Box>
        </Menu>

        <Menu
          anchorEl={opacityMenu}
          open={Boolean(opacityMenu)}
          onClose={() => { setOpacityMenu(null); }}
          transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          className='volume-menu-wrapper'
        >
          <Box className='volume-menu-contain'>
            <VolumeUp className='menu-icon-top' />

            <Slider
              sx={{ '& input[type="range"]': { WebkitAppearance: 'slider-vertical' } }}
              onChange={(e) => { setOpacity(e.target.value) }}
              orientation="vertical" step={0.1} min={0} max={1}
              value={opacity} marks={volumeMarks}
              onChangeCommitted={changedOpacity}
            />

            <VolumeOffIcon className='menu-icon-bottom' />
          </Box>
        </Menu>
      </Box>
    </Box>
  )
}