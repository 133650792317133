
import { VIDEOELEMENTS, MOVEABLEDIVARRAY, SELECTEDITEMID, DATALOADED, WEBGLSIZE, IMAGEELEMENTS, SELECTEDGROUPID, AUDIOELEMENTS, SWITCHVIDEO } from "../constants/webglinfo";

export const VideoElements_Store = (params) => {
 return (dispatch) =>
    dispatch({ type: VIDEOELEMENTS, payload: params, });
};

export const audioElements_Store = (params) => {
  return (dispatch) =>
    dispatch({ type: AUDIOELEMENTS, payload: params, });
};

export const imageElements_Store = (params) => {
  return (dispatch) =>
    dispatch({ type: IMAGEELEMENTS, payload: params, });
};

export const MoveableDivArray_Store = (params) => {
  return (dispatch) =>
    dispatch({ type: MOVEABLEDIVARRAY, payload: params, });
};

export const SelectedItemID_Store = (params) => {
  return (dispatch) =>
    dispatch({ type: SELECTEDITEMID, payload: params });
}

export const dataLoaded_Store = (params) => {
  return (dispatch) =>
    dispatch({ type: DATALOADED, payload: params });
}

export const webGlSize_Store = (params) => {
  return (dispatch) =>
    dispatch({ type: WEBGLSIZE, payload: params });
}

export const selectedGroupID_Store = (params) => {
  return (dispatch) =>
    dispatch({ type: SELECTEDGROUPID, payload: params });
}

export const switchVideo_Store = (params) => {
  return (dispatch) =>
    dispatch({type: SWITCHVIDEO, payload: params});
}