import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Stack, Button, TextField, FormControl, OutlinedInput, FormHelperText } from '@mui/material';
import { MenuItem, Select } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2';
import { PreviewText } from './tooltap.subtitles';
import { defaultPreviewStyle } from './tooltap.config';
import { SectionHeader } from './common/section.header';
import { changeZeroSecond, convertStringtoWStringUTF16, copyObject, getMaxZIndex, getSelectedType } from '../commonFunction';
import { getCenterStyle, getFitContext, getNewTextInformation } from '../webGL/commonFunc';
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import { changedJSON_Store, editSubtitle_Store, ProjectJSON_Store, subCreating_Store, subtitlePostY_store } from '../../../store/actions/projectdata';
import { SERVER_STATUS, createSubtitle, getProjectJSON, subtitleWebHookPoll, updateProjectJSON, updateSubtitleInfo } from '../../../axios/ApiProvider';
import PricingModal from '../../../pages/Pricing/pricingModal';
import { getContentWidthHeight } from '../webGL/Setups/SetupClass';
import { SelectedItemID_Store } from '../../../store/actions/webgldata';
import srtParser2 from "srt-parser-2";
import { useWebGL } from '../videoEdit.provider';

let loadedSubKey = []

const languageToList0 = [
  { label: "Arabic", key: "ar" },
  { label: "English", key: "en" },
  { label: "Bashkir", key: "ba" },
  { label: "Basque", key: "eu" },
  // { label: "Belarusian", key: "be" },
  // { label: "Bulgarian", key: "bg" },
  { label: "Cantonese", key: "yue" },
  { label: "Catalan", key: "ca" },
  { label: "Croatian", key: "hr" },
  { label: "Czech", key: "cs" },
  { label: "Danish", key: "da" },
  { label: "Dutch", key: "nl" },
  { label: "Esperanto", key: "eo" },
  { label: "Estonian", key: "et" },
  { label: "Finnish", key: "fi" },
  { label: "French", key: "fr" },
  { label: "Galician", key: "gl" },
  { label: "German", key: "de" },
  { label: "Greek", key: "el" },
  { label: "Hindi", key: "hi" },
  { label: "Hungarian", key: "hu" },
  { label: "Interlingua", key: "ia" },
  { label: "Italian", key: "it" },
  { label: "Indonesian", key: "id" },
  { label: "Japanese", key: "ja" },
  { label: "Korean", key: "ko" },
  { label: "Latvian", key: "lv" },
  { label: "Lithuanian", key: "lt" },
  { label: "Malay", key: "ms" },
  { label: "Mandarin", key: "cmn" },
  { label: "Marathi", key: "mr" },
  { label: "Mongolian", key: "mn" },
  { label: "Norwegian", key: "no" },
  { label: "Polish", key: "pl" },
  { label: "Portuguese", key: "pt" },
  { label: "Romanian", key: "ro" },
  // { label: "Russian", key: "ru" },
  { label: "Slovakian", key: "sk" },
  { label: "Slovenian", key: "sl" },
  { label: "Spanish", key: "es" },
  { label: "Swedish", key: "sv" },
  { label: "Tamil", key: "ta" },
  { label: "Thai", key: "th" },
  { label: "Turkish", key: "tr" },
  // { label: "Uyghur", key: "ug" },
  // { label: "Ukrainian", key: "uk" },
  { label: "Vietnamese", key: "vi" },
  { label: "Welsh", key: "cy" },
]

const languageToList1 = [
  { label: "Arabic", key: "ar" },
  { label: "English", key: "en" },
  // { label: "Bulgarian", key: "bg" },
  { label: "Catalan", key: "ca" },
  { label: "Mandarin", key: "cmn" },
  { label: "Czech", key: "cs" },
  { label: "Danish", key: "da" },
  { label: "German", key: "de" },
  { label: "Greek", key: "el" },
  { label: "Spanish", key: "es" },
  { label: "Estonian", key: "et" },
  { label: "Finnish", key: "fi" },
  { label: "French", key: "fr" },
  { label: "Galician", key: "gl" },
  { label: "Hindi", key: "hi" },
  { label: "Croatian", key: "hr" },
  { label: "Hungarian", key: "hu" },
  { label: "Indonesian", key: "id" },
  { label: "Italian", key: "it" },
  { label: "Japanese", key: "ja" },
  { label: "Korean", key: "ko" },
  { label: "Lithuanian", key: "lt" },
  { label: "Latvian", key: "lv" },
  { label: "Malay", key: "ms" },
  { label: "Dutch", key: "nl" },
  { label: "Norwegian", key: "no" },
  { label: "Polish", key: "pl" },
  { label: "Portuguese", key: "pt" },
  { label: "Romanian", key: "ro" },
  // { label: "Russian", key: "ru" },
  { label: "Slovakian", key: "sk" },
  { label: "Slovenian", key: "sl" },
  { label: "Swedish", key: "sv" },
  { label: "Turkish", key: "tr" },
  // { label: "Ukrainian", key: "uk" },
  { label: "Vietnamese", key: "vi" },
]

const languageToList2 = [
  { label: "Arabic", key: "ar" },
  { label: "Turkish", key: "tr" },
  // { label: "Bulgarian", key: "bg" },
  { label: "Catalan", key: "ca" },
  { label: "Mandarin", key: "cmn" },
  { label: "Czech", key: "cs" },
  { label: "Danish", key: "da" },
  { label: "German", key: "de" },
  { label: "Greek", key: "el" },
  { label: "Spanish", key: "es" },
  { label: "Estonian", key: "et" },
  { label: "Finnish", key: "fi" },
  { label: "French", key: "fr" },
  { label: "Galician", key: "gl" },
  { label: "Hindi", key: "hi" },
  { label: "Croatian", key: "hr" },
  { label: "Hungarian", key: "hu" },
  { label: "Indonesian", key: "id" },
  { label: "Italian", key: "it" },
  { label: "Japanese", key: "ja" },
  { label: "Korean", key: "ko" },
  { label: "Lithuanian", key: "lt" },
  { label: "Latvian", key: "lv" },
  { label: "Malay", key: "ms" },
  { label: "Dutch", key: "nl" },
  { label: "Norwegian", key: "no" },
  { label: "Polish", key: "pl" },
  { label: "Portuguese", key: "pt" },
  { label: "Romanian", key: "ro" },
  // { label: "Russian", key: "ru" },
  { label: "Slovakian", key: "sk" },
  { label: "Slovenian", key: "sl" },
  { label: "Swedish", key: "sv" },
  // { label: "Ukrainian", key: "uk" },
  { label: "Vietnamese", key: "vi" },
]

const languageToList3 = [
  { label: "English", key: "en" },
]
let subtitleUUIDKey = '';

const inputStyle = { position: 'absolute', top: 0, left: 0, opacity: 0 };

export const TooltapTranslations = () => {
  const editSubtitle = useSelector(store => store.projectdata.editSubtitle);
  const [isEditing, setIsEditing] = useState(false);
  
  useEffect(() => {
    setIsEditing(editSubtitle)
  }, [editSubtitle])
  return (
    <>
    {isEditing?
      <ToolTapEditSubtitle />:<TooltapCreateTranslations />
    }
    </>
  )
}
export const TooltapCreateTranslations = (props) => {
  const dispatch = useDispatch();
  const fileInputer = useRef();

  const projectUUID = GetProjectUUID();

  const uploadingFiles = useSelector((store) => store.projectdata.uploadingFiles);

  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const loginState = useSelector((store) => store.maindata.loginState)
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON)
  const subcreating = useSelector((store) => store.projectdata.subcreating)
  
  const [userData, setUserData] = useState({})
  const [firstLoad, setFirstLoad] = useState(true)
  const [tDisable, setTDisable] = useState(true)

  const [language, setLanguage] = useState('en')
  const [languageTo, setLanguageTo] = useState('tr')
  const [textStyles, setTextStyles] = useState(defaultPreviewStyle)

  const [openUploading, setOpenUploading] = useState(false);

  
  useEffect(() => {
    let loadCount = 0;
    let keys = Object.keys(uploadingFiles);
    if(keys.length > 0){
      keys.map(kItem => {
        let tmpFile = uploadingFiles[kItem];
        if(tmpFile.state==true){
          loadCount++;
        }
      })
      if(loadCount == keys.length){
        setOpenUploading(false);
      }else{
        setOpenUploading(true);
      }
      
    }else{
      setOpenUploading(false);
    }
  
  }, [uploadingFiles])

  const [showPricingPageModal, setShowPricingPageModal] = useState(false);
  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }

  const changeCreating = (flag) => {
    dispatch(subCreating_Store(flag))
  }

  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')

    if (!!item && JSON.parse(item)?.state === 'loggedin') {
      tempData = JSON.parse(item)
    }

    setUserData(tempData)
  }, [])

  useEffect(() => {
    if (!firstLoad) return
    if (!ProjectJSON || !ProjectJSON?.subtitles) return;
    setFirstLoad(false);

    let assets = Object.assign({}, ProjectJSON.asset);
    let assetKeys = Object.keys(assets)
    if (!assetKeys.length) return;

    let subtitles = Object.assign({}, ProjectJSON.subtitles);
    let subKeys = Object.keys(subtitles);
    let checkKey = subKeys.find((key) => {
      let before = loadedSubKey.find((k) => key === k)
      return !before && subtitles[key].state !== 3
    });

    if (checkKey) {
      loadedSubKey.push(checkKey)
      getSubtitleWebHook(assetKeys[0], 'manual');
      changeCreating(true);
    }
  }, [ProjectJSON])
  const [progressTotal, setProgressTotal] = useState(0);
  const [progressVal, setProgressVal] = useState(0);
  const [subtitlePosY, setSubtitlePosY] = useState(0);
  const subtitlePosYStore = useSelector((store) => store.projectdata.subtitlePosY);
  
  useEffect(() => {
    setSubtitlePosY(subtitlePosYStore);
  }, [subtitlePosYStore]);

  const onSetSubtitlePosY = (val) => {
    dispatch(subtitlePostY_store(val));
    // setSubtitlePosY(val);
  }

  useEffect(() => {
    if(progressTotal > 0){
      setTimeout(function() {
        let tmpProgressVal = Math.round(progressVal + ((1/progressTotal) * 100));
        if(tmpProgressVal > 100){
          tmpProgressVal = 100;
        }
        setProgressVal(tmpProgressVal);
      }, 700)
    }
  }, [progressTotal, progressVal])

  const autoSubtitleClick = async () => {
    if (!loginState || !userData?.subtitle_state) {
    //  window.toastr.error('You have spent your Subtitle request limits with your user, consider upgrading your subscription')
      setShowPricingPageModal(true);
      return
    }
    
    if (subcreating) return;
    const tmpTotalVal = (ProjectJSON.duration / 1000) > 70? ((ProjectJSON.duration / 1000) / 3) + 30 : 60;
    setProgressTotal(tmpTotalVal);
    setProgressVal(0);
    let assets = Object.assign({}, ProjectJSON.asset)
    let videos = Object.assign({}, ProjectJSON.videos)

    let assetKeys = Object.keys(assets)
    let videoKeys = Object.keys(videos)
    if (!assetKeys.length || !videoKeys.length) return;
    if (!assetKeys[0]) return;

    changeCreating(true);
    let templan = tDisable ? null : languageTo
    let resData = await createSubtitle(projectUUID, assetKeys[0], language, templan, true);
    
    if (resData.state !== 'success') {
      changeCreating(false);
      setProgressTotal(0);
      setProgressVal(0);
      return;
    }

    // if (resData.data.Code !== 'Subtitle Submit success.') {
    if(!resData.data.uuid){
      changeCreating(false);
      setProgressTotal(0);
      setProgressVal(0);
      return;
    }
    subtitleUUIDKey = resData.data.uuid;
    getSubtitleWebHook(assetKeys[0], 'auto');
  }

  const getSubtitleWebHook = async (asset_uuid, type) => {
    const JSONdata = await getProjectJSON(projectUUID);
    if (JSONdata.state !== 'success') {
      changeCreating(false);
      setProgressTotal(0);
    setProgressVal(0);
      return;
    }

    const projectData = JSONdata?.data[projectUUID];
    if (!projectData) {
      changeCreating(false);
      setProgressTotal(0);
    setProgressVal(0);
      return;
    }

    if (SERVER_STATUS === 'localhost' && type === 'auto') {
      projectData.subtitles = {
        "e904f0db-eafb-4c58-9aaf-63a4a6f037de": { "state": 2 }
      }
    }

    let newSubtitle = Object.assign({}, projectData.subtitles);
    let newSubKeys = Object.keys(newSubtitle);

    if (!newSubKeys.length) {
      changeCreating(false)
      setProgressTotal(0);
    setProgressVal(0);
      return
    }

    let subKey = newSubKeys.find((key) => newSubtitle[key].state !== 3);
    if (!subKey) {
      changeCreating(false)
      setProgressTotal(0);
    setProgressVal(0);
      return
    }

    let subState = newSubtitle[subKey].state;
    if (subState !== 2 && subState !== 3) {
      setTimeout(() => {
        getSubtitleWebHook(asset_uuid, type, 2)
      }, 3000)

      return
    }

    let webhookRes = await subtitleWebHookPoll(projectUUID, asset_uuid, subKey, 1, true)
    changeCreating(false)
    setProgressTotal(0);
    setProgressVal(0);

    if (webhookRes.state !== 'success') return
    if (webhookRes?.data?.vendor !== 'SPEECHMATICS') return

    if (type === 'manual') {
      let newJSONdata = copyObject(ProjectJSON)
      newJSONdata.subtitles = { ...newJSONdata.subtitles, [subKey]: { state: 3 } };
      dispatch(ProjectJSON_Store(newJSONdata));
    }

    let words = []
    let webhookData = webhookRes?.data?.manifest;
    const tempDisabled = webhookRes?.data?.target_lang !== 'null'

    if (tempDisabled) {
      if (webhookData.translations == null) {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Subtitles with translations has failed with error code:" + subKey + " Please contact info@videoo.io for further assistance.",
          showConfirmButton: true,
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            subtitleWebHookPoll(projectUUID, asset_uuid, subKey, 3);
          }
        });
        return;
      }

      let translations = copyObject(webhookData.translations)
      let lanKeys = Object.keys(translations)
      if (lanKeys.length) {
        words = translations[lanKeys[0]]
      }
    } else {
      if (webhookData.results == null) {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Subtitle generation has failed with error code:" + subKey + " Please contact info@videoo.io for further assistance.",
          showConfirmButton: true,
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            subtitleWebHookPoll(projectUUID, asset_uuid, subKey, 3);
          }
        });
        return;
      }
      let translations = copyObject(webhookData.results)

      translations.sort((a, b) => {
        return a.end_time - b.start_time
      })

      // Initialize an array to store sentences
      let allSentences = []

      // Initialize variables to keep track of current sentence
      let currentSentence = [];

      // Iterate through each item in results array
      translations.forEach(item => {
        // Constructing the temporary item object as provided
        let tempItem = {
          type: item.type,
          is_eos: item.hasOwnProperty('is_eos') ? item.is_eos : false,
          attaches_to: item.hasOwnProperty('attaches_to') ? item.attaches_to : null,
          end_time: item.end_time,
          start_time: item.start_time,
          content: item.alternatives[0].content,
          speaker: item.alternatives[0].speaker,
        };

        // Depending on the type of item, handle accordingly
        if (tempItem.type === 'word') {
          if (currentSentence.length < 5) {
            currentSentence.push(tempItem);
          } else {
            // If currentSection has reached 5 words, push it to allSentences
            allSentences.push(currentSentence);
            currentSentence = [tempItem]; // Start new section with the current word
          }
        } else if (tempItem.type === 'punctuation' && tempItem.is_eos) {
          // Append punctuation content to current section and push to allSentences
          currentSentence.push(tempItem);
          allSentences.push(currentSentence);
          currentSentence = []; // Reset current section
        }
      });

      // Check if there's any remaining sentence to add
      if (currentSentence.length > 0) {
        allSentences.push(currentSentence); // Push the last remaining sentence
      }

      // Output the sentences

      let newWords = [];

      allSentences.forEach(items => {
        let tempItem = { ...items[0]}; // Make a copy of the first item to avoid mutation
        for (let i = 1; i < items.length; i++) {
          let item = items[i];
          tempItem.end_time = item.end_time;
          if (item.type === "punctuation" && item.attaches_to === "previous") {
            tempItem.content += item.content;
          } else {
            tempItem.content += ' ' + item.content; // Append content with space and trim
          }
        }
        newWords.push(tempItem);
      });

      words = newWords;
    }

    if (words.length) {
      await parseWordsToTexts(words, asset_uuid);
      await subtitleWebHookPoll(projectUUID, asset_uuid, subKey, 3);
    }
  }

  const parseWordsToTexts = async (words, asset_uuid) => {
    const groupID = uuidv4();
    // const subtitleID = uuidv4();
    const subtitleID = subtitleUUIDKey;

    if (!words || !words?.length) return;
    let zIndex = getMaxZIndex(ProjectJSON) + 3;

    words.sort((a, b) => a.end_time - b.start_time)

    let loopNum = 0;
    let addTextInfo = {};
    let elementOb = null

    words.forEach(async (item) => {
      if (!!item.content && item.start_time >= 0 && item.end_time >= 0) {
        let checkDBItem = await checkDouBleItem(addTextInfo, item);

        if (checkDBItem) {
          const newUUID = uuidv4();
          let end_time = item.end_time * 1000
          let start_time = item.start_time * 1000
          let property = { context: item.content, properties: textStyles }
          let textInfo = await getNewTextInformation(property, ProjectJSON, newUUID, zIndex);
          textInfo.duration = end_time - start_time;
          textInfo.timeline_start = start_time;
          textInfo.timeline_end = end_time;
          textInfo.is_subtitle = true;
          textInfo.groups = groupID;
          textInfo.subtitle_uuid = subtitleID;

          if (item?.speaker) {
            textInfo.metadata = item.speaker;
            textInfo.metadatautf16 = convertStringtoWStringUTF16(item.speaker);
          }

          let projectHeight = ProjectJSON.height;
          let tempTextHeight = textInfo.dimension.h;
          // let textHeight = (projectHeight - tempTextHeight) / 7 * 6;
          let textHeight = (projectHeight - (projectHeight / 100) * subtitlePosY) - tempTextHeight;
          textInfo.coordinate = { ...textInfo.coordinate, y: textHeight };

          addTextInfo[newUUID] = textInfo;
          elementOb = textInfo
        }
      }

      loopNum++;
      if (loopNum === words.length) {
        let changedData = {
          videos: {},
          audios: {},
          images: {},
          asset: {},
          text: addTextInfo
        }

        dispatch(changedJSON_Store(changedData));
        changeZeroSecond(elementOb, dispatch)
      }
    })

  }

  const checkDouBleItem = async (textInfo, item) => {
    if (!item || !item?.start_time && item.start_time !== 0 || !item?.end_time && item.end_time !== 0) return false;
    if (item.start_time > item.end_time) return false;
    let textKeys = Object.keys(textInfo);

    let returnState = true;
    textKeys.forEach((textItem) => {
      let itemS = textItem.timeline_start;
      let itemE = textItem.timeline_end;
      let B1 = itemS < item.start_time && item.start_time < itemE || itemS < item.end_time && item.end_time < itemE;
      let B2 = item.start_time < itemS && itemS < item.end_time || item.start_time < itemE && itemE < item.end_time;
      if (B1 || B2) returnState = false;
    })

    return returnState;
  }

  // translations language
  useEffect(() => {
    setLanguage('en')
    setlanguagetarget('en')
  }, [tDisable])

  const languageChange = (e) => {
    let lan = e.target.value
    setlanguagetarget(lan)
    setLanguage(lan)
  }

  const setlanguagetarget = (lan) => {
    if (!tDisable) {
      if (lan === 'en') setLanguageTo('tr')
      else setLanguageTo('en')
    } else setLanguageTo('')
  }

  const languageChangeTo = ({ target }) => {
    setLanguageTo(target.value)
  }

  const onClickSubtitlefile = () => {
    fileInputer.current.click();
  }

  const chooseSubtitleFile = ({target}) => {
    let subtitleFile = target.files[0];
    const reader = new FileReader();
    reader.readAsText(subtitleFile, 'UTF-8');
    reader.onload = () => {
        const result = reader.result;
        if (result.includes('�')) {
            console.log('The file encoding is not utf-8! Trying CP1251...');
        } else {
          const strTesterr = subtitleFile.name.replace(/\.(srt|txt)$/, '').replace(/_+/g, '\ ').toUpperCase() + '\n' + result;
          const parser = new srtParser2();
          var srt_array = parser.fromSrt(result);
          createSubtitleByList(srt_array)
            // document.querySelector('#textarea1').value = file.name.replace(/\.(srt|txt)$/, '').replace(/_+/g, '\ ').toUpperCase() + '\n' + result;
        }
    }
  }
  const createSubtitleByList = (subtitleList) => {
    const groupID = uuidv4();
    let zIndex = getMaxZIndex(ProjectJSON) + 3;
    const subtitleID = uuidv4();
    let loopNum = 0;
    let addTextInfo = {};
    let tempTextOb = null
    subtitleList.forEach(async (item) => {
      if (!!item.text) {
        // let checkDBItem = await checkDouBleItem(addTextInfo, item);
        // console.log('checsssDB', checkDBItem);
        // if (checkDBItem) {
          // console.log('checDB', checkDBItem);
          const newUUID = uuidv4();
          let property = { context: item.text, properties: textStyles }
          let textInfo = await getNewTextInformation(property, ProjectJSON, newUUID, zIndex);
          textInfo.duration = (item.endSeconds - item.startSeconds) * 1000;
          textInfo.timeline_start = item.startSeconds * 1000;
          textInfo.timeline_end = item.endSeconds * 1000;
          textInfo.is_subtitle = true;
          textInfo.groups = groupID;
          textInfo.subtitle_uuid = subtitleID;

          if (item?.speaker) {
            textInfo.metadata = item.speaker;
            textInfo.metadatautf16 = convertStringtoWStringUTF16(item.speaker);
          }

          let projectHeight = ProjectJSON.height;
          let tempTextHeight = textInfo.dimension.h;
          // let textHeight = (projectHeight - tempTextHeight) / 7 * 6;
          
          let textHeight = (projectHeight - (projectHeight / 100) * subtitlePosY) - tempTextHeight;
          textInfo.coordinate = { ...textInfo.coordinate, y: textHeight };

          addTextInfo[newUUID] = textInfo;
          tempTextOb = textInfo
        }
      // }

      loopNum++;
      if (loopNum === subtitleList.length) {
        let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
        changedData.text = addTextInfo;

        dispatch(changedJSON_Store(changedData));
        changeZeroSecond(tempTextOb, dispatch)
      }
    });
  }


  const onClickSubtitleCreation = () => {
                
    if(openUploading){
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "There is an ongoing upload in progress, are you sure that your want to continue with generating subtitles ?",
        showConfirmButton: true,
        confirmButtonText: "Create Subtitle",
        showCancelButton: true,
        cancelButtonText: "Cancel"
      }).then((result) => {
        if (result.isConfirmed) {
          autoSubtitleClick();
        }
      });     
    }else{
      autoSubtitleClick();
    }
  }

  return (
    <>
    
      <SectionHeader title='Subitles' className='pl-3' />
      <Typography md={12} className='v-block-footer pl-1'>
        {languageStrs.subtitles_allowance_remaining} : {userData?.subtitle_remaining}
      </Typography>
      <Typography md={12} className='v-block-footer pl-1'>
        {userData?.subtitle_time}
      </Typography>
        <Box className='px-10 pt-0'>
          <Grid container spacing={0}>
            <Grid item xs={12} paddingY={2}>
              <input type={`file`} style={inputStyle}
                  ref={fileInputer}
                  accept=".srt"
                  onChange={chooseSubtitleFile}
                />
              <Button onClick={() => onClickSubtitlefile()} variant='outlined' color='primary' fullWidth={true}>Create Subtitle with File</Button>
            </Grid>
          </Grid>
        </Box>
        <hr></hr>
        <Box className='px-10 pt-0'>
          {!subcreating && (
            <Grid container spacing={0}>
              <Grid item xs={12}
                className={`${!subcreating && 'subtitle-hover'} v-subtitlecard mb-3 p-3`}
              >
                <Box md={12} onClick={() => {onClickSubtitleCreation()}}>
                  <Box className='v-center'>
                    <Box className='v-Subtitle_ICON v-center'>
                      <Box className='v-Subtitle_iconAuto'>
                        {languageStrs.auto}<Box className='v-Subtitle_ICONfooter'></Box>
                      </Box>
                    </Box>
                  </Box>

                  <Typography md={12} className='v-Subtitlecardhead mt-3 mb-2'>Auto Subtitles</Typography>
                  <Typography md={12} className='v-Subtitlecardcontent'>{languageStrs.automatically_subtitles_video}</Typography>
                </Box>
              </Grid>
            </Grid>
          )}

          {!!subcreating && (
            <Box className='creating-subtitle'>
              {languageStrs.wait_moments_subtitles_being}
              <LinearProgress value={progressVal}/>
              <span>{progressVal} %</span>
            </Box>
          )}
        </Box>

        <Box className='p-2'>
          <Typography md={12} className='v-block-footer pl-1'>
            {languageStrs.select_language_detect}
          </Typography>

          <Box className='v-autocomplate pt-2'>
            {!tDisable && (
              <Select value={language}
                inputProps={{ 'aria-label': 'Without label' }}
                className='v-hoverborder v-radius v-commonboxheight'
                onChange={languageChange}
              >
                {languageToList1.map((option, key) =>
                  <MenuItem value={option.key} key={key}>
                    <Typography component='span'
                    >{option.label}</Typography>
                  </MenuItem>
                )}
              </Select>
            )}

            {tDisable && (
              <Select value={language}
                inputProps={{ 'aria-label': 'Without label' }}
                className='v-hoverborder v-radius v-commonboxheight'
                onChange={languageChange}
              >
                {languageToList0.map((option, key) =>
                  <MenuItem value={option.key} key={key}>
                    <Typography component='span'
                    >{option.label}</Typography>
                  </MenuItem>
                )}
              </Select>
            )}
          </Box>
        </Box>

        <Box className='p-2'>
          <div className='flex flex-row gap-5 items-center pb-5 pl-10'>
            <input type='checkbox' checked={tDisable}
              onChange={({ target }) => { setTDisable(target.checked) }}
            />

            <span className='text-12 opacity-80'>
              {languageStrs.disable_translate}
            </span>
          </div>

          <Typography md={12} className='v-block-footer pl-1'>
            {languageStrs.select_language_select}
          </Typography>

          <Box className='v-autocomplate pt-2'>
            {language === 'en' && (
              <Select
                value={languageTo}
                disabled={tDisable}
                inputProps={{ 'aria-label': 'Without label' }}
                className='v-hoverborder v-radius v-commonboxheight'
                onChange={languageChangeTo}
              >
                {languageToList2.map((option, key) =>
                  <MenuItem value={option.key} key={key}>
                    <Typography component='span'
                    >{option.label}</Typography>
                  </MenuItem>
                )}
              </Select>
            )}

            {language !== 'en' && (
              <Select
                value={languageTo}
                disabled={tDisable}
                inputProps={{ 'aria-label': 'Without label' }}
                className='v-hoverborder v-radius v-commonboxheight'
                onChange={languageChangeTo}
              >
                {languageToList3.map((option, key) =>
                  <MenuItem value={option.key} key={key}>
                    <Typography component='span'
                    >{option.label}</Typography>
                  </MenuItem>
                )}
              </Select>
            )}
          </Box>
        </Box>


      <PreviewText textStyles={textStyles}
        setTextStyles={setTextStyles}
        subtitlePosY={subtitlePosY}
        setSubtitlePosY={onSetSubtitlePosY}
      />
      <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />
    </>
  )
};

export const ToolTapEditSubtitle = () => {
  const dispatch = useDispatch()
  const [webGLContext] = useWebGL()
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON)
  const projectUUID = useSelector(store => store.projectdata.projectUUID)
  const editSubtitle = useSelector(store => store.projectdata.editSubtitle);
  const selectedID = useSelector(store => store.webgldata.selectedItemID);
  const [subtitleUUID, setSubtitleUUID] = useState()
  const [subtitleList, setSubtitleList] = useState([]);
  const [textStyles, setTextStyles] = useState(defaultPreviewStyle)
  const [subtitlePosY, setSubtitlePosY] = useState(0);
  const subtitlePosYStore = useSelector((store) => store.projectdata.subtitlePosY);
  const [isStyleEdit, setIsStyleEdit] = useState(false);
  const [subKeys, setSubkeys] = useState([]);
  
  useEffect(() => {
    if(selectedID === null){
      setSubtitleList([]);
      setSubtitleUUID('');
      dispatch(editSubtitle_Store(false))
    }else{
      let tempType = getSelectedType(selectedID, ProjectJSON);
      if(tempType === 'text'){
        getSubtitleList();
      }else{
        setSubtitleList([]);
      setSubtitleUUID('');
      dispatch(editSubtitle_Store(false))
      }
      
    }
  }, [selectedID])

  useEffect(() => {
    if(editSubtitle){
      getSubtitleList();
    }
  }, [editSubtitle])

  // useEffect(() => {
  //   onSaveSubtitle();
  // }, [textStyles])

  const getSubtitleList = async () => {
    try {
      let tempFont = 25
      const tempTexts = ProjectJSON.text
      const textKeys = Object.keys(tempTexts)
      setTextStyles(tempTexts[selectedID].properties)
      const tmpSubTitleUUID = tempTexts[selectedID].subtitle_uuid;
      setSubtitleUUID(tmpSubTitleUUID);
      const tmpSubtitleList = [];
      textKeys.map(textKeyItem => {
        if(tempTexts[textKeyItem].subtitle_uuid === tmpSubTitleUUID){
          const tmpSubtitleData = copyObject(tempTexts[textKeyItem]);
          // tmpSubtitleData.sequence = 0;
          if(tmpSubtitleData?.context){
            tmpSubtitleData.context = tmpSubtitleData.context.replaceAll('?entered!*-', '\n')
          }
          tmpSubtitleList.push({
            id: textKeyItem,
            data: tmpSubtitleData
          });
        }
      })
      let textItem = tempTexts[selectedID]
      const textItemPosY = (ProjectJSON.height - (textItem.coordinate.y + textItem.dimension.h)) / (ProjectJSON.height / 100);
      dispatch(subtitlePostY_store(textItemPosY));

      tmpSubtitleList.sort((a,b) => {return a.data.timeline_start - b.data.timeline_start});
      setSubtitleList(tmpSubtitleList)
      setSubkeys(textKeys);
      // let indexes = [];
      // // if (subtitleUUID) {
      //   // const subtitleItems = textKeys.reduce((textitems, key) => {
      //   textKeys.reduce((textitems, key) => {
      //     indexes.push({
      //       order: tempTexts[key].sequence,
      //       key: key
      //     })
      //     let tempItem = tempTexts[key]
      //     let tempItems = textitems

      //     // if (tempItem.subtitle_uuid === subtitleUUID) {
      //     //   tempFont = tempItem.properties.font_size
      //       tempItems = { ...textitems, [key]: tempItem }
      //     // }

      //     return tempItems
      //   }, {});
        
      //   indexes.sort((a,b) => {return a.order - b.order});
      //   let updatedSubtitles = {};
      //   indexes.map(item => {
      //     updatedSubtitles = {...updatedSubtitles, [item.key]: tempTexts[item.key]}
      //   })

        // setActiveUUID('')
        // setStyleFlag(false)
        
        // if (typeof tempFont === 'number' && fontSize !== tempFont) {
        //   setFontSize(tempFont)
        // }
      // } else {
      //   throw new Error('subtitle item not matched')
      // }
    } catch (err) {
      if (Object.keys(subtitleList).length > 0) {
        setSubtitleList({})
        // setFontSize(25)
      }

      // setStyleFlag(false)
      // setActiveUUID('')
    }
  }
  const changeStyleMode = () => {
    setIsStyleEdit(!isStyleEdit)
    // onSaveSubtitle()
  }
  useEffect(() => {
    setSubtitlePosY(subtitlePosYStore);
  }, [subtitlePosYStore]);

  const onSetSubtitlePosY = (val) => {
    dispatch(subtitlePostY_store(val));
    // setSubtitlePosY(val);
  }

  const resetSquenceNum = (subtitleArr) => {
    let convertSubtitles = [];
    subtitleArr.map((subtitleItem, key) => {
      subtitleItem.data.sequence = key;
      convertSubtitles.push(subtitleItem);
    })
    return convertSubtitles;
  }

  const addingSubtitle = (subKey) => {
    let tmpSubtitleList = subtitleList
    const newUUID = uuidv4();
    let newData = copyObject(tmpSubtitleList[subKey].data);
    // newData.context = "";
    const calDuration = Math.round(parseInt(newData.duration)/2);
    
    tmpSubtitleList[subKey].data.duration = calDuration;
    tmpSubtitleList[subKey].data.timeline_end = parseInt(tmpSubtitleList[subKey].data.timeline_end) - calDuration;
    newData.timeline_start = parseInt(tmpSubtitleList[subKey].data.timeline_start) + calDuration;
    newData.duration = parseInt(newData.duration) - calDuration;
    const newSubtitleData = {
      id: newUUID,
      data: newData
    }
    tmpSubtitleList.splice(subKey+1, 0, newSubtitleData);
    const convertedSubtitles = resetSquenceNum(tmpSubtitleList);
    setSubtitleList(convertedSubtitles);
    // tmpSubtitleList
    onSaveSubtitle();
  }

  const removingSubtitle = (subItem) => {
    let tmpSubtitleList = [];
    subtitleList.map((subtitleItem, key) => {
      if(subtitleItem.id !== subItem.id){
        tmpSubtitleList.push(subtitleItem);
      }
    });
    const sortedSubtitles = resetSquenceNum(tmpSubtitleList);
    setSubtitleList(sortedSubtitles);
    setTimeout(() => {
      onSaveSubtitle();
    }, 600)
    
  }

  const onChangeSubtitleText = (changeVal, subkey) => {
    
    let tmpSubtitleList = subtitleList.map((subtitleItem, key) => {
      if(key === subkey){
        subtitleItem.data = {...subtitleItem.data, context: changeVal}
        return subtitleItem
      }else{
        return subtitleItem
      }
    });
    
    // tmpSubtitleList[subkey].data.context = '';
    setSubtitleList(tmpSubtitleList)
    
  }

  

  const renderTimerVal = (timeVal) => {
    const tmpTime = timeVal? new Date(timeVal) : new Date(0)
    return tmpTime.MMSSMM()
  }

  const onSelectSubtitleItem = (subtitleItem) => {
    dispatch(SelectedItemID_Store(subtitleItem.id));
  }

  const onSaveSubtitle = () => {
    let tmpSaveSubtitle = {};
    let elementTextOb = null
    // const existCanvas = document.getElementsByClassName('v-video-element')[0];
    
    const tmpSutitleList = subtitleList;
    const mainCanvas = webGLContext.webGLRef
    tmpSutitleList.map(subtitleItem => {
      let tmpSubtitleData = copyObject(subtitleItem.data);
      tmpSubtitleData.properties = textStyles;
      const newTxt = tmpSubtitleData.context.replaceAll('\n', '?entered!*-');
      tmpSubtitleData.context = newTxt
      tmpSubtitleData.contextutf16 = convertStringtoWStringUTF16(newTxt)
      const [textContext, textElementW, textElementH] = getFitContext(tmpSubtitleData, ProjectJSON)
      const [styleTop, styleLeft] = getCenterStyle(textElementH, textElementW, ProjectJSON);
      tmpSubtitleData.context = textContext
      // let [maxWidth, maxHeight, acceptRatio] = getContentWidthHeight(tmpSubtitleData.context, ProjectJSON, tmpSubtitleData, mainCanvas)
      // const textHeight = maxHeight / acceptRatio
      // const textWidth = maxWidth / acceptRatio
      // const [styleTop, styleLeft] = getCenterStyle(textHeight, textWidth, ProjectJSON);
      tmpSubtitleData.dimension = {
        ...tmpSubtitleData.dimension,
        w: textElementW,
        h: textElementH
      }
      // tmpSubtitleData.dimension.h = textHeight
      // tmpSubtitleData.dimension.w = textWidth
      tmpSubtitleData.coordinate.x = styleLeft
      const coordinateY = ProjectJSON.height - (subtitlePosYStore * (ProjectJSON.height / 100)) - tmpSubtitleData.dimension.h;
      tmpSubtitleData.coordinate.y = coordinateY;
      
      // subtitleItem.data.sequence = 0;
      tmpSaveSubtitle = {...tmpSaveSubtitle, [subtitleItem.id]: tmpSubtitleData}
      elementTextOb = tmpSubtitleData;
    });
    let tempJSON = copyObject(ProjectJSON)
    // tempJSON.text = tmpSaveSubtitle;
    // updateSubtitleInfo(subtitleUUID, textStyles);
    // dispatch(ProjectJSON_Store(tempJSON))
    const tmpTexts = copyObject(tempJSON.text);
    const tmpTextKeys = Object.keys(tmpTexts) ;
    tmpTextKeys.map(tKey => {
      if(tmpTexts[tKey].is_subtitle && tmpTexts[tKey].subtitle_uuid === subtitleUUID){
        delete tmpTexts[tKey]
      }
    });
    const changedText = {...tmpTexts, ...tmpSaveSubtitle};
    let changedData = {
      videos: {},
      audios: {},
      images: {},
      asset: {},
      text: changedText,
      subtitleUpdated: true
    }

    dispatch(changedJSON_Store(changedData));
    changeZeroSecond(elementTextOb, dispatch)
    // updateProjectJSON(projectUUID, tempJSON)
  }

  const onRemoveAll = () => {
    let tempJSON = copyObject(ProjectJSON)
    const tmpTexts = tempJSON.text;
    const tmpTextKeys = Object.keys(tmpTexts);
    let updatedTexts = {};
    tmpTextKeys.map(tKey => {
      if(!(tmpTexts[tKey].is_subtitle && tmpTexts[tKey].subtitle_uuid === subtitleUUID)){
        updatedTexts = {...updatedTexts, [tKey]: tmpTexts[tKey]};
      }
    });
    tempJSON.text = updatedTexts;
    setSubtitleList([]);
    dispatch(ProjectJSON_Store(tempJSON))
    updateProjectJSON(projectUUID, tempJSON)
    dispatch(editSubtitle_Store(false))
  }

  return (
    <>
      <SectionHeader title='Edit Subtitle' className='pl-3' />
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant='h6' fontWeight={'bold'}>{isStyleEdit? 'Subtitle Style':'Subtitle List'}</Typography>
        <Box display={'flex'}>
          <Button variant='contained' size='small' onClick={() =>onSaveSubtitle()}>
            Save
          </Button>
          <Button variant='contained' size='small' onClick={() =>changeStyleMode()}>
            {isStyleEdit? 'Edit Text' : 'Edit Style'}
          </Button>
          <Button variant='contained' size='small' onClick={() =>onRemoveAll()}>
            Delete
          </Button>
        </Box>
        
      </Stack>
      <Box height={'100%'} maxHeight={'100%'} overflow={'scroll'}>
      {isStyleEdit? 
        <>
          <PreviewText textStyles={textStyles}
            setTextStyles={setTextStyles}
            subtitlePosY={subtitlePosY}
            setSubtitlePosY={onSetSubtitlePosY}
          />
        </>
        :
        <>
          <Grid container spacing={1}>
            {subtitleList.map((subTitleItem, key) => (
              <Grid key={key} item xs={12} paddingX={1}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} sx={{backgroundColor: subTitleItem.id === selectedID ? '#dadada6e' : 'transparent'}}>
                  <FormControl fullWidth variant="outlined">
                    <textarea 
                    value={subTitleItem.data.context}
                    onChange={(e) => onChangeSubtitleText(e.target.value, key)}
                      onFocusCapture={(e) => {onSelectSubtitleItem(subTitleItem); }}
                      onBlur={() => {onSaveSubtitle()}}
                  className='form-control w-full'
                    />
                    {/* <TextField
                      id="outlined-adornment-weight"
                      value={subTitleItem.data.context}
                      onChange={(e) => onChangeSubtitleText(e.target.value, key)}
                      onFocusCapture={(e) => {onSelectSubtitleItem(subTitleItem); }}
                      aria-describedby="outlined-weight-helper-text"
                    /> */}
                    <FormHelperText id="outlined-weight-helper-text">
                    <span style={{marginRight: '5px'}}>In: {renderTimerVal(subTitleItem.data.timeline_start)}</span>
                    <span style={{marginRight: '5px'}}>Out: {renderTimerVal(subTitleItem.data.timeline_end)}</span>
                    <span style={{marginRight: '5px'}}>Duration: {subTitleItem.data.duration.toFixed(2)}s</span>
                    </FormHelperText>
                  </FormControl>
                  <Button variant='contained' onClick={() => {removingSubtitle(subTitleItem)}} size='small'>
                    <DeleteForeverIcon />
                  </Button>
                </Stack>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                  <Button size='small' onClick={() => addingSubtitle(key)}>
                    <AddIcon /> Add New Subtitle
                  </Button>
                </Stack>
              </Grid>
              
              ))
            }
          </Grid>
        </>
      }
      </Box>
      
    </>
  )
}