import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Menu } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Menu_options, Pricing_options } from './../menu.config';

export const MobileMenu = ({ pagename }) => {
	const [MenuItemElement, setMenuItemElement] = useState(null);
	const open = Boolean(MenuItemElement);

	const ItemListOpen = (event) => setMenuItemElement(event.currentTarget);
	const ItemListClose = (title) => setMenuItemElement(null);

	return (
		<>
			<Box className="mobileMenuIcon">
				<MenuIcon onClick={ItemListOpen} />
			</Box>

			<Menu
				className='mobile_menu_item_list'
				anchorEl={MenuItemElement}
				open={open}
				onClose={ItemListClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.3))',
						mt: 2,
						left: '0px !important',
						maxWidth: '100% !important',
						backgroundColor: 'unset',
						'& .MuiList-root': {
							padding: '0px',
						}
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<Box className='mobile_itemlist_containner'>
					{Menu_options.map((items, key) => (
						<MobileItems key={key}
							MenuCloseFunc={ItemListClose}
							items={items}
						/>
					))}

					{pagename === 'pricing' && (
						Pricing_options.map((item, key) => (
							<Box className='Mobile_menu_item' key={key}>
								<Box className='parent_item2'>
									<Link to={item.link} className='mobileitem2'>
										{item.title}
									</Link>
								</Box>
							</Box>
						))
					)}
				</Box>
			</Menu>
		</>
	)
}


const MobileItems = ({ items, MenuCloseFunc }) => {
	const [childState, SetchildState] = useState(false);

	const menuItemEnterFunc = () => SetchildState(true);
	const menuItemLeaveFunc = () => SetchildState(false);
	const MobileClick = () => SetchildState(!childState);
	// if (/Android|webOS|iPhone|iPad/i.test(navigator.userAgent)) { SetchildState(!childState); }

	return (
		<Box className="Mobile_menu_item" onMouseEnter={menuItemEnterFunc} onMouseLeave={menuItemLeaveFunc}>
			<Box className="parent_item" onClick={MobileClick}>
				<label>{items.title}</label>
				{childState ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			</Box>

			<Box className="children_items" style={{ display: childState ? 'block' : 'none' }}>
				{items.items.map((child_item, j) => { return (<ChildreItems child_item={child_item} key={j} />) })}
			</Box>
		</Box>
	)
}

const ChildreItems = ({ child_item }) => {
	return (
		<Box className="children_item">
			{child_item.header ? <Box className="children_item_header">{child_item.header}</Box> : ''}

			<Box className="children_item_body">
				{
					child_item.child.map((child, index) => {
						return (
							<Box key={index} className='child_body_item'>
								<Link to={child.link}>
									<label>{child.title}</label>
								</Link>
							</Box>
						)
					})
				}
			</Box>
		</Box>
	)
}