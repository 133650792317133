import { useState } from "react";
import { TimeLineContext, contextDefault } from "./timeline.config";

export const TimelineStarter = ({ children }) => {
  const [ContextData, SetContextData] = useState(contextDefault);

  return (
    <>
      <TimeLineContext.Provider value={[ContextData, SetContextData]}>
        {children}
      </TimeLineContext.Provider>
    </>
    
  )
}