import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import '../display/videoDisplay.scss'
import { ThumbnailGrid } from "./thumbnailGrid"
import { getGLContext, getImageWebGLElements, getTextWebGLElements, getVideoWebGLElements, resizeImage } from "../webGL/commonFunc"
import { DrawScene, initVideoTexture } from "../webGL/Setups/SetupClass"
import { ThumbnailContextProvider, useThumbnail } from "./provider/thumbnail.provider"
import { copyObject, getGroupoptions } from "../commonFunction"
import { createProjectThumbnail, uploadThumbnail } from "../../../axios/ApiProvider"
import { thumbnailState_Store, captureThumbnailFlag_store } from "../../../store/actions/projectdata"

export const PROJECT_THUMBNAIL_WIDTH = process.env.REACT_APP_PROJECT_THUMBNAIL_WIDTH

let resizeState = 0;
let tempJSON = null;

export const ProjectThumbnail = () => {
  return (
    <ThumbnailContextProvider>
      <ThumbnailContainer />
    </ThumbnailContextProvider>
  )
}

const ThumbnailContainer = () => {
  const webGLRef = useRef()
  const dispatch = useDispatch();
  const [thumbnailCtx, setThumnailCtx] = useThumbnail()
  const webGlSize = useSelector(store => store.webgldata.webGlSize)
  const projectJSON = useSelector(store => store.projectdata.projectJSON);
  const projectUUID = useSelector((store) => store.projectdata.projectUUID);
  const thumbnailState = useSelector(store => store.projectdata.thumbnailState);
  const captureThumbnailFlag = useSelector(store => store.projectdata.captureThumbnailFlag);
  const timeCount = useSelector(store => store.videodata.Time_Count);

  const [activeVideos, setActiveVideos] = useState([])
  const [activeImages, setActiveImages] = useState([])
  const [activeTexts, setActiveTexts] = useState([])

  const ratio = webGlSize.h / webGlSize.w;
  const [firstLoad, SetFirstLoad] = useState(false)
  const [resizeChange, setResizeChange] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setResizeChange(resizeState++)
    })

    firstWebGLsetFunc()
  }, [])


  useEffect(() => {
    firstWebGLsetFunc()
  }, [webGLRef.current])


  useEffect(() => {
    setResizeChange(resizeState++)
  }, [webGlSize])


  useEffect(() => {
    let mainCanvas = webGLRef.current;
    const parentEl = mainCanvas?.parentElement;

    const width = parentEl.offsetWidth;
    const height = parentEl.offsetHeight;

    if (width * ratio < height) {
      const W = width;
      const H = width * ratio;

      mainCanvas.style.width = `${W}px`;
      mainCanvas.style.height = `${H}px`;
    } else {
      const W = height / ratio;
      const H = height;

      mainCanvas.style.width = `${W}px`;
      mainCanvas.style.height = `${H}px`;
    }
  }, [resizeChange, ratio])

  const firstWebGLsetFunc = async () => {
    const canvasEl = webGLRef.current;
    const canvasgl = getGLContext(canvasEl)
    const initTexture = await initVideoTexture(canvasgl);

    const initTextureMap = new Map([['init', initTexture]]);
    const initBufferMap = new Map();
    const initModelViewMatricesMap = new Map();
    const initTextCanvasesMap = new Map();


    // const initTexture = await initVideoTexture(canvasgl);

    // const initTextureMap = new Map([['init', initTexture]]);
    // const initBufferMap = new Map();
    // const initModelViewMatricesMap = new Map();
    // const initTextCanvasesMap = new Map();

    setThumnailCtx({
      ...thumbnailCtx,
      activeModelViewMatrices: initModelViewMatricesMap,
      allModelViewMatrices: initModelViewMatricesMap,
      activeBuffers: initBufferMap,
      allBuffers: initBufferMap,
      activeTextures: initTextureMap,
      allTextures: initTextureMap,
      activeTextCanvases: initTextCanvasesMap,
      allTextCanvases: initTextCanvasesMap,

      webGLRef: webGLRef.current
    });
  }

  useEffect(() => {
    tempJSON = projectJSON

    if ((!!tempJSON?.videos || !!tempJSON?.images || !!tempJSON?.text) && !!webGLRef?.current) {
      const tempVideos = getElements(tempJSON.videos)
      const tempImages = getElements(tempJSON.images)
      const tempTexts = getElements(tempJSON.text)

      setActiveVideos(tempVideos)
      setActiveImages(tempImages)
      setActiveTexts(tempTexts)
    }
  }, [projectJSON, timeCount])

  useEffect(() => {
    if ((!!tempJSON?.videos || !!tempJSON?.images || !!tempJSON?.text) && !!webGLRef?.current) {
      if (thumbnailState) {
        dispatch(thumbnailState_Store(false))
        setTimeout(() => { captureThumbnail() }, 1000)
      }
    }
  }, [thumbnailState])

  useEffect(() => {
    if(captureThumbnailFlag) {
      captureThumbnail(new Date(timeCount))
      dispatch(captureThumbnailFlag_store(false))
    }

  }, [captureThumbnailFlag])
  

  const captureThumbnail = (time = new Date(0)) => {

    const mainCanvas = webGLRef.current
    const mainCanvasGl = getGLContext(mainCanvas)
    const groupData = getGroupoptions(projectJSON)

    DrawScene(mainCanvasGl, thumbnailCtx, projectJSON, groupData, time)

    mainCanvas.toBlob(async (blob) => {
      const fileName = "projectThumbnail.jpg"
      const fileType = { type: 'image/jpeg', lastModified: new Date().getTime() }

      const imageUrl = URL.createObjectURL(blob)
      const defaultFile = new File([blob], fileName, fileType);
        const img = new Image();
        img.onload = async function () {
          const ratio = this.width / this.height;
          let file = defaultFile;
          if(this.width > this.height && this.width > PROJECT_THUMBNAIL_WIDTH) {
            file = await resizeImage(defaultFile, PROJECT_THUMBNAIL_WIDTH, PROJECT_THUMBNAIL_WIDTH / ratio);
          }

          if(this.height > this.width && this.height > PROJECT_THUMBNAIL_WIDTH) {
            file = await resizeImage(defaultFile, PROJECT_THUMBNAIL_WIDTH * ratio, PROJECT_THUMBNAIL_WIDTH);
          }

          const result = await createProjectThumbnail(projectUUID);
      
          const thumbnail = result.data

          if (result.state !== 'success' || !thumbnail?.signedurl) {
            return
          }
          await uploadThumbnail(thumbnail.signedurl, file);

        };
        img.src = imageUrl;
    })
  }

  const getElements = (elements) => {
    const tempElements = copyObject(elements)
    const tempKeys = Object.keys(tempElements)

    return tempKeys.reduce((uuids, key) => {
      let element = tempElements[key]
      let tempUuids = uuids
      if (element.timeline_start <= new Date(timeCount) - new Date(0) && element.timeline_end >= new Date(timeCount) - new Date(0)) {
        tempUuids = [...uuids, key]
      }
      return tempUuids
    }, [])
  }

  return (
    <div className="absolute w-full h-full flex flex-col items-center justify-center text-center z-[1]"
    >
      <div className="v-video-container flex">
        {ratio >= 1 &&
          <canvas ref={webGLRef}
            width={720} height={720 * ratio}
            className='v-video-element'
            id="thumbnail_canvas"
          />
        }

        {ratio < 1 &&
          <canvas ref={webGLRef}
            width={720 / ratio} height={720}
            className='v-video-element'
            id="thumbnail_canvas"
          />
        }

        <ThumbnailGrid texts={activeTexts}
          videos={activeVideos}
          images={activeImages}
        />
      </div>
    </div>
  )
}