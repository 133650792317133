import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase, Stack} from "@mui/material"
import ImageIcon from '@mui/icons-material/Image';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import LinkIcon from '@mui/icons-material/Link';

export const PexelsImageModal = (props) => {
  const {stockData} = props;

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Pexels Stock Image
          </Typography>
          <Typography variant="overline" component={'p'}>
            id: <b>{stockData?.id}</b> {/*<LinkIcon />*/}
          </Typography>
          {/* <Typography variant="overline" component={'p'}>
            Url: <b>{stockData?.url} </b>
          </Typography> */}
          <Typography variant="overline" component={'p'}>
            Width & Height: <b>{stockData?.width} X {stockData?.height}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Photographer Id: <b>{stockData?.photographer_id}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Photographer: <b>{stockData?.photographer}</b>
          </Typography>
          
        </Grid>
        <Grid item md={8} xs={12}>
          <div style={{position: 'relative'}}>
            <img 
              src={stockData?.src.tiny}
              style={{width: '100%'}}
            />
            <div className='stockMediaBar'>
              <Stack direction='row' justifyContent='start' alignItems='center'>
                <ImageIcon sx={{ color: 'white' }}/>
                <Typography color={'white'} variant='span'>{stockData?.width} X {stockData?.height}</Typography>
              </Stack>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}