import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Menu, Typography, LinearProgress, Grid, FormControl, InputLabel, Select, MenuItem  } from '@mui/material';
import ReactLoading from 'react-loading'

import CompressIcon from '@mui/icons-material/Compress';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"

import { compressVideo, getWebHookData } from "../../../axios/ApiProvider"

export const Compress = () => {
  const compressBtnRef = useRef(null)
  const [optionEl, setOptionEL] = useState(null);
  const filedata = useSelector((store) => store.projectdata.uploadingFiles[Object.keys(store.projectdata.uploadingFiles)[0]])
  const projectUUID = useSelector((store) => store.projectdata.projectUUID);
	const [openOptionModal, setOpenOptionModal] = useState(false)
	const [openDownloadModal, setOpenDownloadModal] = useState(false)
	const [progressing, setProgressing] = useState(false)
	const [isFinishProgressing, setIsFinishProgressing] = useState(false)
  const [webhookInfo, setWebhookInfo] = useState(null);
	const [convertOptions, setConvertOptions] = useState({
    "Optimize for Social Media" : "No Change",
		"Compression Level" : "No Change",
		"Resolution" : "No Change",
		"FPS Info" : "No Change"
	})
  const history = useNavigate();

  const closeOptionMenu = () => { setOptionEL(null); };
  const openOptionMenu = (e) => { 
    setOpenOptionModal(true)
    setOptionEL(e.currentTarget);
  };

	useEffect(() => {
		if(filedata && !openOptionModal) {
      if(compressBtnRef.current) {
        compressBtnRef.current.click();
      }
		}
	}, [filedata])

  const getOriginalFilename = (url) => {
    const filelink = url.split("?")[0];
    const filename = filelink.split("/").reverse()[0];

    return filename
  }

	const handleOptionChange = e => {
		setConvertOptions({
			...convertOptions,
			[e.target.name]: e.target.value
		})
	}

	const handleConvert = async () => {
		setProgressing(true)
		const response = await compressVideo(projectUUID, convertOptions);
    if (response.state !== 'success') {
			setProgressing(false)
			return;
		}

    const exportData = response.data;
    const ifState = exportData.uuid !== projectUUID
    if (ifState || !exportData.asset_uuid || !exportData.webhook_url) return history('/');
		setOpenDownloadModal(true);
		const webhookUrl = exportData.webhook_url
		if (!isFinishProgressing && !!webhookUrl) {
			const webhookInterval = () => {
				setTimeout(async () => {
					const response = await getWebHookData(webhookUrl);
					if(response.state !== 'success') return
						const webHookData = response.data;
						setWebhookInfo(webHookData);
					if (!!webHookData && !!webHookData.signed_url){
						setProgressing(false)
						setIsFinishProgressing(true)
					} else {
						webhookInterval();
					}
				}, 5000)
			}
			webhookInterval();
		}
	}

	const downloadFunc = () => {
    let videoUrl = webhookInfo?.signed_url;
    const filename = getOriginalFilename(webhookInfo?.signed_url)
		
    if (!isFinishProgressing || !videoUrl) return;
    fetch(videoUrl).then(res => res.blob()).then(file => {
      let tempUrl = URL.createObjectURL(file);
      const link = document.createElement("a");

      link.href = tempUrl;
      link.download = filename || 'video.mp4';

      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(tempUrl);
      link.remove();
    }).catch(() => { alert("Failed to download file!"); });
  }

  return (
    <>
      <Box ref={compressBtnRef} className='v-headerExport v-spacebetween v-font-2-4 v-font-bold pl-4' onClick={openOptionMenu}>
        <span className="v-headerExportText">Compress</span> <CompressIcon className='v-headerExportIcon v-font-4 md:mr-[20px] md:ml-[15px]' />
      </Box>

      <Menu
        anchorEl={optionEl}
        open={Boolean(optionEl)}
        onClose={closeOptionMenu}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        className='export-menu-wrapper'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: 'right',
        }}
      >
        {openOptionModal && !openDownloadModal && 
        <Box className='export-menu-container gap-10'>
        <CloseOutlinedIcon
          onClick={closeOptionMenu}
          className='menu-close-btn '
        />
        <Typography 
          variant="h5"
          textAlign="center" 
          sx={{
            marginBottom: "20px"
          }}
        >Compress my video to a...</Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Optimize for Social Media</InputLabel>
              <Select
                name="Optimize for Social Media"
                value={convertOptions['Optimize for Social Media']}
                label="Age"
                onChange={handleOptionChange}
              >
                <MenuItem value="No Change">
                  No Change
                </MenuItem>
                <MenuItem value="Whatsapp Video">Whatsapp Video</MenuItem>
                <MenuItem value="Instagram Video">Instagram Video</MenuItem>
                <MenuItem value="Facebook Video">Facebook Video</MenuItem>
                <MenuItem value="Tiktok Video">Tiktok Video</MenuItem>
                <MenuItem value="Twitter Video">Twitter Video</MenuItem>
                <MenuItem value="Linkedin Video">Linkedin Video</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Compression Level</InputLabel>
              <Select
                name="Compression Level"
                value={convertOptions['Compression Level']}
                label="Age"
                onChange={handleOptionChange}
              >
                <MenuItem value="No Change">
                  No Change
                </MenuItem>
                <MenuItem value="High">High</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="Low">Low</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Resolution</InputLabel>
              <Select
                name="Resolution"
                value={convertOptions['Resolution']}
                label="Age"
                onChange={handleOptionChange}
              >
                <MenuItem value="No Change">
                  No Change
                </MenuItem>
                <MenuItem value="4K (3840 x 2160)">4K (3840 x 2160)</MenuItem>
                <MenuItem value="HD 1440p (2560 x 1440)">HD 1440p (2560 x 1440)</MenuItem>
                <MenuItem value="HD 1080p (1920 x 1080)">HD 1080p (1920 x 1080)</MenuItem>
                <MenuItem value="480p (854 x 480)">480p (854 x 480)</MenuItem>
                <MenuItem value="DVD (720 x 576)">DVD (720 x 576)</MenuItem>
                <MenuItem value="TV (640 x 480)">TV (640 x 480)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
              <InputLabel id="demo-select-small-label">FPS Info</InputLabel>
              <Select
                name="FPS Info"
                value={convertOptions['FPS Info']}
                label="Age"
                onChange={handleOptionChange}
              >
                <MenuItem value="No Change">
                  No Change
                </MenuItem>
                <MenuItem value="12">12</MenuItem>
                <MenuItem value="24">24</MenuItem>
                <MenuItem value="30">30</MenuItem>
                <MenuItem value="60">60</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center">
          <button 
            disabled={!filedata || !filedata?.state || progressing} 
            className={`convert-btn ${filedata && filedata.state ? "" : "btn-disabled"}`} 
            onClick={handleConvert}
          >
            {filedata && filedata.state ? "Compress" : "Preparing..."}
						{progressing && <ReactLoading type={'spinningBubbles'} color='#FFFFFF' height={14} width={14} />}
          </button>
        </Box>
        </Box>
        }
        {
          openOptionModal && openDownloadModal && 
          <Box className='export-menu-container gap-10'>
          <CloseOutlinedIcon
            onClick={closeOptionMenu}
            className='menu-close-btn'
          />
					<Box className="video-section mt-6">
						<Box className="video-container">
							{!!isFinishProgressing ? (
									<video controls >
										<source src={!!webhookInfo && !!webhookInfo.signed_url ? webhookInfo.signed_url : ''} type="video/mp4" />
									</video>
								) : (
									<Box className="upload-progressing">
										<Box className="progress-title">
											<Typography component='h2'>{!!webhookInfo ? webhookInfo.progress : 0}%</Typography>
										</Box>

										<Box className="progress-bar">
											<LinearProgress variant="determinate" value={!!webhookInfo ? webhookInfo.progress : 0} />
										</Box>
									</Box>
								)}
							</Box>
						</Box>
						<Box component='button' className='download-button' marginTop="10px" onClick={downloadFunc}>
              <FileDownloadOutlinedIcon />
              <Typography component='h2'>Download</Typography>
            </Box>
          </Box>
        }
      </Menu>
    </>
  )
}