import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef, useContext, useEffect } from "react";
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';

import { VideoImages } from "./videoELImages";
import { useWebGL } from "../../videoEdit.provider";
import { TimeLineContext } from "../timeline.config";
import { changeZeroSecond, copyObject, getTransitionInfo } from "../../commonFunction";
import { checkIsType } from '../common/commonFunction';
import { ProjectJSON_Store } from '../../../../store/actions/projectdata';
import { SaveVideoBuffering_Store, SaveVideoPlaying_Store } from '../../../../store/actions/videoPlayAction';
import { dragImgDenable, onDragElements, PixeltoTime, TimetoPixel, scrollDesable, scrollEnable } from "../common/commonFunction";
//import add_transition from "../../../../assets/image/add_transition.png"
import transition_on from "../../../../assets/image/transiton_on.png";
import transition_off from "../../../../assets/image/transition_off.png";
import { endSegment_Store, isChangeSegment_Store, startSegment_Store } from '../../../../store/actions/editAction';

const associatedTexutreTypes = {
  "videos": "Video",
  "images": "Image",
  "text": "Text"
}

export const ElementsItem = (props) => {
  const dispatch = useDispatch();
  const { gridOptions, groupData, ElementsOb, selected, ClickEven, setShiftKey, type } = props;

  const menubarRef = useSelector(store => store.editdata.menubarRef);
  const projectJSON = useSelector((store => store.projectdata.projectJSON));
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const audioElements = useSelector(store => store.webgldata.audioElements);
  const selectedID = useSelector(store => store.webgldata.selectedItemID);
  const videoPlaying = useSelector(store => store.videodata.videoPlaying);
  const [groupObs, setGroupObs] = useState({});

  const customItemRef = useRef();
  const [webGLContext, setWebGLContext] = useWebGL();
  const [timelineData, setTimelineData] = useContext(TimeLineContext);
  const [itemW, setItemW] = useState(0);
  const [itemL, setItemL] = useState(0);
  
  const [firstThumb, setFirstThumb] = useState('');

  useEffect(() => {
   
    const ElUUID = ElementsOb.id;
    const tempGroupData = Object.assign({}, groupData);
    if (!!tempGroupData[ElUUID]) delete tempGroupData[ElUUID];
    setGroupObs(tempGroupData);
    setItemW(ElementsOb.style.width);
    setItemL(ElementsOb.style.left);
    if(ElementsOb.thumbnails && ElementsOb.localThumbnails){
      const tmpThumb = ElementsOb.thumbnails && ElementsOb.thumbnails.length>0? `url(${ElementsOb.thumbnails[0]})` : `url(${ElementsOb.localThumbnails[0]})`
      setFirstThumb(tmpThumb);
    }
  }, [ElementsOb])

  const [offsetX, setOffSetX] = useState(-1);
  const [leftW, setLeftW] = useState(-1);
  const [contentW, setContentW] = useState(-1);
  const [scrollX, setScrollX] = useState(-1);
  const onDrag = async (e, dragType) => {
    
    if(customItemRef.current.parentNode.nextSibling.classList.contains('empty-group-cotainer') && dragType == 'left'){
      customItemRef.current.parentNode.nextSibling.style.height = "60px"
      customItemRef.current.parentNode.nextSibling.style.borderTop = "3px solid #0098fd"
      customItemRef.current.parentNode.nextSibling.style.backgroundColor = '#ffeddd';
    }
    if(Array.isArray(selectedID) && selectedID.includes(ElementsOb.id)) return;
    if (!Array.isArray(selectedID) && ElementsOb.id !== selectedID) return;
    if (!e.screenX || offsetX === -1 || leftW === -1 || contentW === -1) return;
    const param = [timelineData, e, dragType, offsetX, leftW, contentW, scrollX, groupObs, customItemRef.current];
    let { diffL, diffW } = await onDragElements(...param, projectJSON, ElementsOb.id);
    if (type === 'videos' || type === 'audios') [diffL, diffW] = await calcVideoSegment(diffL, diffW);

    setItemL(diffL); setItemW(diffW);
    let newSegStart, newSegEnd = 0;
    [diffL, diffW, newSegStart, newSegEnd] = await calcVideoSegment(diffL, diffW);
    if(dragType === 'width-left' || dragType === 'width-right'){
      dispatch(startSegment_Store(newSegStart));
      dispatch(endSegment_Store(newSegEnd));
    }
  }

  const onDragStart = (e, dragType) => {
    
    if (ElementsOb.id !== selectedID || dragType !== 'left') dragImgDenable(e);
    if(Array.isArray(selectedID) && selectedID.includes(ElementsOb.id)) return;
    if (!Array.isArray(selectedID) && ElementsOb.id !== selectedID) return;
    e.target.style.opacity = 0.8;
    const LeftWidwh = customItemRef.current.offsetLeft - timelineData.timeline.scrollLeft
    setOffSetX(e.screenX);
    setLeftW(LeftWidwh);
    setContentW(itemW);
    setScrollX(timelineData.timeline.scrollLeft);
    if(dragType === 'width-left' || dragType === 'width-right'){
      
      dispatch(isChangeSegment_Store(true));
    }
    if (dragType === 'left') setTimelineData({ ...timelineData, dragingGroups: { id: ElementsOb.groups, type: type } });
    else setTimelineData({ ...timelineData, dragingGroups: null });
  }

  const onDragEnd = async (e, dragType) => {
    if(customItemRef.current.parentNode.nextSibling.classList.contains('empty-group-cotainer')){
      customItemRef.current.parentNode.nextSibling.style.height = "3px"
      customItemRef.current.parentNode.nextSibling.style.borderTop = "3px solid #0098fd"
      customItemRef.current.parentNode.nextSibling.style.backgroundColor = '#ffeddd';
    }
    if(Array.isArray(selectedID) && selectedID.includes(ElementsOb.id)) return;
    if (!Array.isArray(selectedID) && ElementsOb.id !== selectedID) return;
    e.target.style.opacity = 1;
    const param = [timelineData, e, dragType, offsetX, leftW, contentW, scrollX, groupObs, customItemRef.current];
    let { diffL, diffW } = await onDragElements(...param, projectJSON, ElementsOb.id);
    let newSegStart = 0; let newSegEnd = 0;
    if (type === 'videos' || type === 'audios') [diffL, diffW, newSegStart, newSegEnd] = await calcVideoSegment(diffL, diffW);
    dispatch(startSegment_Store(newSegStart));
    dispatch(endSegment_Store(newSegEnd));

    await saveChangeData(diffL, diffW, newSegStart, newSegEnd, timelineData);
    setItemL(diffL); setItemW(diffW);
    setOffSetX(-1); setLeftW(-1);
    setContentW(-1); setScrollX(-1);
    dispatch(isChangeSegment_Store(false));
    
    if (!!timelineData.dragingGroups || !!timelineData.dropedGroup){
      setTimeout(() => { setTimelineData({ ...timelineData, dragingGroups: null, dropedGroup: null });}, 1000)
    }
      
  }

  const calcVideoSegment = async (diffL, diffW) => {
    const legendInfo = timelineData.legendInfo;
    let cVideoTime = await PixeltoTime(legendInfo, diffW);
    let cStartTime = await PixeltoTime(legendInfo, diffL);
    let cEndTime = cStartTime + cVideoTime;

    const videoTime = await PixeltoTime(legendInfo, contentW);
    const startTime = await PixeltoTime(legendInfo, scrollX + leftW);
    const endTime = startTime + videoTime;

    let itemVideo = null
    if (type === 'videos') itemVideo = videoElements[ElementsOb.id]
    if (type === 'audios') itemVideo = audioElements[ElementsOb.id]

    let segStart = ElementsOb.segment_start;
    let segEnd = ElementsOb.segment_end;
    let totalTime = 0;
    if (!!itemVideo && itemVideo.duration) totalTime = itemVideo.duration * 1000;

    let newSegStart = segStart;
    let newSegEnd = segEnd;

    if (cVideoTime !== videoTime) {
      newSegStart += cStartTime - startTime;
      newSegEnd += cEndTime - endTime;

      if (newSegStart < 0) {
        newSegStart = 0;
        cVideoTime = newSegEnd - newSegStart;
        cStartTime = cEndTime - cVideoTime;
      }

      if (newSegEnd > totalTime) {
        newSegEnd = totalTime;
        cVideoTime = newSegEnd - newSegStart;
        cEndTime = cStartTime + cVideoTime;
      }
    }
    diffL = await TimetoPixel(legendInfo, cStartTime);
    // diffW = await TimetoPixel(legendInfo, cVideoTime);
    
    diffW = await TimetoPixel(legendInfo, cVideoTime);
    return [diffL, diffW, newSegStart, newSegEnd];
  }

  const saveChangeData = async (diffL, diffW, newSegStart, newSegEnd, timeLineData) => {
    const legendInfo = timeLineData.legendInfo;
    const duration = await PixeltoTime(legendInfo, diffW);
    const startTime = await PixeltoTime(legendInfo, diffL);
    const endTime = startTime + duration;

    const tempOb = {...ElementsOb}
    const bDuration = ElementsOb.duration;
    const bStartT = ElementsOb.timeline_start;
    const bEndT = ElementsOb.timeline_end;

    const tempJSON = Object.assign({}, projectJSON);
    if (startTime === bStartT && endTime === bEndT && duration === bDuration && !!tempJSON[type]) return;
    let tempElements = tempJSON[type][ElementsOb.id];
    tempElements.duration = duration;
    tempElements.timeline_start = startTime;
    tempElements.timeline_end = endTime;

    if (type === 'videos' || type === 'audios') {
      tempElements.segment_start = newSegStart;
      tempElements.segment_end = newSegEnd;
    }

    let dropedGroup = timeLineData.dropedGroup;
    if (!!dropedGroup && !!dropedGroup.id) {
      const nextGroup = Object.assign({}, gridOptions[dropedGroup.id]);
    
      if (!!nextGroup) {
        const groupKeys = Object.keys(nextGroup);
        let latestTime = 0;
        groupKeys.map((key) => {
          const groupKItem = Object.assign({}, nextGroup[key]);
          latestTime = groupKItem.timeline_end > latestTime? groupKItem.timeline_end : latestTime;
        })
        const accept = groupKeys.every((key) => {
          const groupItem = Object.assign({}, nextGroup[key]);
          const startT = groupItem.timeline_start;
          const endT = groupItem.timeline_end;
          const itemState = (startTime < startT && startT < endTime) || (startTime < endT && endT < endTime);
          const cState = (startT < startTime && startTime < endT) || (startT < endTime && endTime < endT);
          const equal = startTime === startT && endTime === endT;

          return !itemState && !cState && !equal;
        })
        
        if (accept) {
          let newTypeData = {};
          let tempTypeData = tempJSON[type];
          let keys = Object.keys(tempTypeData);
          let putState = false;
          keys.forEach((key) => {
            if (key !== ElementsOb.id) {
              newTypeData[key] = tempTypeData[key];
              if (tempTypeData[key].groups === dropedGroup.beforeID && !putState) {
                putState = true;
                tempElements.groups = dropedGroup.id;
                newTypeData[ElementsOb.id] = tempElements;
              }
            }
          })

          tempJSON[type] = newTypeData;
        }else{
          let newTypeData = {};
          let tempTypeData = tempJSON[type];
          let keys = Object.keys(tempTypeData);
          let putState = false;
          keys.forEach((key) => {
            if (key !== ElementsOb.id) {
              newTypeData[key] = tempTypeData[key];
              if (tempTypeData[key].groups === dropedGroup.beforeID && !putState) {
                putState = true;
                tempElements.groups = dropedGroup.id;
                tempElements.duration = duration;
                tempElements.timeline_start = latestTime;
                tempElements.timeline_end = latestTime + duration;
                newTypeData[ElementsOb.id] = tempElements;

              }
            }
          })

          tempJSON[type] = newTypeData;
        }
      }
    }

    if (tsManual.flag == "neighbour" ) {
      
      let ItemOb = tempJSON[type][tsManual.tsID];
      let neighbourOb = tempJSON[type][tsManual.neighbourId];
      if ((ItemOb && neighbourOb) && (ItemOb.timeline_end.toFixed(2) !== neighbourOb.timeline_start.toFixed(2))) {
        ItemOb.transition.end = {
          duration: 0, 
          preset: '-',
          associated_texture: '-',
          associated_texture_type: '-'
        };
        neighbourOb.transition.start = {
          duration: 0, 
          preset: '-',
          associated_texture: '-',
          associated_texture_type: '-'
        };
      }
      
    }
    dispatch(ProjectJSON_Store(tempJSON));

    if (tempOb.timeline_start === 0) {
      changeZeroSecond(tempOb, dispatch)
    } else {
      changeZeroSecond(tempElements, dispatch)
    }
  }

  const [touchDrag, setTouchDrag] = useState(false);
  const [touchCY, setTouchCY] = useState();
  const [touchTime, setTouchTime] = useState(0);
  const onTouchStarting = (e) => {
    setTouchCY(e.changedTouches[0].clientY);
    setTouchTime(new Date());
    
  }
  const onTouchMove = async (e, dragType) => {
    const newDate = new Date();
    // if(newDate.getTime() - touchTime.getTime() > 1000){
    //   setShiftKey(true);
    // }
    if(dragType === 'width-left' || dragType === 'width-right'){
      
      dispatch(isChangeSegment_Store(true));
    }
    if (ElementsOb.id !== selectedID) return;
    scrollDesable(timelineData);
    
    //if (!touchDrag) 
    if (!touchDrag){
      customItemRef.current.style.position = 'absolute';
      onDragStart(e.changedTouches[0], 'left');
      setTouchDrag(true);
    } else{
      onDrag(e.changedTouches[0], dragType);
    }
    
    e.preventDefault()
  }

  const onTouchEnd = async (e, dragType) => {
    
    const newDate = new Date();
    if(newDate.getTime() - touchTime.getTime() > 1000){
      ClickEven(ElementsOb.id);
    }
    if (ElementsOb.id !== selectedID) return;
    scrollEnable(timelineData);
   
    if (touchDrag){
      
      const touchOffestY = e.changedTouches[0].clientY - touchCY;
      const moveYCheck = customItemRef.current.clientHeight / 2;
      let groupContainer = null;
      var timelineData_tmp = timelineData;
      if(Math.abs(touchOffestY) > moveYCheck){
        if(touchOffestY > 0){
          groupContainer = customItemRef.current.parentElement.parentElement.nextSibling;
          if(!groupContainer){
            groupContainer = customItemRef.current.parentElement.nextSibling;
          }
        }else{
          groupContainer = customItemRef.current.parentElement.parentElement.previousSibling;
        }
        const dragingGroups = timelineData.dragingGroups;
        if(groupContainer != null ){
          let isMoveable = false;
          if(groupContainer.getAttribute('next-uuid')){
            const beforeId = groupContainer.getAttribute('next-uuid');
            const moveId =  uuidv4();
            isMoveable = true;
            timelineData_tmp = { ...timelineData, dropedGroup: { id: moveId, beforeID: beforeId } };
          }else{
            const moveID = groupContainer.getAttribute('group-id');
            isMoveable = checkIsType(projectJSON, type, moveID);
            timelineData_tmp = { ...timelineData, dropedGroup: { id: moveID, beforeID: moveID } };
          }
          // onDragEnd(e.changedTouches[0], dragType);
          
          if (ElementsOb.id === selectedID && isMoveable)
          {
            setTimelineData(timelineData_tmp); 
          }
               
        }   
      }
      const param = [timelineData_tmp, e.changedTouches[0], dragType, offsetX, leftW, contentW, scrollX, groupObs, customItemRef.current];
      let { diffL, diffW } = await onDragElements(...param, projectJSON, ElementsOb.id);
      let newSegStart = 0; let newSegEnd = 0;
      [diffL, diffW, newSegStart, newSegEnd] = await calcVideoSegment(diffL, diffW);
      await saveChangeData(diffL, diffW, newSegStart, newSegEnd, timelineData_tmp);
      setItemL(diffL); setItemW(diffW);
      setOffSetX(-1); setLeftW(-1);
      setContentW(-1); setScrollX(-1);
      if (!!timelineData_tmp.dragingGroups || !!timelineData_tmp.dropedGroup){
        setTimeout(() => { setTimelineData({ ...timelineData, dragingGroups: null, dropedGroup: null });}, 1000)
      }  
      // setTimeout(function() { onDragEnd(e.changedTouches[0], dragType); }, 1000);
    } 
    if(customItemRef.current.parentNode.nextSibling.classList.contains('empty-group-cotainer')){
      customItemRef.current.parentNode.nextSibling.style.height = "3px"
      customItemRef.current.parentNode.nextSibling.style.borderTop = "3px solid #0098fd"
      customItemRef.current.parentNode.nextSibling.style.backgroundColor = '#ffeddd';
    }
    if(dragType === 'width-left' || dragType === 'width-right'){
      
      dispatch(isChangeSegment_Store(false));
    }
    if (touchDrag) setTouchDrag(false);
    
  }

  const state = webGLContext.transition;
  const tsManual = webGLContext.tsManual;
  const selectedState = tsManual.tsID === ElementsOb.id;
  const [settedTs, setSettedTs] = useState({ start: false, end: false, neightbour: false });
  const [transition, setTransition] = useState({ start: false, end: false, neighbour: false, neighbourId: null, preset: '-' });

  useEffect(() => {
    if (type !== 'audios') {
      let params = [groupData, ElementsOb.id];
      let { maxEnd, minStart, clashItem, clashID } = getTransitionInfo(...params);
      let endTime = ElementsOb.timeline_end;
      let startTime = ElementsOb.timeline_start;
      let tempTransition = { start: false, end: false, neighbour: false, neighbourId: null, preset: '-'};

    //  if (state.moving || tsManual.state) {
      tempTransition.start = true;
      tempTransition.end = true;
      
      Object.values(groupData).map(item => {
        if(item.id !== ElementsOb.id && item.timeline_end.toFixed(2) === ElementsOb.timeline_start.toFixed(2)) {
          tempTransition.start = false;
        }
        if(item.id !== ElementsOb.id && item.timeline_start.toFixed(2) === ElementsOb.timeline_end.toFixed(2)) {
          tempTransition.end = false
          tempTransition.neighbour = true;
          tempTransition.preset = item.transition.start.preset;
          tempTransition.neighbourId = item.id;
        }
      })
       
        // if (minStart === startTime) tempTransition.start = true;
        // if (maxEnd === endTime || clashItem) tempTransition.end = true;
     // }
      setTransition(tempTransition);

      let tempJSON = Object.assign({}, projectJSON);
      let ItemOb = tempJSON[type][ElementsOb.id];
      let ts = ItemOb.transition;

      let tsState = { start: false, end: false, neighbour: false };
      if(ts.associated_texture === "-") {
        if (minStart === startTime && !!ts.start.preset) tsState.start = true;
        if (maxEnd === endTime && !!ts.end.preset) tsState.end = true;
      } else {
        tsState.neighbour = true;
      }

      if (clashItem) {
        let clashOb = tempJSON[type][clashID];
        let startTs = clashOb.transition.start;
        if (!!startTs.preset && startTs.preset === ts.end.preset) tsState.end = true;
      }

      setSettedTs(tsState);
    }
  }, [ElementsOb, tsManual, state])


  const onClick = (flag) => {
    let itemKey = ElementsOb.id;
    let params = [groupData, itemKey];
    let { clashID } = getTransitionInfo(...params);

    let temptsManual = { type: "", state: tsManual.state, tsID: "", clashID: "", flag: "" }
    if (tsManual.tsID !== itemKey && tsManual.flag !== flag)
    
      temptsManual = { type: type, state: tsManual.state, tsID: itemKey, clashID: clashID, flag: flag, neighbourId: transition.neighbourId };

    setWebGLContext({ ...webGLContext, tsManual: temptsManual });
    if (!!temptsManual.tsID) menubarRef.children[7].click();
  }

  const onDrop = (e, flag) => {
    if (!state.type || !state.moving) return;
    let target = e.currentTarget;
    target.style.backgroundColor = '#0098fd';

    let params = [groupData, ElementsOb.id];
    let { clashID, clashItem } = getTransitionInfo(...params);
    let groupOptions = JSON.parse(JSON.stringify(projectJSON[type]));
    let ts = groupOptions[ElementsOb.id].transition;

    let duration = state.type === 'none' ? 0 : 2000;
    let resultType = state.type === 'none' ? '' : state.type;
    let result = { 
      duration: duration, 
      preset: resultType, 
      associated_texture: "-", 
      associated_texture_type: "-" 
    };

    if (flag === 'right') { ts.end = result; }
    if (flag === 'left') { ts.start = result; }
    if (flag === "neighbour") {
      ts.end = {
        ...result,
        associated_texture: transition.neighbourId? transition.neighbourId : '-',
        associated_texture_type: associatedTexutreTypes[type]? associatedTexutreTypes[type] : '-'
      };
      groupOptions[transition.neighbourId].transition.start = {
        ...result,
        associated_texture: ElementsOb.id?ElementsOb.id : '-',
        associated_texture_type: associatedTexutreTypes[type]? associatedTexutreTypes[type] : '-'
      };
    }

    if (flag === 'right' && clashItem) {
      let clashTs = groupOptions[clashID].transition;
      clashTs = { ...clashTs, start: result };
    }

    dispatch(ProjectJSON_Store({ ...projectJSON, [type]: groupOptions }));
    setWebGLContext({ ...webGLContext, tsManual: { type: "", state: false, tsID: "", clashID: "", flag: "" } });
  }

  const onDragOver = (e) => {
    if (!state.type || !state.moving) return;
    e.preventDefault();
    e.dataTransfer.effectAllowed = 'all';
    e.dataTransfer.dropEffect = 'move';
    let target = e.currentTarget;
    target.style.backgroundColor = '#f824ff';
  }

  const onDragLeave = (e) => {
    if (!state.type || !state.moving) return;
    let target = e.currentTarget;
    target.style.backgroundColor = '#0098fd';
  }

  const menuStyle = {
    zIndex: !state.moving && selected ? 3 : 1,
    opacity: !state.moving && selected ? 1 : 0
  }

  return (
    <div ref={customItemRef}
      style={{ left: itemL }}
      className={`group-item-wrapper`}
    >
      <div className="item-left-slider"
        draggable='true' style={menuStyle}
        onDrag={(e) => { onDrag(e, 'width-left'); }}
        onDragStart={(e) => {onDragStart(e, 'width-left')}}
        onDragEnd={(e) => { onDragEnd(e, 'width-left'); }}

        onTouchMove={(e) => { onTouchMove(e, 'width-left'); }}
        onTouchEnd={(e) => { onTouchEnd(e, 'width-left'); }}
      />

      <div className={`item-content ${!state.moving && selected && 'active-item'}`}
        data-uuid={ElementsOb.groups} style={{ width: itemW, backgroundImage: firstThumb , backgroundSize: '100px 56px' }} draggable='true'
        onClick={() => { ClickEven(ElementsOb.id); }}
        onMouseDown={() => { ClickEven(ElementsOb.id); }} 
        onDrag={(e) => { onDrag(e, 'left'); }}
        onDragStart={(e) => { onDragStart(e, 'left') }}
        onDragEnd={(e) => { onDragEnd(e, 'left'); }}
        onTouchStart={(e) => {onTouchStarting(e);}}
        onTouchMove={(e) => { onTouchMove(e, 'left'); }}
        onTouchEnd={(e) => { onTouchEnd(e, 'left'); }}
      >
        {type === 'text' && <span>{ElementsOb.context.replaceAll('?entered!*-', ' ')}</span>}

        {(type === 'videos' && videoElements[ElementsOb.id])  && (<VideoImages
          itemWidth={itemW}
          thumbnails={ElementsOb.localThumbnails.length>0? ElementsOb.localThumbnails : ElementsOb.thumbnails}
          uuid={ElementsOb.id} type={type}
          aspect={videoElements[ElementsOb.id].videoWidth/videoElements[ElementsOb.id].videoHeight}
          // localThumbnails={ElementsOb.localThumbnails}
          />)}

        {type === 'images' && (<VideoImages
          aspect={1}
          itemWidth={itemW}
          thumbnails={ElementsOb.thumbnails}
          uuid={ElementsOb.id} type={type}
        />)}
      </div>

      <div className="item-right-slider"
        draggable='true' style={menuStyle}
        onDrag={(e) => { onDrag(e, 'width-right'); }}
        onDragStart={e => {onDragStart(e, 'width-right')}}
        onDragEnd={(e) => { onDragEnd(e, 'width-right'); }}

        onTouchMove={(e) => { onTouchMove(e, 'width-right'); }}
        onTouchEnd={(e) => { onTouchEnd(e, 'width-right'); }}
      />

      {/* <div className={`transition-left ${(selectedState && tsManual.flag === 'left') && ('active-transition')}`}
        onDrop={(e) => { onDrop(e, 'left'); }} onDragOver={onDragOver} onDragLeave={onDragLeave}
        onClick={() => { onClick('left'); }}
      ><RectangleOutlinedIcon /></div> */}

      {/* <div className={`transition-right ${(selectedState && tsManual.flag === 'right') && ('active-transition')}`}
        onDrop={(e) => { onDrop(e, 'right'); }} onDragOver={onDragOver} onDragLeave={onDragLeave}
        onClick={() => { onClick('right'); }}
      ><RectangleOutlinedIcon /></div> */}
      
      {(transition.neighbour && (type !== 'text' && type !== 'audios' && type !== 'subtitles')) && (
        <div className={`transition-right ${(selectedState && tsManual.flag === "neighbour") && ('active-transition')}`}
          onDrop={(e) => { onDrop(e, 'neighbour'); }} onDragOver={onDragOver} onDragLeave={onDragLeave}
          onClick={() => { onClick('neighbour'); }}
          onDoubleClick={() => {console.log("Transition button clicked")}}
        ><img src={transition.preset == "-"? transition_off : transition_on} alt="add transition" width={20} height={20} /></div>
      )}
    </div >
  );
};