import { getGLContext } from '../commonFunc';
import { convertStringtoWStringUTF16 } from '../../commonFunction';
import { UpdateTextTextures } from '../Setups/SetupClass';
import { CanvasTextOnKeyUP, CanvasTextPrinterOnBlur, CanvasTextPrinterOnFocus, CanvasTextRowSplitter } from '../TextHelpers/TextHelper';

export class MoveableDiv {
  divCreate(id) {
    let div = document.createElement('div');
    div.className = `thDiv${id}`;
    div.id = id;

    return div;
  }

  divDragResizeRotationUpdate(div, elementOb, canvas, projectJSON) {
    const canvasW = canvas.offsetWidth;
    const ratio = canvasW / projectJSON.width;
    const ratioWH = elementOb.ratioWH;
    const ratioW = projectJSON.width / ratioWH.w;
    const ratioH = projectJSON.height / ratioWH.h;

    const coordinate = elementOb.coordinate;
    const dimension = elementOb.dimension;
    const divStyleTop = coordinate.y * ratio * ratioH;
    const divStyleLeft = coordinate.x * ratio * ratioW;
    const divHeight = dimension.h * ratio * ratioH;
    const divWidth = dimension.w * ratio * ratioW;
    const rotation = elementOb.rotation;

    div.style.top = `${divStyleTop}px`;
    div.style.left = `${divStyleLeft}px`;
    div.style.height = `${divHeight}px`;
    div.style.width = `${divWidth}px`;

    div.style.display = 'inline';
    div.style.boxSizing = 'border-box';
    div.style.position = 'absolute';
    div.style.zIndex = coordinate.zIndex;
    div.style.transform = `rotate(${rotation}deg)`

    return div
  }

  inputFieldCreate(id) {
    let input = document.createElement('div'); // input
    input.id = id;

    // input.type = 'text';
    input.className = `thDiv${id}`;
    // input.style.color = 'rgba(0,0,0,0)';
    input.style.backgroundColor = 'rgba(255,255,255,0)';

    return input;
  }

  divStyleUpdate(div, divStyleTop, divStyleLeft, divHeight, divWidth) {
    div.style.top = `${divStyleTop}px`;
    div.style.left = `${divStyleLeft}px`;
    div.style.height = `${divHeight}px`;
    div.style.width = `${divWidth}px`;

    div.style.display = 'inline';
    div.style.boxSizing = 'border-box';
    div.style.position = 'absolute';
    div.style.zIndex = '1';

    return div;
  }

  divHeightWidthCalculator(videoHeight, videoWidth, canvasW, canvasH) {
    let divHeight = 0;
    let divWidth = 0;
    const aspectRatio = videoHeight / videoWidth;
    const aspectRatioComparison = videoWidth / videoHeight < canvasW / canvasH;

    if (aspectRatioComparison) {
      divHeight = canvasH;
      divWidth = canvasH / aspectRatio;
    } else {
      divWidth = canvasW;
      divHeight = canvasW * aspectRatio;
    }

    return [divHeight, divWidth];
  }

  divPositionTopLeftCalculator(divHeight, divWidth, canvasW, canvasH) {
    const rePositionToptoCanvas = (canvasH - divHeight) / 2;
    const divStyleTop = rePositionToptoCanvas;

    const rePositionLefttoCanvas = (canvasW - divWidth) / 2;
    const divStyleLeft = rePositionLefttoCanvas;

    return [divStyleTop, divStyleLeft];
  }
  // CLEAN UP TO HERE




  divResizePositionCalculator(div, canvas, distanceChange) {
    // distance from top
    let indexOfEndTop = div.style.top.indexOf('px');
    let divExDistanceTop = Number(div.style.top.slice(0, indexOfEndTop));

    // distance from left
    // let indexOfEndLeft = div.style.left.indexOf('px');
    // let divExDistanceLeft = Number(div.style.left.slice(0, indexOfEndLeft));

    // calculate the neccessary parameters for y axis
    let indexOfEndHeight = div.style.height.indexOf('px');
    let theDivHeight = Number(div.style.height.slice(0, indexOfEndHeight));
    let theEmptyHeight = canvas.height - theDivHeight;

    // calculate the neccessary parameters for x axis
    let indexOfEndWidth = div.style.width.indexOf('px');
    let theDivWidth = Number(div.style.width.slice(0, indexOfEndWidth));
    let theEmptyWidth = canvas.width - theDivWidth;

    let divTopPosition;
    let divLeftPosition;

    divTopPosition = theEmptyHeight / 2;
    divLeftPosition = theEmptyWidth / 2;

    div.style.top = `${divTopPosition}px`;
    div.style.left = `${divLeftPosition}px`;

    return div;
  }
}

export const SetupTextEdit = (textItemOb, moveableItem, moveableKey, serverTextRef, webGLContext, projectJSON, canvas, callBack) => {
  if (!webGLContext.activeTextCanvases.get(moveableKey)) return;
  let textCanvas = document.createElement('canvas')

  const canvasEl = webGLContext.webGLRef;
  const canvasGl = getGLContext(canvasEl);
  const ctx = textCanvas.getContext('2d');
  serverTextRef.current[moveableKey] = textItemOb.context;

  let dbState = false;
  const WebGLActiveText = webGLContext.activeTextures;
  moveableItem.onkeyup = function (event) {
    dbState = true;
    const key = event.key;
    let textInfo = textItemOb;

    const inputFieldRef = textInfo.context;
    const NewServerTextRef = CanvasTextOnKeyUP(inputFieldRef, key, event);
    serverTextRef.current[moveableKey] = NewServerTextRef;

    event.target.value = '';
    textInfo.context = NewServerTextRef;
    textInfo.contextutf16 = convertStringtoWStringUTF16(NewServerTextRef);
    let params = [ctx, textInfo, projectJSON, canvas, moveableKey, webGLContext]
    let textCanvas = CanvasTextPrinterOnFocus(...params)
    UpdateTextTextures(canvasGl, WebGLActiveText.get(moveableKey), textCanvas)
  };

  moveableItem.onblur = function () {
    textEditDisable();
    if (dbState) callBack(textItemOb.context, moveableKey);
  };


  moveableItem.onclick = (e) => {
    dbState = false;
    if (e.pointerType === 'touch' && diffTime < 200 && diffTime !== 0) { }
    else moveableItem.blur();

  };

  moveableItem.ondblclick = function () {
    textEditEnable();
  };

  let touchTime = 0;
  let diffTime = 0;
  moveableItem.ontouchstart = () => {
    diffTime = 0;
    let newTouchTime = + new Date();
    if (newTouchTime - touchTime < 300) {
      diffTime = newTouchTime - touchTime;
      touchTime = 0;
      textEditEnable();
    }
    else touchTime = newTouchTime;
  }

  function textEditEnable() {
    moveableItem.focus();
    let textInfo = textItemOb;
    if (!textInfo.context) {
      textInfo.context = '';
      textInfo.contextutf16 = '';
    }

    serverTextRef.current[moveableKey] = textInfo.context;

    let params = [ctx, textInfo, projectJSON, canvas, moveableKey, webGLContext]
    let textCanvas = CanvasTextPrinterOnFocus(...params)
    UpdateTextTextures(canvasGl, WebGLActiveText.get(moveableKey), textCanvas)
  }

  function textEditDisable() {
    serverTextRef.current[moveableKey] = textItemOb.context;
    CanvasTextPrinterOnBlur(ctx, textItemOb, projectJSON, canvas, moveableKey, webGLContext);
    UpdateTextTextures(canvasGl, WebGLActiveText.get(moveableKey), textCanvas);
  }
}