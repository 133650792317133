import { matchPath, useLocation } from "react-router-dom";

export const GetCanvasEL = () => document.getElementById('glcanvas');
export const GetQuerySelector = (point) => document.querySelector(point);
export const GetElementID = (id) => document.getElementById(id);

export const GetProjectUUID = () => {
  const pathname = useLocation().pathname;
  const pattern = { path: '/edit/:projectUUID/*', exact: true, strict: false };
  const pathMatch = matchPath(pattern, pathname);
  const projectUUID = pathMatch?.params.projectUUID;
  return projectUUID;
}

export const checkUUIDformat = (uuid) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(uuid);
}