import { Box, Typography } from '@mui/material';

export const SectionHeader = (props) => {
  if(props.children) {
    <Box className={`v-toolbar-header ${props?.className}`}>
      {props.children}
    </Box>
  }
  else if (props.beforecontent) {
    return (
      <Box className={`v-toolbar-header ${props?.className}`}>
        <Typography className={`v-header-before-left ${props.beforeclass ? props.beforeclass : ''}`}>
          {props.beforecontent ? props.beforecontent : ''}
        </Typography>
        <Typography className='v-header-right first-letter:uppercase'>{props.title}</Typography>
      </Box>
    )
  } else if (props.footertitle) {
    return (
      <Box className={`v-toolbar-header pt-3 pb-3 ${props?.className}`} sx={{ height: 'initial !important', display: 'block !important' }}>
        <Typography className='v-header-right first-letter:uppercase'>{props.title}</Typography>
        <Typography className={`${props.footerclass ? props.footerclass : ''}`}>{props.footertitle}</Typography>
      </Box>
    )
  } else {
    return (
      <Box className={`v-toolbar-header ${props?.className}`}>
        <Typography className='v-header-right first-letter:uppercase'>{props.title}</Typography>
      </Box>
    )
  }
}