import { styled } from '@mui/material';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';

const blue = {
  50: 'white',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: 'rgb(238 238 240)',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

export const Tab = styled(TabUnstyled)`
  font-family: Inter, sans-serif;
  color: rgb(144, 148, 165);
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 10px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  &:focus {
    border-radius: 8px;
    outline: 2px solid ${blue[200]};
    outline-offset: 2px;
  }
  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[50]};
        box-shadow: rgb(0 0 0 / 3%) 0px 100px 80px, rgb(0 0 0 / 2%) 0px 41.7776px 33.4221px, rgb(0 0 0 / 2%) 0px 22.3363px 17.869px, rgb(0 0 0 / 2%) 0px 12.5216px 10.0172px, rgb(0 0 0 / 1%) 0px 6.6501px 5.32008px, rgb(0 0 0 / 1%) 0px 2.76726px 2.21381px;
        color: rgb(25, 32, 51);
  }
  &.${buttonUnstyledClasses.disabled} {
    cursor: not-allowed;
  }
`;

export const TabPanel = styled(TabPanelUnstyled)`
  font-family: Inter, sans-serif;
`;

export const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: ${blue[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  padding:5px
`;

export const ColorTab = styled(TabUnstyled)`
  color: #212529;
  cursor: pointer;
  background-color:white;
  font-size: 0.875rem;
  width: 100%;
  border: none;
  display: flex;
  justify-content: center;
  &.${tabUnstyledClasses.selected} {
    font-weight: 600;
  }
  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ColorTabPanel = styled(TabPanelUnstyled)`
  max-height: 170px;
  overflow-y:auto;
  width: 100%;
  font-size: 0.875rem;
`;

export const ColorTabsList = styled(TabsListUnstyled)`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;