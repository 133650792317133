import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase, Stack} from "@mui/material"
import VideocamIcon from '@mui/icons-material/Videocam';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';

export const PixabayVideoModal = (props) => {
  const {stockData} = props;
  const renderDuration = (duration) =>{
    let subtitle = "";
    const minute = Math.floor(duration / 30);
    const second = Math.round((duration - minute * 60));
    subtitle += ` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`
  }
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Pixabay Stock Video
          </Typography>
          <Typography variant="overline" component={'p'}>
            Type: <b>{stockData?.type}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Tags: <b>{stockData?.tags}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Duration: <b>{stockData?.duration}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Views: <b>{stockData?.views}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Downloads: <b>{stockData?.downloads}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Likes: <b>{stockData?.likes}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Comments: <b>{stockData?.comments}</b>
          </Typography>
          <Typography variant="overline" sx={{display: 'flex', alignItems: 'center'}} component={'p'}>
            User: <Avatar src={stockData?.userImageURL} sx={{ marginX: '5px', width: 24, height: 24 }}/><b>{stockData?.user}</b>
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <div style={{position: 'relative'}}>
            <video muted src={stockData?.videos.tiny.url}>
            </video>
            <div className='stockMediaBar'>
              <Stack direction='row' justifyContent='start' alignItems='center'>
                <VideocamIcon sx={{ color: 'white' }}/>
                <Typography color={'white'} variant='span'>{renderDuration(stockData?.duration)}</Typography>
              </Stack>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} >
          <Stack sx={{border: '1px solid blue', overflow: 'hidden'}} direction={'row'} justifyContent={'center'} alignItems={'center'}>
            {[...Array(10)].map((e, i) => {
              return <img src={stockData?.videos.tiny.thumbnail} style={{height: '50px', border: '1px'}}/>
            })}
            
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}