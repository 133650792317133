export const editorLangEnStrs = {
    welcomNewProject : 'Welcome to your new project',
    pls_add_media:'Please add media to your project',
    add_video:'Add Video',
    add_audio:'Add Audio',
    add_image:'Add Image',
    add_webcam:'WebCam Capture',
    add_screen:'Screen Capture',
    add_webcam_screen:'WebCam & Screen Capture',
    capture_screen_camera:'Capture your screen & camera !',
    video_captureDevice:'Video Capture Device',
    resolution: 'Resolution',
    audio_captureDevice: 'Audio Capture Device',
    resize_video: 'Resize Video',
    rotate_video: 'Rotate Video',
    loop_video: 'Loop Video',
    joinmarge_video: 'Add Join/Merge Video',
    cut_video: 'Cut Video',
    crop_video: 'Crop Video',
    translate_video: 'Translate Video',
    transcripbe_video: 'Transcribe Video',
    auto_subtitles: 'Auto Subtitles',
    add_subtitle_video: 'Add Subtitle to Video',
    compress_video: 'Compress Video',
    convert_video: 'Convert Video',
    project_not_accessible: 'Project is not accessible',
    leave: 'Leave',
    cancel: 'Cancel',
    okay: 'Ok',
    stop: 'Stop',
    record: 'Record',
    ongoing_upload_project_str: 'There is an ongoing upload to this project.',
    leaving_page_sure: 'You are leaving this page, are you sure?',
    uploading_files: 'Uploading Files',
    record_screen_and_upload: 'Record your screen and upload.',
    show_defaul_sharingMenu: 'Show default sharing MenuItems',
    prefer_share_browser: 'Prefer to share a browser tab',
    prefer_share_window: 'Prefer to share a window',
    prefer_share_entireScreen: 'Prefer to share an entire screen',
    record_webcam_and_upload: 'Record your web camera and upload.',
    sign_signup_to_uploadVideos: 'Please sign in or sign up to upload videos',
    sizelimir_or_timelimit: 'larger than 250 Mbytes or longer than 5 minutes',
    screen_capture_preferences: 'Screen Capture Preferences',
    is_uploading: 'Is Uploading',
    monthly: 'Monthly',
    annually: 'Annually',
    most_popular: 'Most Popular',
    export: 'Export',
    bitrate: 'Bitrate',
    frame_rate_fps: 'Frame Rate (FPS)',
    produce: 'Produce',
    setup: 'Setup',
    template: 'Template',
    templates: 'Templates',
    media: 'Media',
    stock: 'Stock',
    translations: 'Translations',
    subtitles: 'Subtitles',
    text_to_speech: 'Text To Speech',
    transitions: 'Transitions',
    text: 'Text',
    emojis: 'Emojis',
    filters: 'Filters',
    draw: 'Draw',
    edit_subtitle: 'Edit Subtitle',
    add_new_subtitle: 'add new subtitle',
    select_brush_stock_start_draw: 'Select a brush stroke to start drawing',
    brush_stroke: 'Brush Stroke',
    round: 'Round',
    sliced: 'Sliced',
    color: 'Color',
    opacity: 'Opacity',
    size: 'Size',
    duration: 'Duration',
    add_new_line: 'Add New Line',
    all_types: 'All Types',
    load_more: 'Load More',
    upload_video: 'Upload Video',
    upload_audio: 'Upload Audio',
    upload_image: 'Upload Image',
    create_webcam_recording: 'Create Webcam Recording',
    create_screen_capture_recording: 'Create Screen Capture Recording',
    create_webcam_screen_recording: 'Create Webcam & Screen Recording',
    project_asset: 'Project Asset',
    you_want_add_translations: 'How do you want to add translations?',
    uploader: 'Uploader',
    auto: 'AUTO',
    auto_translations: 'Auto Translations',
    automatically_add_transllations_video: 'Automatically add translations to video',
    wait_moments_subtitles_being: 'Wait for a few moments while subtitle is being prepared',
    select_language_detect: 'Select language to detect',
    disable_translate: 'Disable Translate',
    select_language_select: 'Select Language to translate into',
    transition_duration: 'Transition Duration',
    coming_soon: 'Coming Soon',
    language: 'Language',
    speaking_rate_defaul: 'Speaking Rate (default 1)',
    pitch_defaul: 'Pitch (default 0)',
    volume_gain_default: 'Volume Gain (default 0)',
    create_text_speech: 'Create Text To Speech',
    add_text: 'Add Text',
    text: 'Text',
    start: 'Start',
    end: 'End',
    text_style: 'Text Style',
    background_color: 'Background Colour',
    add_new_text_field: 'Add New Text Field',
    how_add_subtitles: 'How do you want to add subtitles',
    automatically_subtitles_video: 'Automatically add subtitles to video',
    text_color: 'Text Colour',
    preview_text: 'Preview Text',
    search: 'Search',
    orientation: 'Orientation',
    next: 'Next',
    previous: 'Previous',
    locale: 'Locale',
    landscape: 'Landscape',
    portrait: 'Portrait',
    square: 'Square',
    time: 'Time',
    background: 'Background',
    project_setting: 'Project Settings',
    use_template_project: 'Use as template project',
    visibility: 'Visibility',
    category: 'Category',
    capture_thumbnail: 'Capture Thumbnail',
    brand_kit: 'Brand Kit',
    video_placeholder: 'Video Placeholder2.mp4',
    replace_video: 'Replace Video',
    transformations: 'Transformations',
    fill_canvas: 'Fill the canvas',
    fit_canvas: 'Fit the canvas',
    round_corners: 'Round Corners',
    audio: 'Audio',
    detach_audio: 'Detach Audio',
    cleanAudio: 'CleanAudio',
    upgrade_basic_unlock: 'Upgrade to BASIC to unlock',
    remove_background_noise_enhance_audio_qty: 'Remove background noise and enhance audio quality',
    color_correction: 'Color Correction',
    reset: 'Reset',
    exposure: 'Exposure',
    brightness: 'Brightness',
    contract: 'Contrast',
    saturation: 'Saturation',
    sharpen: 'Sharpen',
    blur: 'Blur',
    noise: 'Noise',
    trending: 'TRENDING',
    custom: 'CUSTOM',
    portrait: 'PORTRAIT',
    landscape: 'LANDSCAPE',
    settings: 'Settings',
    magic_draw: 'Magic Draw',
    tools: 'Tools',
    brush_size: 'Brush Size',
    save: 'Save',
    clear: 'Clear',
    view_all: 'View All',
    save_your_project_later: 'Save your project for later',
    sign_in: 'sign in',
    sign_up: 'sign up',
    redo: 'REDO',
    undo: 'UNDO',
    collaborate: 'COLLABORATE',
    enter_email_share_project: 'Enter email to share project',
    share_project: 'Share Project',
    create_new_workspace: 'Create new Workspace',
    audio_noise_remove: 'Audio Noise Removal',
    media_upload: 'Media Upload',
    draw_color: 'Draw Color',
    tsl_none: 'none',
    tsl_fade: 'Fade',
    tsl_book_flip: 'Book Flip',
    tsl_disolve: 'Disolve',
    tsl_doorway: 'Doorway',
    tsl_blur: 'Blur',
    tsl_push: 'Push',
    tsl_slide: 'Slide',
    tsl_wipe: 'Wipe',
    tsl_zoom_in: 'Zoom In',
    tsl_zoom_out: 'Zoom Out',
    flt_None: 'None',
    flt_Darker: 'Darker',
    flt_BlacknWhite: 'BlacknWhite',
    flt_Daphne: 'Daphne',
    flt_Penelope: 'Penelope',
    flt_Phoebe: 'Phoebe',
    flt_Thalia: 'Thalia',
    flt_Chloe: 'Chloe',
    flt_Hermione: 'Hermione',
    flt_Hera: 'Hera',
    flt_Rhea: 'Rhea',
    flt_Donna: 'Donna',
    flt_Martina: 'Martina',
    flt_Gabriella: 'Gabriella',
    flt_Elisabetta: 'Elisabetta',
    flt_Isabella: 'Isabella',
    flt_Luna: 'Luna',
    flt_Viola: 'Viola',
    flt_Zita: 'Zita',
    flt_Donata: 'Donata',
    flt_Maddalena: 'Maddalena',
    flt_Tecla: 'Tecla',
    flt_Narcisa: 'Narcisa',
    emj_Smileys: 'Smileys',
    emj_Animals: 'Animals',
    emj_People: 'People',
    emj_Jobs: 'Jobs',
    emj_Sports: 'Sports',
    emj_Flags: 'Flags',
    emj_Signs: 'Signs',
    emj_Nature: 'Nature',
    emj_Travel: 'Travel',
    emj_Letters: 'Letters',
    emj_Misc: 'Misc',
    create_empty_project_templates: 'Create an Empty Project From Templates',
    days_ago: 'days ago',
    create_workspace: 'Create Workspace',
    workspace: 'Workspaces',
    delete_workspace: 'Delete Workspace',
    transfer_owner: 'Transfer Owner',
    can_create_new_workspace: 'Could you create new workspace?',
    could_delete_workspace: 'Could you delete workspace?',
    available_subtitles_time: 'Available Subtitles Time',
    available_storage_space: 'Available Storage Space',
    other_workspaces: 'Other workspaces',
    members: 'Members',
    add_member: 'Add Member',
    remove_member: 'Remove Member',
    change_member_level: 'Change Member Level',
    owner: 'Owner',
    admin: 'Admin',
    member: 'Member',
    read_only: 'Read Only',
    duplicate: 'Duplicate',
    splite: 'Split',
    remove: 'remove',
    layer: 'Layer',
    volume: 'Volume',
    disable: 'Disable',
    deleterow: 'DeleteRow',
    fullscreen: 'Fullscreen',
    editsubtitle: 'EditSubtitle',
    fit: 'Fit',
    green_screen: 'Green Screen',
};