import { v4 as uuidv4 } from 'uuid';
import { useState, useEffect } from 'react';
//import { FaHourglassEnd } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Typography, Stack, Slider, Menu } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import './controller.scss';
import {Fullscreen} from '@mui/icons-material';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import PauseIcon from '@mui/icons-material/Pause';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import VolumeUp from '@mui/icons-material/VolumeUp';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import OpacityIcon from '@mui/icons-material/Opacity';
import SkipNextIcon from '@mui/icons-material/SkipNext'
import FastForwardIcon from '@mui/icons-material/FastForward';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ReplayIcon from '@mui/icons-material/Replay';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import ConnectedTvOutlinedIcon from '@mui/icons-material/ConnectedTvOutlined';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import { changeZeroSecond, copyObject, getGroupoptions } from '../commonFunction';
import { useWebGL } from '../videoEdit.provider';
import Swal from 'sweetalert2';

import { volumeMarks, zoomMarks, speedMarks } from './control.config';
import { getSelectedType, getNextZIndex, getMaxZIndex } from '../commonFunction';
import { getImageInformation } from '../webGL/commonFunc';
import { editSubtitle_Store, thumbNails_Store, timelineFit_Store, sequenceStatus_Store, sequenceNum_Store, ProjectJSON_Store, projectvolume_store, fullScreenFlag_store, StretchScreenFlag_store, uploadingFiles_Store, changedJSON_Store } from '../../../store/actions/projectdata';
import { SelectedItemID_Store, VideoElements_Store, audioElements_Store, imageElements_Store, selectedGroupID_Store, dataLoaded_Store, } from '../../../store/actions/webgldata';
import { SaveVideoPlaying_Store, SaveZoomRate_Store, TimeCount_Store, SaveVideoPlayStarted_Store } from '../../../store/actions/videoPlayAction';
import { removeElement, checkZoomValue, duplicateElementCreate, freezeFrameCreate, splitElementCreate, fullVideoSize,checkVolumeValue, nextTimeCalc } from './controlCommonFunc';
import { getProjectHistory, getProjectJSON, updateProjectJSON, SERVER_STATUS } from '../../../axios/ApiProvider';
import { loading_Store } from '../../../store/actions/mainAction';
import { styled } from '@mui/material/styles';
let tempLoadingData = null;
let apiController = null;

const VolumeSlider = styled(Slider)({
  color: '#52af77',
  width: '10px',
  '& .MuiSlider-track': {
    border: 'none',
    background: 'transparent'
  },
  '& .MuiSlider-rail': {
    background: 'linear-gradient(355deg, #35e15afa, #ff0303)'
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    position: 'relative',
    right: '1px',
    top: '25px',
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: '1px',
    width: 32,
    borderRadius: '50% 50% 50% 50%',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const volumeMmarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 150,
    label: '150',
  },
  {
    value: 200,
    label: '200',
  },
];

export const VideoController = () => {
  const dispatch = useDispatch();
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const [webGLContext, setWebGLContext] = useWebGL();
  const ProjectData = useSelector(store => store.projectdata.projectJSON);
  const selectedID = useSelector((store) => store.webgldata.selectedItemID);
  const Time_Count = useSelector((store) => store.videodata.Time_Count);
  const VideoPlaying = useSelector((store) => store.videodata.videoPlaying);
  const videoPlayerStarted = useSelector((store) => store.videodata.videoPlayerStarted);
  const videoBuffering = useSelector((store) => store.videodata.videoBuffering);
  const zoomRate = useSelector((store) => store.videodata.zoomRate);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const audioElements = useSelector(store => store.webgldata.audioElements);
  const imageElements = useSelector(store => store.webgldata.imageElements);
  const timelineFit = useSelector(store => store.projectdata.timelineFit);
  const selectedGroupID = useSelector(store => store.webgldata.selectedGroupID);
  const thumbNails = useSelector(store => store.projectdata.thumbNails);
  const editSubtitle = useSelector(store => store.projectdata.editSubtitle);
  const sequenceNumber = useSelector((store) => store.projectdata.sequenceNumber);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const projectUUID = useSelector((store) => store.projectdata.projectUUID);
  const projectVolume = useSelector((store) => store.projectdata.projectVolume);
  const fullScreenFlag = useSelector((store) => store.projectdata.fullScreenFlag);
  const stretchScreenFlag = useSelector((store) => store.projectdata.stretchScreenFlag);
  const uploadingFiles = useSelector((store) => store.projectdata.uploadingFiles);

  const [activeType, setActiveType] = useState('')
  const [activevideo, setActiveVideo] = useState(null)
  const [activeElement, setActiveElement] = useState(null);
  const [subtitleFlag, setSubtitleFlag] = useState(false)

  useEffect(() => { tempLoadingData = { ...uploadingFiles } }, [uploadingFiles]);

  useEffect(() => {
    let tempFlag = false

    if (selectedID && ProjectData) {
      let tempType = getSelectedType(selectedID, ProjectData);
      if (tempType === 'text') {
        let textItem = ProjectData.text[selectedID]
        if (textItem.subtitle_uuid) {
          tempFlag = true
        }
      }
    }

    if (subtitleFlag !== tempFlag) {
      setSubtitleFlag(tempFlag)
    }
  }, [selectedID, ProjectData])

  useEffect(() => {
    let tempType = ''
    let activeVideo = null

    if (selectedID && ProjectData) {
      tempType = getSelectedType(selectedID, ProjectData);
      if(tempType){
        let acEle = ProjectData[tempType][selectedID];
        setActiveElement(acEle);
      }
    }

    if (tempType === 'videos') {
      activeVideo = ProjectData.videos[selectedID]
    }

    setActiveVideo(activeVideo)

    if (tempType !== activeType) {
      setActiveType(tempType)
    }
  }, [selectedID, ProjectData])

  const editSubtitleClick = () => {
    if (subtitleFlag && !editSubtitle) {
      dispatch(editSubtitle_Store(true))
    }
  }

  const changeDisable = ({ target }) => {
    if (activeType !== 'videos') {
      return
    }

    let checked = target.checked
    let tempJSON = copyObject(ProjectData)
    let activeVideo = tempJSON.videos[selectedID]

    if (!activeVideo || activeVideo?.disable_video === checked) {
      return
    }

    activeVideo.disable_video = checked
    dispatch(ProjectJSON_Store(tempJSON))
    changeZeroSecond(activeVideo, dispatch)
  }

  const [isPlayAction, setIsPlayAction] = useState(true);
  const VideoPlayFunc = (status) => {
    /*let viKeys = Object.keys(videoElements);
    viKeys.map(item => {
      console.log(videoElements[item], videoElements[item].readyState);
    })*/
    if(isPlayAction){
      dispatch(SaveVideoPlayStarted_Store(status))
      dispatch(SaveVideoPlaying_Store(status));
      setIsPlayAction(false);
      setTimeout(function (){setIsPlayAction(true);}, 100)
    }
  }

  const isKeyboardPlaying = (e) => {
    if(e.target.nodeName == "BODY"){
      if(e.code == "Space" ){
        if(videoPlayerStarted && VideoPlaying){
          dispatch(SaveVideoPlayStarted_Store(false))
          dispatch(SaveVideoPlaying_Store(false));
        }else{
          dispatch(SaveVideoPlayStarted_Store(true))
          dispatch(SaveVideoPlaying_Store(true));
        }  
      }
      if(e.code == 'Backspace'){
        if(selectedID){
          ItemRemoveFunc();
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', isKeyboardPlaying, false);
    return () => {
        window.removeEventListener("keyup", isKeyboardPlaying)
    }
  }, [videoPlayerStarted, VideoPlaying, selectedID]);

  useEffect(() => {
    if(videoBuffering){
      //setIsPlayAction(false);
    //  dispatch(SaveVideoPlaying_Store(false));

    }else{
      //setIsPlayAction(true);
    }
  }, [videoBuffering])

  const ZoomChange = async (v) => {
    const value = await checkZoomValue(v);
    if (!value && value !== 0) return;
    dispatch(SaveZoomRate_Store(value));
  }

  const ItemRemoveFunc = async () => {
    if(Array.isArray(selectedID)){
      selectedID.forEach(async (idItem) => {
        const [newJSONdata, deleteItem] = await removeElement(ProjectData, idItem);
   
        if (!!newJSONdata) {
          dispatch(SelectedItemID_Store(null));
          dispatch(ProjectJSON_Store(newJSONdata));
          changeZeroSecond(deleteItem, dispatch)
        }
      });
    }else{
      const [newJSONdata, deleteItem] = await removeElement(ProjectData, selectedID);
   
      if (!!newJSONdata) {
        dispatch(SelectedItemID_Store(null));
        dispatch(ProjectJSON_Store(newJSONdata));
        changeZeroSecond(deleteItem, dispatch)
      }
    }
    
  }

  const splitFunction = async () => {
    const newUUID = uuidv4();
    const [newJSONdata, type] = await splitElementCreate(ProjectData, selectedID, newUUID, Time_Count);
    if (!newJSONdata) return;

    if (type === 'videos') {
      if (!videoElements[selectedID]) return;
      let newVideoEL = videoElements[selectedID]; //.cloneNode(true);
      newVideoEL.id = newUUID;

      dispatch(VideoElements_Store({
        ...videoElements,
        [newUUID]: newVideoEL
      }))

      if (thumbNails[selectedID]) {
        dispatch(thumbNails_Store({
          ...thumbNails,
          [newUUID]: thumbNails[selectedID]
        }))
      }
    }

    if (type === 'audios') {
      if (!audioElements[selectedID]) return;
      let newAudioEl = audioElements[selectedID].cloneNode(true);
      newAudioEl.id = newUUID;

      dispatch(audioElements_Store({ ...audioElements, [newUUID]: newAudioEl }))
    }

    if (type === 'images') {
      if (!imageElements[selectedID]) return;
      let newImageEL = imageElements[selectedID].cloneNode(true);
      newImageEL.id = newUUID;

      let tempImageEL = Object.assign({}, imageElements);
      tempImageEL[newUUID] = newImageEL;
      await dispatch(imageElements_Store(tempImageEL));
    }

    dispatch(ProjectJSON_Store(newJSONdata));
  }

  const duplicateFunction = async () => {
    const newUUID = uuidv4();
    const [newJSONdata, type] = await duplicateElementCreate(ProjectData, selectedID, newUUID);
    if (!newJSONdata) return;

    if (type === 'videos') {
      if (!videoElements[selectedID]) return;
      let newVideoEL = videoElements[selectedID];
      newVideoEL.id = newUUID;

      dispatch(VideoElements_Store({
        ...videoElements,
        [newUUID]: newVideoEL
      }))

      if (thumbNails[selectedID]) {
        dispatch(thumbNails_Store({
          ...thumbNails,
          [newUUID]: thumbNails[selectedID]
        }))
      }
    }

    if (type === 'audios') {
      if (!audioElements[selectedID]) return;
      const newAudioEl = audioElements[selectedID].cloneNode(true);
      newAudioEl.id = newUUID;
      
      dispatch(audioElements_Store({ ...audioElements, [newUUID]: newAudioEl }))
    }

    if (type === 'images') {
      if (!imageElements[selectedID]) return;
      let newImageEL = imageElements[selectedID].cloneNode(true);
      newImageEL.id = newUUID;

      let tempImageEL = Object.assign({}, imageElements);
      tempImageEL[newUUID] = newImageEL;
      await dispatch(imageElements_Store(tempImageEL));
    }

    dispatch(ProjectJSON_Store(newJSONdata));
  }

  const levelUpDown = (flag) => {
    if (!selectedID) return;
    if (flag !== 1 && flag !== -1) return;

    let tempJSON = JSON.parse(JSON.stringify(ProjectData));
    let type = getSelectedType(selectedID, tempJSON);
    if (!type || !tempJSON[type][selectedID]) return;

    let elementOb = tempJSON[type][selectedID];
    let zIndex = getNextZIndex(tempJSON, selectedID, type, flag)
    let currentIndex = elementOb.coordinate.zIndex;
    if (currentIndex === zIndex) return;

    elementOb.coordinate.zIndex = zIndex;
    dispatch(ProjectJSON_Store(tempJSON));
    changeZeroSecond(elementOb, dispatch)
  }

  const transitionFunc = () => {
    let tsManual = webGLContext.tsManual;
    let temptsManual = { type: "", state: !tsManual.state, tsID: "", clashID: "", flag: "" }
    setWebGLContext({ ...webGLContext, tsManual: temptsManual });
    if (!!selectedID) dispatch(SelectedItemID_Store(null));
  }

  // video volume change func start
  const [volumeValue, setVolumeValue] = useState(0);
  const [volumeMenu, setVolumeMenu] = useState(null);
  const closeVolumnMenu = () => { setVolumeMenu(null); };

  useEffect(() => {
    if (selectedID && ProjectData) {
      let tempType = getSelectedType(selectedID, ProjectData);

      if (tempType === 'videos' || tempType === 'audios') {
        let tempObject = ProjectData[tempType][selectedID]
        let value = checkVolumeValue(tempObject.volume);
        setVolumeValue(value);
      } else setVolumeValue(0);
    }
  }, [selectedID, ProjectData])

  const openVolumnMenu = async ({ currentTarget }) => {
    if (activeType === 'videos' || activeType === 'audios') {
      setVolumeMenu(currentTarget)
    } else {
      window.toastr.error('could you please select Video Item?');
      closeVolumnMenu();
    }
  }

  const changedVolume = async () => {
    if (activeType === 'videos' || activeType === 'audios') {
      const newJSONdata = Object.assign({}, ProjectData);
      let tempObject = newJSONdata[activeType][selectedID]
      tempObject.volume = volumeValue;

      newJSONdata[activeType] = { ...newJSONdata[activeType], [selectedID]: tempObject };
      dispatch(ProjectJSON_Store(newJSONdata));
    } else setVolumeMenu(null);
  }
  // video volume change func end

  // opacity change func start
  const [opacity, setOpacity] = useState(0);
  const [opacityMenu, setOpacityMenu] = useState(null);

  useEffect(() => {
    if (!selectedID) return;
    let type = getSelectedType(selectedID, ProjectData);
    if (!type || !ProjectData[type][selectedID]) { setOpacity(0); return; }
    let selectedItem = ProjectData[type][selectedID];
    setOpacity(selectedItem.alpha)
  }, [selectedID, ProjectData])

  const changedOpacity = async () => {
    let tempJSON = copyObject(ProjectData);
    if (!selectedID) { setOpacity(0); return; }
    let tempType = getSelectedType(selectedID, tempJSON);

    let selectedOb = tempJSON[tempType];
    if (!selectedOb || !selectedOb[selectedID]) {
      setOpacity(1);
      return;
    }

    let elementOb = tempJSON[tempType][selectedID]
    if (elementOb.alpha !== opacity) {
      elementOb.alpha = opacity;
      dispatch(ProjectJSON_Store(tempJSON));
      changeZeroSecond(elementOb, dispatch)
    }
  }
  // opacity change func end

  //video speed change func
  const [vSpeedVal, setVSpeedVal] = useState(0);
  const [speedMenu, setSpeedMenu] = useState(null);

  useEffect(() => {
    if(!selectedID) return;
    let type = getSelectedType(selectedID, ProjectData);
    if(!type || !ProjectData[type][selectedID]) {setVSpeedVal(1); return;}
    let selectedItem = ProjectData[type][selectedID];
    if(selectedItem.speed){
      setVSpeedVal(selectedItem.speed);
    }else{
      setVSpeedVal(1);
    }
    
  }, [selectedID, ProjectData])
  
  const changedVSpeed = async () => {
    let tempJSON = copyObject(ProjectData);
    if(!selectedID) {setVSpeedVal(1); return; }
    let tempType = getSelectedType(selectedID, tempJSON);

    let selectedOb = tempJSON[tempType];
    if (!selectedOb || !selectedOb[selectedID]) {
      setVSpeedVal(1);
      return;
    }

    let elementOb = tempJSON[tempType][selectedID]
    if(elementOb?.speed !== vSpeedVal) {
      
      const groupElements = getGroupoptions(tempJSON);
      
      const selectedGroupData = groupElements[elementOb.groups]; 
      const selectedGroupitemsKeys = Object.keys(groupElements[elementOb.groups]);
      
      videoElements[selectedID].playbackRate = vSpeedVal;
      selectedGroupitemsKeys.map(itemKey => {
        if(itemKey != selectedID){
          let checkType = getSelectedType(itemKey, tempJSON);
          let checkEleObj = tempJSON[checkType][itemKey];
          if(checkEleObj.timeline_start > elementOb.timeline_start && (vSpeedVal / elementOb.speed) < 1){
            checkEleObj.timeline_start = ((elementOb.duration / (vSpeedVal / elementOb.speed)) - elementOb.duration) + checkEleObj.timeline_start;
            checkEleObj.timeline_end = checkEleObj.timeline_start + checkEleObj.duration;
          }
        }
      })
      elementOb.timeline_end = (elementOb.duration / (vSpeedVal / elementOb.speed));
      elementOb.duration = elementOb.duration / (vSpeedVal / elementOb.speed);
      elementOb.speed = vSpeedVal;
      dispatch(ProjectJSON_Store(tempJSON));
      changeZeroSecond(elementOb, dispatch)
    }
  }
  //video speed change func end
  

  // project volume change func start
  const [projectVolumeValue, setProjectVolumeValue] = useState(1);
  const [projectVolumeMenu, setProjectVolumeMenu] = useState(null);
  const openProjectVolumnMenu = async (e) => { setProjectVolumeMenu(e.currentTarget); }
  const closeProjectVolumnMenu = () => { setProjectVolumeMenu(null); };

  useEffect(() => {
    if (projectVolume !== projectVolumeValue) {
      setProjectVolumeValue(projectVolume);
      // dispatch(ProjectJSON_Store(tempJSON));
    }
  }, [projectVolume])

  const projectVolumeChanged = () => {
    if (projectVolume !== projectVolumeValue) {
      let tempJSON = copyObject(ProjectData);
      tempJSON.masteraudiolevel = projectVolumeValue;
      updateProjectJSON(projectUUID, {
        ...tempJSON
      });
      dispatch(projectvolume_store(projectVolumeValue))
    }
  }
  // project volume change func end

  const nextTimeFunc = async (type) => {
    const nextT = await nextTimeCalc(Time_Count, type);
    if (!nextT && nextT !== 0) return;

    checkVideoPlay()
    dispatch(TimeCount_Store(nextT));
  }

  const firstTimeFunc = async () => {
    checkVideoPlay()
    dispatch(TimeCount_Store(new Date(0)))
  }

  const endTimeFunc = async () => {
    if (!ProjectData) return

    let items = {
      ...ProjectData.text,
      ...ProjectData.videos,
      ...ProjectData.elements,
    }

    let keys = Object.keys(items)
    if (keys.length < 1) return

    let duration = ProjectData?.duration
    let endTime = new Date(duration - 1)
    if (duration < 1) return

    checkVideoPlay()
    dispatch(TimeCount_Store(endTime))
  }

  const [existsVideo, setExistsVideo] = useState(false);
  useEffect(() => {
    if (!ProjectData.videos) setExistsVideo(false);
    else {
      const videoKeys = Object.keys(ProjectData.videos);
      setExistsVideo(!!videoKeys.length);
    }
  }, [ProjectData]);

  const menubarRef = useSelector(store => store.editdata.menubarRef);
  const addVideoClick = () => { menubarRef.children[2].click(); }
  const fitOnClick = () => { dispatch(timelineFit_Store(!timelineFit)); }

  const [selected, setSelected] = useState({})
  useEffect(() => {
    let tempSelected = {}
    if (!!selectedID) {
      let type = getSelectedType(selectedID, ProjectData);
      if(!ProjectData[type]) return;
      let tempItem = ProjectData[type][selectedID]

      if (!!type && !!tempItem)
        tempSelected = tempItem
    }

    setSelected(tempSelected)
  }, [selectedID, ProjectData])

  const checkVideoPlay = () => {
    if (VideoPlaying) {
      dispatch(loading_Store(true))
      dispatch(SaveVideoPlaying_Store(false))

      setTimeout(() => {
        dispatch(loading_Store(false))
        dispatch(SaveVideoPlaying_Store(true))
      }, 1000)
    }
  }

  useEffect(() => {
    if (!selectedID) {
      dispatch(selectedGroupID_Store(null))
    }
  }, [selectedID])

  
  const removeGroup = () => {
    if (!ProjectData?.videos || !selectedGroupID) {
      return
    }

    const tempJSON = copyObject(ProjectData)
    const videos = tempJSON.videos
    const audios = tempJSON.audios
    const images = tempJSON.images
    const texts = tempJSON.text

    const videoKeys = Object.keys(videos)
    const audiokeys = Object.keys(audios)
    const imageKeys = Object.keys(images)
    const textKeys = Object.keys(texts)

    const tempVideos = {}
    const tempAudios = {}
    const tempImages = {}
    const tempTexts = {}

    let removeObject = null

    videoKeys.forEach((key) => {
      const videoEl = videos[key]
      if (videoEl.groups !== selectedGroupID) {
        tempVideos[key] = videoEl
      } else if (videoEl.timeline_start === 0) {
        removeObject = videoEl
      }
    })

    audiokeys.forEach((key) => {
      const audioEl = audios[key]
      if (audioEl.groups !== selectedGroupID) {
        tempAudios[key] = audioEl
      } else if (audioEl.timeline_start === 0) {
        removeObject = audioEl
      }
    })

    imageKeys.forEach((key) => {
      const imageEl = images[key]
      if (imageEl.groups !== selectedGroupID) {
        tempImages[key] = imageEl
      } else if (imageEl.timeline_start === 0) {
        removeObject = imageEl
      }
    })

    textKeys.forEach((key) => {
      const textEl = texts[key]
      if (textEl.groups !== selectedGroupID) {
        tempTexts[key] = textEl
      } else if (textEl.timeline_start === 0) {
        removeObject = textEl
      }
    })

    let tempNewJson = {
      ...tempJSON,
      videos: tempVideos,
      audios: tempAudios,
      images: tempImages,
      text: tempTexts,
    }

    dispatch(SelectedItemID_Store(null))
    dispatch(selectedGroupID_Store(null))
    dispatch(ProjectJSON_Store(tempNewJson))

    if (!!removeObject) {
      changeZeroSecond(removeObject, dispatch)
    }
  }

  const settingBackFunc = async (flag) => {
    if (sequenceNumber.c === undefined) return;

    let type = 'undo';
    const sequence = sequenceNumber.c + flag;
    if (sequence < 0 || sequenceNumber.m < sequence) return;
    if(apiController != null){
      apiController.abort();
    }
     
    dispatch(sequenceNum_Store({ ...sequenceNumber, c: sequence }));
    dispatch(sequenceStatus_Store(false));
    apiController = new AbortController();
    let response = await getProjectHistory(projectUUID, type, sequence, apiController);
    let history = response.data?.history;
    if (response.state !== "success" || !history) return;

    let sequenceNum = response.data.sequence;
    let newJSON = { ...history, sequence: sequenceNum, asset: ProjectJSON.asset };
    dispatch(sequenceNum_Store({ ...sequenceNumber, c: sequenceNum }));
    dispatch(sequenceStatus_Store(false));
    apiController = null;
    dispatch(ProjectJSON_Store(newJSON));
  }

  const onFullScreen = () => {
    if (!fullScreenFlag) {
      dispatch(fullScreenFlag_store(true))
    }
  }

  const stretchScreenVideo = async () => {
    const [newJSONdata, type] = await fullVideoSize(ProjectData, selectedID);
    if (type === 'videos' || type === 'images'){
      dispatch(StretchScreenFlag_store(!stretchScreenFlag))
    }
  }
  const [isGreenVideo, setIsGreenVideo] = useState(false);
  const setGreenScreenVideo = () => {
    if(isGreenVideo == false){
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Do you want to upoad new behind media?",
        showConfirmButton: true,
        confirmButtonText: "upload Image",
        //showDenyButton: true,
        //denyButtonText: 'upload Video',
        showCancelButton: true,
        cancelButtonText: "No",
        }).then((result) => {
        if (result.isConfirmed) {
          document.getElementById("behindImg").click();
          window.isGreenVideo = true;
          setIsGreenVideo(true);    
        }else if(result.isDismissed){
          document.getElementById("greenBg").style.display = 'block';
          window.isGreenVideo = true;
          setIsGreenVideo(true);    
        }
      });
    }else{
      document.getElementById("greenBg").style.display = 'none';
      window.isGreenVideo = false;
      setIsGreenVideo(false);
    }
    
  }

  const chooseBehindImgFunc = (e) => {
    if (!e || !e.target || !e.target.files[0]) {
      window.toastr.warning('file select error')
      return
    }
    if (FileReader && e.target.files && e.target.files.length) {
      var fr = new FileReader();
      fr.onload = function () {
          document.getElementById("greenBg").src = fr.result;
          document.getElementById("greenBg").style.display = 'block';
          window.isGreenVideo = true;
          setIsGreenVideo(true);
      }
      fr.readAsDataURL(e.target.files[0]);
    }
  
  }

  const onFreezeFrame = async () => {
    const blobImg = await freezeFrameCreate(ProjectJSON, selectedID, videoElements[selectedID], Time_Count);
  
    const newUUID = uuidv4();
    const newImgUrl = URL.createObjectURL(blobImg);
    const originalFileName = 'newImage.png';
    const mimeType = 'images/png';

    let fileData = { pc: 0, type: 'image', name: originalFileName, state: false }
    dispatch(uploadingFiles_Store({ ...uploadingFiles, [newUUID]: fileData }));
    dispatch(dataLoaded_Store(false));

    let maxZIndex = getMaxZIndex(ProjectJSON);
    let props = [ProjectJSON, originalFileName, mimeType, newImgUrl, newUUID, projectUUID, 'image', maxZIndex]
    await getImageInformation(...props, async (img, imageInfo) => {
      let imgThumbNail = [{ uuid: uuidv4(), content_type: 'blob', signed_url: newImgUrl }];
      dispatch(thumbNails_Store({ ...thumbNails, [imageInfo.asset_uuid]: imgThumbNail }));
      let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      let startTime = Time_Count.getTime()

      imageInfo = {
        ...imageInfo,
        timeline_start: startTime,
        timeline_end: imageInfo.duration + startTime
      }

      changedJSON.images = {
        [newUUID]: imageInfo
      }

      dispatch(imageElements_Store({ ...imageElements, [newUUID]: img }));
      dispatch(changedJSON_Store(changedJSON));
      changeZeroSecond(imageInfo, dispatch)
    }, async (newAssets, assetUUID) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      // if (!tempProjectJSON.images[newUUID]) return;
      //changedData.images[newUUID] = tempProjectJSON.images[newUUID]

      const JSONdata = await getProjectJSON(projectUUID);
      const projectData = JSONdata?.data[projectUUID];

      if (JSONdata.state !== 'success' || SERVER_STATUS === 'localhost')
        changedData = { ...changedData, asset: { ...newAssets } };
      else changedData = { ...changedData, asset: projectData.asset };

      let fileItem = { ...tempLoadingData[newUUID], state: true };
      dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
      dispatch(changedJSON_Store(changedData));
    }, () => { dispatch(dataLoaded_Store(true)); }, (e) => {
      let pc = parseFloat(e.loaded / e.total * 100).toFixed(2);
      let fileItem = { ...tempLoadingData[newUUID], pc: Number(pc) };
      dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
    });
    
  }

  const onFadeOutAudio = async () => {
    const tempJSON = copyObject(ProjectData)
    const tempType = getSelectedType(selectedID, tempJSON);
    let tempEle = tempJSON[tempType][selectedID];
    if(tempEle?.fade_out_audio){
      tempEle.fade_out_audio = false;
    }else{
      tempEle.fade_out_audio = true;
    }
    dispatch(ProjectJSON_Store(tempJSON));
  }

  const onFadeInAudio = async () => {
    const tempJSON = copyObject(ProjectData)
    const tempType = getSelectedType(selectedID, tempJSON);
    let tempEle = tempJSON[tempType][selectedID];
    if(tempEle?.fade_in_audio){
      tempEle.fade_in_audio = false;
    }else{
      tempEle.fade_in_audio = true;
    }
    dispatch(ProjectJSON_Store(tempJSON));
  }

  return (
    <Box className='v-video-controller v-center'>
      <Grid container spacing={0} className='v-controller-wrapper'>
        <Grid item xs={4} md={6} className='controller-containner z-[1]'>
          <Box className='controller-items-containner v-left'>
            {!existsVideo && <Box className='controller-item left-item' onClick={addVideoClick}>
              <AddCircleOutlineIcon className='flex-1' />
              <Typography component='h2' >{languageStrs.add_video}</Typography>
            </Box>}
            {/* <input type='file' id='behindVideo' onChange={chooseBehindImgFunc} style={{display: 'none'}} accept="image/*"/> */}
            <ZoomOutIcon className="zoom-control-btn"
              onClick={() => { ZoomChange(zoomRate - 1) }}
            />

            <ZoomInIcon className="zoom-control-btn"
              onClick={() => { ZoomChange(zoomRate + 1) }}
            />

            {!!selectedID && <>
              {/* <Box className='controller-item left-item'>
                <FaHourglassEnd onClick={transitionFunc} className='flex-1 p-3' />
                <Typography component='h2'>Transition</Typography>
              </Box> */}
              {!Array.isArray(selectedID) &&  (<Box className='controller-item left-item' component='span'>
                <ContentCopyIcon onClick={duplicateFunction} className='flex-1' />
                <Typography component='h2'>{languageStrs.duplicate}</Typography>
              </Box>)}

              {!Array.isArray(selectedID) &&  (<Box className='controller-item left-item' component='span'>
                <ContentCutIcon onClick={splitFunction} className='flex-1' />
                <Typography component='h2'>{languageStrs.splite}</Typography>
              </Box>)}

              <Box className='controller-item left-item' component='span'>
                <DeleteOutlineIcon className='flex-1 v-cener' onClick={ItemRemoveFunc} />
                <Typography component='h2'>{languageStrs.remove}</Typography>
              </Box>

              {!Array.isArray(selectedID) && activeType !== 'audios' && (
                <Box className='controller-item left-item' component='span'>
                  <div className='flex-1 flex flex-row gap-5 items-center px-5'>
                    <ArrowUpwardOutlinedIcon onClick={() => { levelUpDown(1); }} />
                    <ArrowDownwardOutlinedIcon onClick={() => { levelUpDown(-1); }} />
                  </div>
                  <Typography component='h2'>
                    {languageStrs.layer} {selected?.coordinate?.zIndex + 9}
                  </Typography>
                </Box>
              )}

              {!Array.isArray(selectedID) &&  (<Box className='controller-item left-item' component='span'>
                <VolumeUp className="flex-1 video-control-btn" onClick={openVolumnMenu} />
                <Typography component='h2'>{languageStrs.volume}</Typography>

                <Menu
                  anchorEl={volumeMenu}
                  open={Boolean(volumeMenu)}
                  onClose={() => { setVolumeMenu(null); }}
                  transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  className='volume-menu-wrapper'
                >
                  <Box className='volume-menu-contain'>
                    <Stack height="100%" direction="row" alignItems="end" useFlexGap spacing={1}>
                      <Stack direction="column">
                        <IconButton onClick={() => {onFadeInAudio()}} color={activeElement?.fade_in_audio? 'primary' : 'default'}>
                          <SignalCellularAltIcon />  
                        </IconButton>
                      </Stack>
                      <Stack height="100%" direction="column">
                        <VolumeUp className='menu-icon-top' />

                        <Slider
                          sx={{ '& input[type="range"]': { WebkitAppearance: 'slider-vertical' } }}
                          orientation="vertical"
                          value={volumeValue}
                          step={0.1} min={0} max={1}
                          marks={volumeMarks}
                          onChange={(e) => { setVolumeValue(e.target.value) }}
                          onChangeCommitted={changedVolume}
                        />

                        <VolumeOffIcon className='menu-icon-bottom' />
                      </Stack>
                      <Stack direction="column">
                        <IconButton onClick={() => {onFadeOutAudio()}} color={activeElement?.fade_out_audio? 'primary' : 'default'}>
                          <SignalCellularAltIcon sx={{ transform: 'scaleX(-1)'}}/>  
                        </IconButton>
                      </Stack>
                    </Stack>
                    
                  </Box>
                </Menu>
              </Box>)}


              {!Array.isArray(selectedID) && activeType !== 'audios' && (
                <Box className='controller-item left-item' component='span'>
                  <OpacityIcon className="flex-1 video-control-btn" onClick={(e) => { setOpacityMenu(e.currentTarget); }} />
                  <Typography component='h2'>{languageStrs.opacity}</Typography>

                  <Menu
                    anchorEl={opacityMenu}
                    open={Boolean(opacityMenu)}
                    onClose={() => { setOpacityMenu(null); }}
                    transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    className='volume-menu-wrapper'
                  >
                    <Box className='volume-menu-contain'>
                      <VolumeUp className='menu-icon-top' />

                      <Slider
                        sx={{ '& input[type="range"]': { WebkitAppearance: 'slider-vertical' } }}
                        onChange={(e) => { setOpacity(e.target.value) }}
                        orientation="vertical" step={0.1} min={0} max={1}
                        value={opacity} marks={volumeMarks}
                        onChangeCommitted={changedOpacity}
                      />

                      <VolumeOffIcon className='menu-icon-bottom' />
                    </Box>
                  </Menu>
                </Box>
              )}

              {!Array.isArray(selectedID) && activeType == 'videos' && (
                <Box className='controller-item left-item' component='span'>
                  <FastForwardIcon className="flex-1 video-control-btn" onClick={(e) => { setSpeedMenu(e.currentTarget); }} />
                  <Typography component='h2'>Speed</Typography>

                  <Menu
                    anchorEl={speedMenu}
                    open={Boolean(speedMenu)}
                    onClose={() => { setSpeedMenu(null); }}
                    transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    className='volume-menu-wrapper'
                  >
                    <Box className='volume-menu-contain'>
                      <VolumeUp className='menu-icon-top' />

                      <Slider
                        sx={{ '& input[type="range"]': { WebkitAppearance: 'slider-vertical' } }}
                        onChange={(e) => { setVSpeedVal(e.target.value) }}
                        orientation="vertical" step={0.25} min={0.25} max={4}
                        value={vSpeedVal} marks={speedMarks}
                        onChangeCommitted={changedVSpeed}
                      />

                      <VolumeOffIcon className='menu-icon-bottom' />
                    </Box>
                  </Menu>
                </Box>
              )}

              {activeType === 'videos' && !Array.isArray(selectedID) && (<>
                <Box component='span' className='controller-item left-item' onClick={() => onFreezeFrame()}>
                  <LocalMoviesIcon className='flex-1'/>
                  <Typography component='h2' >freeze</Typography>
                </Box>
                <span className='controller-item left-item'>
                  <input type='checkbox'
                    onChange={changeDisable}
                    checked={!!activevideo?.disable_video}
                    className='default-input flex-1'
                  />
                  <Typography component='h2'>
                    {languageStrs.disable}
                  </Typography>
                </span>
                <Box className='controller-item left-item' onClick={() => {setGreenScreenVideo()}}>
                  {isGreenVideo && <ConnectedTvOutlinedIcon className='flex-1' />}
                  {!isGreenVideo && <AddToQueueIcon className='flex-1' />}
                  <Typography component='h2' >{languageStrs.green_screen}</Typography>
                </Box>
                <input type='file' id='behindImg' onChange={chooseBehindImgFunc} style={{display: 'none'}} accept="image/*"/>
              </>)}
              
            </>}

            {selectedGroupID && !Array.isArray(selectedID) && (
              <>
                <Box component='span'
                  className='controller-item left-item'
                >
                  <FolderDeleteIcon onClick={removeGroup} className='flex-1 v-cener' />
                  <Typography component='h2'>{languageStrs.deleterow}</Typography>
                </Box>
                <Box component='span' className='controller-item left-item'>
                  <ZoomOutMapOutlinedIcon onClick={stretchScreenVideo} className='flex-1 v-cener' />
                  <Typography component='h2'>{languageStrs.fullscreen}</Typography>
                </Box>
              </>
            )}

            {/* {subtitleFlag && !Array.isArray(selectedID) && (
              <Box component='span'
                className='controller-item left-item'
              >
                <AutoFixHighIcon onClick={editSubtitleClick} className='flex-1 v-cener' />
                <Typography component='h2'>{languageStrs.editsubtitle}</Typography>
              </Box>
            )} */}
          </Box>
        </Grid>

        <Grid item xs={5} md={3} className='controller-containner'>
          <Box className='controller-items-containner v-center'>
            <Box className='controller-item video-nextBefore-btn'>
              <SkipPreviousIcon className="video-control-btn" onClick={firstTimeFunc} />
            </Box>

            <Box className='controller-item video-nextBefore-btn'>
              <KeyboardDoubleArrowLeftIcon className="video-control-btn"
                onClick={() => { nextTimeFunc('before') }}
              />
            </Box>

            <Box className='controller-item'>
              {videoPlayerStarted ? (
                <Box className={isPlayAction? 'video-playPause-btn': 'video-playPause-btn disable-btn'} onClick={(() => { VideoPlayFunc(false); })}><PauseIcon /></Box>
              ) : (
                <Box className={isPlayAction? 'video-playPause-btn': 'video-playPause-btn disable-btn'} onClick={(() => { VideoPlayFunc(true); })}><PlayArrowIcon /></Box>
              )}
            </Box>

            <Box className='controller-item time-count'>
              {Time_Count.MMSSM()}
            </Box>

            <Box className='controller-item video-nextBefore-btn'>
              <KeyboardDoubleArrowRightIcon className="video-control-btn"
                onClick={() => { nextTimeFunc('next') }}
              />
            </Box>

            <Box className='controller-item video-nextBefore-btn'>
              <SkipNextIcon className="video-control-btn" onClick={endTimeFunc} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={3} md={3} className='controller-containner'>
          <Box className='controller-items-containner v-right'>
            <Box className='controller-item'>
              <Box className='inline-flex sm:hidden'>
                <Box className='v-center items-end gap-2 pointer'>
                  <Badge onClick={() => { settingBackFunc(-1); }} badgeContent={sequenceNumber.c?sequenceNumber.c:'0'} size="small" color="secondary">
                    <ReplayIcon
                      className='-rotate-45'
                    />
                  </Badge>
                  <span className='hidden mobile:flex text-12'>
                    {languageStrs.redo}
                  </span>
                </Box>

                <Box className='v-center items-end gap-2 pointer'>
                  <Badge onClick={() => { settingBackFunc(1); }} size="small" badgeContent={(sequenceNumber.m-sequenceNumber.c)?(sequenceNumber.m-sequenceNumber.c):'0'} color="secondary">
                    <ReplayIcon
                      
                      style={{ WebkitTransform: 'rotatey(180deg) rotate(-45deg)' }}
                    />
                  </Badge>
                  
                  <span className='hidden mobile:flex text-12'>
                  {languageStrs.undo}
                  </span>
                </Box>
              </Box>
            </Box>

            <Box className='controller-item'>
              <VolumeUp className="video-control-btn" onClick={openProjectVolumnMenu} />

              <Menu
                anchorEl={projectVolumeMenu}
                open={Boolean(projectVolumeMenu)}
                onClose={closeProjectVolumnMenu}
                transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                className='volume-menu-wrapper'
              >
                <Box className='volume-menu-contain'>
                  <VolumeUp className='menu-icon-top' />

                  <VolumeSlider orientation="vertical"
                    sx={{ '& input[type="range"]': { WebkitAppearance: 'slider-vertical' } }}
                    onChange={(e) => { setProjectVolumeValue(e.target.value) }}
                    onChangeCommitted={projectVolumeChanged}
                    valueLabelDisplay="on"
                    value={projectVolumeValue}
                    step={1} min={0} max={200}
                    marks={volumeMmarks}
                  />

                  <VolumeOffIcon className='menu-icon-bottom' />
                </Box>
              </Menu>
            </Box>

            <Box className='controller-item' component='span'>
              <ZoomOutIcon className="zoom-control-btn"
                onClick={() => { ZoomChange(zoomRate - 1) }}
              />

              <Stack spacing={0} direction="row" className='zoom-slider'>
                <Slider step={1}
                  value={zoomRate}
                  min={-10} max={10}
                  marks={zoomMarks}
                  aria-label="Volume"
                  onChange={(e) => { ZoomChange(e.target.value) }}
                />
              </Stack>

              <ZoomInIcon className="zoom-control-btn"
                onClick={() => { ZoomChange(zoomRate + 1) }}
              />
            </Box>

            <Typography component='h4' className='pointer' onClick={fitOnClick} style={{ userSelect: 'none' }}>
              {languageStrs.fit}
            </Typography>

            <div className='flex flex-col cursor-pointer' onClick={onFullScreen}>
              <Fullscreen className='text-20' />
            </div>

            {/* <Box className='controller-item' component='span'>
              <svg width="16px" height="16" viewBox="0 0 16 16" fill="none" color="#9094a5"><path d="M14.6668 8H12.0002L10.0002 14L6.00016 2L4.00016 8H1.3335" stroke="currentColor" strokwidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}