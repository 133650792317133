import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Stack, Autocomplete, Slider, Select, MenuItem } from '@mui/material';
import { Radio, RadioGroup, FormControl, FormControlLabel, TextField, Divider, Checkbox } from '@mui/material';
import TabsUnstyled from '@mui/base/TabsUnstyled';

import PanoramaFishEyeOutlinedIcon from '@mui/icons-material/PanoramaFishEyeOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import VerticalAlignCenterOutlinedIcon from '@mui/icons-material/VerticalAlignCenterOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import HexagonSharpIcon from '@mui/icons-material/HexagonSharp';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import HeightIcon from '@mui/icons-material/Height';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

import { SectionHeader } from './common/section.header';
import { Tab, TabPanel, TabsList } from './common/section.tabbar';
import { IOSSwitch } from './common/section.switch';
import { ColorPicker } from './common/colorPicker';

import { useGlobalContext } from '../../../provider/index'
import { requestTemplate, createProjectThumbnail, uploadThumbnail } from '../../../axios/ApiProvider';
import { ProjectJSON_Store, captureThumbnailFlag_store } from '../../../store/actions/projectdata';
import { getWebGlSizeLevel, getWebglResizeJson } from './../commonFunction';
import { categoryList, visibilityList, webGlSizeOptions } from '../videoEdit.config';
import PricingModal from '../../../pages/Pricing/pricingModal';

import { tips } from '../../../util';

const HeaderInfo1 = {
  title: 'Edit Video',
  className: 'pl-3',
  footertitle: 'Video Placeholder 2.mp4',
  footerclass: 'v-block-footer'
}

export const TooltapSettings = () => {
  const dispatch = useDispatch();
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const [globalState, { onLoading }] = useGlobalContext()
  const HeaderInfo = { title: languageStrs.project_setting, className: 'pl-3' };
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const projectUUID = useSelector((store) => store.projectdata.projectUUID);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const imageElements = useSelector(store => store.webgldata.imageElements);
  const Settings_State = useSelector((store) => store.maindata.SettingsState);
  const timeCount = useSelector(store => store.videodata.Time_Count);
  const [sizeType, setSizeType] = useState('');
  const [noiseRemoval, setNoiseRemoval] = useState(false)
  const [showPricingPageModal, setShowPricingPageModal] = useState(false)
  const loginState = useSelector((store) => store.maindata.loginState)
  const [userData, setUserData] = useState({})
  const [plan, setPlan] = useState(0);
  const [usePreview, setUsePreview] = useState(true);

  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') {
      tempData = JSON.parse(item)
    }

    setUserData(tempData)
    setPlan(tempData.subscription);
  }, [loginState])

  useEffect(() => {
    const activeLabel = getWebGlSizeLevel(ProjectJSON);
    if (!!activeLabel) setSizeType(activeLabel);
    else setSizeType(webGlSizeOptions[0]);
    if (!!ProjectJSON?.bg_color) SetBackgroundColor(ProjectJSON?.bg_color);
    if(ProjectJSON.audio_cleanup_enabled == "false") setNoiseRemoval(false);
    if(ProjectJSON.audio_cleanup_enabled == "true") setNoiseRemoval(true);
    if(ProjectJSON.audio_cleanup_enabled == false) setNoiseRemoval(false);
    if(ProjectJSON.audio_cleanup_enabled == true) setNoiseRemoval(true);
  }, [ProjectJSON])

  const [status, setStatus] = useState(false)
  useEffect(() => {
    let tempStatus = false
    if (!!ProjectJSON?.text) {
      let texts = ProjectJSON.text
      let videos = ProjectJSON.videos
      let images = ProjectJSON.images
      let tempArr = Object.keys({ ...texts, ...videos, ...images })
     // if (tempArr.length > 0) tempStatus = true
    }

    setStatus(tempStatus)
  }, [ProjectJSON])

  const changeWebGlSige = ({ target }) => {
    if (target.value) {
      const option = target.value;
      const { videoitems, imageitems, textItems } = getWebglResizeJson(ProjectJSON, option, videoElements, imageElements)
      saveChangedJSON({ ...option.wSize, videos: videoitems, images: imageitems, text: textItems });
    }
  }

  const saveChangedJSON = (changedJSON) => {
    let newJSON = Object.assign({}, ProjectJSON);
    newJSON = { ...newJSON, ...changedJSON };
    dispatch(ProjectJSON_Store(newJSON));
  }

  const [backgroundColor, SetBackgroundColor] = useState('00000000');
  const backgroundChanged = (color) => {
    if (ProjectJSON.bg_color !== color)
      dispatch(ProjectJSON_Store({ ...ProjectJSON, bg_color: color.substring(1) }));
  }

  const [flag, setFlag] = useState(false)
  const [active, setActive] = useState(false)
  const [category, setCategory] = useState(languageStrs.trending)
  const [visibility, setVisibility] = useState('PUBLIC')

  useEffect(() => {
    if (ProjectJSON?.templateproject && !flag) {
      let templateKey = Object.keys(ProjectJSON?.templateproject)
      let templateData = ProjectJSON?.templateproject[templateKey[0]]

      setFlag(true)
      setActive(templateData?.state || false)
      setCategory(templateData?.category || languageStrs.trending)
      setVisibility(templateData?.visibility || languageStrs.custom)
    }
    if(ProjectJSON.hasOwnProperty('use_preview')){
      setUsePreview(ProjectJSON.use_preview);
    }else{
      setUsePreview(true);
    }
  }, [ProjectJSON])

  const getProjectSize = (json) => {
    if (json?.height > json.width) {
      return "PORTRAIT"
    } else if (json.height < json.width) {
      return "LANDSCAPE"
    } else {
      return "SQUARE"
    }
  }

  const changeRequestInfo = async (category_, visibility_, active_) => {
    try {
      if (category !== category_ || visibility !== visibility_ || active !== active_) {
        onLoading(true)
        const projectSize = getProjectSize(ProjectJSON)
        const result = await requestTemplate(projectUUID, category_, visibility_, projectSize, active_)

        if (result.state === 'success') {
          onLoading(false)
          setActive(active_)
          setCategory(category_)
          setVisibility(visibility_)
          tips("success", 'request successed')
        } else {
          throw new Error('Setting save failure')
        }
      }
    } catch (err) {
      onLoading(false)
      tips("warning", err.message)
    }
  }

  const handleTakeThumbnail = () => {
    dispatch(captureThumbnailFlag_store(true))
  }

  useEffect(() => {
    dispatch(ProjectJSON_Store({
      ...ProjectJSON,
      audio_cleanup_enabled: noiseRemoval
    }))
  }, [noiseRemoval])
  

  const handleChangeNoiseRemoval = (e) => {
    
    if(e.target.checked) {
      if(!loginState || userData?.audionoiseremoval_state === false) {
        return setShowPricingPageModal(true)
      }
    }
    
    setNoiseRemoval(e.target.checked)
  }
  const handleChangeIsPreview = (e) => {
    setUsePreview(e.target.checked);
    dispatch(ProjectJSON_Store({
      ...ProjectJSON,
      use_preview: e.target.checked
    }))
  }
  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }

  return (
    <>
      {!Settings_State && (
        <>
          <SectionHeader {...HeaderInfo} />

          <Box md={12} className="px-10 pt-0">
            <Box className='v-block-header pb-3'>
              <Typography className='v-block-header-left'>{languageStrs.size}</Typography>
            </Box>

            <Box className="v-autocomplate">
              <Select
                className='v-hoverborder v-radius v-commonboxheight'
                inputProps={{ 'aria-label': 'Without label' }}
                value={sizeType}
                onChange={changeWebGlSige}
                 disabled={status}
              >
                {webGlSizeOptions.map((option, key) =>
                  <MenuItem value={option} key={key}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      alignItems: 'center'
                    }}>
                      <Box className='display-size' sx={{
                        minWidth: option.size.x,
                        height: option.size.y,
                        border: '1px solid #9597a9', margin: '10px', borderRadius: '0px'
                      }} md={2}></Box>

                      <Typography sx={{
                        flex: 1,
                        marginLeft: '10px',
                        fontWeight: 'light', color: '#9597a9'
                      }}>{option.label}</Typography>

                      <Typography className='display-ratio' sx={{
                        minWidth: '50px',
                        fontWeight: 'light', color: '#9597a9'
                      }}>{option.ratio}</Typography>
                    </Box>
                  </MenuItem>
                )}
              </Select>
            </Box>

            <Box className='v-block-header pt-3 pb-3'>
              <Typography className='v-block-header-left'>{languageStrs.duration}</Typography>
            </Box>

            <Box md={12} className='v-movetimeincrease v-userselect'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box className='v-timeincrease v-border v-spacebetween v-cursor-resize'
                  // onMouseLeave={DurationTimeChangeFalse}
                  // onMouseDown={DurationTimeChangeTrue}
                  // onMouseUp={DurationTimeChangeFalse}
                  // onMouseMove={MouseMove}
                  >
                    <Typography className='v-font-2-4 pl-2'>{languageStrs.time}</Typography>
                    <input type="text"
                      className='v-input-text mr-3 v-font-2-2'
                      value={(new Date(ProjectJSON?.duration ? ProjectJSON.duration : 0)).MMSSMM()} disabled
                    // value={Duration.value}
                    // onKeyUp={DurationKeyUp}
                    // onChange={DurationKeyChange}
                    // onKeyPress={DurationKeyPress}
                    />
                  </Box>
                </Grid>

                {/* <Grid item xs={2}>
                  <Box className='v-timeincreaseicon v-hoverborder v-center'>
                    <HeightIcon className='v-rotate90' />
                  </Box>
                </Grid> */}
              </Grid>
            </Box>

            <Box className='v-block-header pt-3 pb-3'>
              <Typography className='v-block-header-left'>{languageStrs.background}</Typography>
            </Box>

            <Box md={12}>
              <FormControl sx={{ width: '100%' }}>
                <RadioGroup sx={{ width: '100%' }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Color"
                  name="Background"
                >
                  <Grid container spacing={0} className='v-border v-radius p-3'>
                    <Grid item xs={12} className='v-font-2-4'>
                      <Box className='v-spacebetween'>
                        <FormControlLabel value="Color" control={<Radio sx={{
                          '& .MuiSvgIcon-root': { fontSize: 20 }
                        }} />} label={languageStrs.color} className='v-radio' />
                        <Box className='v-center mr-3'>
                          <input type="text"
                            className='v-input-text mr-2 v-font-2-2'
                            value={'#' + backgroundColor}
                            onChange={() => { }} disabled />

                          <ColorPicker
                            Icon={<FormatColorFillIcon className='v-font-3-2 v-colorpicker-icon' />}
                            color={'#' + backgroundColor}
                            change={backgroundChanged} />
                        </Box>
                      </Box>
                    </Grid>

                    {/* <Grid item xs={12} className='pt-3 pb-2 v-font-2-4'>
                <Box className='v-spacebetween'>
                  <FormControlLabel value="Image" control={<Radio sx={{
                    '& .MuiSvgIcon-root': { fontSize: 20 }
                  }} />} label="Image" className='v-radio' />

                  <Box className='v-center mr-3'>
                    <Typography className='v-hovercolor v-pointer v-font-2-4 mr-2'>Upload</Typography>
                    <BackupOutlinedIcon className='v-font-3-2' />
                  </Box>
                </Box>
              </Grid> */}
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Box>

            <div className='relative w-full flex flex-col mt-50 cursor-default border border-solid border-l-black/30 rounded-lg px-20'>
              <div className='absolute left-10 -top-[20px] flex flex-row items-center pr-20 bg-white cursor-pointer select-none'>
                <Radio checked={active} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  onClick={() => { changeRequestInfo(category, visibility, !active) }}
                />
                <span className='text-15'>{languageStrs.use_template_project}</span>
              </div>

              <div className='flex flex-row gap-20 items-center pt-20'>
                <span className='w-90 text-15'>{languageStrs.visibility} </span>

                <div className="flex-1 sortby-wrapper flex-col">
                  <Select value={visibility} className="toolbar-SortBy-select setting-select"
                    onChange={({ target }) => { changeRequestInfo(category, target.value, active) }}
                    disabled={(!(plan < 3))}
                  >
                    {visibilityList.map((title, key) => (
                      <MenuItem key={key} value={title} className="toolbar-SortBy-select-item" >
                        <PanoramaFishEyeOutlinedIcon className="radio-outline" />
                        <RadioButtonCheckedOutlinedIcon className="radio-checked" />
                        <span>{title}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              <div className='flex flex-row gap-20 items-center'>
                <span className='w-90 text-15'>{languageStrs.category} </span>

                <div className="flex-1 sortby-wrapper flex-col">
                  <Select value={category} className="toolbar-SortBy-select setting-select"
                    onChange={({ target }) => { changeRequestInfo(target.value, visibility, active) }}
                    disabled={(!(plan < 3))}
                  >
                    {categoryList.map((title, key) => (
                      <MenuItem key={key} value={title} className="toolbar-SortBy-select-item" >
                        <PanoramaFishEyeOutlinedIcon className="radio-outline" />
                        <RadioButtonCheckedOutlinedIcon className="radio-checked" />
                        <span>{title}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <Box className='v-input-container v-font-2-2 pr-3 pl-3 mt-4' onClick={handleTakeThumbnail} >
              <Box className='v-pointer input-text' >{languageStrs.capture_thumbnail}</Box>
            </Box>

            <FormControlLabel control={<Checkbox checked={noiseRemoval}  onChange={handleChangeNoiseRemoval}/>} label={languageStrs.audio_noise_remove} />
            <FormControlLabel control={<Checkbox checked={usePreview} onChange={handleChangeIsPreview}/>} label={'Use Preview Videos'}/>
          </Box>
          <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />
        </>
      )}

      {!!Settings_State && (
        <>
          <SectionHeader {...HeaderInfo1} />

          <Box md={12} className='px-10 pt-0'>
            <TabsUnstyled defaultValue={0}>
              <TabsList>
                <Tab>{languageStrs.Uploader}</Tab>
                <Tab>{languageStrs.brand_kit}</Tab>
              </TabsList>

              <TabPanel value={0} md={12}>
                <Box md={12} className='v-setting-replace-video p-4'>
                  <Typography className='v-replace-video-header v-font-2-4 mb-2'>
                    <TheatersOutlinedIcon className='v-replace-icon mr-2' />
                    {languageStrs.video_placeholder}
                  </Typography>

                  <Box className='v-replace-video-box v-left v-pointer v-font-2-2 p-3'>
                    <CloudUploadOutlinedIcon className='v-replace-box-icon mr-2' />{languageStrs.replace_video}
                  </Box>
                </Box>

                <Box md={12}>
                  <Box className='v-block-header pt-3 pb-3'>
                    <Typography className='v-block-header-left'>{languageStrs.transformations}</Typography>
                  </Box>

                  {/* to do fix */}
                  <Grid container spacing={0} >
                    <Grid item xs={6} className='pr-1'>
                      <Box className='v-hoverbc v-radius v-spacebetween v-commonboxheight v-font-2-2 pl-3'>
                        {languageStrs.fill_canvas}
                        <FullscreenIcon className='v-font-3-4 mr-4' />
                      </Box>
                    </Grid>

                    <Grid item xs={6} className='pl-1'>
                      <Box className='v-hoverbc v-radius v-spacebetween v-commonboxheight v-font-2-2 pl-3'>
                        {languageStrs.fit_canvas}
                        <OpenInFullIcon className='v-font-2-3 mr-4' />
                      </Box>
                    </Grid>

                    <Grid item xs={12} className='mt-3'>
                      <Box className='v-border v-radius v-commonboxheight v-spacebetween pl-3' xs={12}>
                        {languageStrs.round_corners}
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} />}
                          label=""
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box md={12}>
                  <Box className='v-block-header pt-4 pb-3'>
                    <Typography className='v-block-header-left'>{languageStrs.audio}</Typography>
                    <Typography className='v-block-header-right v-hoverbackcolor pr-2'>
                      {languageStrs.detach_audio}
                      <InsertLinkIcon className='v-rotate90 v-font-3-3' />
                    </Typography>
                  </Box>

                  <Grid container spacing={0}>
                    <Grid item xs={2} className='pr-1'>
                      <Box className='v-hoverbc v-radius v-center v-commonboxheight'>
                        <VolumeUpOutlinedIcon />
                      </Box>
                    </Grid>

                    <Grid item xs={10} className='pl-1'>
                      <Box className='v-border v-radius'>
                        <Grid container spacing={0} className='v-center'>
                          <Grid item xs={8} className='v-commonboxheight v-center'>
                            <Slider
                              value={0}
                              aria-labelledby="input-slider"
                            />
                          </Grid>

                          <Grid item xs={2} className='v-commonboxheight v-center'>0</Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>

                  <Stack md={12} className='v-hoverborder v-radius p-3 mt-4'>
                    <Grid container spacing={0} className='v-cleanaudio'>
                      <Grid item xs={10}>
                        <Typography className='v-cleanaudio-text'>{languageStrs.cleanAudio}</Typography>
                        <Typography className='v-cleanaudiobottom-text'>{languageStrs.upgrade_basic_unlock}</Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <HexagonSharpIcon className='v-cleanaudio-icon' />
                      </Grid>
                    </Grid>
                  </Stack>

                  <Typography className='v-block-footer mt-3'>{languageStrs.remove_background_noise_enhance_audio_qty}</Typography>
                </Box>

                <Box md={12}>
                  <Box className='v-block-header pt-4 pb-3'>
                    <Typography className='v-block-header-left'>{languageStrs.audio}</Typography>
                  </Box>

                  <Grid container spacing={0}>
                    <Grid item xs={2}>
                      <Box className='v-commonboxheight v-hoverbc v-radius v-center v-font-2-3 mr-1'> 0.5x</Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box className='v-commonboxheight v-hoverbc v-radius v-center v-font-2-3 ml-1 mr-1'>1x</Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box className='v-commonboxheight v-hoverbc v-radius v-center v-font-2-3 ml-1 mr-1'>1x</Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box className='v-commonboxheight v-hoverbc v-radius v-center v-font-2-3 ml-1 mr-1'>2x</Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box className='v-commonboxheight v-hoverbc v-radius v-center v-font-2-3 ml-1'>Custom</Box>
                    </Grid>
                  </Grid>

                  <Box className='v-border v-radius v-commonboxheight v-spacebetween pl-3 mt-3' xs={12}>
                    {languageStrs.round_corners}
                    <FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label=""
                    />
                  </Box>
                </Box>

                <Box md={12}>
                  <Box className='v-block-header pt-4 pb-3'>
                    <Typography className='v-block-header-left'>{languageStrs.duration}</Typography>
                    <Typography className='v-block-header-right mr-3'>00:00.0</Typography>
                  </Box>

                  <Box className='v-border v-radius v-commonboxheight v-center'>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <AccessAlarmOutlinedIcon className='v-font-4' />
                      </Grid>

                      <Grid item className='v-font-2-3 v-center' xs={2}> {languageStrs.start}</Grid>
                      <Grid item className='v-font-2-3 v-center' xs={2}> 00:08.7</Grid>
                      <Grid item className='v-font-2-3 v-center' xs={2}> {languageStrs.end}</Grid>
                      <Grid item className='v-font-2-3 v-center' xs={2}> 00:04.3</Grid>
                      <Grid item className='v-font-2-3 v-center' xs={2}>
                        <AccessAlarmOutlinedIcon className='v-font-4' />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box md={12} className='mb-5'>
                  <Box className='v-block-header pt-4 pb-3'>
                    <Typography className='v-block-header-left'>{languageStrs.media}</Typography>
                  </Box>

                  <Grid container spacing={0}>
                    <Grid item xs={10}>
                      <Box className='v-spacebetween v-hoverbc v-radius v-commonboxheight pl-3 pr-3 mr-2 v-font-2-3'>
                        {languageStrs.replace_video}
                        <FindReplaceIcon className='v-font-3-4' />
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box className='v-hoverbc v-radius v-commonboxheight v-center ml-1'>
                        <DeleteOutlineOutlinedIcon />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>

              <TabPanel value={1}>
                <Box md={12}>
                  <Box className='v-block-header pt-3 pb-3'>
                    <Typography className='v-block-header-left'>{languageStrs.audio}</Typography>
                  </Box>

                  <Grid container spacing={0} className='v-center'>
                    <Grid item xs={8} className='pl-1'>
                      <Box className='v-border v-radius mr-2'>
                        <Grid container spacing={0} className='v-center'>
                          <Grid item xs={8} className='v-commonboxheight v-center'>
                            <Slider value={0} aria-labelledby="input-slider" />
                          </Grid>

                          <Grid item xs={2} className='v-commonboxheight v-center'>0</Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box className='v-commonboxheight v-hoverbc v-center v-radius mr-1'>
                        <VerticalAlignCenterOutlinedIcon className='v-rotate90' />
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box className='v-commonboxheight v-hoverbc v-center v-radius ml-1'>
                        <VerticalAlignCenterOutlinedIcon />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box md={12}>
                  <Box className='v-block-header pt-3 pb-3'>
                    <Typography className='v-block-header-left'>{languageStrs.color_correction}</Typography>
                    <Typography className='v-block-header-right v-hoverbackcolor'> {languageStrs.reset} <FindReplaceIcon className='v-font-3-3 mr-3' /></Typography>
                  </Box>

                  <Box md={12}>
                    <Grid container spacing={0} className='v-border v-radius p-3'>
                      <Grid item xs={12} container spacing={0} className='v-border_b v-font-2-4'>
                        <Grid item xs={3} className='v-commonboxheight v-left v-font-2-2'>{languageStrs.exposure}</Grid>
                        <Grid item xs={7} className='v-commonboxheight v-center'>
                          <Slider value={10} aria-labelledby="input-slider" />
                        </Grid>

                        <Grid item xs={2} className='v-commonboxheight v-center'>0</Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={0} className='v-border_b v-font-2-4'>
                        <Grid item xs={3} className='v-commonboxheight v-left v-font-2-2'>{languageStrs.brightness}</Grid>
                        <Grid item xs={7} className='v-commonboxheight v-center'>
                          <Slider value={30} aria-labelledby="input-slider" />
                        </Grid>

                        <Grid item xs={2} className='v-commonboxheight v-center'>1</Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={0} className='v-border_b v-font-2-4'>
                        <Grid item xs={3} className='v-commonboxheight v-left v-font-2-2'>{languageStrs.contract}</Grid>
                        <Grid item xs={7} className='v-commonboxheight v-center'>
                          <Slider value={20} aria-labelledby="input-slider" />
                        </Grid>

                        <Grid item xs={2} className='v-commonboxheight v-center'>1</Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={0} className='v-border_b v-font-2-4'>
                        <Grid item xs={3} className='v-commonboxheight v-left v-font-2-2'>{languageStrs.saturation}</Grid>
                        <Grid item xs={7} className='v-commonboxheight v-center'>
                          <Slider value={40} aria-labelledby="input-slider" />
                        </Grid>

                        <Grid item xs={2} className='v-commonboxheight v-center'>1</Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={0} className='v-border_b v-font-2-4'>
                        <Grid item xs={3} className='v-commonboxheight v-left v-font-2-2'>{languageStrs.sharpen}</Grid>
                        <Grid item xs={7} className='v-commonboxheight v-center'>
                          <Slider value={60} aria-labelledby="input-slider" />
                        </Grid>

                        <Grid item xs={2} className='v-commonboxheight v-center'>0</Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={0} className='v-border_b v-font-2-4'>
                        <Grid item xs={3} className='v-commonboxheight v-left v-font-2-2'>{languageStrs.blur}</Grid>
                        <Grid item xs={7} className='v-commonboxheight v-center'>
                          <Slider value={0} aria-labelledby="input-slider" />
                        </Grid>

                        <Grid item xs={2} className='v-commonboxheight v-center'>0</Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={0} className='v-border_b v-font-2-4'>
                        <Grid item xs={3} className='v-commonboxheight v-left v-font-2-2'>{languageStrs.Noise}</Grid>
                        <Grid item xs={7} className='v-commonboxheight v-center'>
                          <Slider value={0} aria-labelledby="input-slider" />
                        </Grid>

                        <Grid item xs={2} className='v-commonboxheight v-center'>0</Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </TabPanel>
            </TabsUnstyled >
          </Box >
        </>
      )}
    </>
  )
}