export const TOOLKEY = 'TOOLKEY';
export const SUBTITLESTATEKEY = 'SUBTITLESTATEKEY';
export const ELEMENTSTATEKEY = 'ELEMENTSTATEKEY';
export const DRAWSTATEKEY = 'DRAWSTATEKEY';
export const MENUBARREF = 'MENUBARREF';
export const CAPTUREDIALOG = 'CAPTUREDIALOG';
export const LANGUAGESTRS = 'LANGUAGESTRS';
export const ISELEMENTDRAGING = 'ISELEMENTDRAGING';
export const ISCHANGSEGMENT = 'ISCHANGSEGMENT';
export const STARTSEGMENT = 'STARTSEGMENT';
export const ENDSEGMENT = 'ENDSEGMENT';
export const ISTIMELINESCROLL = 'ISTIMELINESCROLL';

export const videoEditData = {
  menubarRef: null,
  selectedTool: null,
  SubtitlesState: 0,
  ElementState: 0,
  DrawState: false,
  isElementDraging: false,
  captureDialog: {
    webcam: false,
    screen: false,
    webcamScreen: false
  },
  isChangeSegment: false,
  startSegment: 0,
  endSegment:0,
  languageStrs: {},
  isTimelineScroll: true,
};