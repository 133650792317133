import { projectsData, templatesData, ProjectJsonData, DefaultProjectJSONdata, settingBackData } from "./datas";

export const ProjectLink = {
  "2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9": {
    "fps": 24,
    "text": {},
    "asset": {},
    "title": "Your Video - 2f9c8c6fd3e94f0da8755d81e879b6d9",
    "width": 1080,
    "height": 1920,
    "images": {},
    "videos": {},
    "audios": {},
    "effects": {},
    "filters": { "preset": "", "value": "#ffffff" },
    "version": 1.1,
    "bg_color": "00000000",
    "duration": 0,
    "elements": {},
    "subtitles": {},
    "user_uuid": null,
    "audio_only": false,
    "created_at": "2022-11-30 18:16:35",
    "updated_at": "2022-11-30 18:16:39",
    "subtitle_url": null,
    "thumbnail_url": {},
    "workspace_uuid": '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9',
    "sequence": 0
  }
}

export const Registration = {
  locale: "en",
  lastname: "D",
  state: "loggedin",
  firstname: "Artiom",
  email_verified: true,
  email: "zengda0501@gmail.com",
  username: "zengda0501@gmail.com",
  uuid: "2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9",
  picture: "https://lh3.googleusercontent.com/a/ALm5wu0FacZXSq_negncsz9SO9fTcojiu1yIAmeU3JWK=s96-c",
  token: "ya29.a0Aa4xrXMPOoiVG-Pq163QHkiUO478-UKBpy_x5W6dk-2XJt2rnsn9yZ94qo-NtN-HaySr8wKaVwpBG3ZPCZ33_4LSpkDNeCnGaLNFsFfrw2b-s11j2ry_CzKSOqlPfdyR4YOyD-B4ZcyiNRlDJ9a5QKyJysRUaCgYKATASARISFQEjDvL9GkIVq0WEH0t4gibn3VowFw0163",
  subscription: 1,
  subtitle_time: "0 of 720",
  subtitle_state: true,
  storage_usage_size: "0.018 GBytes of 20 GBytes",
  storage_usage_state: true
}

export const Projects = projectsData;
export const Templates = templatesData;

export const ProjectJSON = ProjectJsonData;
export const DefaultProjectJSON = DefaultProjectJSONdata;
export const projectHistoryData = settingBackData;

export const AssetsData = {
  uuid: "3a689333-57af-4e3f-a2f1-64ff95f87e1c",
  category: null,
  signedurl: "https://storage.googleapis.com/staging-videoo-storage-4/3a689333-57af-4e3f-a2f1-64ff95f87e1c?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=cloudrun%40videoo4.iam.gserviceaccount.com%2F20230114%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20230114T150125Z&X-Goog-Expires=7199&X-Goog-SignedHeaders=content-type%3Bhost&X-Goog-Signature=7779858bd4d28bdda8cb348b033a70f673ac3c1678c980be2c561f78ed0f1e830bd6c5f05987d95f70480b2441c442edcabdf82caf2cd27a676429aa5555f7c9f097be57da41adc574c2ab732f6cdcaa9d1797d21cf8f11b1fd1caf805616433d42105119171c9e9f9ce576cbb17bb98b7e56738167aed89f137abb1683e3c6b3d74d063ead4468e024fbd682c695d1502d679af7aca121c5b421c3b070e628952c5fa6aaf0f0e3005f318221c043c048caa8a3a69da331075270d6842c44b32009d266026ca242fad43249aa550079eb2e45823dd1a3ced0b768f00336c0939e323fb72adb4297efb96fa14d79497c838d3014eeaa00ded6b26e439385d9cbd"
}

export const UploadAssetsData = {
  uuid: "adaf2b4b-e880-4b36-83e7-b23228cb42cd",
  category: 1,
  signed_url: "https://storage.googleapis.com/staging-videoo-storage-2/3d7385c5-d159-45da-b5bf-39d82843e568?Expires=1666662834&GoogleAccessId=cloudrun-674%40videoo-365200.iam.gserviceaccount.com&Signature=IYcxv9xAjnW4SAG3sDh%2BI1Y2IXcHTFkGccsTpZZpKRevfSzLTKWt17xkGmbI64drIMXd4oOsL89NBsDYMwnYyKhb%2BKYm6GRVWh9qnTSpFT6cUL1L6Y77%2FDSvA1A1Ox2Sb9EltPSgB5DA7HdZcWFMuNoBIfJBVQyYQC8b0jBCCtuB9uRNdQITnF%2F4HxzHlpJk54%2FPrPN0kgK2MrRsgiccatr%2B6aL9AZjvSzGCPLp%2BfkHe0KUrgBVF%2BAuvKys9bSLhODhjNNHot6c%2Fha%2FuosORlBao0qRNoxt0DY1Idt9CP3TGR0fKYmCkwqsh1e1TvdhH%2Fbq8f8MyrqCdEqxuGCWFdQ%3D%3D"
}

export const createThumbnailData = {
  "uuid": "f190bc28-622e-4a7e-82fe-48a34c9b50cd",
  "timestamp": 8.0,
  "content_type": "blob",
  "signedurl": "https://storage.googleapis.com/staging-videoo-storage-2/bd847a81-d76d-41d8-ab84-8fbc8f01a241?Expires=1666662834&GoogleAccessId=cloudrun-674%40videoo-365200.iam.gserviceaccount.com&Signature=YYLgVNJcVs8V2toJkIr7LR2lFbZ8yFttDyW9oz4CWeXaq4eQ7bBHbYlYBzKVqzSQF0u88%2F8tcFy0Dpf0F%2BafxckmWeI61LoeSKbSFu9%2Bz3gk4Mkt6VzO8Q7aYex%2BAhyVDYIy%2F58LlGpAreyARXkUuIA%2BRnCBPPj2lTF7Fr%2BPeqrOwvVbCMM59RJ2PGKLucQBWF6KjBg3P6Y9LbGRkLhcGQcvOr4RVv53%2Fw2mcE1JHRKhI7Kz9kQkc8A2C8mbduayf9Zbxa6cDCTaNoOqe63jAqjpnHQihm3tteylNXbPGaCRrMdFXKIQ%2F6iLv%2B4fizMYa4TnUQpVYJ8CkTTdtUooKQ%3D%3D"
}

export const uploadThumbnailData = {}

export const exportProjectData = {
  "uuid": "2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9",
  "asset_uuid": "405c1353-043a-460b-93f4-1ab56eb3d94f",
  "signed_url": "https://storage.googleapis.com/staging-videoo-storage-2/405c1353-043a-460b-93f4-1ab56eb3d94f?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=cloudrun-674%40videoo-365200.iam.gserviceaccount.com%2F20221101%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20221101T182117Z&X-Goog-Expires=7199&X-Goog-SignedHeaders=content-type%3Bhost&X-Goog-Signature=0405bb8d765f29cb35e03cec607a86edbdb3bd9ce77f3b5e7c988a2d196d88128ea80b9a4e63279d921afce27fd62cae1c4c6f947eae061684ce5fdb6dcd6806d7a4ded54fba24e549af13e430144eef97d25016fb7b43903321c68ea092cbf06f904a626f1090de9b50c7ae608a51cef6bde6eb99e0ed54460cac3958f56e2e10ff735ffaf30227e6ed0196fb94f2f485d53c6c53509ab3c4a7adc5b2b590a3f438ee737510298254d1cebdb298f2f427a2bab1783e5ad24cc422441d7eee74f1d0c8589bb3d8bf4a53c2cfb46258343ecb6a2349fc9c865d4dc8535670df233bd1d332a8b1143aafee04a7df4e6a3896266a1e7656e8ba6850bba1a37d5091",
  "webhook_url": "https://api.videoo.dev/project/2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9/export/405c1353-043a-460b-93f4-1ab56eb3d94f/"
}

export const webHookData = {
  "uuid": "2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9",
  "asset_uuid": "405c1353-043a-460b-93f4-1ab56eb3d94f",
  "signed_url": null,
  "created_at": "2022-10-12 06:16:38",
  "state": 0,
  "progress": 10
}

export const createSubtitleData = {
  "Code": "Subtitle Submit success."
}

export const subtitleWebhookData = {
  "Code": "Subtitle Webhook GET success, please POST for webhook.",
  "state": 2,
  "vendor": "ASSEMBLYAI",
  "manifest": {
    "id": "ry3y9j75ip-2fc6-45c7-b976-f73a799c274d",
    "text": "Now let's meet someone who can bring a little history to us. Her name is Mrs. Florence Panel and she's 108 years old. Unlike most women of her time, Florence Panel managed to set up her own beauty care business. She worked for many years in Paris and in London and she knows a great deal about fashion. Florence, tell me, was it all right for a lady to show her ankle in Paris? Nothing mattered. I'm speaking of years before that. Years when I suppose I was about ten. Yes. There was a cartoon in Punch over the old fashioned bus with the seats going parallel with the bus and all the men going like this. Yes. What were they looking for? There was a woman crossing the road and holding her dress up and showing her ankle to the ear tut. Florence. Yes. Tell me a bit about how you managed to start your own beauty business. No lady worked in those days. She's only a companion or a teacher. She could be a hospital nurse, but not private. So it was difficult for women? Shockingly difficult. I can tell you a story about that. That was also, I think, in Punch. A scraggy woman seating the chair with a girl playing on the floor. About four years old and an awful looking man this side in an arm jet. Mother, when I grew up, should I have to be married? Yes, dear. Should I have to marry a man like Father? Yes, dear. And if I don't marry him, should I be an old maid like Aunt Eliza? Yes, dear. Silence for me. Say, Mother, it's a hard world for us for me, isn't it? Have you ever been in an airplane? Never. Would you like to? I shouldn't mind now, but I wouldn't. When they first came in, I never thanked them. But I won't know because I'm more adventuresome. You've been very adventurous right through your life. What's the biggest change of all you've seen? The biggest change? Everything. Nothing is the same. Everything has changed.",
    "words": [{
      "end": 517,
      "text": "Now",
      "start": 312,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 777,
      "text": "let's",
      "start": 565,
      "speaker": null,
      "confidence": 0.9869
    }, {
      "end": 997,
      "text": "meet",
      "start": 807,
      "speaker": null,
      "confidence": 0.99988
    }, {
      "end": 1222,
      "text": "someone",
      "start": 1030,
      "speaker": null,
      "confidence": 0.9982
    }, {
      "end": 1402,
      "text": "who",
      "start": 1255,
      "speaker": null,
      "confidence": 0.99785
    }, {
      "end": 1597,
      "text": "can",
      "start": 1420,
      "speaker": null,
      "confidence": 0.99456
    }, {
      "end": 1822,
      "text": "bring",
      "start": 1630,
      "speaker": null,
      "confidence": 0.99957
    }, {
      "end": 2002,
      "text": "a",
      "start": 1855,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 2197,
      "text": "little",
      "start": 2020,
      "speaker": null,
      "confidence": 0.99872
    }, {
      "end": 2512,
      "text": "history",
      "start": 2230,
      "speaker": null,
      "confidence": 0.99868
    }, {
      "end": 2797,
      "text": "to",
      "start": 2575,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 3067,
      "text": "us.",
      "start": 2830,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 3277,
      "text": "Her",
      "start": 3115,
      "speaker": null,
      "confidence": 0.9991
    }, {
      "end": 3427,
      "text": "name",
      "start": 3295,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 3622,
      "text": "is",
      "start": 3445,
      "speaker": null,
      "confidence": 0.99874
    }, {
      "end": 3897,
      "text": "Mrs.",
      "start": 3655,
      "speaker": null,
      "confidence": 0.82913
    }, {
      "end": 4377,
      "text": "Florence",
      "start": 3942,
      "speaker": null,
      "confidence": 0.97792
    }, {
      "end": 4812,
      "text": "Panel",
      "start": 4407,
      "speaker": null,
      "confidence": 0.60435
    }, {
      "end": 5167,
      "text": "and",
      "start": 4887,
      "speaker": null,
      "confidence": 0.98
    }, {
      "end": 5592,
      "text": "she's",
      "start": 5215,
      "speaker": null,
      "confidence": 0.90351
    }, {
      "end": 6667,
      "text": "108",
      "start": 5652,
      "speaker": null,
      "confidence": 0.88
    }, {
      "end": 6967,
      "text": "years",
      "start": 6715,
      "speaker": null,
      "confidence": 0.86101
    }, {
      "end": 7625,
      "text": "old.",
      "start": 7015,
      "speaker": null,
      "confidence": 0.99478
    }, {
      "end": 10347,
      "text": "Unlike",
      "start": 9787,
      "speaker": null,
      "confidence": 0.99836
    }, {
      "end": 10642,
      "text": "most",
      "start": 10392,
      "speaker": null,
      "confidence": 0.99972
    }, {
      "end": 10942,
      "text": "women",
      "start": 10690,
      "speaker": null,
      "confidence": 0.99952
    }, {
      "end": 11152,
      "text": "of",
      "start": 10990,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 11347,
      "text": "her",
      "start": 11170,
      "speaker": null,
      "confidence": 0.99685
    }, {
      "end": 11662,
      "text": "time,",
      "start": 11380,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 12222,
      "text": "Florence",
      "start": 11725,
      "speaker": null,
      "confidence": 0.6387
    }, {
      "end": 12597,
      "text": "Panel",
      "start": 12267,
      "speaker": null,
      "confidence": 0.90639
    }, {
      "end": 13032,
      "text": "managed",
      "start": 12642,
      "speaker": null,
      "confidence": 0.99278
    }, {
      "end": 13177,
      "text": "to",
      "start": 13047,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 13372,
      "text": "set",
      "start": 13195,
      "speaker": null,
      "confidence": 0.99889
    }, {
      "end": 13642,
      "text": "up",
      "start": 13405,
      "speaker": null,
      "confidence": 0.98
    }, {
      "end": 13852,
      "text": "her",
      "start": 13690,
      "speaker": null,
      "confidence": 0.99828
    }, {
      "end": 14092,
      "text": "own",
      "start": 13870,
      "speaker": null,
      "confidence": 0.99872
    }, {
      "end": 14547,
      "text": "beauty",
      "start": 14140,
      "speaker": null,
      "confidence": 0.62093
    }, {
      "end": 14887,
      "text": "care",
      "start": 14592,
      "speaker": null,
      "confidence": 0.95631
    }, {
      "end": 15397,
      "text": "business.",
      "start": 14950,
      "speaker": null,
      "confidence": 0.99988
    }, {
      "end": 15772,
      "text": "She",
      "start": 15505,
      "speaker": null,
      "confidence": 0.99955
    }, {
      "end": 16042,
      "text": "worked",
      "start": 15805,
      "speaker": null,
      "confidence": 0.99883
    }, {
      "end": 16252,
      "text": "for",
      "start": 16090,
      "speaker": null,
      "confidence": 0.99841
    }, {
      "end": 16492,
      "text": "many",
      "start": 16270,
      "speaker": null,
      "confidence": 0.99983
    }, {
      "end": 16972,
      "text": "years",
      "start": 16540,
      "speaker": null,
      "confidence": 0.99987
    }, {
      "end": 17347,
      "text": "in",
      "start": 17080,
      "speaker": null,
      "confidence": 0.99602
    }, {
      "end": 17907,
      "text": "Paris",
      "start": 17380,
      "speaker": null,
      "confidence": 0.99965
    }, {
      "end": 18247,
      "text": "and",
      "start": 17997,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 18427,
      "text": "in",
      "start": 18280,
      "speaker": null,
      "confidence": 0.9703
    }, {
      "end": 18747,
      "text": "London",
      "start": 18445,
      "speaker": null,
      "confidence": 0.99414
    }, {
      "end": 19177,
      "text": "and",
      "start": 18792,
      "speaker": null,
      "confidence": 0.72
    }, {
      "end": 19477,
      "text": "she",
      "start": 19270,
      "speaker": null,
      "confidence": 0.9961
    }, {
      "end": 19707,
      "text": "knows",
      "start": 19495,
      "speaker": null,
      "confidence": 0.99856
    }, {
      "end": 19852,
      "text": "a",
      "start": 19722,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 20092,
      "text": "great",
      "start": 19870,
      "speaker": null,
      "confidence": 0.9998
    }, {
      "end": 20392,
      "text": "deal",
      "start": 20140,
      "speaker": null,
      "confidence": 0.99998
    }, {
      "end": 20692,
      "text": "about",
      "start": 20440,
      "speaker": null,
      "confidence": 0.99964
    }, {
      "end": 21512,
      "text": "fashion.",
      "start": 20740,
      "speaker": null,
      "confidence": 0.82255
    }, {
      "end": 22872,
      "text": "Florence,",
      "start": 21937,
      "speaker": null,
      "confidence": 0.98934
    }, {
      "end": 23362,
      "text": "tell",
      "start": 22992,
      "speaker": null,
      "confidence": 0.99985
    }, {
      "end": 23872,
      "text": "me,",
      "start": 23425,
      "speaker": null,
      "confidence": 0.99993
    }, {
      "end": 24247,
      "text": "was",
      "start": 23980,
      "speaker": null,
      "confidence": 0.99794
    }, {
      "end": 24472,
      "text": "it",
      "start": 24280,
      "speaker": null,
      "confidence": 0.99909
    }, {
      "end": 24652,
      "text": "all",
      "start": 24505,
      "speaker": null,
      "confidence": 0.83136
    }, {
      "end": 24892,
      "text": "right",
      "start": 24670,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 25102,
      "text": "for",
      "start": 24940,
      "speaker": null,
      "confidence": 0.9992
    }, {
      "end": 25252,
      "text": "a",
      "start": 25120,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 25572,
      "text": "lady",
      "start": 25270,
      "speaker": null,
      "confidence": 0.73372
    }, {
      "end": 25777,
      "text": "to",
      "start": 25617,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 26017,
      "text": "show",
      "start": 25795,
      "speaker": null,
      "confidence": 0.96
    }, {
      "end": 26317,
      "text": "her",
      "start": 26065,
      "speaker": null,
      "confidence": 0.99965
    }, {
      "end": 27137,
      "text": "ankle",
      "start": 26365,
      "speaker": null,
      "confidence": 0.82399
    }, {
      "end": 29722,
      "text": "in",
      "start": 29362,
      "speaker": null,
      "confidence": 0.51046
    }, {
      "end": 30237,
      "text": "Paris?",
      "start": 29755,
      "speaker": null,
      "confidence": 0.58866
    }, {
      "end": 30682,
      "text": "Nothing",
      "start": 30312,
      "speaker": null,
      "confidence": 0.98355
    }, {
      "end": 31637,
      "text": "mattered.",
      "start": 30760,
      "speaker": null,
      "confidence": 0.4232
    }, {
      "end": 33072,
      "text": "I'm",
      "start": 32587,
      "speaker": null,
      "confidence": 0.84187
    }, {
      "end": 33682,
      "text": "speaking",
      "start": 33117,
      "speaker": null,
      "confidence": 0.92113
    }, {
      "end": 34237,
      "text": "of",
      "start": 33835,
      "speaker": null,
      "confidence": 0.89
    }, {
      "end": 34702,
      "text": "years",
      "start": 34300,
      "speaker": null,
      "confidence": 0.99703
    }, {
      "end": 35227,
      "text": "before",
      "start": 34795,
      "speaker": null,
      "confidence": 0.99939
    }, {
      "end": 35975,
      "text": "that.",
      "start": 35320,
      "speaker": null,
      "confidence": 0.95558
    }, {
      "end": 38150,
      "text": "Years",
      "start": 37387,
      "speaker": null,
      "confidence": 0.92965
    }, {
      "end": 40822,
      "text": "when",
      "start": 40012,
      "speaker": null,
      "confidence": 0.87568
    }, {
      "end": 41347,
      "text": "I",
      "start": 41005,
      "speaker": null,
      "confidence": 0.97
    }, {
      "end": 41742,
      "text": "suppose",
      "start": 41380,
      "speaker": null,
      "confidence": 0.97926
    }, {
      "end": 41977,
      "text": "I",
      "start": 41802,
      "speaker": null,
      "confidence": 0.97
    }, {
      "end": 42172,
      "text": "was",
      "start": 41995,
      "speaker": null,
      "confidence": 0.98825
    }, {
      "end": 42577,
      "text": "about",
      "start": 42205,
      "speaker": null,
      "confidence": 0.99117
    }, {
      "end": 43147,
      "text": "ten.",
      "start": 42670,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 43925,
      "text": "Yes.",
      "start": 43255,
      "speaker": null,
      "confidence": 0.82
    }, {
      "end": 49072,
      "text": "There",
      "start": 48712,
      "speaker": null,
      "confidence": 0.68388
    }, {
      "end": 49297,
      "text": "was",
      "start": 49105,
      "speaker": null,
      "confidence": 0.99357
    }, {
      "end": 49522,
      "text": "a",
      "start": 49330,
      "speaker": null,
      "confidence": 0.95
    }, {
      "end": 50127,
      "text": "cartoon",
      "start": 49555,
      "speaker": null,
      "confidence": 0.71952
    }, {
      "end": 50347,
      "text": "in",
      "start": 50157,
      "speaker": null,
      "confidence": 0.62181
    }, {
      "end": 50987,
      "text": "Punch",
      "start": 50380,
      "speaker": null,
      "confidence": 0.96681
    }, {
      "end": 51892,
      "text": "over",
      "start": 51487,
      "speaker": null,
      "confidence": 0.18
    }, {
      "end": 52102,
      "text": "the",
      "start": 51940,
      "speaker": null,
      "confidence": 0.96
    }, {
      "end": 52387,
      "text": "old",
      "start": 52120,
      "speaker": null,
      "confidence": 0.99851
    }, {
      "end": 52917,
      "text": "fashioned",
      "start": 52450,
      "speaker": null,
      "confidence": 0.45606
    }, {
      "end": 53467,
      "text": "bus",
      "start": 52977,
      "speaker": null,
      "confidence": 0.99878
    }, {
      "end": 53872,
      "text": "with",
      "start": 53590,
      "speaker": null,
      "confidence": 0.92414
    }, {
      "end": 54187,
      "text": "the",
      "start": 53905,
      "speaker": null,
      "confidence": 0.75
    }, {
      "end": 54687,
      "text": "seats",
      "start": 54250,
      "speaker": null,
      "confidence": 0.97431
    }, {
      "end": 55357,
      "text": "going",
      "start": 54762,
      "speaker": null,
      "confidence": 0.9914
    }, {
      "end": 56262,
      "text": "parallel",
      "start": 55510,
      "speaker": null,
      "confidence": 0.87881
    }, {
      "end": 56527,
      "text": "with",
      "start": 56337,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 56722,
      "text": "the",
      "start": 56545,
      "speaker": null,
      "confidence": 0.84
    }, {
      "end": 57350,
      "text": "bus",
      "start": 56755,
      "speaker": null,
      "confidence": 0.99646
    }, {
      "end": 60397,
      "text": "and",
      "start": 59587,
      "speaker": null,
      "confidence": 0.89
    }, {
      "end": 60967,
      "text": "all",
      "start": 60580,
      "speaker": null,
      "confidence": 0.99468
    }, {
      "end": 61222,
      "text": "the",
      "start": 61015,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 61850,
      "text": "men",
      "start": 61255,
      "speaker": null,
      "confidence": 0.98274
    }, {
      "end": 63532,
      "text": "going",
      "start": 63037,
      "speaker": null,
      "confidence": 0.99611
    }, {
      "end": 63892,
      "text": "like",
      "start": 63610,
      "speaker": null,
      "confidence": 0.99836
    }, {
      "end": 64550,
      "text": "this.",
      "start": 63940,
      "speaker": null,
      "confidence": 0.9947
    }, {
      "end": 68075,
      "text": "Yes.",
      "start": 67312,
      "speaker": null,
      "confidence": 0.97
    }, {
      "end": 71122,
      "text": "What",
      "start": 70762,
      "speaker": null,
      "confidence": 0.99976
    }, {
      "end": 71302,
      "text": "were",
      "start": 71155,
      "speaker": null,
      "confidence": 0.99701
    }, {
      "end": 71542,
      "text": "they",
      "start": 71320,
      "speaker": null,
      "confidence": 0.99735
    }, {
      "end": 71887,
      "text": "looking",
      "start": 71590,
      "speaker": null,
      "confidence": 0.99901
    }, {
      "end": 72575,
      "text": "for?",
      "start": 71950,
      "speaker": null,
      "confidence": 0.99936
    }, {
      "end": 73372,
      "text": "There",
      "start": 73012,
      "speaker": null,
      "confidence": 0.70997
    }, {
      "end": 73597,
      "text": "was",
      "start": 73405,
      "speaker": null,
      "confidence": 0.96476
    }, {
      "end": 73912,
      "text": "a",
      "start": 73630,
      "speaker": null,
      "confidence": 0.98
    }, {
      "end": 74412,
      "text": "woman",
      "start": 73975,
      "speaker": null,
      "confidence": 0.99894
    }, {
      "end": 74997,
      "text": "crossing",
      "start": 74487,
      "speaker": null,
      "confidence": 0.85234
    }, {
      "end": 75247,
      "text": "the",
      "start": 75042,
      "speaker": null,
      "confidence": 0.98
    }, {
      "end": 75875,
      "text": "road",
      "start": 75280,
      "speaker": null,
      "confidence": 0.98318
    }, {
      "end": 77512,
      "text": "and",
      "start": 76837,
      "speaker": null,
      "confidence": 0.95
    }, {
      "end": 78222,
      "text": "holding",
      "start": 77650,
      "speaker": null,
      "confidence": 0.99726
    }, {
      "end": 78472,
      "text": "her",
      "start": 78267,
      "speaker": null,
      "confidence": 0.99481
    }, {
      "end": 78822,
      "text": "dress",
      "start": 78505,
      "speaker": null,
      "confidence": 0.43811
    }, {
      "end": 79475,
      "text": "up",
      "start": 78867,
      "speaker": null,
      "confidence": 0.98
    }, {
      "end": 80872,
      "text": "and",
      "start": 80512,
      "speaker": null,
      "confidence": 0.66
    }, {
      "end": 81297,
      "text": "showing",
      "start": 80905,
      "speaker": null,
      "confidence": 0.50332
    }, {
      "end": 81502,
      "text": "her",
      "start": 81342,
      "speaker": null,
      "confidence": 0.99005
    }, {
      "end": 81852,
      "text": "ankle",
      "start": 81520,
      "speaker": null,
      "confidence": 0.49442
    }, {
      "end": 82072,
      "text": "to",
      "start": 81882,
      "speaker": null,
      "confidence": 0.95
    }, {
      "end": 82207,
      "text": "the",
      "start": 82105,
      "speaker": null,
      "confidence": 0.6
    }, {
      "end": 82867,
      "text": "ear",
      "start": 82210,
      "speaker": null,
      "confidence": 0.90623
    }, {
      "end": 83912,
      "text": "tut.",
      "start": 83065,
      "speaker": null,
      "confidence": 0.83649
    }, {
      "end": 85107,
      "text": "Florence.",
      "start": 84262,
      "speaker": null,
      "confidence": 0.98895
    }, {
      "end": 85852,
      "text": "Yes.",
      "start": 85197,
      "speaker": null,
      "confidence": 0.5
    }, {
      "end": 86347,
      "text": "Tell",
      "start": 86020,
      "speaker": null,
      "confidence": 0.99993
    }, {
      "end": 86572,
      "text": "me",
      "start": 86380,
      "speaker": null,
      "confidence": 0.9999
    }, {
      "end": 86707,
      "text": "a",
      "start": 86605,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 87097,
      "text": "bit",
      "start": 86710,
      "speaker": null,
      "confidence": 0.99931
    }, {
      "end": 87652,
      "text": "about",
      "start": 87205,
      "speaker": null,
      "confidence": 0.99982
    }, {
      "end": 88042,
      "text": "how",
      "start": 87745,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 88297,
      "text": "you",
      "start": 88090,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 88797,
      "text": "managed",
      "start": 88330,
      "speaker": null,
      "confidence": 0.72524
    }, {
      "end": 89047,
      "text": "to",
      "start": 88842,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 89362,
      "text": "start",
      "start": 89080,
      "speaker": null,
      "confidence": 0.99979
    }, {
      "end": 89692,
      "text": "your",
      "start": 89425,
      "speaker": null,
      "confidence": 0.99906
    }, {
      "end": 89992,
      "text": "own",
      "start": 89740,
      "speaker": null,
      "confidence": 0.99957
    }, {
      "end": 90447,
      "text": "beauty",
      "start": 90040,
      "speaker": null,
      "confidence": 0.98574
    }, {
      "end": 91100,
      "text": "business.",
      "start": 90492,
      "speaker": null,
      "confidence": 0.99995
    }, {
      "end": 92782,
      "text": "No",
      "start": 92287,
      "speaker": null,
      "confidence": 0.99416
    }, {
      "end": 93537,
      "text": "lady",
      "start": 92860,
      "speaker": null,
      "confidence": 0.73847
    }, {
      "end": 93982,
      "text": "worked",
      "start": 93612,
      "speaker": null,
      "confidence": 0.94544
    }, {
      "end": 94297,
      "text": "in",
      "start": 94060,
      "speaker": null,
      "confidence": 0.99129
    }, {
      "end": 94567,
      "text": "those",
      "start": 94330,
      "speaker": null,
      "confidence": 0.99326
    }, {
      "end": 95225,
      "text": "days.",
      "start": 94615,
      "speaker": null,
      "confidence": 0.7895
    }, {
      "end": 96657,
      "text": "She's",
      "start": 96112,
      "speaker": null,
      "confidence": 0.32153
    }, {
      "end": 96892,
      "text": "only",
      "start": 96672,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 97102,
      "text": "a",
      "start": 96940,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 97692,
      "text": "companion",
      "start": 97120,
      "speaker": null,
      "confidence": 0.99934
    }, {
      "end": 97972,
      "text": "or",
      "start": 97752,
      "speaker": null,
      "confidence": 0.99118
    }, {
      "end": 98152,
      "text": "a",
      "start": 98005,
      "speaker": null,
      "confidence": 0.95
    }, {
      "end": 98892,
      "text": "teacher.",
      "start": 98170,
      "speaker": null,
      "confidence": 0.999
    }, {
      "end": 99322,
      "text": "She",
      "start": 99027,
      "speaker": null,
      "confidence": 0.99799
    }, {
      "end": 99547,
      "text": "could",
      "start": 99355,
      "speaker": null,
      "confidence": 0.67714
    }, {
      "end": 99727,
      "text": "be",
      "start": 99580,
      "speaker": null,
      "confidence": 0.99763
    }, {
      "end": 99877,
      "text": "a",
      "start": 99745,
      "speaker": null,
      "confidence": 0.98
    }, {
      "end": 100362,
      "text": "hospital",
      "start": 99895,
      "speaker": null,
      "confidence": 0.99719
    }, {
      "end": 101162,
      "text": "nurse,",
      "start": 100437,
      "speaker": null,
      "confidence": 0.99131
    }, {
      "end": 104497,
      "text": "but",
      "start": 104137,
      "speaker": null,
      "confidence": 0.78499
    }, {
      "end": 104767,
      "text": "not",
      "start": 104530,
      "speaker": null,
      "confidence": 0.99474
    }, {
      "end": 105662,
      "text": "private.",
      "start": 104815,
      "speaker": null,
      "confidence": 0.99883
    }, {
      "end": 106522,
      "text": "So",
      "start": 106162,
      "speaker": null,
      "confidence": 0.85857
    }, {
      "end": 106702,
      "text": "it",
      "start": 106555,
      "speaker": null,
      "confidence": 0.99668
    }, {
      "end": 106897,
      "text": "was",
      "start": 106720,
      "speaker": null,
      "confidence": 0.99851
    }, {
      "end": 107525,
      "text": "difficult",
      "start": 106930,
      "speaker": null,
      "confidence": 0.99963
    }, {
      "end": 109297,
      "text": "for",
      "start": 108937,
      "speaker": null,
      "confidence": 0.99806
    }, {
      "end": 109702,
      "text": "women?",
      "start": 109330,
      "speaker": null,
      "confidence": 0.99912
    }, {
      "end": 110547,
      "text": "Shockingly",
      "start": 109795,
      "speaker": null,
      "confidence": 0.9747
    }, {
      "end": 111247,
      "text": "difficult.",
      "start": 110592,
      "speaker": null,
      "confidence": 0.99954
    }, {
      "end": 111772,
      "text": "I",
      "start": 111430,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 111952,
      "text": "can",
      "start": 111805,
      "speaker": null,
      "confidence": 0.98841
    }, {
      "end": 112102,
      "text": "tell",
      "start": 111970,
      "speaker": null,
      "confidence": 0.99982
    }, {
      "end": 112297,
      "text": "you",
      "start": 112120,
      "speaker": null,
      "confidence": 0.89
    }, {
      "end": 112432,
      "text": "a",
      "start": 112330,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 113047,
      "text": "story",
      "start": 112435,
      "speaker": null,
      "confidence": 0.5808
    }, {
      "end": 113707,
      "text": "about",
      "start": 113230,
      "speaker": null,
      "confidence": 0.5812
    }, {
      "end": 114157,
      "text": "that.",
      "start": 113785,
      "speaker": null,
      "confidence": 0.99034
    }, {
      "end": 114472,
      "text": "That",
      "start": 114235,
      "speaker": null,
      "confidence": 0.96957
    }, {
      "end": 114787,
      "text": "was",
      "start": 114505,
      "speaker": null,
      "confidence": 0.96636
    }, {
      "end": 115387,
      "text": "also,",
      "start": 114850,
      "speaker": null,
      "confidence": 0.99543
    }, {
      "end": 115822,
      "text": "I",
      "start": 115525,
      "speaker": null,
      "confidence": 0.91
    }, {
      "end": 116137,
      "text": "think,",
      "start": 115855,
      "speaker": null,
      "confidence": 0.99828
    }, {
      "end": 116377,
      "text": "in",
      "start": 116200,
      "speaker": null,
      "confidence": 0.98458
    }, {
      "end": 117062,
      "text": "Punch.",
      "start": 116395,
      "speaker": null,
      "confidence": 0.91723
    }, {
      "end": 121582,
      "text": "A",
      "start": 121087,
      "speaker": null,
      "confidence": 0.75
    }, {
      "end": 122322,
      "text": "scraggy",
      "start": 121660,
      "speaker": null,
      "confidence": 0.22762
    }, {
      "end": 123062,
      "text": "woman",
      "start": 122367,
      "speaker": null,
      "confidence": 0.98095
    }, {
      "end": 124362,
      "text": "seating",
      "start": 123787,
      "speaker": null,
      "confidence": 0.58664
    }, {
      "end": 124627,
      "text": "the",
      "start": 124437,
      "speaker": null,
      "confidence": 0.89
    }, {
      "end": 124992,
      "text": "chair",
      "start": 124645,
      "speaker": null,
      "confidence": 0.76017
    }, {
      "end": 125227,
      "text": "with",
      "start": 125052,
      "speaker": null,
      "confidence": 0.2502
    }, {
      "end": 125377,
      "text": "a",
      "start": 125245,
      "speaker": null,
      "confidence": 0.87
    }, {
      "end": 125662,
      "text": "girl",
      "start": 125395,
      "speaker": null,
      "confidence": 0.99913
    }, {
      "end": 126147,
      "text": "playing",
      "start": 125725,
      "speaker": null,
      "confidence": 0.91
    }, {
      "end": 126307,
      "text": "on",
      "start": 126192,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 126472,
      "text": "the",
      "start": 126310,
      "speaker": null,
      "confidence": 0.71
    }, {
      "end": 127092,
      "text": "floor.",
      "start": 126505,
      "speaker": null,
      "confidence": 0.50228
    }, {
      "end": 127657,
      "text": "About",
      "start": 127227,
      "speaker": null,
      "confidence": 0.99887
    }, {
      "end": 128017,
      "text": "four",
      "start": 127735,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 128362,
      "text": "years",
      "start": 128065,
      "speaker": null,
      "confidence": 0.99627
    }, {
      "end": 129050,
      "text": "old",
      "start": 128425,
      "speaker": null,
      "confidence": 0.99012
    }, {
      "end": 129997,
      "text": "and",
      "start": 129637,
      "speaker": null,
      "confidence": 0.95
    }, {
      "end": 130222,
      "text": "an",
      "start": 130030,
      "speaker": null,
      "confidence": 0.98786
    }, {
      "end": 130632,
      "text": "awful",
      "start": 130255,
      "speaker": null,
      "confidence": 0.71609
    }, {
      "end": 131062,
      "text": "looking",
      "start": 130722,
      "speaker": null,
      "confidence": 0.99156
    }, {
      "end": 131482,
      "text": "man",
      "start": 131125,
      "speaker": null,
      "confidence": 0.99574
    }, {
      "end": 131797,
      "text": "this",
      "start": 131560,
      "speaker": null,
      "confidence": 0.9589
    }, {
      "end": 132247,
      "text": "side",
      "start": 131830,
      "speaker": null,
      "confidence": 0.7839
    }, {
      "end": 132622,
      "text": "in",
      "start": 132355,
      "speaker": null,
      "confidence": 0.99306
    }, {
      "end": 132802,
      "text": "an",
      "start": 132655,
      "speaker": null,
      "confidence": 0.99329
    }, {
      "end": 133077,
      "text": "arm",
      "start": 132820,
      "speaker": null,
      "confidence": 0.9865
    }, {
      "end": 133787,
      "text": "jet.",
      "start": 133107,
      "speaker": null,
      "confidence": 0.31353
    }, {
      "end": 135587,
      "text": "Mother,",
      "start": 134812,
      "speaker": null,
      "confidence": 0.85718
    }, {
      "end": 137017,
      "text": "when",
      "start": 136612,
      "speaker": null,
      "confidence": 0.99878
    }, {
      "end": 137272,
      "text": "I",
      "start": 137065,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 137502,
      "text": "grew",
      "start": 137305,
      "speaker": null,
      "confidence": 0.53515
    }, {
      "end": 137722,
      "text": "up,",
      "start": 137532,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 137947,
      "text": "should",
      "start": 137755,
      "speaker": null,
      "confidence": 0.99356
    }, {
      "end": 138172,
      "text": "I",
      "start": 137980,
      "speaker": null,
      "confidence": 0.72
    }, {
      "end": 138352,
      "text": "have",
      "start": 138205,
      "speaker": null,
      "confidence": 0.98891
    }, {
      "end": 138502,
      "text": "to",
      "start": 138370,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 138697,
      "text": "be",
      "start": 138520,
      "speaker": null,
      "confidence": 0.99643
    }, {
      "end": 139412,
      "text": "married?",
      "start": 138730,
      "speaker": null,
      "confidence": 0.99941
    }, {
      "end": 140317,
      "text": "Yes,",
      "start": 139912,
      "speaker": null,
      "confidence": 0.98
    }, {
      "end": 141062,
      "text": "dear.",
      "start": 140365,
      "speaker": null,
      "confidence": 0.90692
    }, {
      "end": 143572,
      "text": "Should",
      "start": 143212,
      "speaker": null,
      "confidence": 0.42932
    }, {
      "end": 143752,
      "text": "I",
      "start": 143605,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 143902,
      "text": "have",
      "start": 143770,
      "speaker": null,
      "confidence": 0.87965
    }, {
      "end": 144052,
      "text": "to",
      "start": 143920,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 144327,
      "text": "marry",
      "start": 144070,
      "speaker": null,
      "confidence": 0.99837
    }, {
      "end": 144457,
      "text": "a",
      "start": 144357,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 144712,
      "text": "man",
      "start": 144460,
      "speaker": null,
      "confidence": 0.73725
    }, {
      "end": 145042,
      "text": "like",
      "start": 144775,
      "speaker": null,
      "confidence": 0.99658
    }, {
      "end": 145787,
      "text": "Father?",
      "start": 145090,
      "speaker": null,
      "confidence": 0.58904
    }, {
      "end": 146617,
      "text": "Yes,",
      "start": 146212,
      "speaker": null,
      "confidence": 0.95
    }, {
      "end": 147362,
      "text": "dear.",
      "start": 146665,
      "speaker": null,
      "confidence": 0.97822
    }, {
      "end": 149422,
      "text": "And",
      "start": 149062,
      "speaker": null,
      "confidence": 0.72
    }, {
      "end": 149647,
      "text": "if",
      "start": 149455,
      "speaker": null,
      "confidence": 0.54194
    }, {
      "end": 149962,
      "text": "I",
      "start": 149680,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 150297,
      "text": "don't",
      "start": 150025,
      "speaker": null,
      "confidence": 0.6249
    }, {
      "end": 150672,
      "text": "marry",
      "start": 150342,
      "speaker": null,
      "confidence": 0.99814
    }, {
      "end": 151102,
      "text": "him,",
      "start": 150717,
      "speaker": null,
      "confidence": 0.99924
    }, {
      "end": 151447,
      "text": "should",
      "start": 151195,
      "speaker": null,
      "confidence": 0.80037
    }, {
      "end": 151627,
      "text": "I",
      "start": 151480,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 151822,
      "text": "be",
      "start": 151645,
      "speaker": null,
      "confidence": 0.69164
    }, {
      "end": 152047,
      "text": "an",
      "start": 151855,
      "speaker": null,
      "confidence": 0.99511
    }, {
      "end": 152362,
      "text": "old",
      "start": 152080,
      "speaker": null,
      "confidence": 0.99972
    }, {
      "end": 152997,
      "text": "maid",
      "start": 152425,
      "speaker": null,
      "confidence": 0.91125
    }, {
      "end": 153532,
      "text": "like",
      "start": 153117,
      "speaker": null,
      "confidence": 0.99424
    }, {
      "end": 154002,
      "text": "Aunt",
      "start": 153610,
      "speaker": null,
      "confidence": 0.84748
    }, {
      "end": 155087,
      "text": "Eliza?",
      "start": 154032,
      "speaker": null,
      "confidence": 0.54988
    }, {
      "end": 156967,
      "text": "Yes,",
      "start": 156562,
      "speaker": null,
      "confidence": 0.97
    }, {
      "end": 157712,
      "text": "dear.",
      "start": 157015,
      "speaker": null,
      "confidence": 0.98033
    }, {
      "end": 159942,
      "text": "Silence",
      "start": 159262,
      "speaker": null,
      "confidence": 0.66473
    }, {
      "end": 160177,
      "text": "for",
      "start": 160002,
      "speaker": null,
      "confidence": 0.77887
    }, {
      "end": 160775,
      "text": "me.",
      "start": 160195,
      "speaker": null,
      "confidence": 0.99028
    }, {
      "end": 162667,
      "text": "Say,",
      "start": 162262,
      "speaker": null,
      "confidence": 0.78987
    }, {
      "end": 163137,
      "text": "Mother,",
      "start": 162715,
      "speaker": null,
      "confidence": 0.70013
    }, {
      "end": 163527,
      "text": "it's",
      "start": 163212,
      "speaker": null,
      "confidence": 0.46389
    }, {
      "end": 163702,
      "text": "a",
      "start": 163557,
      "speaker": null,
      "confidence": 0.97
    }, {
      "end": 163942,
      "text": "hard",
      "start": 163720,
      "speaker": null,
      "confidence": 0.99837
    }, {
      "end": 164242,
      "text": "world",
      "start": 163990,
      "speaker": null,
      "confidence": 0.53173
    }, {
      "end": 164497,
      "text": "for",
      "start": 164290,
      "speaker": null,
      "confidence": 0.56075
    }, {
      "end": 164722,
      "text": "us",
      "start": 164530,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 164902,
      "text": "for",
      "start": 164755,
      "speaker": null,
      "confidence": 0.72753
    }, {
      "end": 165142,
      "text": "me,",
      "start": 164920,
      "speaker": null,
      "confidence": 0.55927
    }, {
      "end": 165477,
      "text": "isn't",
      "start": 165190,
      "speaker": null,
      "confidence": 0.95697
    }, {
      "end": 166100,
      "text": "it?",
      "start": 165507,
      "speaker": null,
      "confidence": 0.603
    }, {
      "end": 167572,
      "text": "Have",
      "start": 167212,
      "speaker": null,
      "confidence": 0.99911
    }, {
      "end": 167752,
      "text": "you",
      "start": 167605,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 167992,
      "text": "ever",
      "start": 167770,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 168247,
      "text": "been",
      "start": 168040,
      "speaker": null,
      "confidence": 0.99982
    }, {
      "end": 168427,
      "text": "in",
      "start": 168280,
      "speaker": null,
      "confidence": 0.99691
    }, {
      "end": 168622,
      "text": "an",
      "start": 168445,
      "speaker": null,
      "confidence": 0.99673
    }, {
      "end": 169562,
      "text": "airplane?",
      "start": 168655,
      "speaker": null,
      "confidence": 0.87987
    }, {
      "end": 170825,
      "text": "Never.",
      "start": 170062,
      "speaker": null,
      "confidence": 0.99685
    }, {
      "end": 171967,
      "text": "Would",
      "start": 171562,
      "speaker": null,
      "confidence": 0.73535
    }, {
      "end": 172177,
      "text": "you",
      "start": 172015,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 172462,
      "text": "like",
      "start": 172195,
      "speaker": null,
      "confidence": 0.77463
    }, {
      "end": 173152,
      "text": "to?",
      "start": 172525,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 173647,
      "text": "I",
      "start": 173320,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 174072,
      "text": "shouldn't",
      "start": 173680,
      "speaker": null,
      "confidence": 0.77393
    }, {
      "end": 174412,
      "text": "mind",
      "start": 174117,
      "speaker": null,
      "confidence": 0.2461
    }, {
      "end": 174877,
      "text": "now,",
      "start": 174475,
      "speaker": null,
      "confidence": 0.93
    }, {
      "end": 175177,
      "text": "but",
      "start": 174970,
      "speaker": null,
      "confidence": 0.98264
    }, {
      "end": 175372,
      "text": "I",
      "start": 175195,
      "speaker": null,
      "confidence": 0.91
    }, {
      "end": 175647,
      "text": "wouldn't.",
      "start": 175405,
      "speaker": null,
      "confidence": 0.93035
    }, {
      "end": 175897,
      "text": "When",
      "start": 175692,
      "speaker": null,
      "confidence": 0.99693
    }, {
      "end": 176122,
      "text": "they",
      "start": 175930,
      "speaker": null,
      "confidence": 0.991
    }, {
      "end": 176572,
      "text": "first",
      "start": 176155,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 176992,
      "text": "came",
      "start": 176680,
      "speaker": null,
      "confidence": 0.99957
    }, {
      "end": 177650,
      "text": "in,",
      "start": 177040,
      "speaker": null,
      "confidence": 0.99626
    }, {
      "end": 178822,
      "text": "I",
      "start": 178462,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 179092,
      "text": "never",
      "start": 178855,
      "speaker": null,
      "confidence": 0.99881
    }, {
      "end": 179622,
      "text": "thanked",
      "start": 179140,
      "speaker": null,
      "confidence": 0.3581
    }, {
      "end": 180322,
      "text": "them.",
      "start": 179667,
      "speaker": null,
      "confidence": 0.97799
    }, {
      "end": 181252,
      "text": "But",
      "start": 180505,
      "speaker": null,
      "confidence": 0.65674
    }, {
      "end": 181747,
      "text": "I",
      "start": 181420,
      "speaker": null,
      "confidence": 0.9
    }, {
      "end": 182052,
      "text": "won't",
      "start": 181780,
      "speaker": null,
      "confidence": 0.51679
    }, {
      "end": 182407,
      "text": "know",
      "start": 182082,
      "speaker": null,
      "confidence": 0.79089
    }, {
      "end": 182767,
      "text": "because",
      "start": 182485,
      "speaker": null,
      "confidence": 0.99689
    }, {
      "end": 183027,
      "text": "I'm",
      "start": 182815,
      "speaker": null,
      "confidence": 0.83518
    }, {
      "end": 183247,
      "text": "more",
      "start": 183057,
      "speaker": null,
      "confidence": 0.83
    }, {
      "end": 184412,
      "text": "adventuresome.",
      "start": 183280,
      "speaker": null,
      "confidence": 0.35624
    }, {
      "end": 187902,
      "text": "You've",
      "start": 187537,
      "speaker": null,
      "confidence": 0.79266
    }, {
      "end": 188347,
      "text": "been",
      "start": 187932,
      "speaker": null,
      "confidence": 0.99676
    }, {
      "end": 188812,
      "text": "very",
      "start": 188455,
      "speaker": null,
      "confidence": 0.99893
    }, {
      "end": 189702,
      "text": "adventurous",
      "start": 188875,
      "speaker": null,
      "confidence": 0.59433
    }, {
      "end": 190117,
      "text": "right",
      "start": 189807,
      "speaker": null,
      "confidence": 0.98
    }, {
      "end": 190417,
      "text": "through",
      "start": 190165,
      "speaker": null,
      "confidence": 0.99827
    }, {
      "end": 190672,
      "text": "your",
      "start": 190465,
      "speaker": null,
      "confidence": 0.99754
    }, {
      "end": 191212,
      "text": "life.",
      "start": 190705,
      "speaker": null,
      "confidence": 0.99962
    }, {
      "end": 191727,
      "text": "What's",
      "start": 191350,
      "speaker": null,
      "confidence": 0.76563
    }, {
      "end": 191902,
      "text": "the",
      "start": 191757,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 192267,
      "text": "biggest",
      "start": 191920,
      "speaker": null,
      "confidence": 0.9879
    }, {
      "end": 192592,
      "text": "change",
      "start": 192327,
      "speaker": null,
      "confidence": 0.99842
    }, {
      "end": 192802,
      "text": "of",
      "start": 192640,
      "speaker": null,
      "confidence": 0.99
    }, {
      "end": 192997,
      "text": "all",
      "start": 192820,
      "speaker": null,
      "confidence": 0.99921
    }, {
      "end": 193302,
      "text": "you've",
      "start": 193030,
      "speaker": null,
      "confidence": 0.87611
    }, {
      "end": 193792,
      "text": "seen?",
      "start": 193332,
      "speaker": null,
      "confidence": 0.99976
    }, {
      "end": 194197,
      "text": "The",
      "start": 193915,
      "speaker": null,
      "confidence": 0.98
    }, {
      "end": 194517,
      "text": "biggest",
      "start": 194230,
      "speaker": null,
      "confidence": 0.5338
    }, {
      "end": 195200,
      "text": "change?",
      "start": 194577,
      "speaker": null,
      "confidence": 0.99055
    }, {
      "end": 196325,
      "text": "Everything.",
      "start": 195562,
      "speaker": null,
      "confidence": 0.99832
    }, {
      "end": 198352,
      "text": "Nothing",
      "start": 197812,
      "speaker": null,
      "confidence": 0.68506
    }, {
      "end": 198742,
      "text": "is",
      "start": 198445,
      "speaker": null,
      "confidence": 0.98915
    }, {
      "end": 198952,
      "text": "the",
      "start": 198790,
      "speaker": null,
      "confidence": 1.0
    }, {
      "end": 199462,
      "text": "same.",
      "start": 198970,
      "speaker": null,
      "confidence": 0.99987
    }, {
      "end": 200167,
      "text": "Everything",
      "start": 199600,
      "speaker": null,
      "confidence": 0.99835
    }, {
      "end": 200662,
      "text": "has",
      "start": 200290,
      "speaker": null,
      "confidence": 0.68309
    }, {
      "end": 201162,
      "text": "changed.",
      "start": 200725,
      "speaker": null,
      "confidence": 0.99137
    }],
    "status": "completed",
    "summary": null,
    "chapters": null,
    "entities": null,
    "audio_url": "https://storage.googleapis.com/staging-videoo-storage-2/133a56e4-b004-471c-85da-036d15caadc0?Expires=1668603570&GoogleAccessId=cloudrun-674%40videoo-365200.iam.gserviceaccount.com&Signature=gE7AjtK7eJICp1eCpz2%2B5R2MfuFcsTOfbIazocroT2q34a6dEfFJK90iEQAFI6DGhg0tVFn5qZ53DNs5sD%2BRR6ZQTIaFnIRQXuGApn32zXmOyxhimkaPxvGBAnknr6aF49i507h87TWKuSRAtEr4w5xpQf5fv7UODhpa6clQ3i3vHHzIKEXnLOC56WTKo93ayanU%2FI%2FgvtwRzYLD%2FDKvXw69AbmBupWJe%2F8azjsgqL3fIX3%2FsdoC82Ov45yM2hiu1jVSgBuxS%2FqmEXEYshZCXBTEUekvgq47WO63qTDVV4v10bxg16fnot1nQ%2BSaGhGOK8lwYfMAHcLJahL5tZHb1A%3D%3D",
    "punctuate": true,
    "throttled": null,
    "cluster_id": null,
    "confidence": 0.895999914529914,
    "redact_pii": false,
    "utterances": null,
    "word_boost": [],
    "boost_param": null,
    "format_text": true,
    "speed_boost": false,
    "webhook_url": "https://api.videoo.dev/subtitle/webhook/14352d40-7ae4-4d37-bd2a-5e2256a9dbc2/0ba4db00-6865-4bb9-910d-5948f62f6b4f/133a56e4-b004-471c-85da-036d15caadc0/2",
    "audio_end_at": null,
    "disfluencies": false,
    "dual_channel": null,
    "summary_type": null,
    "webhook_auth": false,
    "auto_chapters": false,
    "language_code": "en_us",
    "summarization": false,
    "acoustic_model": "assemblyai_default",
    "audio_duration": 218,
    "content_safety": false,
    "iab_categories": false,
    "language_model": "assemblyai_default",
    "redact_pii_sub": null,
    "speaker_labels": false,
    "auto_highlights": false,
    "custom_spelling": null,
    "audio_start_from": null,
    "entity_detection": false,
    "filter_profanity": false,
    "redact_pii_audio": false,
    "language_detection": false,
    "sentiment_analysis": false,
    "redact_pii_policies": null,
    "webhook_status_code": null,
    "content_safety_labels": {
      "status": "unavailable",
      "results": [],
      "summary": {}
    },
    "iab_categories_result": {
      "status": "unavailable",
      "results": [],
      "summary": {}
    },
    "auto_highlights_result": null,
    "redact_pii_audio_quality": null,
    "webhook_auth_header_name": null,
    "sentiment_analysis_results": null
  }
}

export const translateWebhookData = {
  "Code": "Subtitle Webhook GET success, please POST for webhook.",
  "state": 2,
  "lang": "en",
  "target_lang": "null",
  "vendor": "SPEECHMATICS",
  "manifest": {
    "job": {
      "id": "sls31p9btr",
      "duration": 22,
      "data_name": "",
      "created_at": "2023-04-05T08:27:20.032Z"
    },
    "format": "2.9",
    "results": [
      {
        "type": "word",
        "end_time": 0.54,
        "start_time": 0.18,
        "alternatives": [
          {
            "content": "Now",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 0.75,
        "start_time": 0.54,
        "alternatives": [
          {
            "content": "let's",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 0.93,
        "start_time": 0.75,
        "alternatives": [
          {
            "content": "meet",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 1.26,
        "start_time": 0.93,
        "alternatives": [
          {
            "content": "someone",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 1.38,
        "start_time": 1.26,
        "alternatives": [
          {
            "content": "who",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 1.53,
        "start_time": 1.38,
        "alternatives": [
          {
            "content": "can",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 1.86,
        "start_time": 1.53,
        "alternatives": [
          {
            "content": "bring",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 1.98,
        "start_time": 1.86,
        "alternatives": [
          {
            "content": "a",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 2.19,
        "start_time": 1.98,
        "alternatives": [
          {
            "content": "little",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 2.52,
        "start_time": 2.19,
        "alternatives": [
          {
            "content": "history",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 2.73,
        "start_time": 2.52,
        "alternatives": [
          {
            "content": "to",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 3.07,
        "start_time": 2.73,
        "alternatives": [
          {
            "content": "us",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "punctuation",
        "is_eos": true,
        "end_time": 3.07,
        "start_time": 3.07,
        "attaches_to": "previous",
        "alternatives": [
          {
            "content": ".",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 3.24,
        "start_time": 3.09,
        "alternatives": [
          {
            "content": "Her",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 3.45,
        "start_time": 3.24,
        "alternatives": [
          {
            "content": "name",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 3.57,
        "start_time": 3.45,
        "alternatives": [
          {
            "content": "is",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 3.9,
        "start_time": 3.57,
        "alternatives": [
          {
            "content": "Mrs.",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 4.32,
        "start_time": 3.9,
        "alternatives": [
          {
            "content": "Florence",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 4.8,
        "start_time": 4.32,
        "alternatives": [
          {
            "content": "panel",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "punctuation",
        "is_eos": false,
        "end_time": 4.8,
        "start_time": 4.8,
        "attaches_to": "previous",
        "alternatives": [
          {
            "content": ",",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 5.13,
        "start_time": 4.8,
        "alternatives": [
          {
            "content": "and",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 5.52,
        "start_time": 5.13,
        "alternatives": [
          {
            "content": "she's",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 6.66,
        "start_time": 5.52,
        "alternatives": [
          {
            "content": "108",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 6.93,
        "start_time": 6.66,
        "alternatives": [
          {
            "content": "years",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 7.5,
        "start_time": 6.93,
        "alternatives": [
          {
            "content": "old",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "punctuation",
        "is_eos": true,
        "end_time": 7.5,
        "start_time": 7.5,
        "attaches_to": "previous",
        "alternatives": [
          {
            "content": ".",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 10.33,
        "start_time": 9.85,
        "alternatives": [
          {
            "content": "Unlike",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 10.69,
        "start_time": 10.33,
        "alternatives": [
          {
            "content": "most",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 10.99,
        "start_time": 10.69,
        "alternatives": [
          {
            "content": "women",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 11.11,
        "start_time": 10.99,
        "alternatives": [
          {
            "content": "of",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 11.29,
        "start_time": 11.11,
        "alternatives": [
          {
            "content": "her",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 11.71,
        "start_time": 11.29,
        "alternatives": [
          {
            "content": "time",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "punctuation",
        "is_eos": false,
        "end_time": 11.71,
        "start_time": 11.71,
        "attaches_to": "previous",
        "alternatives": [
          {
            "content": ",",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 12.19,
        "start_time": 11.71,
        "alternatives": [
          {
            "content": "Florence",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 12.58,
        "start_time": 12.19,
        "alternatives": [
          {
            "content": "Pannell",
            "speaker": "S1",
            "language": "en",
            "confidence": 0.84
          }
        ]
      },
      {
        "type": "word",
        "end_time": 13,
        "start_time": 12.58,
        "alternatives": [
          {
            "content": "managed",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 13.15,
        "start_time": 13,
        "alternatives": [
          {
            "content": "to",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 13.39,
        "start_time": 13.15,
        "alternatives": [
          {
            "content": "set",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 13.6,
        "start_time": 13.39,
        "alternatives": [
          {
            "content": "up",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 13.81,
        "start_time": 13.6,
        "alternatives": [
          {
            "content": "her",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 14.08,
        "start_time": 13.81,
        "alternatives": [
          {
            "content": "own",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 14.56,
        "start_time": 14.08,
        "alternatives": [
          {
            "content": "beauty",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 14.86,
        "start_time": 14.56,
        "alternatives": [
          {
            "content": "care",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 15.45,
        "start_time": 14.86,
        "alternatives": [
          {
            "content": "business",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "punctuation",
        "is_eos": true,
        "end_time": 15.45,
        "start_time": 15.45,
        "attaches_to": "previous",
        "alternatives": [
          {
            "content": ".",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 15.73,
        "start_time": 15.46,
        "alternatives": [
          {
            "content": "She",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 16,
        "start_time": 15.73,
        "alternatives": [
          {
            "content": "worked",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 16.18,
        "start_time": 16,
        "alternatives": [
          {
            "content": "for",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 16.48,
        "start_time": 16.18,
        "alternatives": [
          {
            "content": "many",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 16.96,
        "start_time": 16.48,
        "alternatives": [
          {
            "content": "years",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 17.32,
        "start_time": 16.96,
        "alternatives": [
          {
            "content": "in",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 17.86,
        "start_time": 17.32,
        "alternatives": [
          {
            "content": "Paris",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 18.31,
        "start_time": 17.86,
        "alternatives": [
          {
            "content": "and",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 18.43,
        "start_time": 18.31,
        "alternatives": [
          {
            "content": "in",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 18.97,
        "start_time": 18.43,
        "alternatives": [
          {
            "content": "London",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "punctuation",
        "is_eos": false,
        "end_time": 18.97,
        "start_time": 18.97,
        "attaches_to": "previous",
        "alternatives": [
          {
            "content": ",",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 19.27,
        "start_time": 18.97,
        "alternatives": [
          {
            "content": "and",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 19.45,
        "start_time": 19.27,
        "alternatives": [
          {
            "content": "she",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 19.69,
        "start_time": 19.45,
        "alternatives": [
          {
            "content": "knows",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 19.75,
        "start_time": 19.69,
        "alternatives": [
          {
            "content": "a",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 20.05,
        "start_time": 19.75,
        "alternatives": [
          {
            "content": "great",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 20.38,
        "start_time": 20.05,
        "alternatives": [
          {
            "content": "deal",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 20.68,
        "start_time": 20.38,
        "alternatives": [
          {
            "content": "about",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 21.46,
        "start_time": 20.68,
        "alternatives": [
          {
            "content": "fashion",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      },
      {
        "type": "word",
        "end_time": 22.14,
        "start_time": 21.76,
        "alternatives": [
          {
            "content": "floors",
            "speaker": "S1",
            "language": "en",
            "confidence": 0.23
          }
        ]
      },
      {
        "type": "punctuation",
        "is_eos": true,
        "end_time": 22.14,
        "start_time": 22.14,
        "attaches_to": "previous",
        "alternatives": [
          {
            "content": ".",
            "speaker": "S1",
            "language": "en",
            "confidence": 1
          }
        ]
      }
    ],
    "metadata": {
      "type": "transcription",
      "created_at": "2023-04-05T08:27:33.305429Z",
      "language_pack_info": {
        "itn": true,
        "adapted": false,
        "word_delimiter": " ",
        "writing_direction": "left-to-right",
        "language_description": "English"
      },
      "translation_config": {
        "target_languages": [
          "fr"
        ]
      },
      "transcription_config": {
        "language": "en",
        "diarization": "speaker"
      }
    },
    "translations": {
      "fr": [
        {
          "content": "Maintenant, rencontrons quelqu'un qui peut nous apporter un peu d'histoire.",
          "speaker": "S1",
          "end_time": 3.07,
          "start_time": 0.18
        },
        {
          "content": "Son nom est Mme Florence panel, et elle a 108 ans.",
          "speaker": "S1",
          "end_time": 7.5,
          "start_time": 3.09
        },
        {
          "content": "Contrairement à la plupart des femmes de son temps, Florence Pannell a réussi à créer sa propre entreprise de soins de beauté.",
          "speaker": "S1",
          "end_time": 15.45,
          "start_time": 9.85
        },
        {
          "content": "Elle a travaillé pendant de nombreuses années à Paris et à Londres, et elle en sait beaucoup sur les planchers de mode.",
          "speaker": "S1",
          "end_time": 22.14,
          "start_time": 15.46
        }
      ]
    }
  }
}

export const tempUserPlan = {
  username_email: 'utkubulkan@gmail.com',
  stripe_customer_id: 'cus_NTqz02ZhedmRxR',
  clientsecret: 'pi_3Mj62LLS6SANVcyC0LqDED8G_sec',
  stripe_subscription_id: 'Sub_1Mj62LLS6SANVcyC1',
  'product.plan': 3,
  active: true
}

export const userWorkSpaceListData = [
  {
    "uuid": "2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9",
    "title": "Desmond's workspace-1",
    "user": "zengda0501@gmail.com",
    "level": 0
  },
  {
    "uuid": "cac967bd-fe5c-4d3c-bf78-149169e54a30",
    "title": "Desmond's workspace-1",
    "user": "zengda0501@gmail.com",
    "level": 0
  }, {
    "uuid": "5c18504a-8078-4f45-8a92-e20083461e58",
    "title": "Desmond's workspace-2",
    "user": "zengda0501@gmail.com",
    "level": 0
  }, {
    "uuid": "fc13581e-cd67-4d60-83a2-4e67e79f6ae5",
    "title": "Desmond's workspace-3",
    "user": "zengda0501@gmail.com",
    "level": 0
  }
]

export const workspace_memberData = [
  {
    uuid: "fb65f768-d2a1-432c-8899-52ddf8c9bd7e",
    title: "",
    user: "zengda0501@gmail.com",
    level: 0
  }, {
    uuid: "fb65f768-d2a1-432c-8899-63ddf8c9bd7e",
    title: "",
    user: "smirnovvvasyl@gmail.com",
    level: 1
  }
]

export const workspace_invitationData = [
  {
    uuid: "fb65f768-d2a1-432c-8899-52ddf8c9bd7e",
    email: "zengda0501@gmail.com",
  }, {
    uuid: "fb65f768-d2a1-432c-8899-63ddf8c9bd7e",
    email: "smirnovvvasyl@gmail.com",
  }
]

export const workspaceProjectData = [
  {
    "uuid": "af9a795f-7940-48e1-84de-cb0ab8d868dd",
    "title": "Project - 0",
    "updated_at": "2023-05-31 13:34:49"
  }
]