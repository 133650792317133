import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase, Stack} from "@mui/material"
import ImageIcon from '@mui/icons-material/Image';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';

export const PixabayImageModal = (props) => {
  const {stockData} = props;

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Pixabay Stock Image
          </Typography>
          <Typography variant="overline" component={'p'}>
            id: <b>{stockData?.id}</b>
          </Typography>
          {/* <Typography variant="overline" component={'p'}>
            pageLink: {stockData?.pageURL}
          </Typography> */}
          <Typography variant="overline" component={'p'}>
            Width x Height: <b>{stockData?.imageWidth} X {stockData?.imageHeight}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Image Size: <b>{stockData?.imageSize}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Views: <b>{stockData?.views}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Downloads: <b>{stockData?.downloads}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Collections: <b>{stockData?.collections}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Likes: <b>{stockData?.likes}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Comments: <b>{stockData?.comments}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Tags: <b>{stockData?.tags}</b>
          </Typography>
          <Typography variant="overline" sx={{display: 'flex'}} component={'p'}>
            User: <Avatar src={stockData?.userImageURL} sx={{ marginX: '5px', width: 24, height: 24 }}/><b>{stockData?.user}</b>
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <div style={{position: 'relative'}}>
            <img 
              src={stockData?.previewURL}
              style={{width: '100%'}}
            />
            <div className='stockMediaBar'>
              <Stack direction='row' justifyContent='start' alignItems='center'>
                <ImageIcon sx={{ color: 'white' }}/>
                <Typography color={'white'} variant='span'>{stockData?.imageWidth} X {stockData?.imageHeight}</Typography>
              </Stack>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}