import { setUpTextTexture } from "../Setups/SetupClass";
import { UpdateBuffers } from "../Setups/SetupClass";
import { UpdateTextTextures } from "../Setups/SetupClass";

export const DragResizeInitialCalculator = (elementOb, canvas, modelViewMatrix, projectJSON) => {
  let distanceLeft = elementOb.coordinate.x;
  let distanceTop = elementOb.coordinate.y;
  let divHeight = elementOb.dimension.h;
  let divWidth = elementOb.dimension.w;

  const canvasH = canvas.offsetHeight;
  const canvasW = canvas.offsetWidth;
  const ratio = canvasW / projectJSON.width;
  const ratioWH = elementOb.ratioWH;
  const ratioW = projectJSON.width / ratioWH.w;
  const ratioH = projectJSON.height / ratioWH.h;

  distanceLeft = distanceLeft * ratio * ratioW;
  distanceTop = distanceTop * ratio * ratioH;
  divHeight = divHeight * ratio * ratioH;
  divWidth = divWidth * ratio * ratioW;

  let moveableY;
  let moveableX;
  let elementAspectRatio = divHeight / divWidth;
  let canvasAspectRatio = canvasH / canvasW;

  moveableY = (-distanceTop + (canvasH - divHeight) / 2) / (divHeight / 2);
  moveableX = (distanceLeft - (canvasW - divWidth) / 2) / elementAspectRatio / (divWidth / 2);

  let resizeInteger;
  let theCoefficient = 2.415;

  let zIndexAdjuster = toMinus(elementOb.coordinate.zIndex);
  if (divWidth / divHeight > canvasW / canvasH) {
    let updatedCoefficient = -theCoefficient * (canvasAspectRatio / elementAspectRatio);
    resizeInteger = ((canvasW - divWidth) * updatedCoefficient) / divWidth;
  } else resizeInteger = -((canvasH - divHeight) * theCoefficient) / divHeight;


  let zIndexRatio = canvasW / divWidth;
  if (divWidth / divHeight < canvasW / canvasH) zIndexRatio = canvasH / divHeight

  modelViewMatrix[12] = moveableX;
  modelViewMatrix[13] = moveableY;
  modelViewMatrix[14] = resizeInteger;
  modelViewMatrix[11] = zIndexAdjuster * zIndexRatio;

  return modelViewMatrix;
}

export const RotationInitialCalculator = (rotation, modelViewMatrix) => {
  // This part is about rotation
  // parsing the text and calculating the rotation
  let moveableRotationdegree = rotation;

  // rotation in radian
  let moveableRotation = moveableRotationdegree * (Math.PI / 180);

  modelViewMatrix[0] = Math.cos(moveableRotation);
  modelViewMatrix[1] = -Math.sin(moveableRotation);
  modelViewMatrix[4] = Math.sin(moveableRotation);
  modelViewMatrix[5] = Math.cos(moveableRotation);

  return modelViewMatrix;
}

export const RotationCalculator = (theRotationText, modelViewMatrix) => {
  // This part is about rotation
  // parsing the text and calculating the rotation
  let indexRotateStart = theRotationText.indexOf('rotate');
  let indexRotateEnd = theRotationText.indexOf('deg)');

  let moveableRotationdegree = theRotationText.slice(indexRotateStart + 7, indexRotateEnd);

  // rotation in radian
  let moveableRotation = moveableRotationdegree * (Math.PI / 180);

  modelViewMatrix[0] = Math.cos(moveableRotation);
  modelViewMatrix[1] = -Math.sin(moveableRotation);
  modelViewMatrix[4] = Math.sin(moveableRotation);
  modelViewMatrix[5] = Math.cos(moveableRotation);

  return modelViewMatrix;
}

export const DragResizeCalculator = (elementInfo, canvas, modelViewMatrix, type, webGLContext, itemKey, gl, projectJSON, edit) => {
  // *********************************************
  // first part is about translate (Drag)
  // parsing the text
  let canvasWidth = canvas.offsetWidth
  let canvasHeight = canvas.offsetHeight
  let { left, top, width, height, zIndex } = elementInfo

  let moveableY, moveableX
  let elRatio = height / width
  let cRatio = canvasHeight / canvasWidth

  moveableY = (-top + (canvasHeight - height) / 2) / (height / 2)
  moveableX = (left - (canvasWidth - width) / 2) / elRatio / (width / 2)

  let resizeInteger, theCoefficient = 2.415;
  if (width / height > canvasWidth / canvasHeight) {
    let updatedCoefficient = -theCoefficient * (cRatio / elRatio)
    resizeInteger = ((canvasWidth - width) * updatedCoefficient) / width
  } else resizeInteger = -((canvasHeight - height) * theCoefficient) / height

  if (type === 'text') {
    let tempBuffer = webGLContext.activeBuffers.get(itemKey)
    tempBuffer = UpdateBuffers(tempBuffer, gl, height, width, canvasHeight, canvasWidth);

    const textCanvas = webGLContext.activeTextCanvases.get(itemKey)
    const ctx = textCanvas.getContext('2d')

    let textInfo = projectJSON[type][itemKey]
    let tempTextCanvas = setUpTextTexture(ctx, width, height, textInfo, projectJSON, canvas, !!edit)
    let tempTexture = UpdateTextTextures(gl, webGLContext.activeTextures.get(itemKey), tempTextCanvas)

    webGLContext.activeBuffers.set(itemKey, tempBuffer)
    webGLContext.activeTextures.set(itemKey, tempTexture)
    webGLContext.activeTextCanvases.set(itemKey, tempTextCanvas)

    let zIndexAdjuster = toMinus(zIndex)
    let zIndexRatio = canvasWidth / width;
    if (width / height < canvasWidth / canvasHeight) zIndexRatio = canvasWidth / height
    modelViewMatrix[11] = zIndexAdjuster * zIndexRatio;
  }

  modelViewMatrix[12] = moveableX
  modelViewMatrix[13] = moveableY
  modelViewMatrix[14] = resizeInteger

  return modelViewMatrix;
}

const toMinus = (num) => {
  let tempNum = num;
  if (typeof tempNum !== 'number') tempNum = 0;
  if (tempNum > 9) tempNum = 9;
  if (tempNum < -9) tempNum = -9;

  return (tempNum + 9) / -2;
}

