import { createContext } from "react";

export const WebGLContext = createContext();
export const WebGLDefaultData = {
  activeModelViewMatrices: null,
  allModelViewMatrices: null,
  activeBuffers: null,
  allBuffers: null,
  activeTextures: null,
  allTextures: null,
  activeTextCanvases: null,
  allTextCanvases: null,

  webGLRef: null,
  moveableParentRef: null,
  programInfo: null,

  dragingGroups: null,
  dropedGroup: null,

  transition: {
    type: '',
    moving: false,
  },
  tsManual: {
    type: '',
    state: false,
    tsID: '',
    clashID: '',
    flag: '',
  }
}

export const DefaultTextData = {
  context: "",
  contextutf16: "",
  is_flipped: 0,
  rotation: 0,
  dimension: { h: 0, w: 0 },
  coordinate: { x: 0, y: 0, zIndex: 0 },
  ratioWH: { w: 1, h: 1 },
  alpha: 1,
  properties: {
    bold: "bold",
    font_size: 40,
    italic: "italic",
    text_color: "#000000ff",
    alignment: "center",
    background_color: "#ffffffff",
    font_family: "BrunoAceSC",
  },
  transition: {
    start: { preset: "-", duration: 0, associated_texture : "-", associated_texture_type: "-"},
    end: { preset: "-", duration: 0, associated_texture : "-", associated_texture_type: "-"}
  },
  duration: 0,
  timeline_end: 0,
  timeline_start: 0,
  groups: null,
  subtitle_uuid: null,
  metadata: null,
  metadatautf16: null,
  sequence: 0,
}

export const DefaultVideoData = {
  disable_video: false,
  asset_uuid: "",
  is_flipped: 0,
  volume: 1,
  fade_in_audio: false,
  fade_out_audio: false,
  rotation: 0,
  dimension: { h: 0, w: 0 },
  coordinate: { x: 0, y: 0, zIndex: 0 },
  ratioWH: { w: 1, h: 1 },
  alpha: 1,

  duration: 0,
  segment_start: 0,
  segment_end: 0,
  timeline_end: 0,
  timeline_start: 0,
  signed_url: "",

  transition: {
    start: { preset: "-", duration: 0, associated_texture: "-", associated_texture_type: "-"},
    end: { preset: "-", duration: 0, associated_texture: "-", associated_texture_type: "-"}
  },
  filters: { value: "#FFFFFFFF", preset: "" },
  groups: null,
  sequence: 0,
}

export const defaultAudioData = {
  asset_uuid: "",
  volume: 1,
  fade_in_audio: false,
  fade_out_audio: false,
  duration: 0,
  segment_start: 0,
  segment_end: 0,
  timeline_end: 0,
  timeline_start: 0,
  signed_url: "",
  groups: null,
  sequence: 0,
}

export const DefaultImageData = {
  rotation: 0,
  dimension: { h: 0, w: 0 },
  coordinate: { x: 0, y: 0, zIndex: 0 },
  duration: 0,
  alpha: 1,

  asset_uuid: null,
  filters: { value: "#FFFFFFFF" },
  transition: {
    start: { preset: "-", duration: 0, associated_texture : "-", associated_texture_type: "-"},
    end: { preset: "-", duration: 0, associated_texture : "-", associated_texture_type: "-"}
  },

  timeline_start: 0,
  timeline_end: 0,
  sequence: 0,
}

export const DefaultAssetData = {
  filters: { value: "#FFFFFFFF", preset: "" },
  cdn_url: "",
  signed_url: "",
  type: 0,
  thumbnail: {}
}

// tooltap config Start
export const webGlSizeOptions = [
  {
    ratio: '9:16',
    label: 'Portrait',
    size: { x: 20, y: 30 },
    wSize: { width: 1080, height: 1920 }
  }, {
    ratio: '1:1',
    label: 'Square',
    size: { x: 30, y: 30 },
    wSize: { width: 1080, height: 1080 },
  }, {
    ratio: '16:9',
    label: 'Youtube',
    size: { x: 32, y: 16 },
    wSize: { width: 1920, height: 1080 },
  }, {
    ratio: '16:9',
    label: 'Landscape',
    size: { x: 32, y: 16 },
    wSize: { width: 1080, height: 566 },
  }, {
    ratio: '4:5',
    label: 'Twitter & Facebook Portrait',
    size: { x: 24, y: 30 },
    wSize: { width: 1080, height: 1350 },
  }, {
    ratio: '5:4',
    label: 'Twitter & Facebook Landscape',
    size: { x: 30, y: 24 },
    wSize: { width: 1080, height: 566 },
  }, {
    ratio: '9:16',
    label: 'Instagram Story',
    size: { x: 16, y: 32 },
    wSize: { width: 1080, height: 1921 },
  }, {
    ratio: '9:16',
    label: 'TikTok',
    size: { x: 16, y: 32 },
    wSize: { width: 1080, height: 1922 },
  }, {
    ratio: '2:3',
    label: 'Pinterest',
    size: { x: 20, y: 30 },
    wSize: { width: 1200, height: 1800 }
  }
];
// tooltap config End

export const visibilityList = [
  "PUBLIC", "CUSTOM"
]

export const categoryList = [
  "TRENDING", "STORIES_AND_REELS", "SOCIAL_MARKETING",
  "CORPORATE_MEDIA", "EVENTS", "EDUCATIONAL_MEDIA", "AUDIO_CONTENT",
  "GENERAL_MEDIA"
]