import { v4 as uuidv4 } from 'uuid';
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { changedJSON_Store, thumbNails_Store, uploadingFiles_Store } from "../../../store/actions/projectdata"; 
import { dataLoaded_Store, imageElements_Store } from "../../../store/actions/webgldata";
import { getImageInformation } from "../webGL/commonFunc";
import { Box, Typography, Grid, Slider, MenuItem, FormControl, Select, Button, Collapse } from '@mui/material';
import { SectionHeader } from './common/section.header';
import { changeZeroSecond, getMaxZIndex } from "../commonFunction";
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import { getChatgptdalle, genChatgptdalle, getProjectJSON, GetRegistration, SERVER_STATUS } from "../../../axios/ApiProvider";
import PricingModal from '../../../pages/Pricing/pricingModal';
let tempProjectJSON = null;
let tempLoadingData = null;

export const TooltapAiDirector = (props) => {

  const dispatch = useDispatch();
  const projectUUID = GetProjectUUID();
  const [userData, setUserData] = useState({})
  const [aiImgRemains, setAiImgRemains] = useState('0 of 0');
  const loginState = useSelector((store) => store.maindata.loginState)
  const HeaderInfo = { title: "Ai Director", className: 'pl-3' };
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const uploadingFiles = useSelector((store) => store.projectdata.uploadingFiles);
  const thumbNails = useSelector(store => store.projectdata.thumbNails);
    const imageElements = useSelector(store => store.webgldata.imageElements);
    const Time_Count = useSelector((store) => store.videodata.Time_Count);
  const [textValue, setTextValue] = useState('');
  const [sizeVal, setSizeVal] = useState('Landscape');
  const [aiResData, setAiResData] = useState(null);
  const [showPricingPageModal, setShowPricingPageModal] = useState(false)
  const [creatingAiImg, setCreatingAiImg] = useState(false);
  useEffect(() => {
    // getChatgptdalle().then((reponseData => {
    //   if(reponseData.state == 'success'){
    //     setAiResData(reponseData.data);
    //   }  
    // }));
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') {
      tempData = JSON.parse(item)
      setAiImgRemains(tempData.aiimage_status);
    }

    setUserData(tempData)
  }, [loginState])

  const textchanged = (textVal) => {
    setTextValue(textVal);
  }

  const [openSetting, setOpenSetting] = useState(false);
  const handleOpenSettings = () => {
    setOpenSetting(!openSetting);
  }

  useEffect(() => { 
    tempProjectJSON = { ...ProjectJSON };
 
  }, [ProjectJSON]);

  useEffect(() => { tempLoadingData = { ...uploadingFiles } }, [uploadingFiles]);

  const crateAiImg = async () => {
    setCreatingAiImg(true);  
    const getAiRes = await getChatgptdalle();
    if(getAiRes.state != 'success'){
      setCreatingAiImg(false);
      return;
    } 
    setAiResData(getAiRes.data);  
    
    const reponseData = await genChatgptdalle(getAiRes.data.uuid, textValue, sizeVal);
    if(reponseData.state !== "success" ){
      setCreatingAiImg(false);
      window.toastr.error('AI image creation is failed');
      return;
    }
    if(reponseData.data.asset_url){
      
      const newUUID = uuidv4();
      const originalFileName = reponseData.data.asset_original_filename;
      const mimeType = reponseData.data.asset_mime_type;
      // let blob = await fetch(reponseData.data.url, {mode: 'no-cors'}).then(r => r.blob());
      //const drawImagUrl = URL.createObjectURL(blob);
      const drawImagUrl = reponseData.data.asset_url;
      // let img = new Image();
      // img.onload = function () {
      // };
      // img.crossOrigin = "anonymous";
      // img.src = drawImagUrl;
      let fileData = { pc: 0, type: 'image', name: originalFileName, state: false }
        dispatch(uploadingFiles_Store({ ...uploadingFiles, [newUUID]: fileData }));
        dispatch(dataLoaded_Store(false));
  
      let maxZIndex = getMaxZIndex(ProjectJSON);
      let props = [ProjectJSON, originalFileName, mimeType, drawImagUrl, newUUID, projectUUID, 'image', maxZIndex];
      
      const updatedUserData = await GetRegistration();
      setUserData(updatedUserData.data)
      setAiImgRemains(updatedUserData.data.aiimage_status);

      await getImageInformation(...props, async(img, imageInfo) => {
          let imgThumbNail = [{uuid: uuidv4(), content_type: 'blob', signed_url: drawImagUrl }];
          dispatch(thumbNails_Store({ ...thumbNails, [imageInfo.asset_uuid]: imgThumbNail }));
          let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
          let startTime = Time_Count.getTime()
          const imgKeys = Object.keys(ProjectJSON.images);
          if(imgKeys.length > 0){
            // imgGroupId = imgKeys[0];
            imgKeys.map(keyItem => {
              if(startTime < ProjectJSON.images[keyItem].timeline_end){
                startTime = ProjectJSON.images[keyItem].timeline_end;
              }
            })
          }
          imageInfo = {
            ...imageInfo,
            timeline_start: startTime,
            timeline_end: imageInfo.duration + startTime
          }
          changedJSON.images = {
            [newUUID]: imageInfo
          }

          dispatch(imageElements_Store({ ...imageElements, [newUUID]: img }));
          dispatch(changedJSON_Store(changedJSON));
          changeZeroSecond(imageInfo, dispatch);
        }, async (newAssets, assetUUID) => {
          let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
          if (!tempProjectJSON.images[newUUID]) return;

          const JSONdata = await getProjectJSON(projectUUID);
          const projectdata = JSONdata?.data[projectUUID];

          if(JSONdata.state !== 'success' || SERVER_STATUS === 'localhost')
            changedData = { ...changedData, asset: { ...newAssets } };
          else changedData = { ...changedData, asset: projectdata.asset };
          let fileItem = { ...tempLoadingData[newUUID], state: true };
          dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }));
          dispatch(changedJSON_Store(changedData));
          setCreatingAiImg(false);
        }, () => {dispatch(dataLoaded_Store(true));}, (e) => {
          let pc = parseFloat(e.loaded / e.total * 100).toFixed(2);
          let fileItem = { ...tempLoadingData[newUUID], pc: Number(pc) };
          dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
        })
      // let fileData = { pc: 0, type: 'image', name: originalFileName, state: false }
      // dispatch(uploadingFiles_Store({ ...uploadingFiles, [newUUID]: fileData }));
      // dispatch(dataLoaded_Store(false));

      // let maxZIndex = getMaxZIndex(ProjectJSON);
      // let props = [ProjectJSON, originalFileName, mimeType, drawImagUrl, newUUID, projectUUID, 'image', maxZIndex];
    }
  }

  const onCreateAiImage = () => {
    if(!loginState || userData?.aiimage_state === false || userData?.state === "anonymous" || userData.aiimage_remaining < 1) {
      // return setShowPricingPageModal(true)
      
      setShowPricingPageModal(true)
    }else{
      crateAiImg();
    }
    
  }
  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }
  return (
    <>
      <SectionHeader {...HeaderInfo} />
      <Box md={12} className='px-10 pt-0'>
        <Typography className='v-block-footer mb-2'>Tell me what you want the AI to draw for you?</Typography>
        
        <Box className='v-hoverborder v-radius'>
          <Box className='v-inputbox px-10 py-5'>
            <textarea rows={3}
              value={textValue}
              className='default-input w-full'
              onChange={({ target }) => {
                textchanged(target.value)
              }}
            />

          </Box>
        </Box>
        <Typography variant='body2' paddingY={1}>{aiImgRemains} AI Image credits remain.</Typography>
        <Grid item xs={12}>
          <Button variant="outlined" fullWidth size='large' onClick={handleOpenSettings}>SETTING</Button>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={openSetting} timeout="auto" unmountOnExit>
            <Grid container>
              <Grid item xs={12}>
                
                <FormControl fullWidth className='v-autocomplate'>
                  <Select labelId="demo-simple-select-label"
                    className='v-hoverborder v-radius v-commonboxheight'
                    onChange={(e) => { console.log(e); setSizeVal(e.target.value) }}
                    value={sizeVal} label="Orientation">
                    <MenuItem value={'Landscape'} className='p-3 v-font-2-3'>LandScape</MenuItem>
                    <MenuItem value={'Portrait'} className='p-3 v-font-2-3'>Portrait</MenuItem>
                    <MenuItem value={'Square'} className='p-3 v-font-2-3'>Square</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        <Grid className='pt-8' item xs={12}>
          <Button className="mt-4" variant="outlined" disabled={creatingAiImg} fullWidth size='large' onClick={() => {onCreateAiImage()}}>Create AI Visual</Button>
        </Grid>
      </Box>
      <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />
    </>
      
  )
}