import fadeGL from './fade.js'
import bookflipGl from './bookfliip.js'
import desolveGl from './desolve.js'
import doorwayGl from './doorway.js'
import blurGl from './blur.js'
import pushGl from './push.js'
import slideGl from './slide.js'
import wipeGl from './wipe.js';
import zoominGl from './zoomin.js'
import zoomoutGl from './zoomout.js'

export const transitionGLSLData = [
  fadeGL, bookflipGl, desolveGl, doorwayGl, blurGl, pushGl, slideGl, wipeGl, zoominGl, zoomoutGl
]