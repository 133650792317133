import ReactLoading from 'react-loading'
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Slider, Stack, Typography } from '@mui/material';

import './videoDisplay.scss';
import { useWebGL } from '../videoEdit.provider';
import { WebGLStarter } from '../webGL/webGLStarter';
import { initVideoTexture, DrawScene } from '../webGL/Setups/SetupClass';
import { webGlSize_Store } from '../../../store/actions/webgldata';
import { displaySizeChange_Store } from '../../../store/actions/mainAction';
import { getGLContext, resizeImage } from '../webGL/commonFunc';
import { copyObject, getGroupoptions } from "../commonFunction"
import { SaveVideoPlaying_Store, SaveVideoBuffering_Store, TimeCount_Store } from '../../../store/actions/videoPlayAction';
import { createProjectThumbnail, uploadThumbnail } from "../../../axios/ApiProvider"
import { thumbnailState_Store, captureThumbnailFlag_store } from "../../../store/actions/projectdata"
import bgImg from "../../../assets/image/BlackScreen.png";
import { VideoSegmentSilder } from './VideoSegmentSilder';
export const PROJECT_THUMBNAIL_WIDTH = process.env.REACT_APP_PROJECT_THUMBNAIL_WIDTH

let resizeState = 0;
export const VideoDisplay = () => {
  const dispatch = useDispatch();
  const [webGLContext, setWebGLContext] = useWebGL();
  const webGlSize = useSelector(store => store.webgldata.webGlSize);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const activeVideoUUID = useSelector(store => store.projectdata.activeVideoUUID);
  const VideoPlaying = useSelector((store) => store.videodata.videoPlaying);
  const videoPlayerStarted = useSelector((store) => store.videodata.videoPlayerStarted);
  const videoBuffering = useSelector((store) => store.videodata.videoBuffering);
  const projectUUID = useSelector((store) => store.projectdata.projectUUID);
  const captureThumbnailFlag = useSelector(store => store.projectdata.captureThumbnailFlag);
  const timeCount = useSelector(store => store.videodata.Time_Count);
  const thumbnailState = useSelector(store => store.projectdata.thumbnailState);

  const isElementDraging = useSelector(store => store.editdata.isElementDraging);

  const webGLRef = useRef();
  const bufferRef = useRef(null);
  const moveableParentRef = useRef();
  
  const [cWidth, setCWidth] = useState();
  const [cHeight, setCHeight] = useState();

  useEffect(() => {
    const height = ProjectJSON?.height;
    const width = ProjectJSON?.width;
    if (!height || !width) return;
    if (ratio === height / width) return;

    dispatch(webGlSize_Store({ w: width, h: height }));
  }, [ProjectJSON]);

  const [resizeChange, setResizeChange] = useState(0);
  useEffect(() => { setResizeChange(resizeState++); }, [webGlSize]);
  useEffect(() => {
    window.addEventListener('resize', () => { setResizeChange(resizeState++); });
    firstWebGLsetFunc();
  }, [])

  const [status, setStatus] = useState(false);
  const ratio = webGlSize.h / webGlSize.w;
  useEffect(() => {
    let bufferEl = bufferRef.current;
    let mainCanvas = webGLRef.current;
    let moveableDiv = moveableParentRef.current
    const parentEl = mainCanvas.parentElement;
    const width = parentEl.offsetWidth;
    const height = parentEl.offsetHeight;

    if (width * ratio < height) {
      const W = width;
      const H = width * ratio;
      setCWidth(W);
      setCHeight(H);
      bufferEl.style.width = `${W}px`;
      bufferEl.style.height = `${H}px`;

      mainCanvas.style.width = `${W}px`;
      mainCanvas.style.height = `${H}px`;

      moveableDiv.style.width = `${W}px`;
      moveableDiv.style.height = `${H}px`;
    } else {
      const H = height;
      const W = height / ratio;
      setCWidth(W);
      setCHeight(H);
      bufferEl.style.width = `${W}px`;
      bufferEl.style.height = `${H}px`;

      mainCanvas.style.width = `${W}px`;
      mainCanvas.style.height = `${H}px`;
      
      moveableDiv.style.width = `${W}px`;
      moveableDiv.style.height = `${H}px`;
    }
    if (!status) setStatus(true);
    if (status) dispatch(displaySizeChange_Store(1));
  }, [resizeChange, ratio])

  const firstWebGLsetFunc = async () => {
    const canvasEl = webGLRef.current;
    const canvasgl = getGLContext(canvasEl)
    const initTexture = await initVideoTexture(canvasgl);

    const initTextureMap = new Map([['init', initTexture]]);
    const initBufferMap = new Map();
    const initModelViewMatricesMap = new Map();
    const initTextCanvasesMap = new Map();

    setWebGLContext({
      ...webGLContext,
      activeModelViewMatrices: initModelViewMatricesMap,
      allModelViewMatrices: initModelViewMatricesMap,
      activeBuffers: initBufferMap,
      allBuffers: initBufferMap,
      activeTextures: initTextureMap,
      allTextures: initTextureMap,
      activeTextCanvases: initTextCanvasesMap,
      allTextCanvases: initTextCanvasesMap,

      webGLRef: webGLRef.current,
      moveableParentRef: moveableParentRef.current
    });
  }

  const [seeking, setSeeking] = useState(false)

  useEffect(() => {
    let handler;
    if(activeVideoUUID.length > 0) {
      const checkReadyState = () => {
        const states = [];
        Object.keys(videoElements).map(key => {
          if(videoElements[key].readyState === 3 || videoElements[key].readyState === 2 || videoElements[key].readyState === 1) {
            states.push(videoElements[key].readyState);
          }
        });
    
        if(states.length > 0) {
          setSeeking(true);
        } else {
          setSeeking(false)
        }

        handler = setTimeout(() => {
          checkReadyState()
        }, 50)
      };
      checkReadyState();
    }

    return () => handler && clearTimeout(handler);
  }, [activeVideoUUID])
 
  useEffect(() => {
    if ((!!ProjectJSON?.videos || !!ProjectJSON?.images || !!ProjectJSON?.text) && !!webGLRef?.current) {
      if (thumbnailState) {
        dispatch(thumbnailState_Store(false))
        captureThumbnail(new Date(timeCount))
        //setTimeout(() => { captureThumbnail(new Date(timeCount)) }, 1000)
      }
    }
  }, [thumbnailState])

  useEffect(() => {
    if(captureThumbnailFlag) {
      captureThumbnail(new Date(timeCount))
      dispatch(captureThumbnailFlag_store(false))
    }

  }, [captureThumbnailFlag])

  const captureThumbnail = (time = new Date(0)) => {
    const mainCanvas = webGLRef.current
    const mainCanvasGl = getGLContext(mainCanvas)
    const groupData = getGroupoptions(ProjectJSON)

    DrawScene(mainCanvasGl, webGLContext, ProjectJSON, groupData, time)
    
    let drawCanvas = document.createElement('canvas');
    drawCanvas.width = cWidth;
    drawCanvas.height = cHeight;
    let drawGl = drawCanvas.getContext('2d');
    var newImg = document.createElement("img");
   // url = URL.createObjectURL(blob);
    newImg.width = cWidth;
    newImg.height = cHeight
    newImg.onload = function() {
      drawGl.drawImage(this, 0, 0, cWidth, cHeight);
   //   photo = blob;
      drawCanvas.toBlob(async(blob) =>{
        const fileName = "projectThumbnail.jpg"
        const fileType = { type: 'image/jpeg', lastModified: new Date().getTime() }

        const imageUrl = URL.createObjectURL(blob)
        const defaultFile = new File([blob], fileName, fileType);
        const img = new Image();
        img.onload = async function () {
          const ratio = this.width / this.height;
          let file = defaultFile;
          if(this.width > this.height && this.width > PROJECT_THUMBNAIL_WIDTH) {
            file = await resizeImage(defaultFile, PROJECT_THUMBNAIL_WIDTH, PROJECT_THUMBNAIL_WIDTH / ratio);
          }

          if(this.height > this.width && this.height > PROJECT_THUMBNAIL_WIDTH) {
            file = await resizeImage(defaultFile, PROJECT_THUMBNAIL_WIDTH * ratio, PROJECT_THUMBNAIL_WIDTH);
          }

          const result = await createProjectThumbnail(projectUUID);
      
          const thumbnail = result.data

          if (result.state !== 'success' || !thumbnail?.signedurl) {
            return
          }
          await uploadThumbnail(thumbnail.signedurl, file);

          drawCanvas.remove();
          newImg.remove();
        };
        img.src = imageUrl;
      })
     // URL.revokeObjectURL(url);
    };

    newImg.src = mainCanvas.toDataURL();

   /* mainCanvas.toBlob(async (blob) => {
      let drawCanvas = document.createElement('canvas');
      drawCanvas.width = cWidth;
      drawCanvas.height = cHeight;
      let drawGl = drawCanvas.getContext('2d');
      var newImg = document.createElement("img");
      url = URL.createObjectURL(blob);
      newImg.width = cWidth;
      newImg.height = cHeight
      newImg.onload = function() {
        drawGl.drawImage(this, 0, 0, cWidth, cHeight);
     //   photo = blob;
        console.log(drawCanvas.toDataURL())
       // URL.revokeObjectURL(url);
      };

      newImg.src = url;
//updating logic
      
      const fileName = "projectThumbnail.jpg"
      const fileType = { type: 'image/jpeg', lastModified: new Date().getTime() }

      const imageUrl = URL.createObjectURL(blob)
      const file = new File([blob], fileName, fileType);
      
      const result = await createProjectThumbnail(projectUUID);
      
      const thumbnail = result.data

      if (result.state !== 'success' || !thumbnail?.signedurl) {
        return
      }
      console.log("thumbnail Image", imageUrl);
      await uploadThumbnail(thumbnail.signedurl, file)
    })*/
  }

  useEffect(() => {
    if(seeking) {
      if(VideoPlaying) {
        dispatch(SaveVideoPlaying_Store(false));
        dispatch(SaveVideoBuffering_Store(true));
      }
    } else {
      
      setTimeout(() => {
        if(videoPlayerStarted && !VideoPlaying) {
          dispatch(SaveVideoPlaying_Store(true));
        }
        dispatch(SaveVideoBuffering_Store(false));
      }, 10)
    }
  }, [seeking, videoPlayerStarted, VideoPlaying])
  
  return (
    <Box className='v-video-container relative flex z-[2]'>
      <canvas ref={webGLRef}
        width={1920} height={1080}
        className='v-video-element'
      />
      <WebGLStarter ref={moveableParentRef} />
     
      {isElementDraging && 
        <div className='v-video-center-ruler'>
          <div className='v-video-vertical-rule'></div>
          <div className='v-video-horizen-rule'></div>
        </div>
      }
     
      <div ref={bufferRef} className={`buffering-wrapper ${videoBuffering ? 'visible' : 'invisible'}`}>
        <ReactLoading type='spinningBubbles' className='loading-icon' />
        <span className='text-16'>Bufferring</span>
      </div>
      <img src={bgImg} id='greenBg' style={{display: 'none', width: cWidth+'px', height: cHeight+'px'}}/>
      
      <VideoSegmentSilder />
    </Box>
  )
}