import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase, Stack} from "@mui/material"
import VideocamIcon from '@mui/icons-material/Videocam';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';

export const PexelsVideoModal = (props) => {
  const {stockData} = props;
  const renderDuration = (duration) =>{
    let subtitle = "";
    const minute = Math.floor(duration / 60);
    const second = Math.round((duration - minute * 60));
    subtitle += `   ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}  `;
    return subtitle;
  }

  return (
    <Box >
      <Grid container spacing={3}>
        <Grid item md={4} xs={12} >
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Pexels Stock Video
          </Typography>
          <Typography variant="overline" component={'p'}>
            Width: <b>{stockData?.width}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Height: <b>{stockData?.height}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Duration: <b>{stockData?.duration}</b>
          </Typography>
          <Typography variant="overline" component={'p'}>
            Url: <b>{stockData?.url}</b>
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <div style={{position: 'relative'}}>
            <video muted src={stockData?.video_files[0].link}>
            </video>
            <div className='stockMediaBar'>
              <Stack direction='row' justifyContent='start' alignItems='center'>
                <VideocamIcon sx={{ color: 'white' }}/>
                <Typography color={'white'} variant='span'>{renderDuration(stockData?.duration)}</Typography>
              </Stack>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} >
          <Stack sx={{border: '1px solid blue', overflow: 'hidden'}} direction={'row'} justifyContent={'center'} alignItems={'center'}>
            {stockData?.video_pictures.map((e, i) => {
              return <img src={e.picture} style={{height: '50px', border: '1px'}}/>
            })}
            
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}