import { Link } from "react-router-dom"
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Box, Menu, Divider } from "@mui/material"
import axios from 'axios';

import './header.scss';
import logoimg from '../../assets/image/Logo.png';
import { MenuIemsContainner } from './common/menuItems';
import { MobileMenu } from './common/mobileMenu';
import { BASE_BACKEND_URL } from "../../axios/ApiProvider"
import { tips } from '../../util'
const HOME_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_HOME_URL
export const Header = ({ pagename }) => {
	const loginState = useSelector((store) => store.maindata.loginState);

	const [userData, setUserData] = useState({})
	useEffect(() => {
		let tempData = {}
		let item = localStorage.getItem('user_data')
		if (!!item && JSON.parse(item)?.state === 'loggedin') tempData = JSON.parse(item)
		setUserData(tempData)
	}, [loginState])

	const toHome = e => {
		e.preventDefault();
		window.location.href = HOME_FRONTEND_URL;
	}

	return (
		pagename === 'pricing' ? <>
			<Box className="logoImg">
				<Link to={'..'}>
					<img src={logoimg} alt='logoimg' />
				</Link>
			</Box>

			<Box className="menu-containner">
				<Box className="menu-left">
					<MenuIemsContainner />

					<Link to='../pricing'>
						<Box className="pricing-btn">Pricing</Box>
					</Link>
				</Box>


				<Box className="menu-right">
					{!loginState ? <>
						<Link to='/signin' className="login-containner">
							<Box className="login-btn">Login</Box>
						</Link>

						<Link to='/signup' className="signUp-containner">
							<Box className="signUp-btn">Get Started-it's free</Box>
						</Link>
					</> : (
						<UserAvatar userData={userData} />
						// <Link to='/signout' className="signOut-containner">
						// 	<Box className="signOut-btn">Logout</Box>
						// </Link>
					)}
				</Box>
			</Box>

			<Box className="mobile-container">
				<UserAvatar userData={userData} />
				<MobileMenu pagename={pagename} />
			</Box>
		</> : <>
			<Box className="mobile-container"><MobileMenu /></Box>

			<Box className="logoImg">
				<Link to={'..'} onClick={toHome}>
					<img src={logoimg} alt='logoimg' />
				</Link>
			</Box>

			<Box className="menu-containner">
				<Box className="menu-left">
					<MenuIemsContainner />
				</Box>

				<Box className="menu-right">
					<Link to='../pricing'>
						<Box className="pricing-btn">Pricing</Box>
					</Link>

					{!loginState ? <>
						<Link to='/signin' className="login-containner">
							<Box className="login-btn">Login</Box>
						</Link>

						<Link to='/signup' className="signUp-containner">
							<Box className="signUp-btn">Sign Up</Box>
						</Link>
					</> : (
						<UserAvatar userData={userData} />
						// <Link to='/signout' className="signOut-containner">
						// 	<Box className="signOut-btn">Logout</Box>
						// </Link>
					)}
				</Box>
			</Box>
		</>
	)
}

export const UserAvatar = ({ userData, page }) => {
	const [planMenuEl, setPlanMenuEl] = useState(null)

	let firName = userData?.firstname || ''
	let lastname = userData?.lastname || ''

	const handleSignOut = () => {
		setPlanMenuEl(null)
		localStorage.clear();
		axios({
			method: 'get',
			url: `${BASE_BACKEND_URL}/registration/logout/`,
			withCredentials: true,
		}).then(res => {
			window.location.href = HOME_FRONTEND_URL;
		})
			.catch((err) => {
				tips('error', "Log out failure. Please try again.")
			});
	}

	return (
		<>
			{!!userData?.picture && (
				<img alt=''
					src={userData?.picture}
					className="w-40 h-40 rounded-md cursor-pointer"
					onClick={(e) => { setPlanMenuEl(e.currentTarget) }}
				/>
			)}

			{!userData?.picture && (
				<div onClick={(e) => { setPlanMenuEl(e.currentTarget) }}
					className="w-40 h-40 flex justify-center items-center bg-[#b66eff] rounded-full cursor-pointer"
				>
					<span className="text-15 text-white font-extrabold">
						{firName.slice(0, 1) + lastname.slice(0, 1)}
					</span>
				</div>
			)}

			<Menu
				anchorEl={planMenuEl}
				open={Boolean(planMenuEl)}
				onClose={() => { setPlanMenuEl(null) }}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
				transformOrigin={{ vertical: 'top', horizontal: 'right', }}
				PaperProps={{ sx: { mt: 1 } }}
				className="default-menu"
			>
				<div className="py-5 border-1 border-[#140505] bg-white rounded-lg">
					<div className="flex flex-col gap-5 items-center justify-center">
						<span className="flex flex-col gap-5 px-10 items-center justify-center cursor-pointer">
							<span className="text-15 font-semibold leading-none">
								{firName + ' ' + lastname}
							</span>

							<span className="text-10 font-semibold leading-none hidden md:block">
								{userData?.email}
							</span>
						</span>

						<Divider className="w-full" />
						{page !== 'dashboard' && (
							<>
							<a href={`${HOME_FRONTEND_URL}/dashboard/projects`} 
							onClick={() => { setPlanMenuEl(null) }} 
							className="px-10 text-15 font-semibold cursor-pointer">
								Dashboard
							</a>
							{/* <Link to='../../workspaces/projects'
								onClick={() => { setPlanMenuEl(null) }}
								className="px-10 text-15 font-semibold cursor-pointer"
							>Dashboard</Link> */}
							</>
						)}
						<a href={`${HOME_FRONTEND_URL}/dashboard/profile`} 
							onClick={() => { setPlanMenuEl(null) }} 
							className="px-10 text-15 font-semibold cursor-pointer">
							Profile
						</a>
						<a href={`${HOME_FRONTEND_URL}/dashboard/projects`} 
							onClick={() => { setPlanMenuEl(null) }} 
							className="px-10 text-15 font-semibold cursor-pointer">
							Projects
						</a>
						{/* <Link to='../../workspaces/projects'
							onClick={() => { setPlanMenuEl(null) }}
							className="px-10 text-15 font-semibold cursor-pointer"
						>Projects</Link> */}
						<a href={`${HOME_FRONTEND_URL}/dashboard/templates`} onClick={() => { setPlanMenuEl(null) }} className="px-10 text-15 font-semibold cursor-pointer">Templates</a>
						{/* <Link to='../../workspaces/templates'
							onClick={() => { setPlanMenuEl(null) }}
							className="px-10 text-15 font-semibold cursor-pointer"
						>Templates</Link> */}
						<a href={`${HOME_FRONTEND_URL}/dashboard/workspace`} onClick={() => { setPlanMenuEl(null) }} className="px-10 text-15 font-semibold cursor-pointer">Workspaces</a>
						{/* <Link to='../../workspaces/workspace	'
							onClick={() => { setPlanMenuEl(null) }}
							className="px-10 text-15 font-semibold cursor-pointer"
						>Workspaces</Link> */}
						
						<a href={`${HOME_FRONTEND_URL}/apikeys`} onClick={() => { setPlanMenuEl(null) }} className="px-10 text-15 font-semibold cursor-pointer md:hidden">Pricing</a>
						<a href={`${HOME_FRONTEND_URL}/pricing`} onClick={() => { setPlanMenuEl(null) }} className="px-10 text-15 font-semibold cursor-pointer md:hidden">Pricing</a>
						{/* <Link to='../../pricing'
							onClick={() => { setPlanMenuEl(null) }}
							className="px-10 text-15 font-semibold cursor-pointer md:hidden"
						>Pricing</Link> */}
						
						<a href={`${HOME_FRONTEND_URL}/signout`} onClick={handleSignOut} className="px-10 text-15 font-semibold cursor-pointer">Logout</a>
						{/* <Link to='../../signout'
							onClick={handleSignOut}
							className="px-10 text-15 font-semibold cursor-pointer"
						>Logout</Link> */}
					</div>
				</div>
			</Menu>
		</>
	)
}