export const editorLangGmStrs = {
    welcomNewProject : 'Willkommen bei Ihrem neuen Projekt',
    pls_add_media: 'Bitte fügen Sie Medien zu Ihrem Projekt hinzu',
    add_video: 'Video hinzufügen',
    add_audio: 'Audio hinzufügen',
    add_image: 'Bild hinzufügen',
    add_webcam: 'Webcam-Aufnahme',
    add_screen: 'Bildschirmaufnahme',
    add_webcam_screen: 'Webcam & Bildschirmaufnahme',
    capture_screen_camera: 'Erfassen Sie Ihren Bildschirm & Kamera!',
    video_captureDevice: 'Videofanggerät',
    resolution: 'Auflösung',
    audio_captureDevice: 'Audiofanggerät',
    resize_video: 'Video Größe ändern',
    rotate_video: 'Video drehen',
    loop_video: 'Video schleifen',
    joinmarge_video: 'Video hinzufügen/zusammenführen',
    cut_video: 'Video schneiden',
    crop_video: 'Video zuschneiden',
    translate_video: 'Video übersetzen',
    transcripbe_video: 'Video transkribieren',
    auto_subtitles: 'Automatische Untertitel',
    add_subtitle_video: 'Untertitel zum Video hinzufügen',
    compress_video: 'Video komprimieren',
    convert_video: 'Video konvertieren',
    project_not_accessible: 'Projekt ist nicht zugänglich',
    leave: 'Verlassen',
    cancel: 'Abbrechen',
    okay: 'OK',
    stop: 'Stoppen',
    record: 'Aufnehmen',
    ongoing_upload_project_str: 'Es läuft gerade ein Upload zu diesem Projekt.',
    leaving_page_sure: 'Sie verlassen diese Seite, sind Sie sicher?',
    uploading_files: 'Dateien hochladen',
    record_screen_and_upload: 'Nehmen Sie Ihren Bildschirm auf und laden Sie hoch.',
    show_defaul_sharingMenu: 'Standardfreigabemenü anzeigen',
    prefer_share_browser: 'Bevorzugen, einen Browser-Tab zu teilen',
    prefer_share_window: 'Bevorzugen, ein Fenster zu teilen',
    prefer_share_entireScreen: 'Bevorzugen, den ganzen Bildschirm zu teilen',
    record_webcam_and_upload: 'Nehmen Sie Ihre Webcam auf und laden Sie hoch.',
    sign_signup_to_uploadVideos: 'Bitte melden Sie sich an oder registrieren Sie sich, um Videos hochzuladen',
    sizelimir_or_timelimit: 'größer als 250 MB oder länger als 5 Minuten',
    screen_capture_preferences: 'Bildschirmaufnahme-Einstellungen',
    is_uploading: 'Wird hochgeladen',
    monthly: 'Monatlich',
    annually: 'Jährlich',
    most_popular: 'Am beliebtesten',
    export: 'Exportieren',
    bitrate: 'Bitrate',
    frame_rate_fps: 'Bildrate (FPS)',
    produce: 'Produzieren',
    setup: 'Einrichten',
    template: 'Vorlage',
    templates: 'Vorlagen',
    media: 'Medien',
    stock: 'Lager',
    translations: 'Übersetzungen',
    subtitles: 'Untertitel',
    text_to_speech: 'Text zu Sprache',
    transitions: 'Übergänge',
    text: 'Text',
    emojis: 'Emojis',
    filters: 'Filter',
    draw: 'Zeichnen',
    edit_subtitle: 'Untertitel bearbeiten',
    add_new_subtitle: 'neuen Untertitel hinzufügen',
    select_brush_stock_start_draw: 'Wählen Sie einen Pinselstrich, um mit dem Zeichnen zu beginnen',
    brush_stroke: 'Pinselstrich',
    round: 'Rund',
    sliced: 'Geschnitten',
    color: 'Farbe',
    opacity: 'Deckkraft',
    size: 'Größe',
    duration: 'Dauer',
    add_new_line: 'Neue Zeile hinzufügen',
    all_types: 'Alle Typen',
    load_more: 'Mehr laden',
    upload_video: 'Video hochladen',
    upload_audio: 'Audio hochladen',
    upload_image: 'Bild hochladen',
    create_webcam_recording: 'Webcam-Aufnahme erstellen',
    create_screen_capture_recording: 'Bildschirmaufnahme erstellen',
    create_webcam_screen_recording: 'Webcam & Bildschirmaufnahme erstellen',
    project_asset: 'Projektvermögen',
    you_want_add_translations: 'Wie möchten Sie Übersetzungen hinzufügen?',
    uploader: 'Uploader',
    auto: 'AUTO',
    auto_translations: 'Automatische Übersetzungen',
    automatically_add_transllations_video: 'Automatisch Übersetzungen zum Video hinzufügen',
    wait_moments_subtitles_being: 'Warten Sie einen Moment, während der Untertitel vorbereitet wird',
    select_language_detect: 'Sprache zur Erkennung auswählen',
    disable_translate: 'Übersetzen deaktivieren',
    select_language_select: 'Sprache zum Übersetzen auswählen',
    transition_duration: 'Übergangsdauer',
    coming_soon: 'Demnächst',
    language: 'Sprache',
    speaking_rate_defaul: 'Sprechgeschwindigkeit (Standard 1)',
    pitch_defaul: 'Tonhöhe (Standard 0)',
    volume_gain_default: 'Lautstärkeerhöhung (Standard 0)',
    create_text_speech: 'Text zu Sprache erstellen',
    add_text: 'Text hinzufügen',
    text: 'Text',
    start: 'Start',
    end: 'Ende',
    text_style: 'Textstil',
    background_color: 'Hintergrundfarbe',
    add_new_text_field: 'Neues Textfeld hinzufügen',
    how_add_subtitles: 'Wie möchten Sie Untertitel hinzufügen',
    subtitles_allowance_remaining: 'Untertitelzulage verbleibend (Minuten)',
    automatically_subtitles_video: 'Automatisch Untertitel zum Video hinzufügen',
    text_color: 'Textfarbe',
    preview_text: 'Textvorschau',
    search: 'Suchen',
    orientation: 'Orientierung',
    next: 'Weiter',
    previous: 'Vorherige',
    locale: 'Standort',
    landscape: 'Querformat',
    portrait: 'Hochformat',
    square: 'Quadratisch',
    time: 'Zeit',
    background: 'Hintergrund',
    project_setting: 'Projekteinstellungen',
    use_template_project: 'Als Vorlageprojekt verwenden',
    visibility: 'Sichtbarkeit',
    category: 'Kategorie',
    capture_thumbnail: 'Miniaturansicht erfassen',
    brand_kit: 'Marken-Kit',
    video_placeholder: 'Video-Platzhalter2.mp4',
    replace_video: 'Video ersetzen',
    transformations: 'Transformationen',
    fill_canvas: 'Die Leinwand füllen',
    fit_canvas: 'Auf die Leinwand anpassen',
    round_corners: 'Abgerundete Ecken',
    audio: 'Audio',
    detach_audio: 'Audio trennen',
    cleanAudio: 'CleanAudio',
    upgrade_basic_unlock: 'Auf BASIC upgraden, um freizuschalten',
    remove_background_noise_enhance_audio_qty: 'Hintergrundgeräusche entfernen und Audioqualität verbessern',
    color_correction: 'Farbkorrektur',
    reset: 'Zurücksetzen',
    exposure: 'Belichtung',
    brightness: 'Helligkeit',
    contract: 'Kontrast',
    saturation: 'Sättigung',
    sharpen: 'Schärfen',
    blur: 'Weichzeichnen',
    noise: 'Rauschen',
    trending: 'TRENDING',
    custom: 'BENUTZERDEFINIERT',
    portrait: 'HOCHFORMAT',
    landscape: 'QUERFORMAT',
    settings: 'Einstellungen',
    magic_draw: 'Magisches Zeichnen',
    tools: 'Werkzeuge',
    brush_size: 'Pinselgröße',
    save: 'Speichern',
    clear: 'Löschen',
    view_all: 'Alle anzeigen',
    save_your_project_later: 'Speichern Sie Ihr Projekt für später',
    sign_in: 'Anmelden',
    sign_up: 'Registrieren',
    redo: 'Wiederholen',
    undo: 'Rückgängig machen',
    collaborate: 'Kooperieren',
    enter_email_share_project: 'E-Mail eingeben, um Projekt zu teilen',
    share_project: 'Projekt teilen',
    create_new_workspace: 'Neuen Arbeitsbereich erstellen',
    audio_noise_remove: 'Entfernung von Audiorauschen',
    media_upload: 'Medien-Upload',
    draw_color: 'Farbe zeichnen',
    tsl_none: 'keiner',
    tsl_fade: 'Verblassen',
    tsl_book_flip: 'Buch-Flip',
    tsl_disolve: 'Sich auflösen',
    tsl_doorway: 'Tür',
    tsl_blur: 'Verwischen',
    tsl_push: 'Drücken',
    tsl_slide: 'Gleiten',
    tsl_wipe: 'Wischen',
    tsl_zoom_in: 'Hineinzoomen',
    tsl_zoom_out: 'Rauszoomen',
    flt_None: 'Keiner',
    flt_Darker: 'Dunkler',
    flt_BlacknWhite: 'Schwarz und Weiß',
    flt_Daphne: 'Seidelbast',
    flt_Penelope: 'Penelope',
    flt_Phoebe: 'Phoebe',
    flt_Thalia: 'Thalia',
    flt_Chloe: 'Chloe',
    flt_Hermione: 'Hermine',
    flt_Hera: 'Hera',
    flt_Rhea: 'Rhea',
    flt_Donna: 'Donna',
    flt_Martina: 'Martina',
    flt_Gabriella: 'Gabriella',
    flt_Elisabetta: 'Elisabetta',
    flt_Isabella: 'Isabella',
    flt_Luna: 'Luna',
    flt_Viola: 'Viola',
    flt_Zita: 'Zita',
    flt_Donata: 'Donata',
    flt_Maddalena: 'Maddalena',
    flt_Tecla: 'Tecla',
    flt_Narcisa: 'Narcisa',
    emj_Smileys: 'Smileys',
    emj_Animals: 'Tiere',
    emj_People: 'Menschen',
    emj_Jobs: 'Arbeitsplätze',
    emj_Sports: 'Sport',
    emj_Flags: 'Flaggen',
    emj_Signs: 'Zeichen',
    emj_Nature: 'Natur',
    emj_Travel: 'Reisen',
    emj_Letters: 'Briefe',
    emj_Misc: 'Sonstiges',
    create_empty_project_templates: 'Erstellen Sie ein leeres Projekt aus Vorlagen',
    days_ago: 'Vor Tagen',
    create_workspace: 'Arbeitsbereich erstellen',
    workspace: 'Arbeitsbereiche',
    delete_workspace: 'Arbeitsbereich löschen',
    transfer_owner: 'Eigentümer übertragen',
    can_create_new_workspace: 'Könnten Sie einen neuen Arbeitsbereich erstellen?',
    could_delete_workspace: 'Könnten Sie den Arbeitsbereich löschen?',
    available_subtitles_time: 'Verfügbare Untertitelzeit',
    available_storage_space: 'Verfügbarer Speicherplatz',
    other_workspaces: 'Andere Arbeitsbereiche',
    members: 'Mitglieder',
    add_member: 'Mitglied hinzufügen',
    remove_member: 'Mitglied entfernen',
    change_member_level: 'Mitgliedsebene ändern',
    owner: 'Eigentümer',
    admin: 'Administratorin',
    member: 'Member',
    read_only: 'Schreibgeschützt',
    invitation_list: 'Einladungsliste',
    duplicate: 'Duplikat',
    splite: 'Teilt',
    remove: 'entfernen',
    layer: 'Schicht',
    volume: 'Volumen',
    deleterow: 'Zeile löschen',
    fullscreen: 'Ganzer Bildschirm',
    editsubtitle: 'Untertitel bearbeiten',
    fit: 'Fit',
    green_screen: 'Grüner Bildschirm',
};