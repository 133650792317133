import { wrapText } from '../Setups/SetupClass';
import { setUpTextTexture } from '../Setups/SetupClass';
import { getTextWidthHeight } from '../Setups/SetupClass';
import { getContentWidthHeight } from '../Setups/SetupClass';
import { DragResizeCalculator } from '../Calculators/PositionCalculators';
import { GetQuerySelector } from '../../../../assets/script/commonFunction';
import { UpdateBuffers } from '../Setups/SetupClass';
import { InitBuffers } from '../Setups/SetupClass';
import { getGLContext } from '../commonFunc';

class TextHelper {
  onKeyUp(inputFieldRef, key, event) {
    let newInputFieldRef;
    if (inputFieldRef !== undefined) {
      if (key === 'Backspace') {
        let enterCheckerField = inputFieldRef.slice(-11);
        if (enterCheckerField === '?entered!*-') newInputFieldRef = inputFieldRef.slice(0, -11);
        else newInputFieldRef = inputFieldRef.slice(0, -1);

        inputFieldRef = newInputFieldRef;
      } else if (key === 'Enter') inputFieldRef = inputFieldRef + '?entered!*-';
      else inputFieldRef = inputFieldRef + event.target.value;
    } else inputFieldRef = event.target.value;

    return inputFieldRef;
  }

  textRowSplitter(inputFieldRef) {
    // Pseudo Code:
    // mainText = firstRowText, enterSign, secondRowText, enterSign ...
    // remainingText = mainText
    // check if enterSign exists in remaining Text
    //   yes -> slice the remaining text from start until the sign
    //       -> save the sliced text into related row
    //       -> new remaining Text should start from end of the enterSign
    //   no  -> save remaining Text to last row

    let rows = [];
    let remainingText = inputFieldRef;
    let enterSign = '?entered!*-';

    let i = 0;
    while (remainingText.indexOf(enterSign) !== -1) {
      rows[i] = remainingText.slice(0, remainingText.indexOf('?entered!*-'));
      remainingText = remainingText.slice(remainingText.indexOf('?entered!*-') + enterSign.length, remainingText.length);
      i++;
    }
    rows[i] = remainingText;

    return rows;
  }

  onFocusTextPrinter(ctx, refCurrent, textCanvas, textRows, currentIndex) {
    // ref.current[3][0] -> ctxFontWeight
    // ref.current[3][1] -> ctxFontSize
    // ref.current[3][2] -> ctxFontFamily
    // ref.current[3][3] -> ctxFontItalic
    // ref.current[3][4] -> ctxTextColor
    // ref.current[3][5] -> ctxBackgorundColor
    // ref.current[3][6] -> textAlignment

    if (refCurrent[3][0][currentIndex] === undefined) refCurrent[3][0][currentIndex] = 'normal';
    if (refCurrent[3][1][currentIndex] === undefined) refCurrent[3][1][currentIndex] = '30';
    if (refCurrent[3][2][currentIndex] === undefined) refCurrent[3][2][currentIndex] = 'Arial';
    if (refCurrent[3][3][currentIndex] === undefined) refCurrent[3][3][currentIndex] = '';
    if (refCurrent[3][4][currentIndex] === undefined) refCurrent[3][4][currentIndex] = 'black';
    if (refCurrent[3][5][currentIndex] === undefined) refCurrent[3][5][currentIndex] = 'white';
    if (refCurrent[3][6][currentIndex] === undefined) refCurrent[3][6][currentIndex] = '';

    ctx.font = `${refCurrent[3][3][currentIndex]} ${refCurrent[3][0][currentIndex]} ${refCurrent[3][1][currentIndex]}px ${refCurrent[3][2][currentIndex]}`;
    ctx.clearRect(0, 0, textCanvas.width, textCanvas.height);
    ctx.fillStyle = refCurrent[3][5][currentIndex];
    ctx.fillRect(0, 0, textCanvas.width, textCanvas.height);
    ctx.fillStyle = refCurrent[3][4][currentIndex];

    let count = 0;
    let totalLength = 1;
    textRows.forEach((text) => { totalLength += wrapText(ctx, text, textCanvas.width).length; });
    textRows.forEach((text) => {
      let alignment = refCurrent[3][6][currentIndex];
      let y = textCanvas.height / totalLength;
      let wrappedText = wrapText(ctx, text, textCanvas.width);
      wrappedText.forEach(function (item) {
        count++;
        ctx.fillText(item, alignment, y * count);
      })
    })
  }

  onBlurTextPrinter(ctx, refCurrent, textCanvas, textRows, currentIndex) {
    // ref.current[3][0] -> ctxFontWeight
    // ref.current[3][1] -> ctxFontSize
    // ref.current[3][2] -> ctxFontFamily
    // ref.current[3][3] -> ctxFontItalic
    // ref.current[3][4] -> ctxTextColor
    // ref.current[3][5] -> ctxBackgorundColor

    ctx.font = `${refCurrent[3][3][currentIndex]} ${refCurrent[3][0][currentIndex]} ${refCurrent[3][1][currentIndex]}px ${refCurrent[3][2][currentIndex]}`;
    ctx.clearRect(0, 0, textCanvas.width, textCanvas.height);
    ctx.fillStyle = refCurrent[3][5][currentIndex];
    ctx.fillRect(0, 0, textCanvas.width, textCanvas.height);
    ctx.fillStyle = refCurrent[3][4][currentIndex];

    let count = 0;
    let totalLength = 1;
    textRows.forEach((text) => { totalLength += wrapText(ctx, text, textCanvas.width).length; });
    textRows.forEach((text) => {
      let alignment = refCurrent[3][6][currentIndex];
      let y = textCanvas.height / totalLength;
      let wrappedText = wrapText(ctx, text, textCanvas.width);
      wrappedText.forEach(function (item) {
        count++;
        ctx.fillText(item, alignment, y * count);
      })
    })
  }
}
export default TextHelper;




export const CanvasTextRowSplitter = (inputFieldRef) => {
  // Pseudo Code:
  // mainText = firstRowText, enterSign, secondRowText, enterSign ...
  // remainingText = mainText
  // check if enterSign exists in remaining Text
  //   yes -> slice the remaining text from start until the sign
  //       -> save the sliced text into related row
  //       -> new remaining Text should start from end of the enterSign
  //   no  -> save remaining Text to last row

  let rows = [];
  let remainingText = inputFieldRef;
  let enterSign = '?entered!*-';

  let i = 0;
  while (remainingText.indexOf(enterSign) !== -1) {
    rows[i] = remainingText.slice(0, remainingText.indexOf('?entered!*-'));
    remainingText = remainingText.slice(remainingText.indexOf('?entered!*-') + enterSign.length, remainingText.length);
    i++;
  }
  rows[i] = remainingText;
  return rows;
}

export const CanvasTextOnKeyUP = (inputFieldRef, key, event) => {
  let newInputFieldRef;
  if (inputFieldRef !== undefined) {
    if (key === 'Backspace') {
      let enterCheckerField = inputFieldRef.slice(-11);
      if (enterCheckerField === '?entered!*-') newInputFieldRef = inputFieldRef.slice(0, -11);
      else newInputFieldRef = inputFieldRef.slice(0, -1);

      inputFieldRef = newInputFieldRef;
    } else if (key === 'Enter') inputFieldRef = inputFieldRef + '?entered!*-';
    else inputFieldRef = inputFieldRef + event.target.value;
  } else inputFieldRef = event.target.value;

  return inputFieldRef;
}

export const CanvasTextPrinterOnFocus = (ctx, textInfo, projectJSON, canvas, elementKey, webGLContext) => {
  let [maxWidth, maxHeight, acceptRatio] = getContentWidthHeight(textInfo.context, projectJSON, textInfo, canvas);
  let tempTextCanvas = setUpTextTexture(ctx, maxWidth, maxHeight, textInfo, projectJSON, canvas, true);
  webGLContext.activeTextCanvases.set(elementKey, tempTextCanvas)

  let type = 'text';
  let canvasGL = getGLContext(canvas);
  const ActiveMatrices = webGLContext.activeModelViewMatrices;
  const currentDiv = GetQuerySelector(`.thDiv${elementKey}`);
  let currentMatrix = ActiveMatrices.get(elementKey);

  currentDiv.style.width = `${maxWidth}px`;
  currentDiv.style.height = `${maxHeight}px`;

  let top = currentDiv.offsetTop
  let left = currentDiv.offsetLeft

  let elementInfo = { width: maxWidth, height: maxHeight, top: top, left: left, zIndex: textInfo.coordinate.zIndex }
  const resizeData = [elementInfo, canvas, currentMatrix, type, webGLContext, elementKey, canvasGL, projectJSON];
  currentMatrix = DragResizeCalculator(...resizeData, true);
  webGLContext.activeModelViewMatrices.set(elementKey, currentMatrix)

  let moveable_wrap = GetQuerySelector('.moveable-container_');
  moveable_wrap.style.display = 'none'

  return tempTextCanvas
}

export const CanvasTextPrinterOnBlur = (ctx, textInfo, projectJSON, canvas, elementKey, webGLContext) => {
  let [maxWidth, maxHeight] = getContentWidthHeight(textInfo.context, projectJSON, textInfo, canvas);

  let tempTextCanvas = setUpTextTexture(ctx, maxWidth, maxHeight, textInfo, projectJSON, canvas)
  webGLContext.activeTextCanvases.set(elementKey, tempTextCanvas)

  let moveable_wrap = GetQuerySelector('.moveable-container_');
  moveable_wrap.style.display = 'block'
}