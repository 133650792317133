import { calculateFrameCount, calculatePproTicks, calculatePproTicksFromMs, calculateTimecodeString, sequence_timecode } from "./adobexmlformat_helper";

export function sequence_media_video_format(xmlDoc, projectJson, projectTimebase, projectNtsc) {
    const video = xmlDoc.createElement("video");
    const format = xmlDoc.createElement("format");
    const sampleCharacteristics = xmlDoc.createElement("samplecharacteristics");

    // Sample characteristics
    const rateFormat = xmlDoc.createElement("rate");
    const timebaseFormat = xmlDoc.createElement("timebase");
    timebaseFormat.textContent = projectTimebase
    const ntscFormat = xmlDoc.createElement("ntsc");
    ntscFormat.textContent = projectNtsc;
    rateFormat.appendChild(timebaseFormat);
    rateFormat.appendChild(ntscFormat);
    sampleCharacteristics.appendChild(rateFormat);

    // Codec section
    const codec = xmlDoc.createElement("codec");
    const codecName = xmlDoc.createElement("name");
    codecName.textContent = "Apple ProRes 422"; // Adjust if needed
    codec.appendChild(codecName);

    const appSpecificData = xmlDoc.createElement("appspecificdata");
    const appName = xmlDoc.createElement("appname");
    appName.textContent = "Final Cut Pro";
    const appManufacturer = xmlDoc.createElement("appmanufacturer");
    appManufacturer.textContent = "Apple Inc.";
    const appVersion = xmlDoc.createElement("appversion");
    appVersion.textContent = "7.0";

    appSpecificData.appendChild(appName);
    appSpecificData.appendChild(appManufacturer);
    appSpecificData.appendChild(appVersion);
    codec.appendChild(appSpecificData);

    const data = xmlDoc.createElement("data");
    const qtCodec = xmlDoc.createElement("qtcodec");
    const codecNameQt = xmlDoc.createElement("codecname");
    codecNameQt.textContent = "Apple ProRes 422";
    qtCodec.appendChild(codecNameQt);

    // Additional codec attributes
    const codecTypeName = xmlDoc.createElement("codectypename");
    codecTypeName.textContent = "Apple ProRes 422";
    qtCodec.appendChild(codecTypeName);

    const codecTypeCode = xmlDoc.createElement("codectypecode");
    codecTypeCode.textContent = "apcn";
    qtCodec.appendChild(codecTypeCode);

    const codecVendorCode = xmlDoc.createElement("codecvendorcode");
    codecVendorCode.textContent = "appl";
    qtCodec.appendChild(codecVendorCode);

    const spatialQuality = xmlDoc.createElement("spatialquality");
    spatialQuality.textContent = "1024";
    qtCodec.appendChild(spatialQuality);

    const temporalQuality = xmlDoc.createElement("temporalquality");
    temporalQuality.textContent = "0";
    qtCodec.appendChild(temporalQuality);

    const keyframeRate = xmlDoc.createElement("keyframerate");
    keyframeRate.textContent = "0";
    qtCodec.appendChild(keyframeRate);

    const dataRate = xmlDoc.createElement("datarate");
    dataRate.textContent = "0";
    qtCodec.appendChild(dataRate);

    data.appendChild(qtCodec);
    appSpecificData.appendChild(data);
    sampleCharacteristics.appendChild(codec);

    // Width, Height, Anamorphic, Pixel Aspect Ratio, Field Dominance, Color Depth
    const width = xmlDoc.createElement("width");
    width.textContent = "1920"; // Set the width
    sampleCharacteristics.appendChild(width);

    const height = xmlDoc.createElement("height");
    height.textContent = "1080"; // Set the height
    sampleCharacteristics.appendChild(height);

    const anamorphic = xmlDoc.createElement("anamorphic");
    anamorphic.textContent = "FALSE"; // Set anamorphic
    sampleCharacteristics.appendChild(anamorphic);

    const pixelAspectRatio = xmlDoc.createElement("pixelaspectratio");
    pixelAspectRatio.textContent = "square"; // Set pixel aspect ratio
    sampleCharacteristics.appendChild(pixelAspectRatio);

    const fieldDominance = xmlDoc.createElement("fielddominance");
    fieldDominance.textContent = "none"; // Set field dominance
    sampleCharacteristics.appendChild(fieldDominance);

    const colorDepth = xmlDoc.createElement("colordepth");
    colorDepth.textContent = "24"; // Set color depth
    sampleCharacteristics.appendChild(colorDepth);

    format.appendChild(sampleCharacteristics);
    video.appendChild(format);

    return video;
}

export function sequence_media_video_track(xmlDoc, projectJson, videos, video_asset_map, projectTimebase, projectNtsc) {
    const track = xmlDoc.createElement("track");
    track.setAttribute("TL.SQTrackShy", "0");
    track.setAttribute("TL.SQTrackExpandedHeight", "41");
    track.setAttribute("TL.SQTrackExpanded", "0");
    track.setAttribute("MZ.TrackTargeted", "1");

    videos = videos.sort((a, b) => a.timeline_start - b.timeline_start);
    videos.forEach((videoData, index) => {
        let is_asset_used_before = false;
        if (video_asset_map[videoData.asset_uuid] == true) {
            is_asset_used_before = true;
        } 
        const clipitem = sequence_media_video_track_clipitem(xmlDoc, projectJson, videoData, index, is_asset_used_before, projectTimebase, projectNtsc);
        if (is_asset_used_before === false) {
            video_asset_map[videoData.asset_uuid] = true;
        }
        track.appendChild(clipitem);
    });

    return track;
}

export function sequence_media_video_track_clipitem(xmlDoc, projectJson, videoData, index, is_asset_used_before, projectTimebase, projectNtsc) {
    const clipitem = xmlDoc.createElement("clipitem");
    clipitem.setAttribute("id", `clipitem-${index + 1}`);

    const masterclipid = xmlDoc.createElement("masterclipid");
    masterclipid.textContent = `masterclip-1`;
    clipitem.appendChild(masterclipid);

    const nameElement = xmlDoc.createElement("name");
    nameElement.textContent = videoData.asset_filename;
    clipitem.appendChild(nameElement);

    const enabled = xmlDoc.createElement("enabled");
    enabled.textContent = "TRUE";
    clipitem.appendChild(enabled);

    // Duration, start, end, in, out
    appendTimingElements(xmlDoc, clipitem, videoData, projectJson, projectTimebase, projectNtsc);

    // Create file section
    const file = sequence_media_video_track_clipitem_file(xmlDoc, projectJson, videoData, index, is_asset_used_before, projectTimebase, projectNtsc);
    clipitem.appendChild(file);

    let numberOfVideos = Array.isArray(projectJson.videos) ? projectJson.videos.length : Object.keys(projectJson.videos).length;

    // Create links
    const video_link = sequence_media_video_track_clipitem_video_link(xmlDoc, index);
    clipitem.appendChild(video_link);

    const audio_link_track_1 = sequence_media_video_track_clipitem_audio_link(xmlDoc, index, 1, numberOfVideos);
    clipitem.appendChild(audio_link_track_1);

    const audio_link_track_2 = sequence_media_video_track_clipitem_audio_link(xmlDoc, index, 2, numberOfVideos);
    clipitem.appendChild(audio_link_track_2);

    // Logging info section
    const loggingInfo = sequence_media_video_track_clipitem_logginginfo(xmlDoc);
    clipitem.appendChild(loggingInfo);

    // Color info section
    const colorInfo = xmlDoc.createElement("colorinfo");
    colorInfo.appendChild(xmlDoc.createElement("lut"));
    colorInfo.appendChild(xmlDoc.createElement("lut1"));
    colorInfo.appendChild(xmlDoc.createElement("asc_sop"));
    colorInfo.appendChild(xmlDoc.createElement("asc_sat"));
    colorInfo.appendChild(xmlDoc.createElement("lut2"));
    clipitem.appendChild(colorInfo);
    
    // Labels section
    const labels = sequence_media_video_track_clipitem_labels(xmlDoc);
    clipitem.appendChild(labels);

    return clipitem;
}

export function appendTimingElements(xmlDoc, clipitem, videoData, projectJson, projectTimebase, projectNtsc) {
    const clipDuration = xmlDoc.createElement("duration");
    clipDuration.textContent = calculateFrameCount(videoData.duration, projectJson.fps, projectNtsc);
    clipitem.appendChild(clipDuration);

    // Rate
    const rate = xmlDoc.createElement("rate");
    const timebase = xmlDoc.createElement("timebase");
    timebase.textContent = projectTimebase;
    const ntsc = xmlDoc.createElement("ntsc");
    ntsc.textContent = projectNtsc;
    rate.appendChild(timebase);
    rate.appendChild(ntsc);
    clipitem.appendChild(rate);

    const start = xmlDoc.createElement("start");
    start.textContent = calculateFrameCount(videoData.timeline_start, projectJson.fps, projectNtsc);
    clipitem.appendChild(start);

    const end = xmlDoc.createElement("end");
    end.textContent = calculateFrameCount(videoData.timeline_end, projectJson.fps, projectNtsc);
    clipitem.appendChild(end);

    const inPoint = xmlDoc.createElement("in");
    inPoint.textContent = calculateFrameCount(videoData.segment_start, projectJson.fps, projectNtsc);
    clipitem.appendChild(inPoint);

    const outPoint = xmlDoc.createElement("out");
    outPoint.textContent = calculateFrameCount(videoData.segment_end, projectJson.fps, projectNtsc);
    clipitem.appendChild(outPoint);

    // Additional elements
    appendAdditionalElements(xmlDoc, clipitem, videoData, projectJson);
}

export function appendAdditionalElements(xmlDoc, clipitem, videoData, projectJson, projectTimebase, projectNtsc) {
    // Add pproTicksIn, pproTicksOut, and alphaType
    // const pproTicksIn = xmlDoc.createElement("pproTicksIn");
    // pproTicksIn.textContent = calculatePproTicksFromMs(videoData.segment_start, projectJson.fps, projectNtsc); // (calculateFrameCount(videoData.timeline_start, projectJson.fps), projectJson.fps);
    // clipitem.appendChild(pproTicksIn);
    
    // const pproTicksOut = xmlDoc.createElement("pproTicksOut");
    // pproTicksOut.textContent = calculatePproTicksFromMs(videoData.segment_end, projectJson.fps, projectNtsc); // calculatePproTicks(calculateFrameCount(videoData.timeline_end, projectJson.fps), projectJson.fps);
    // clipitem.appendChild(pproTicksOut);
    
    const alphaType = xmlDoc.createElement("alphatype");
    alphaType.textContent = "none"; // Set alpha type
    clipitem.appendChild(alphaType);

    const pixelAspectRatio = xmlDoc.createElement("pixelaspectratio");
    pixelAspectRatio.textContent = "square"; // Set Pixel Aspect Ratio
    clipitem.appendChild(pixelAspectRatio);

    const anamorphic = xmlDoc.createElement("anamorphic");
    anamorphic.textContent = "FALSE"; // Set anamorphic
    clipitem.appendChild(anamorphic);
}

export function sequence_media_video_track_clipitem_file(xmlDoc, projectJson, videoData, index, is_asset_used_before, projectTimebase, projectNtsc) {
    const file = xmlDoc.createElement("file");
    file.setAttribute("id", `file-${1}`);

    if (is_asset_used_before === false) {
        // Ensure assets and videos are arrays or object values
        let assets = projectJson.asset ? projectJson.asset : {};
        let videos = Array.isArray(projectJson.videos) ? projectJson.videos : Object.values(projectJson.videos || {});

        // Initialize variable to hold original_filename
        let original_filename = null;

        // Loop through videos to find matching asset by uuid
        videos.forEach((video) => {
            // Compare the video.asset_uuid with the keys in assets (which are the UUIDs)
            Object.keys(assets).forEach((asset_uuid) => {
                console.log("videos.forEach -  Object.keys(assets) : ", asset_uuid, " - ");
                if (String(video.asset_uuid) === asset_uuid) {
                    console.log("videos.forEach -  Object.keys(assets) : String(video.asset_uuid) === asset_uuid : ", video.original);
                    original_filename = assets[asset_uuid].original;
                }
            });
        });

        const filename = xmlDoc.createElement("name");
        filename.textContent = original_filename;
        file.appendChild(filename);

        const pathurl = xmlDoc.createElement("pathurl");
        pathurl.textContent = original_filename; // Ensure you have the signed URL
        file.appendChild(pathurl);

        const fileRate = xmlDoc.createElement("rate");
        const fileTimebase = xmlDoc.createElement("timebase");
        fileTimebase.textContent = projectTimebase;
        const fileNtsc = xmlDoc.createElement("ntsc");
        fileNtsc.textContent = projectNtsc;
        fileRate.appendChild(fileTimebase);
        fileRate.appendChild(fileNtsc);
        file.appendChild(fileRate);

        // Add duration, timecode, media characteristics
        appendFileDetails(xmlDoc, file, videoData, projectJson, projectTimebase, projectNtsc);
    }

    return file;
}

export function appendFileDetails(xmlDoc, file, videoData, projectJson, projectTimebase, projectNtsc) {
    const fileDuration = xmlDoc.createElement("duration");
    fileDuration.textContent = calculateFrameCount(videoData.duration, projectJson.fps, projectNtsc);
    file.appendChild(fileDuration);

    const timecode = xmlDoc.createElement("timecode");
    const timecodeRate = xmlDoc.createElement("rate");
    const timecodeTimebase = xmlDoc.createElement("timebase");
    timecodeTimebase.textContent = projectTimebase; // Adjust if necessary
    const timecodeNtsc = xmlDoc.createElement("ntsc");
    timecodeNtsc.textContent = projectNtsc; // Adjust if necessary
    timecodeRate.appendChild(timecodeTimebase);
    timecodeRate.appendChild(timecodeNtsc);
    timecode.appendChild(timecodeRate);

    const timecodeString = xmlDoc.createElement("string");
    timecodeString.textContent = calculateTimecodeString(0, projectJson.fps) // Adjust if necessary
    const timecodeFrame = xmlDoc.createElement("frame");
    timecodeFrame.textContent = calculateFrameCount(videoData.timeline_start, projectJson.fps, projectNtsc); // Adjust if necessary
    const timecodeDisplayFormat = xmlDoc.createElement("displayformat");
    timecodeDisplayFormat.textContent = projectNtsc === "TRUE" ? "DF" : "NDF";
    timecode.appendChild(timecodeString);
    timecode.appendChild(timecodeFrame);
    timecode.appendChild(timecodeDisplayFormat);
    file.appendChild(timecode);

    // Create media section
    const media = sequence_media_video_track_clipitem_file_media(xmlDoc, videoData, projectJson, projectTimebase, projectNtsc);
    file.appendChild(media);
}

export function sequence_media_video_track_clipitem_file_media(xmlDoc, videoData, projectJson, projectTimebase, projectNtsc) {
    const media = xmlDoc.createElement("media");
    const videoMedia = xmlDoc.createElement("video");
    const sampleCharacteristicsMedia = xmlDoc.createElement("samplecharacteristics");

    const mediaRate = xmlDoc.createElement("rate");
    const mediaTimebase = xmlDoc.createElement("timebase");
    mediaTimebase.textContent = projectTimebase; // Adjust if necessary
    const mediaNtsc = xmlDoc.createElement("ntsc");
    mediaNtsc.textContent = projectNtsc; // Adjust if necessary
    mediaRate.appendChild(mediaTimebase);
    mediaRate.appendChild(mediaNtsc);
    sampleCharacteristicsMedia.appendChild(mediaRate);

    const mediaWidth = xmlDoc.createElement("width");
    mediaWidth.textContent = videoData.dimension.w;
    sampleCharacteristicsMedia.appendChild(mediaWidth);

    const mediaHeight = xmlDoc.createElement("height");
    mediaHeight.textContent = videoData.dimension.h;
    sampleCharacteristicsMedia.appendChild(mediaHeight);

    const mediaAnamorphic = xmlDoc.createElement("anamorphic");
    mediaAnamorphic.textContent = "FALSE"; // Set anamorphic for media
    sampleCharacteristicsMedia.appendChild(mediaAnamorphic);

    const mediaPixelAspectRatio = xmlDoc.createElement("pixelaspectratio");
    mediaPixelAspectRatio.textContent = "square"; // Set pixel aspect ratio for media
    sampleCharacteristicsMedia.appendChild(mediaPixelAspectRatio);

    const mediaFieldDominance = xmlDoc.createElement("fielddominance");
    mediaFieldDominance.textContent = "none"; // Set field dominance for media
    sampleCharacteristicsMedia.appendChild(mediaFieldDominance);

    videoMedia.appendChild(sampleCharacteristicsMedia);
    media.appendChild(videoMedia);

    // Add audio section
    const audioMedia = xmlDoc.createElement("audio");
    const sampleCharacteristicsAudio = xmlDoc.createElement("samplecharacteristics");
    const depth = xmlDoc.createElement("depth");
    depth.textContent = "16"; // Set audio depth
    const sampleRate = xmlDoc.createElement("samplerate");
    sampleRate.textContent = "44100"; // Set audio sample rate
    sampleCharacteristicsAudio.appendChild(depth);
    sampleCharacteristicsAudio.appendChild(sampleRate);
    audioMedia.appendChild(sampleCharacteristicsAudio);
    
    const channelCount = xmlDoc.createElement("channelcount");
    channelCount.textContent = "2"; // Set audio channel count
    audioMedia.appendChild(channelCount);
    
    media.appendChild(audioMedia);

    return media;
}

export function sequence_media_video_track_clipitem_video_link(xmlDoc, videoIndex, numberOfVideos) {
    const linkVideo = xmlDoc.createElement("link");

    // Video link
    const linkClipRefVideo = xmlDoc.createElement("linkclipref");
    linkClipRefVideo.textContent = `clipitem-${videoIndex + 1}`;
    linkVideo.appendChild(linkClipRefVideo);

    const mediaTypeVideo = xmlDoc.createElement("mediatype");
    mediaTypeVideo.textContent = "video"; // Media type is video
    linkVideo.appendChild(mediaTypeVideo);

    const trackIndexVideo = xmlDoc.createElement("trackindex");
    trackIndexVideo.textContent = 1; // Video track index
    linkVideo.appendChild(trackIndexVideo);

    const clipIndexVideo = xmlDoc.createElement("clipindex");
    clipIndexVideo.textContent = videoIndex + 1; // Clip index for video
    linkVideo.appendChild(clipIndexVideo);

    return linkVideo;
}

export function sequence_media_video_track_clipitem_audio_link(xmlDoc, audioIndex, trackIndex) {
    const groupIndex = 1;
    const linkAudio = xmlDoc.createElement("link");
    const linkClipRefAudio = xmlDoc.createElement("linkclipref");
    linkClipRefAudio.textContent = `clipitem-${trackIndex}-${audioIndex + 1}`;
    linkAudio.appendChild(linkClipRefAudio);

    const mediaTypeAudio = xmlDoc.createElement("mediatype");
    mediaTypeAudio.textContent = "audio"; // Media type is audio
    linkAudio.appendChild(mediaTypeAudio);

    const trackIndexAudio = xmlDoc.createElement("trackindex");
    trackIndexAudio.textContent = trackIndex; // Track index for audio
    linkAudio.appendChild(trackIndexAudio);

    const clipIndexAudio = xmlDoc.createElement("clipindex");
    clipIndexAudio.textContent = audioIndex + 1; // Adjust based on your logic
    linkAudio.appendChild(clipIndexAudio);

    const groupIndexAudio = xmlDoc.createElement("groupindex");
    groupIndexAudio.textContent = 1; // audioIndex; // Adjust based on your logic
    linkAudio.appendChild(groupIndexAudio);

    return linkAudio;
}

export function sequence_media_video_track_clipitem_logginginfo(xmlDoc) {
    const loggingInfo = xmlDoc.createElement("logginginfo");

    // Create elements with empty text content
    const description = xmlDoc.createElement("description");
    description.textContent = ""; // Ensure it appears as <description></description>
    
    const scene = xmlDoc.createElement("scene");
    scene.textContent = ""; // Ensure it appears as <scene></scene>
    
    const shottake = xmlDoc.createElement("shottake");
    shottake.textContent = ""; // Ensure it appears as <shottake></shottake>
    
    const lognote = xmlDoc.createElement("lognote");
    lognote.textContent = ""; // Ensure it appears as <lognote></lognote>
    
    const good = xmlDoc.createElement("good");
    good.textContent = ""; // Ensure it appears as <good></good>
    
    const originalVideoFileName = xmlDoc.createElement("originalvideofilename");
    originalVideoFileName.textContent = ""; // Ensure it appears as <originalvideofilename></originalvideofilename>
    
    const originalAudioFileName = xmlDoc.createElement("originalaudiofilename");
    originalAudioFileName.textContent = ""; // Ensure it appears as <originalaudiofilename></originalaudiofilename>

    // Append elements to loggingInfo
    loggingInfo.appendChild(description);
    loggingInfo.appendChild(scene);
    loggingInfo.appendChild(shottake);
    loggingInfo.appendChild(lognote);
    loggingInfo.appendChild(good);
    loggingInfo.appendChild(originalVideoFileName);
    loggingInfo.appendChild(originalAudioFileName);

    return loggingInfo;
}

export function sequence_media_video_track_clipitem_labels(xmlDoc) {
    const labels = xmlDoc.createElement("labels");
    const label = xmlDoc.createElement("label2");
    label.textContent = "Iris"; // Adjust if necessary
    labels.appendChild(label);
    return labels;
}