import { useEffect } from 'react';
import ReactLoading from 'react-loading'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


// import { Home } from './pages/Home/Home';
// import { Tools } from './pages/Tools/tools';
// import { SubtitlesTranscription } from './pages/SubtitlesTranscription/subtitlestranscription';
// import { PricingPage } from './pages/Pricing/pricing';
// import { DashBoard } from './pages/workspaces/workspaces';
import { VideoEditCotainer } from './pages/Video-Edit/videoEditContainer';
// import { ProjectView } from './pages/project-view/projectView';
// import {AssetView} from './pages/asset-view/assetView';
// import { More } from "./pages/More/more"

import logAnimation from './assets/image/logoAnimation.GIF';

import { GetRegistration } from './axios/ApiProvider';
import { loginState_Store } from './store/actions/mainAction';
// import { VideoEditor } from './pages/VideoEditor/videoeditor';
// import { VideooBlog } from './pages/blogs/videoblog/videoblog'
// import { VideooGuide } from './pages/blogs/videoguide/vieoguide';

// import { Blog1 } from './pages/blogs/videoblog/blog1';
// import { Blog2 } from './pages/blogs/videoblog/blog2';
// import { Blog3 } from './pages/blogs/videoblog/blog3';
// import { Blog4 } from './pages/blogs/videoblog/blog4';
// import { Blog5 } from './pages/blogs/videoblog/blog5';
// import { Blog6 } from './pages/blogs/videoblog/blog6';
// import { Blog7 } from './pages/blogs/videoblog/blog7';
// import { Blog8 } from './pages/blogs/videoblog/blog8';
// import { Blog9 } from './pages/blogs/videoblog/blog9';
// import { Blog10 } from './pages/blogs/videoblog/blog10';
// import { Blog11 } from './pages/blogs/videoblog/blog11';
// import { Blog12 } from './pages/blogs/videoblog/blog12';
// import { Blog13 } from './pages/blogs/videoblog/blog13';
// import { Blog14 } from './pages/blogs/videoblog/blog14';
// import { Blog15 } from './pages/blogs/videoblog/blog15';
// import { Blog16 } from './pages/blogs/videoblog/blog16';

// import { Career } from './pages/careers/videoocareer';
// import { Career1 } from './pages/careers/career1';
// import { Career2 } from './pages/careers/career2';
// import { Career3 } from './pages/careers/career3';
// import { Career4 } from './pages/careers/career4';
// import { Career5 } from './pages/careers/career5';
// import { Career6 } from './pages/careers/career6';
// import { Career7 } from './pages/careers/career7';
// import { Career8 } from './pages/careers/career8';
// import { Career9 } from './pages/careers/career9';
// import { Career10 } from './pages/careers/career10';

// import { Terms } from './pages/terms'
// import { Cookies } from './pages/cookies';
// import { Privacy } from './pages/privacy';

// import { Security } from './pages/security';
import { Loading } from './component/Loading';
import { useGlobalContext } from './provider';

export const Router = () => {
	const dispatch_ = useDispatch();
	const [globalState, { dispatch }] = useGlobalContext()
	const isLoading = useSelector((store) => store.maindata.loading);
	const dataLoaded = useSelector(store => store.webgldata.dataLoaded)
	const isAnalyzing = useSelector((store) => store.maindata.isAnalyzing);
	const stageText = useSelector((store) => store.maindata.stageText);
	useEffect(() => {
		const routerName = window.location.pathname.split('/');
		if(routerName[1] != 'edit'){
			setAxiosAuthToken();
		}
	}, []);
	
	const [cookies, setCookie, removeCookie] = useCookies(['csrftoken']);

	const setAxiosAuthToken = async () => {
		let expires = new Date();
		localStorage.setItem('user_data', null);
		let resultState = await GetRegistration();
		let signed = resultState.state === 'success' && resultState.data['state'].localeCompare('loggedin') === 0;

		if (!!signed) {
			dispatch({ type: 'userData', payload: resultState.data })

			dispatch_(loginState_Store(true))
			expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
			setCookie('csrftoken', resultState.data.token, { path: '/', expires })
			localStorage.setItem('user_data', JSON.stringify(resultState.data));
		} else removeCookie('csrftoken', { path: '/', expires });
	};

	return (
		<BrowserRouter>
			<Routes>
				{/* <Route path="/signup" element={<SignUp />} />
				<Route path="/signin" element={<SignIn />} /> */}
{/* 
				<Route path="/videoeditor/*" element={<VideoEditor />} />
				<Route path="/subtitlestranscription/*" element={<SubtitlesTranscription />} />
				<Route path="/tools/*" element={<Tools />} />
				<Route path="/more/*" element={<More />} />

				<Route path="/pricing" element={<PricingPage />} />
				<Route path="/workspaces/*" element={<DashBoard />} /> */}
				<Route path="/edit/:projectUUID" element={<VideoEditCotainer />} />
				{/* <Route path="/*" element={<VideoEditCotainer />} /> */}
				{/* <Route path="/view/:projectUUID" element={<ProjectView />} />
				<Route path="/asset/view/:uuid" element={<AssetView />} />
				<Route path="/asset/view/:uuid/:projectUUID" element={<AssetView />} />

				<Route path="/videooblog" element={<VideooBlog />} />
				<Route path="/videoohowtos" element={<VideooGuide />} />

				<Route path="/videooblog/blog1" element={<Blog1 />} />
				<Route path="/videooblog/blog2" element={<Blog2 />} />
				<Route path="/videooblog/blog3" element={<Blog3 />} />
				<Route path="/videooblog/blog4" element={<Blog4 />} />
				<Route path="/videooblog/blog5" element={<Blog5 />} />
				<Route path="/videooblog/blog6" element={<Blog6 />} />
				<Route path="/videooblog/blog7" element={<Blog7 />} />
				<Route path="/videooblog/blog8" element={<Blog8 />} />
				<Route path="/videooblog/blog9" element={<Blog9 />} />
				<Route path="/videooblog/blog10" element={<Blog10 />} />
				<Route path="/videooblog/blog11" element={<Blog11 />} />
				<Route path="/videooblog/blog12" element={<Blog12 />} />
				<Route path="/videooblog/blog13" element={<Blog13 />} />
				<Route path="/videooblog/blog14" element={<Blog14 />} />
				<Route path="/videooblog/blog15" element={<Blog15 />} />
				<Route path="/videooblog/blog16" element={<Blog16 />} />

				<Route path="/careers/career1" element={<Career1 />} />
				<Route path="/careers/career2" element={<Career2 />} />
				<Route path="/careers/career3" element={<Career3 />} />
				<Route path="/careers/career4" element={<Career4 />} />
				<Route path="/careers/career5" element={<Career5 />} />
				<Route path="/careers/career6" element={<Career6 />} />
				<Route path="/careers/career7" element={<Career7 />} />
				<Route path="/careers/career8" element={<Career8 />} />
				<Route path="/careers/career9" element={<Career9 />} />
				<Route path="/careers/career10" element={<Career10 />} />

				<Route path="/terms" element={<Terms />} />
				<Route path="/cookies" element={<Cookies />} />
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/security" element={<Security />} />
				<Route path="/videoocareers" element={<Career />} /> */}

				{/* <Route path="/app" element={<App />} /> */}

				{/* <Route path="/*" element={<Home />} /> */}
			</Routes>

			<Loading show={globalState.loading} opacity={0.7} />

			{(isLoading || !dataLoaded) && (
				<div className='projects-loading'>
					<div className='projects-loading-content'>
					<img src={logAnimation} alt="Loading animation" />
					{/* <ReactLoading type={'spinningBubbles'} color='rgb(53 126 221)' height={100} width={100} /> */}
					</div>
				</div>
			)}
			{isAnalyzing && 
				<div className='projects-loading'>
					<div className='projects-loading-content'>
					<h1>{stageText}</h1>
					</div>
				</div>
			}
		</BrowserRouter>
	)
}