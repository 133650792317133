export const PROJECT_UUID = 'PROJECT_UUID';
export const PROJECTJSON = 'PROJECTJSON';
export const ACTIVEVIDEOUUID = 'ACTIVEVIDEOUUID';
export const ACTIVETEXTUUID = 'ACTIVETEXTUUID';
export const ACTIVEIMAGEUUID = 'ACTIVEIMAGEUUID';
export const ACTIVEAUDIOUUID = 'ACTIVEAUDIOUUID';
export const CHANGEDJSON = 'CHANGEDJSON';
export const SAVESTATE = 'SAVESTATE';
export const SEQUENCESTATUS = 'SEQUENCESTATUS';
export const SEQUENCENUMBER = 'SEQUENCENUMBER';
export const THUMBNAILS = 'THUMBNAILS';
export const UPLOADINGFILES = 'UPLOADINGFILES';
export const TIMELINEFIT = 'TIMELINEFIT';
export const SUBCREATING = 'SUBCREATING';
export const THUMBNAILSTATE = 'THUMBNAILSTATE';
export const EDITSUBTITLE = 'EDITSUBTITLE';
export const PROJECTVOLUME = 'PROJECTVOLUME';
export const FULLSCREENFLAG = 'FULLSCREENFLAG';
export const STRETCHSCREENFLAG = 'STRETCHSCREENFLAG'
export const CAPTURETHUMBNAIL = 'CAPTURETHUMBNAIL';
export const ADDEDTEMPLATE = 'ADDEDTEMPLATE';
export const SUBTITLEPOSY = 'SUBTITLEPOSY';

export const projectdata = {
  thumbNails: {},
  projectUUID: '',
  projectJSON: {},
  activeVideoUUID: [],
  activeTextUUID: [],
  activeImageUUID: [],
  activeAudioUUID: [],
  changedJSON: null,
  sequenceStatus: true,
  sequenceNumber: { m: 0, c: 0 },
  subcreating: false,

  subtitlePosY: 5.375,

  uploadingFiles: {},
  timelineFit: false,

  saveState: false,
  thumbnailState: false,
  editSubtitle: false,

  projectVolume: 100,
  fullScreenFlag: false,
  stretchScreenFlag: false,
  captureThumbnailFlag: false,
  addedTemplate: false
};

