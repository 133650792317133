import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Menu, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Menu_options } from '../menu.config';

export const MenuIemsContainner = () => {
	return (
		<>
			{
				Menu_options.map((itemdata, index) => {
					return (
						<Box key={index} className='Header_Menu_item'>
							<MenuItems itemdata={itemdata} index={index} />
						</Box>
					)
				})
			}
		</>
	)
}

let overState_ = 0;
const MenuItems = ({ itemdata, index }) => {
	const [MenuItemElement, setMenuItemElement] = useState(null);
	const open = Boolean(MenuItemElement);
	const ItemListOpen = (event) => setMenuItemElement(event.currentTarget);

	const ItemListClose = (title) => {
		if (title === 'headerItem')
			setTimeout(() => { if (overState_ !== 1) setMenuItemElement(null); overState_ = 0; }, 10);
		else if (title === 'itemList')
			setTimeout(() => { if (overState_ !== 2) setMenuItemElement(null); overState_ = 0; }, 10);
		else setMenuItemElement(null);
	};

	return (
		<>
			<Box className='Menu_item_header S-center' onMouseOver={ItemListOpen}>
				<Typography className='menu_title'>{itemdata.title}</Typography>
				{
					itemdata.items.length ?
						<>
							<KeyboardArrowDownIcon className='menu_item_icon' />
							<Box className='_hidden_card' style={{ display: open ? 'block' : 'none' }} onMouseLeave={() => { ItemListClose('headerItem') }} onMouseOver={() => { overState_ = 2 }}></Box>
						</>
						: ''
				}
			</Box>

			{
				itemdata.items.length ?
					<Menu
						className='menu_item_list'
						anchorEl={MenuItemElement}
						open={open}
						onClose={ItemListClose}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 0,
								backgroundColor: 'unset',
								'& .MuiList-root': {
									padding: '0px'
								}
							},
						}}
						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						<Box className='itemlistcontainner' onMouseLeave={() => { ItemListClose('itemList') }} onMouseOver={() => { overState_ = 1; }}>
							<MenuItem items={itemdata.items} closeFunc={ItemListClose} key_={index} />
						</Box>
					</Menu>
					: ''
			}
		</>
	)
}

const MenuItem = ({ items, key_, closeFunc }) => {
	return (
		items.map((item, index) => {
			return (
				< Box key={index} className='itemList_items'>
					{item.header ? <Box className='item-header'>{item.header}</Box> : ''}

					{
						item.child.map((itemdata, index) => {
							return (
								<Link to={itemdata.link} key={index}>
									< Box onClick={closeFunc} className='List_item'>
										{itemdata.title}
									</Box >
								</Link>
							)
						})
					}
				</Box >
			)
		})
	)
}