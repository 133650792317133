export const projectsData = {
  'https://api.videoo.dev/user/projects/': {
    "count": 5,
    "next": null,
    "previous": null,
    "results": []
  }
}

export const templatesData = {
  'https://api.videoo.dev/templateproject/': {
    "count": 10,
    "next": null,
    "previous": null,
    "results": []
  }
}

export const DefaultProjectJSONdata = {
  "fps": 24,
  "text": {},
  "asset": {},
  "title": "Your Video - 2f9c8c6fd3e94f0da8755d81e879b6d9",
  "width": 1920,
  "height": 1080,
  "images": {},
  "videos": {},
  "audios": {},
  "effects": {},
  "filters": { "preset": "" },
  "version": 1.0,
  "bg_color": "00000000",
  "duration": 0,
  "elements": {},
  "subtitles": {},
  "user_uuid": null,
  "audio_only": false,
  "created_at": "2022-11-30 18:16:35",
  "updated_at": "2022-11-30 18:16:39",
  "subtitle_url": null,
  "thumbnail_url": {},
  "workspace_uuid": '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9',
  "sequence": 0,
  "templateproject": {}
}

export const ProjectJsonData = {
  "2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9": {
    "fps": 24,
    "text": {},
    "asset": {
      "9183d546-70e0-4860-928d-b45ad4d0c890": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "9183d546-70e0-4860-928d-b45ad4d0c890",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/9183d546-70e0-4860-928d-b45ad4d0c890?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=N0GYJe097go2sXec5DwOkfoDd17aa1Ahi3Rwj94Qgwl4MtaOSWMaYpwZk2od8XL45BMUtnysTBMCso1fIK6%2BPUAqFrAoLcl%2FnO8HzyqPB1r2vKuc43%2BHHXQWjzYN41R3mEyvdTF9sYyuktDckSEjEhl7exlk7eD%2B%2FBjl8LlqV0C8SyO05P9j00pDJB2IPdTfLj%2Bz1uYbwAGs4m5SmuflpBS8q0fLIuBbXwXfHFGq0qCGEcZ5pxEjndAQLRqQv%2FXNvgmwAZw9iFqEC%2BuunjRvAYYh%2FHxZhTVCwiR67iwFpLQf%2Fr%2FVDv0dp8DWL3VqtJRCBWSN5ByyJhav0l2BCBwHSQ%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/9183d546-70e0-4860-928d-b45ad4d0c890?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=N0GYJe097go2sXec5DwOkfoDd17aa1Ahi3Rwj94Qgwl4MtaOSWMaYpwZk2od8XL45BMUtnysTBMCso1fIK6%2BPUAqFrAoLcl%2FnO8HzyqPB1r2vKuc43%2BHHXQWjzYN41R3mEyvdTF9sYyuktDckSEjEhl7exlk7eD%2B%2FBjl8LlqV0C8SyO05P9j00pDJB2IPdTfLj%2Bz1uYbwAGs4m5SmuflpBS8q0fLIuBbXwXfHFGq0qCGEcZ5pxEjndAQLRqQv%2FXNvgmwAZw9iFqEC%2BuunjRvAYYh%2FHxZhTVCwiR67iwFpLQf%2Fr%2FVDv0dp8DWL3VqtJRCBWSN5ByyJhav0l2BCBwHSQ%3D%3D",
        "type": 1,
        "thumbnail": {
          "a405fcdc-940e-4bb8-9f0f-b76234cb4b62": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/a405fcdc-940e-4bb8-9f0f-b76234cb4b62?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=FJk%2Fm7L8L2A1gWatdvWcqgmb1%2FFQJRh8xTh7PgGV8Yhat%2Bg4DE2Wo6yYs%2BUa3oM45%2BKI%2BfOy2xyCsukitAobeb47zortlJObGpThX59U5cjgy9Bx506lR8T1aH6yO9I%2FLsDvz%2FafENSs2BZMV7mbMeOYIgPAGEss18O1CTsmz0BcUSnsDaMYoGM4N9HhN7grbay1LrfQLeqt5tHJ4Jm7VVRj6QNfS%2FjQTooICX3VwYoEVsxZCQJqSyIwWbRe9ALGh3RBja2uoa%2FJtLR%2Fo42uGEcSoasORg95wlprtahIq1ZNKy%2BqsjYevwGZLpWrlfu6eGkoaGTGG7J7yRUlc1dFLA%3D%3D",
            "timestamp": 0.1
          },
          "fabddc5e-1dee-444d-880d-69f6a5c84961": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/fabddc5e-1dee-444d-880d-69f6a5c84961?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=bgn91N2qeEWLDuVpyHMHzrooUOVpNYvAwq2UnarfGMTKM0haoKCbff5LuC86wv2FQohEwjj%2BNSAoCBDG%2BofFidzktFWN6SHHH4%2BeRCo%2BjqQ92%2Fi0Goi0w%2BO5WF3diKwrirv%2BIT9lznyHSv%2BxPPY%2BAQ%2BkIZ148l%2B2bq%2FwFt0QnaKb0dI4UlpO2WTXEnCWAu%2FMyOSQBj467kZdPu4NcyHUQFkscMLn6oQP3nC5U3xvE%2BvSMzwYopfWd43AY5w9WWvHJWOnUsS4pcxOCUlc0rTNBruQ16I9qP1UuG%2F7B%2BUFh0IWu40Ahef66FIRSdhx5cwy5EJlJuRWs3HEOBF29QCtZA%3D%3D",
            "timestamp": 8.4
          },
          "cc90b732-0cc5-4799-b68e-c695a19460fe": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/cc90b732-0cc5-4799-b68e-c695a19460fe?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=N5jQ4QD1BlfQVkQVfUmi6mf8lfKnCNlMq2KE6vCvAOJVJT4biqdeLbGHqL2vL8Z8q7msfH9yv%2BWjm7I0v70nWFidBlGQaBGLVazr2WPV1VOmLicn4c5BuWahlCU8EeJsj4Nn%2B12yhEPCqFhWudksMPU7NabypVMJiWnds5UQmL6Bow19Qq1sr4rjQe85l0DYLXg3zfF1K70ITswqO8sBnNkg7Kj0UfFArjv7gfl1dErufPV7RDUdAP0YVp8%2FquBF7PbQ9QcejTwBPNKsNzSLodOyh%2BXv3v0dUy%2B0%2FSg2RA%2FS%2FAgN23H%2FevYINRQL%2BJuWkMifENdk8j1SH0YWcMV1Aw%3D%3D",
            "timestamp": 16.7
          },
          "1cab354d-3b07-435d-a7c9-d83a71023323": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/1cab354d-3b07-435d-a7c9-d83a71023323?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=fYXnQopNCpfsJhsTiXEc6fKGhp4zFYe6VESz6Y7wzUcTtfctekfnlN4xQSYQn16aBA0%2F4NT1YiycjX3kE429HIjyKugOh8Mmls%2FdzTEHhh6QlrUrsGEwJBoz51eaq%2Bb2Q1O4%2FhfPYoab1z%2BLUzCOiiviOq5OjjrNgrYYbNr4ty9siMAMsxwgcDCs6THtDHmbfuL4i5yRWHKcrH1XPbYKfhwr8%2F0j1DkPJq6xS%2F3t8rthlMaXFB6c3BDHcsZoCNcSslnMOnPzFpGK%2Brycwyb9e0LRzVlOT5fdK5YLOy0CpNLObUku5tHgLi%2FKhog8510%2BzQteyc7FteuwVukuY7xO9g%3D%3D",
            "timestamp": 25.1
          },
          "78437fc0-05a0-43f8-8234-8402ce8ae786": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/78437fc0-05a0-43f8-8234-8402ce8ae786?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=ibn7SnL9qVeOtuLrtNoYuD%2Bdm5zbcoyeZEN8Ov4JkrLhOP9DzUNnIOY8e69mh2b%2FUXqSoCfGb8T4OYbHyllgF1CHTYJxQWBuKx7bZDWR7zIKiMdZveL%2Fe7353Yc6siF74wImdTuRS865O2Tp1mFmL4f3sG5S3yUn2%2BeanmtP2E%2FUIQh5tyxHqU8h7tyWLCGwGS9zMUSZPFd%2BZA5fmnvGAjLoGR9VjGxNz%2FODcFTd8q26JHm8IkuCTv3Kw%2BU%2BVfsKC8Irdw%2FYtU8a7bGrQgA2XEq%2Bl5mLnMRvG1tWIEtEjkGEdep%2FcNrVW5dPOdxCxpuDPdlj2Ef65uN5UKpPeTUz%2FQ%3D%3D",
            "timestamp": 33.4
          }
        }
      },
      "638c0c7e-9c2a-4197-954a-b3fc061cd9c7": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "638c0c7e-9c2a-4197-954a-b3fc061cd9c7",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/638c0c7e-9c2a-4197-954a-b3fc061cd9c7?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=ZzkK6qDGICspK5QYWuUmQp0Ow28lbk6oYxEcL1Qe%2BHrSHoWbzSSnNvhc82n5ippD51gfVAEpnL%2BNGNDkdmeVfbhPzMX14CAAEZDFfBWSaP0thHUObQ79UfRyG3yeqgkV17VRRT%2F3vsY%2FeDRAImkOrAfe3jGEiyXjk%2BNxh6U30BzRpaW4zhJtWgIThYMU19AnJjZHgUGpHdQYl3AtJAM2zq6RIKUi6QrqXbJ%2FuDmQw2jdhUmicdWWtp0Ks6aiUa3WFRNYn1ySUGAeGt%2B%2BDGAIek6TeBh%2FdAMI27bCJkRmyEwiRG6pmYvnMPbbi%2FNCg5JjcmxiT9pO8m8n21K6Aw2Dew%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/638c0c7e-9c2a-4197-954a-b3fc061cd9c7?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=ZzkK6qDGICspK5QYWuUmQp0Ow28lbk6oYxEcL1Qe%2BHrSHoWbzSSnNvhc82n5ippD51gfVAEpnL%2BNGNDkdmeVfbhPzMX14CAAEZDFfBWSaP0thHUObQ79UfRyG3yeqgkV17VRRT%2F3vsY%2FeDRAImkOrAfe3jGEiyXjk%2BNxh6U30BzRpaW4zhJtWgIThYMU19AnJjZHgUGpHdQYl3AtJAM2zq6RIKUi6QrqXbJ%2FuDmQw2jdhUmicdWWtp0Ks6aiUa3WFRNYn1ySUGAeGt%2B%2BDGAIek6TeBh%2FdAMI27bCJkRmyEwiRG6pmYvnMPbbi%2FNCg5JjcmxiT9pO8m8n21K6Aw2Dew%3D%3D",
        "type": 1,
        "thumbnail": {
          "de870b6d-b657-4a08-a317-8525a9773467": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/de870b6d-b657-4a08-a317-8525a9773467?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=KQmA4XFQz%2FKDYghtdrnAZE2HqeTRIuWxqXkaWoYJ45NLI%2FqbD0R1Ym57FeDMZeBWnlldxlCsO9Jrcb4n6NFJM1ZrYVjInyWG4ZOjq%2Fd%2FuWdGMNrrSxUEJZTeFk16qC9PxG3UD5pbRGUodla5p7tkzrX%2BRIapCXdQTT4tVi3xD6NziD9vwBpBzFlP9I5w5zPAGth0xDsmzBj3j98TH0Wt4N2ItlytcN0l3TKuoLtwRo3iigmp5%2B7SwOzh7DGPUk9Nd95dPvSXCTfN%2B3uiFhKX02%2Bok8Ca2jbcuzs%2BvIjLSmdF%2FllYMhns%2FfjOASu1kUrH%2B3Ol3UiO5KeXTefR1B5Exw%3D%3D",
            "timestamp": 0.1
          },
          "22319043-1520-4e33-bd82-11ad52c3c3b5": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/22319043-1520-4e33-bd82-11ad52c3c3b5?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Wt4DRJu2lIT%2BOrad19%2BGtYHKIkse1izgCu8tyOedeOqVRyVaful4aGsAHUbGVwBmNXKe6d%2BxxGoGpv%2FNx%2F%2BFqypkJiPpatLfvhRTwoXu6gnh80xWkca7j5YVLhgfyXT858rlRxJ%2BzlGALE5BT1hwe8XWv7Y%2FOCRiBaMQULhL1UrhaNSrgJ7MBwgMxuPtwER15rGOF8GoPBfMOPKkMHKgdnwGSxVy99m0afWo2AYZssDf9o6f8b%2Fj%2B1p0mh%2BSLdURQeSnzVC2muLXXuMwKtGc5TxxP4lCRpiFwEZRaHt6hx1%2BKp7DJzD34KhERmon%2F0LVq075lyJcHoAXTALCBd0FDA%3D%3D",
            "timestamp": 12.1
          },
          "6199b7d5-8cfb-489b-a10d-3d03c71fa7b5": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/6199b7d5-8cfb-489b-a10d-3d03c71fa7b5?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=fT46xlMCAUSlxOO%2B4sKD%2FL5jK0EG3mVklbbWY%2BzmIRImbArgEtwrjBrYEx3qSCCZ0I2IYcgLnzXvwYdd8cS5qNY8cZxa4IjUJSX%2Bs82WuUjGyrgTWjlPMC58n5rdc48am%2Fcwqm0GATqbVfzudqE%2BuxqtW%2BZR4ctY69pi7xG4PbmfoXRNfrQjDIRHe8xOPaDhjpaRaVL9EMpdo9JwrskoJPT8zvpT4f3naHwrS8fUA6WVbZBH%2FMpMvFXHTTSRvR9uT4gCxfZDYaoq%2F%2FUMj4I7yGUairOZY7R0rG5hbZLcNWYp%2B3B%2FVQ5HkMbluHvjDVkecQPz0USWFHi8EWz58ThMMA%3D%3D",
            "timestamp": 24.1
          },
          "d49d13bc-763f-4495-a0c3-ee6ed80c0ab6": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/d49d13bc-763f-4495-a0c3-ee6ed80c0ab6?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=FtJRdh5A64F%2FxuTHOdi8cwybtRl8g79KZjM04fmA3Fq3pVJqgUPtYDcuSOqGmACig8LhqPe5Zrey1bekl3%2FVS1ZzVvoRiyVJUo9EFSHNkGkrXizpFp5lhAsm2YpC%2BeWse%2BasRIiYkBHoGg7CBrj3uMIIiz97X%2FkSe6e4X6Ex%2FFjAZ5AydNrDVyBJBWGQqK6L4lmKRAvK78ZyP9jOf9hL7rdMvXHAeQz7QmerAdyu75xKgrHqrRhw9Ug%2BGb2cnN%2BvYwU%2BtLECxKPFo57tZxqkhtuBL3fV2hu31Il94zeq%2FJbS8rYeWFRFBONG%2FyuDZohRe39DwBJGc7OOkoIMgSfgdw%3D%3D",
            "timestamp": 36.1
          },
          "7e052456-28b2-49ea-bd40-9a54d2979b7b": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/7e052456-28b2-49ea-bd40-9a54d2979b7b?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=I177rVVtE%2FFh7uLibRLNs3L7Vjd1abZjsolYCLv2omOwpTtrbF8TdZhxV3EbP6iM29rv82UTdvmazU7QUSsBBIigN2Eb0EOU5umv4V0C2kkS7vMNo%2Bfb8YJiBEU3EVgfdDm2U%2Bcx7BJwgDuS8I1ymxYCwywbEjJRBzpTugGJUQ0PQ14l9xzDiqtI7ilE2Z9A%2B5oT1dBM%2FKF04ZLXrwwIdhJkKEyhkpvJjc7Uo86Qs8Ar3f1jWi0UiuUdi4oTMg0a0O%2BnOAtWo9evf6XBcvxmtcf1xJh%2B8vt%2F9QucgvZCyIhIFk5UggiFFLRTQmde7wRVcR%2B%2FuWvLcBmFfhwsmsN9rQ%3D%3D",
            "timestamp": 48.1
          }
        }
      },
      "98d6ba7c-2079-479c-825f-ff00507a4519": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "98d6ba7c-2079-479c-825f-ff00507a4519",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/98d6ba7c-2079-479c-825f-ff00507a4519?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=UHEnse7SkD1Ov3ztYsgM5dZt%2Bs9X0Buk%2BlK9rW8P26TLevnBZvM0cwALZKlclX0ck7J90xs6Ree7xyVCLun8czznFRQ9hqh4h8lG0tYz%2F5nG75%2FJLvKXtzpV%2BfP%2BRsJ%2BBtmioJyC4DlXX7CRl1%2BxwchYw5%2B%2FZCwxVHoTIv%2FSSgN3Adrq3SVJji3DHCZs9wplZ6oCTaLOL8vGsnIoNki8uJ%2B1%2FlVu5FBtVRgHDBw2w%2BXJuwueIYT6Tlu%2BKX1%2FSsTCZHh9xbUFa8As2oqX3DD1d7jqxV5g1FtX%2FF7Anq%2BGjkS3eK6Yxl5U7rD5SrN7dRYVYvV1jR5HbIxecYnTXHAbBw%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/98d6ba7c-2079-479c-825f-ff00507a4519?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=UHEnse7SkD1Ov3ztYsgM5dZt%2Bs9X0Buk%2BlK9rW8P26TLevnBZvM0cwALZKlclX0ck7J90xs6Ree7xyVCLun8czznFRQ9hqh4h8lG0tYz%2F5nG75%2FJLvKXtzpV%2BfP%2BRsJ%2BBtmioJyC4DlXX7CRl1%2BxwchYw5%2B%2FZCwxVHoTIv%2FSSgN3Adrq3SVJji3DHCZs9wplZ6oCTaLOL8vGsnIoNki8uJ%2B1%2FlVu5FBtVRgHDBw2w%2BXJuwueIYT6Tlu%2BKX1%2FSsTCZHh9xbUFa8As2oqX3DD1d7jqxV5g1FtX%2FF7Anq%2BGjkS3eK6Yxl5U7rD5SrN7dRYVYvV1jR5HbIxecYnTXHAbBw%3D%3D",
        "type": 1,
        "thumbnail": {
          "3a6eb8e2-b7ea-40a1-a4df-de98843018b3": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/3a6eb8e2-b7ea-40a1-a4df-de98843018b3?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=HG0Iw8PnmDJS546UCqcIGCoqKC4Z7pFw6DcGjVg8mGAs8Wi%2F1TOm%2Byvl5K1AoXFk9KAfAILhb8KUbUNmEbnT9aoRJ8%2BGH7dZKMNNb02mZCVk3N5l50lklYjzAorlZ2PcVnbP5p6Z7flVJ0yxkZHizNHSjpDK4QKAZrujtuFFU1g8Zbgw7rxe2pb%2BWSuJXWjd3HjuzckWWqKPsANeUypDAftxdjFG%2BHacZO9IMzcQ0R%2BWS1DBWzkFaINoBv775ucYnCowlgvhKRkyHlRbB2MZv2CGwkcTaZxZVQN9nUX%2FmNhVrXzJbtPtxGq1tGn%2FksNJ2HfHUuYabRiazDlVVB%2F1MA%3D%3D",
            "timestamp": 0.1
          },
          "8c76e5f4-5621-4c27-b86c-53874c0814ef": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/8c76e5f4-5621-4c27-b86c-53874c0814ef?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Yi%2F7UORRPFLDDvS9mEiwXgjPEWUiffd5EXXkKkBYSfzd9XczmlKxlgQ3zLRQn2AbmXjWVp5OnwEggj6vIg1dJTEB%2FFsXrupUHfOETEjF7eSldflp4UjZBi%2F%2F7hum2SkD7fUkZWyTKd1gX8P6nmteAjy%2BmBbAj8Oana2Mp%2FNrS9axv11H8b6oS0M0q9OpjcbdArYi7EsLgB0s18ouV2E51%2BZ4Wb0RAKWLskPRHZA2c%2BVwZaCWGvKD%2F0fx5Aux2%2FYZ9kIdN9sTDsMFckuwG1sQgMMUnWlk6pcdy7bOWRRehVTjLaQrE59iX3NHNvhcJ%2BWFYeE8kIcMF26XL4zhgp%2FgWw%3D%3D",
            "timestamp": 12.1
          },
          "e7937124-dbac-4914-844c-757bdfc1bc06": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/e7937124-dbac-4914-844c-757bdfc1bc06?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=HSjfEez7MDM9YJhyzLvHJI8UGvXdgMw04d9apz7imiFWLthEPhiwbBpjgVNTWF6Mrk8fn37ndPCV8JMOocx8EjM3no3XO2ljODbGAnyMvQuXOrgmtnO0TKxTJjwO4G4zh%2FBaEcZ%2FXCRPpXSyhJJi3ay%2F63bBT60sWWXjXvIS8y9ZVTahAOh%2BDJabe0qfDQhwzbTBU01kGXJuHA0sDGp2P%2BpwHEF68igg%2BHKo5CkxoKBJ1vS%2F2Tz%2FvckS9QFA5%2BDXawe%2BO61btXHMI0Fw1nVNtUVsvLz%2BTGysKoGYTviMrcDba86MmE%2BuBs%2Buv2eo5wgTEtaUgCw1ACcsllXgUBK%2FzQ%3D%3D",
            "timestamp": 24.1
          },
          "4e8eb6bb-a56b-4827-a952-fd226b7bef22": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/4e8eb6bb-a56b-4827-a952-fd226b7bef22?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Zg%2FPmR1DnjGMqYUkL8cvsfW7NoklMwdng7p8K7L2qmztV7qE%2BqKWmU5pcuRi0JcNLI0D2CsBQEvLxAJBW%2BX8s468aZQOt%2BPBGN9DSBmNs6UB1wLCGZ8tWsB6n%2FAh69gEwvjS5pQIPal%2Blygk6lJRKOqVpQqaQSHAclg4pb4wvOlYa16VcEPFvP7w2A0HzvoksuaWDLCetwq17OC%2FqrDTn%2F0KtGltSwCxiU8u3Z47oEg%2BP8incsoSJWxOGkjap7jxvvPl8M74t90%2BBz%2BJ9v%2FWytJecsDHrCVSjicZSIcK6LuOY8BYgwjJgHcV4cy9Mgwus03oTZcjwpa4%2B68CKyLA4g%3D%3D",
            "timestamp": 36.1
          },
          "972316f7-8894-4a7c-b3a8-de7d684b466a": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/972316f7-8894-4a7c-b3a8-de7d684b466a?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=eprnvPY%2B6yQ0eZo5C%2F6ocPNa86pI72j9jI3gPy9TNA4RubZ5jFuMscpE3YTNkNzuxhPi8kCxNde%2BEHk7u3ZLd9p7Wd93GB0hVMAd3iVvFtpy%2B0oT%2BEh0BbGx09xnTDjr%2BELLsd%2BHJMdN%2BwoZUxD0YmzkK5GiwqVwDi9efXxuKOQklEwHyZg27AeSJlMrVJ3t4Cyf83PRKWRAX%2BD2j6DAIV50bVosSjkVBVIT1A8oJjkThct1UAmPQWuu06F83aQ%2FH1iF9tSsVqX5nXJDDYykvBMPRkBxWpN1K9U1YvjTyWXZ58%2BKllcFIh%2Ffm0eMAu4rgtDRe44URRpZtyQ%2Fi5q7sg%3D%3D",
            "timestamp": 48.1
          },
          "46c9849c-22ac-4091-b83a-5ca9ff5de2f7": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/46c9849c-22ac-4091-b83a-5ca9ff5de2f7?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=CdGT9PWL6m7e2oh2HnldCg1QYlbnw4rHpg3UwgoX1F%2B1DDOQiJOv%2BO9o8BBBxuKJrJYBtTfx4vaVRkkpgB%2FteIpAx4OdI5of3WWIGOfFk9QmJyOpycFTMsLTQDMOY6s5mGbri340T0aNwfB6DkE8O%2Bxsz4TLlpY%2FmHBqQWf1TE%2FKlp3NUla4fRH2u%2FYRtC1YyLElEkSmYPZgIB4MLzNB1LUiO%2Fc0UXzzpPwKAnPophGwy1hpbkI%2F%2FidIQrXeB0SM9g48%2Fqeg4sIxRGtPlLBXRj35%2BfO2l2DyNMTNRPSgvbUb%2B%2FfTYLkyvWMPaMbix3Hjlf%2FukZARHumuDI%2F4NPeXAQ%3D%3D",
            "timestamp": 60.1
          },
          "ddbe68e0-a7c0-4409-a16f-cd16b8e843db": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/ddbe68e0-a7c0-4409-a16f-cd16b8e843db?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=OiA6ISRDod5BzCz%2FeIvb%2F1tG6ZAs2RddJrrYrPn8naptWh3Vw3oEW4LlTDcMp0ZT8hJvt6auhg0qo46ZGASJi2x2IfOhp4PkFJF0vOy%2BzIeBpudeoUVAWvUPlz%2F4mCN9lWDp%2FLrgexxRYIn2AffVE9HOIY25Dxq9FxsW%2FUpZxxRA9p5hsEXGWBWd5WiBUXWaiA6GGukdWLeoeiHT%2FTMUoA9TV3VQr5agYILnm4JD1JlEQwqS8AxzFMGhOc%2FFkKO6MbNuYv6AxDs12QJ8ABMpG8IM4Y4HCeKFnX%2BAu4prQeZgqVCbno0FmhsIiqoaOFU3pPCEGjP%2Fsdc2nI6KaDCwDg%3D%3D",
            "timestamp": 72.1
          },
          "33575664-a44f-435f-bd7a-f04cc6c6e52e": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/33575664-a44f-435f-bd7a-f04cc6c6e52e?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=HS%2BJBCmgRBPlSHi4qg35PPMzfOfzCU9eWSRITzrkOF%2FAeq7VoDTfp%2Btghsg2eUMujd3pHuetGn59dlvFismt2HgyOqiYIsLuFM3BbfpMqIBvzndga8uavg4964iaBTNgI5lQngvkjHG63JZIlbD7B4Kais%2BgBy3B7kK8MP8rjOYridPEf4PoDdB%2Fn5PUF333rKIDB3qd0p7raAWplAiZtBhSBWuqxblyI92TexJvwbT%2BhXWhASzjkupm1F2tlwKQQX3tcb7ZSgslkNZ0MOaFWLyi2HvheaAwve3NYuO59EHGcNhCchDqBBM6MroHZpSQ4RfA7m9r%2FOhX3%2FCUBjpWBw%3D%3D",
            "timestamp": 84.1
          },
          "d37e65b1-23b5-4375-9b12-0952cf3e841b": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/d37e65b1-23b5-4375-9b12-0952cf3e841b?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=W%2FIWM4jVh%2Bl%2BH6QNKDrfa6xrrNpxfwHKTH1R14dGZyzelkmX9La2UilywN%2FE07LLwZHJiVbfwJuJKhMIoSHs4zCFBJgGW%2Bv1AL7kp4o%2BzJIZRapl6UF6eaNJl2MkwuKnjxoGuoVzi051jHqmHY46U%2BJ1G6xuTij2eteEbtiLen6CcIpQPYbdjo%2BdLK4deoW%2FeFukK0lZlpialiToyG3X%2BZJf1UZcfI7FfKI9Y%2FSGapUSa9vmehzLtD2p6CaEucYzUhIbtH24aVgGcjqvR9lWw5RPdVTbKqb4Zn22HWkUYA%2BoyOpnlunBfxxSrKwiKxtOF6YJAGWwm82fdSmMZKDrHA%3D%3D",
            "timestamp": 96.2
          },
          "256f2944-b920-43a2-9365-0cedc447c1f1": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/256f2944-b920-43a2-9365-0cedc447c1f1?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Y1of99NKjocKMTqpj9VfQ7Mq23rag9%2FxAZ8gQ7tek4Awo73ISMUooKW8WwVFJmdnufKWXjEhs%2FMMj9M3BEf76Rx7Xff90O%2FfPQtqGd%2F0JRzPCcobgFQlqhQ9n6LoMeIRA4dW852meUgE5c9bbYOJntCWZ2N6N9iDJtN5Q%2F3iKsQnRYAdQtvZ0CgiUFiLYMZcdXXxWo3Zi2kKvMYyOB6PdeaCvj%2FF7HHHQVBavGbylzS4zZF2EtYLjCB0FNOVqxKqUviQZVV296NIjIWS%2FZa8ApoTI0EC2xnkarI%2FznbtJWzAtDktaYwQh0sXQxjwq%2Fes7GXoEB2SxdDQcabFLa3kGQ%3D%3D",
            "timestamp": 108.2
          }
        }
      },
      "623693bb-ccda-4288-9fe4-32b8056ed487": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "623693bb-ccda-4288-9fe4-32b8056ed487",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/623693bb-ccda-4288-9fe4-32b8056ed487?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Z1i1Z76GT%2FPxx8O4OIqwqzaPOBCcw3Nhq2kinSjNLD9owVBxv8FvrFxYHuB682px9e7YUcMgiurV4heNDVsT9v8GSOLXfKeG1CNxQ%2FluZvQm6AWGW%2B2UE%2BxKS81L54eDPIo9hVGUBqA3u94kJI1m9SAgODkJaXqOxC8MDFgyVd7DYvSbcaBNOYFlKU0P%2Fh%2F3H%2Fr5BAO7IbIQIDo8oez8Sn6SpS77KZkZ0rOR5LcTSfJ1aqd165JwLVXAsRteA05hMGWhuTVC11e%2B7c1bqRtpsjUsKWivadiwB6vyyxAQmwCSL50ld72JbgWyl1LEsRiS2sjivCk4tgAhDXwre6UOGQ%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/623693bb-ccda-4288-9fe4-32b8056ed487?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Z1i1Z76GT%2FPxx8O4OIqwqzaPOBCcw3Nhq2kinSjNLD9owVBxv8FvrFxYHuB682px9e7YUcMgiurV4heNDVsT9v8GSOLXfKeG1CNxQ%2FluZvQm6AWGW%2B2UE%2BxKS81L54eDPIo9hVGUBqA3u94kJI1m9SAgODkJaXqOxC8MDFgyVd7DYvSbcaBNOYFlKU0P%2Fh%2F3H%2Fr5BAO7IbIQIDo8oez8Sn6SpS77KZkZ0rOR5LcTSfJ1aqd165JwLVXAsRteA05hMGWhuTVC11e%2B7c1bqRtpsjUsKWivadiwB6vyyxAQmwCSL50ld72JbgWyl1LEsRiS2sjivCk4tgAhDXwre6UOGQ%3D%3D",
        "type": 1,
        "thumbnail": {
          "88f4fb1c-377b-4daa-a00e-cfa594d2f031": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/88f4fb1c-377b-4daa-a00e-cfa594d2f031?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=IF2B2i%2F9BxZ9t%2FiIUY4mN9vUIHKl1Pu20g2%2FkEad88hoE9yOIANNgG1R4jCB5fIH4RhWaAbEW8VshGhJH5doGUthv09DYSRCr5%2FE8iC8dJNg38lBKDGzkvcgRvAT9r%2FYxzFs9zS5fsFKIfX1DMu5k%2FjZ3BUNxy7v11ckqTE5IB4VA3xHER7ackuyZX22DHV33HIXUjufELIVcIdOcWJe7cdhl0EZa5k0c0IvAuy%2FuGiHYKvyF1XaRKNY%2BzVKWj%2Fof5ZvFkJQSmWXUqAN93osKXJ8gWzj3zTTUNO%2F78UOTZS4DKIH%2Fb73Mge7BzR1U6VYG4x8ajFSaqROOgx7slu2Fw%3D%3D",
            "timestamp": 0.1
          },
          "94b17512-2aec-47cb-94a0-94ea9ba5c272": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/94b17512-2aec-47cb-94a0-94ea9ba5c272?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=XKSrtEE7EMlJyKq9s9RRtLWxkSTYTIBtRRIcE14xI3GGNoWOvB7nuDouB%2ByXf5TbyH5OafwTbaHMha0Et%2FtS%2BuUnsX0lvPJpNYQ6rN%2FBPjZceI1LYcR%2Fjrlo5be4G79GhCEx6WPHnH20XcT1HyZyrlN1suECYCwNje%2BvkXPJVkjLVHaIXVkAd239e6O1v6vFxyA4z%2BYes%2F%2B%2FUkJI%2BmUWl2eiZ0kSCzeHbtOLsjJ3i5KkOjY2QakUfRx%2BcbmKY1x8xtgf4iyBMH9JQ%2FhD3jcvOoK0KY7Ly2l2otjxM8v43kEpwBPkKXvtCmpqWCcFE68nGZIsAd5rWscViB5d4p9vcg%3D%3D",
            "timestamp": 12.4
          },
          "da7344a4-556a-49e4-95cd-6725d548622b": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/da7344a4-556a-49e4-95cd-6725d548622b?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=R%2BY0s8vBNUI5aiN6%2B1nXuHD8qonobdFBHLCcv47gjjUTy4LUOxT1C%2B69lb%2BUFgoVV%2FsldKkrDAexfgiwMXizyMavP7uGPDDNO5uULlzptMCtnGUxKSAoDZuMP%2FC8%2F7qEut%2FQYIuloYqsWI6txx6HmW6Y1Q0JgJc6H69w1%2FbKZJSVh4%2BR1QxV%2FCzxYXYWxsRzIdUaOA%2FwyjZIbmTew%2BNz6oqsTOzeEzkfF1cyZxOEyKhosYjNRsImV5RSlH7XamR75bhC7ZAZJa7p8xQB6szHKKhphuBM9sh4UQ2%2FqHYcU9FpxF%2ByhlyTpta5LfMvDTe2F%2FiyhYJiVGdfKAudt%2Fv9mQ%3D%3D",
            "timestamp": 24.6
          },
          "028dd938-128c-4239-8f69-8869a0eef266": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/028dd938-128c-4239-8f69-8869a0eef266?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=YWBI2ByBaNwKNyYBqma6v40P16U%2Bfjl4Dh6UR%2BDgL3Eay6cFps8Jue9vUZQOjjSyQ3aCBM8x7V9SC2U9eCkC%2B1nVsEOWuWhXp4PX6kx%2FLPnLNe7pRedP8%2Bb5AanfCEjH%2FmWresTm%2FLyPBmc%2BfxVBjalM3rbWVvDDOt9Z5mFd3QAzm4cebXp391ej5vp6gcQOjgDggFRM3b363nHw7vbxFQG8AM%2B59eYhN7j5uR1cTN20Fjxslyd9sUvbySDummPqxbCbprS2F2WxzXD2EcXXDjcvK89ehgD7I8eisEDsV44Zpgz0Xt3G44pHuIfPLGF1ufI8He18Hp4tfhpgfEMxZw%3D%3D",
            "timestamp": 36.9
          },
          "5554a1b9-d9fb-4367-8e14-4ce1917f37c9": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/5554a1b9-d9fb-4367-8e14-4ce1917f37c9?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=VUqSu8GYpGi%2BULgfagqolUL7uPW880P%2BZ7XQ9OPMMfp%2FKRj5C5E9pVYAu3bDHCEEeWYZw30bemeUzwVaIkqkJGqaoCjT5L7BdM9UKGmljFhAy4zwD5nq%2F6hBJ14K7%2B%2B93LPqI2tBl0tQhffRjKvDnpIGYPQRJzG0m2tJkYXfjNOzTEfNFJwzE2ECKWU3DGQFD1dc4MGxFjIW6rwdju1%2BiB0EaZ5yiscRVhBuNSD4nqAabyUAAmcArpU4%2F5ScsKn%2Fhztq%2FU%2BNpkqjPwHgqNjeMCiLzdysULUYaChu0XA1TK1SHcpc%2F6vjdNtTOaT1rlbuZRjOVwgLJDKs2KIw45fnmw%3D%3D",
            "timestamp": 49.2
          }
        }
      },
      "0be3f3c4-97a7-4c86-a3b9-6b130dd52a62": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "0be3f3c4-97a7-4c86-a3b9-6b130dd52a62",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/0be3f3c4-97a7-4c86-a3b9-6b130dd52a62?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RzHfahbOQTVw2XcyaZ1eeoAC81yqu9F3EV88djKkWtEMaAj72QYYA8WoR07LpTeJ1gDSM2AyI15weXp0pLpXRcB4otSifcVwUnz14I72ePXoVaarbWOf16Q4Yxa4aLnQUoxiUcST5UXTZpS9WpgdUywmVp4FG7AsciUq4BS%2BcCFfvdlzjoEK6Qt6m2cEphtv6FTpVN5BlyWMrCjtj%2FXQDDIcOpIeyYcBL%2BkQwFZYa03ZpbQwsjJM4Ytp1RUtIm%2BgDwOK0L2JDEnwrJsCbJZ6mB7ezS%2F0PUA05Rr7sRWz72VBud7tTXZgWuExxWBEV5n%2BfUUcm66BMa41fxx%2BBSERsQ%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/0be3f3c4-97a7-4c86-a3b9-6b130dd52a62?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RzHfahbOQTVw2XcyaZ1eeoAC81yqu9F3EV88djKkWtEMaAj72QYYA8WoR07LpTeJ1gDSM2AyI15weXp0pLpXRcB4otSifcVwUnz14I72ePXoVaarbWOf16Q4Yxa4aLnQUoxiUcST5UXTZpS9WpgdUywmVp4FG7AsciUq4BS%2BcCFfvdlzjoEK6Qt6m2cEphtv6FTpVN5BlyWMrCjtj%2FXQDDIcOpIeyYcBL%2BkQwFZYa03ZpbQwsjJM4Ytp1RUtIm%2BgDwOK0L2JDEnwrJsCbJZ6mB7ezS%2F0PUA05Rr7sRWz72VBud7tTXZgWuExxWBEV5n%2BfUUcm66BMa41fxx%2BBSERsQ%3D%3D",
        "type": 1,
        "thumbnail": {
          "d2a8fa66-2076-4128-a4bc-fca4c588a731": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/d2a8fa66-2076-4128-a4bc-fca4c588a731?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=IAeTBm%2FV%2FEYbn04FU4fabRzXznMOornAgkRO9f25e8nuwWK54Ov2A0Itbti9gdsDxBTQwig1xJ1TZqcIIHNTvpVqzxqdCAdgpDHUC1jSOB6ra8hedKxPymzbSyFH%2BPPea%2BKQ82YmA3dSZALsVDijVxOXy5QduswVbj6tCCZX46pC60BHFsDxpph2heGlOrTTBxdUmgO%2BJM8L2oyHQnosYSIzj%2F1IE8ybR3EqwpSQyVpmlysgVPVAmFx3pX6xXuF6wkaNRbVyTpxOqvPqOC5Qoc6vsX9HQrABew7ERzg8u53XTFemVSW24RUGLEzBQP5Q6p52wRXh65p%2BwOZfrv1UQA%3D%3D",
            "timestamp": 0.1
          },
          "9bfafe9c-cf77-4f3e-b741-58dc0a3b62c7": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/9bfafe9c-cf77-4f3e-b741-58dc0a3b62c7?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Z3FdLIJLttCt3zpqWxeFEQwstzouDGQnI%2FERooCOcgpLHqqNULtGJS5GGtCO5n3Z9TJcnrq%2FnbsQ1BZFsKxiTCZFW%2F53SzBiSsjw2s6TZ6oOGYsoq8z%2BoAZKMJe9DG32RqkCltqpUK6UnmttoNwYO0xVEaK3yPt9giJz67ED0ckfNxg61YzU0sjMQ3jrtCBOXsEGIwRDUaQFApjQpZQNvSU82MPogJXalngPLy8axvKKpZ7AIPeWLzIIcIJmS6xakVcAyW9q98SwYLF3CfTJA00lL2Ac%2F7%2FIF1R%2Fkblbexa5Iq1ndGxzRhuSjc5YolvbCPtZNKu4hvzyA1TQN%2FCzug%3D%3D",
            "timestamp": 4.6
          },
          "7acd5005-c439-46f4-a8de-ab9fe3e3b7ff": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/7acd5005-c439-46f4-a8de-ab9fe3e3b7ff?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=ho3%2Fc0Uuio62aIVuYLi%2BxO1%2Bx37mS775QKWTcAwYb8pl4TlHCO%2BZf4klWUXMG7P1TVPTIwfTwFVWXV7b6N8GrHnQuVu7cl3%2BP43RGM%2FpzeAeW6qixEUqroa80hqC9Wn1hOGMkm8KfNdxCVnSmdrLiLlHaP88xmD7qJNFZ2Ecx%2FsZyehYLTu1PE0FXMV%2BJMlt%2BkOO0jarnorpRHHzWjSE1Z8s1rO1JkPTEzZ331e%2FjtpdVNzm3GI%2F%2FgvFbA1t8nN9sXk44%2BexJi532pfX3KH%2BXIVOp8eQ%2FTqV77JG7o2nPYo%2BDcoYXH0XPv9WHY6JNtm31raFmssOENG4jN%2FvdZa%2F7w%3D%3D",
            "timestamp": 9.0
          },
          "4e13a1e7-0f67-4782-a941-a24f4fb7e2ea": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/4e13a1e7-0f67-4782-a941-a24f4fb7e2ea?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Zva7JAFBzuHgWApTiSoqqpHS9I0%2BvEwRXsNB%2BLs1Uf7l4LZuonxo2sKquUf4SZ%2BzWkMKmgQviWjMN5Bm5QHApNS7%2FnKWMnhZ5cp4yaxPBRqWTclclSJq3pLgrkwG4rsSFMTvs%2FIvXCHjJHOjKmbOFm2AwaoeddkACasbWaNIlJafy2HVzH59OvzJQ5LpRUPIJCyy5MbUoZ9PlwS%2ByQnA8VaPmmyRQ7kY01QtpXsYTRTadnKprgP%2FvkTZsSy8BzGSbBGEMK%2FqosuJJqrjbja99b%2B%2Fh31d3GXWQYtnZOKFzKxfEilVR4Ui%2Fk7Yn1h1Aqlh2IgOBWCmpheZy4wrIygXwA%3D%3D",
            "timestamp": 13.5
          },
          "354fd902-d85b-4ad7-ab44-8892e2b5db0f": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/354fd902-d85b-4ad7-ab44-8892e2b5db0f?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=YjggZ9R9HNZ0%2FHDLf8hMt9cv6nzXSLCE250yASfmBIYraO2d4TAmD9Xr%2BVW8fWhraT%2FI8lgtjHgTE6D%2BOqPbJYawNoobewAhhdyKBNTOe0uFz4wA6P%2FIJlqQUSL5jaNHefqj%2B7yoEFa7xeN26WyxBRLPp6J89B%2BWqLTvTs0MapBEyE8XdRD5O1Di6NsR0B9%2BURLX6uMNs%2FHrZeOtNcW%2Bq%2B2fJm9w9ITtgKGlLhmPnV7aacU6K9LXshiVmPeD7EaxNyHMLjvbDZ%2FV4gyeii2WftEkqUBLSzrDA3EvNBYRvxRNT5iJ2iLBySn5TEe1efKSAttsl2Rr0%2BDyUcTYsDZGaw%3D%3D",
            "timestamp": 17.9
          }
        }
      },
      "2d9aeb87-863e-401a-83d5-ee0cf1fb12ae": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "2d9aeb87-863e-401a-83d5-ee0cf1fb12ae",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/2d9aeb87-863e-401a-83d5-ee0cf1fb12ae?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=cvEybn2XQrcU4ZQpTgo2bkg9zzdECD3VPE3Ounsn%2FohwuJEajeYPmdS8LMLd5IUBD6RiQDiMgkwlJOcALl2ecwQq2j7FhpS%2BvIkBGKRN98qIeGGOFFsAj1s4EnchD%2F460UKQzSfEE0LdsbTW9t1JleY7BTVnlkkBGXl60TkVVqjAUtsiVKonRp%2B1WTHC6Gi%2B69NUwbDt28avTG5T6jzgORY6MXW30SfIlG5LLJ52YihHmuOTx4Km2UdA9QBvKnI4OFCG8%2Fx6gC%2BelKevQlFIKLF6Ln8rmXGamjzKSomS%2F7qh1bRpLuZndMMYT0AtUO3lpum7Zjk8kQg%2B0nmGYgHyWg%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/2d9aeb87-863e-401a-83d5-ee0cf1fb12ae?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=cvEybn2XQrcU4ZQpTgo2bkg9zzdECD3VPE3Ounsn%2FohwuJEajeYPmdS8LMLd5IUBD6RiQDiMgkwlJOcALl2ecwQq2j7FhpS%2BvIkBGKRN98qIeGGOFFsAj1s4EnchD%2F460UKQzSfEE0LdsbTW9t1JleY7BTVnlkkBGXl60TkVVqjAUtsiVKonRp%2B1WTHC6Gi%2B69NUwbDt28avTG5T6jzgORY6MXW30SfIlG5LLJ52YihHmuOTx4Km2UdA9QBvKnI4OFCG8%2Fx6gC%2BelKevQlFIKLF6Ln8rmXGamjzKSomS%2F7qh1bRpLuZndMMYT0AtUO3lpum7Zjk8kQg%2B0nmGYgHyWg%3D%3D",
        "type": 3,
        "thumbnail": {}
      },
      "e9cf8635-1b80-4064-9fea-ed43caa84954": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "e9cf8635-1b80-4064-9fea-ed43caa84954",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/e9cf8635-1b80-4064-9fea-ed43caa84954?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=MDrHFx394wMFLzXA5QBmeBy%2BDQrKmTPRVeyCqzB%2B2sYnIr%2BqZMhc3BY7%2Fl6Tg4pE%2BcZFrYwIUQzg96NgMIXW5lNqVRsJXXFQXEqjiHrrqxoFPo03GHiWA%2FTmMWL4Thf6zu%2F0lTrtq%2F1sFDoF3t8qEjeh2nGu0NLyW70gO7H8sZdx%2Bj6JmVqdum%2FefMQ1QrmZ%2FTe62bbjxsDuEkfMGyh9i0ltexgcp8v2yAVn6b3Y5E6CQEANOSra%2FT%2Fr0hjFuUi8QoQe5YIS4c4fmu3mXgtzCs%2BS8kaOpDNewl87gj1JwLJ9X%2FeWgIu94qTyNcgjq4Re7gi5ibTnmJzfHjjTpbcXPw%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/e9cf8635-1b80-4064-9fea-ed43caa84954?Expires=1690303993&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=MDrHFx394wMFLzXA5QBmeBy%2BDQrKmTPRVeyCqzB%2B2sYnIr%2BqZMhc3BY7%2Fl6Tg4pE%2BcZFrYwIUQzg96NgMIXW5lNqVRsJXXFQXEqjiHrrqxoFPo03GHiWA%2FTmMWL4Thf6zu%2F0lTrtq%2F1sFDoF3t8qEjeh2nGu0NLyW70gO7H8sZdx%2Bj6JmVqdum%2FefMQ1QrmZ%2FTe62bbjxsDuEkfMGyh9i0ltexgcp8v2yAVn6b3Y5E6CQEANOSra%2FT%2Fr0hjFuUi8QoQe5YIS4c4fmu3mXgtzCs%2BS8kaOpDNewl87gj1JwLJ9X%2FeWgIu94qTyNcgjq4Re7gi5ibTnmJzfHjjTpbcXPw%3D%3D",
        "type": 1,
        "thumbnail": {
          "9918f8f4-5c6f-47b3-aaed-091b52471b44": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/9918f8f4-5c6f-47b3-aaed-091b52471b44?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RGjIaCtUGLK%2F3zto6RuRSBF5EWTi1%2FRfVmKejo5ESeNoae%2BtxTM7J7EjFFm0NfEeuqfK%2FazThrkDeskkVMDwSgVEXQ%2FK58tWjPRYOp8yBpR1DK50oC8r3E6ZDHWOpePTskXrTjhsO2iAVkb4jOrvDyb8LKpIHrv5W40FS6yJJE1%2FUrbbttwF3xmfZWTTzkq6hhIPjLJ2tRVhkWy1F1ZFMAX%2Ftgh1kJW80Fy5NXgfjKMby%2Bj4NNqqx7PCykjoEzG7sECsryevPxx3S%2BHM1ivrm8FPkMjrMQpQzBCOYRqP4Ad%2FnvqKj7u2j4k%2BtKnspm10NdGpiS9ePn%2Bg%2BmpqonPi5w%3D%3D",
            "timestamp": 0.1
          },
          "9cde4799-243c-41aa-8b74-6d1fa7898a21": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/9cde4799-243c-41aa-8b74-6d1fa7898a21?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=EnvE%2BSKrgxfm7enlTutiMeHbZAfr8PBwv4Hx%2F6E5PmX6RMkk5D7TBnnRL89prtFtjsBPejogVJZRYDryIVGjwQct7Vp5YLWomjRuPzzSqTwGmE11%2F5a%2BnPAObU7FhOHcn96cftJjBlqWd7WWeYWj5fy9jEEB35JvfWxwQSuvRObGMDbZi3zgOaCKzD4Vbjl5MqOcMuSocJK9F9Rm%2FOqTV%2FHFBdH2ig1SvmqO70kcpQolHRjxr3lnKUJbv9N0qb5rP2JTFLSmp9QPh5CZBJu7PGDO0qchOKbbrKv8U%2F5KjwyDddqYSxWkh05j1hqGnzitpOPbjS2rDrvqXR%2BSi5sO%2FA%3D%3D",
            "timestamp": 10.5
          },
          "f977dcb0-63d2-48e8-be7d-ca29ac14149e": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/f977dcb0-63d2-48e8-be7d-ca29ac14149e?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=gw2rXYJEZknzVEL9Ww6qxRLZGqHd3xH%2FDT9qIFTBv2MoFD%2B%2FGCZwCnkCDj2UO%2BU%2BOGX%2BIT8b81KZKlkO%2BK%2BGOTE9FGHnHMacknhipV0D7FItlEgERAagwJdrvceYHdkZcwUnKsxHYpL%2B%2FSCDv5ZWVhtv1Kl5OBlZTzsO%2BqXCq%2F687macKymEXInN%2Fu6jNtpBCt2LWlnV1o60wLg6BY3SmLS38hbKVWxR34UNnkxJMsVmJ%2BwKZAqabtjXPuwsuiOU%2B7CBbz%2BZ%2BlVZvWTX9sHnJi1aFsQHO6LpkLLSTZfn7vZSU4WGWrPYVs2D6wDkzmjQFNyGIcggG03W7zWqJiZcFw%3D%3D",
            "timestamp": 20.8
          },
          "3956122c-3808-4940-95a4-ebba0d30ba70": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/3956122c-3808-4940-95a4-ebba0d30ba70?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=Cs%2BG2moao5u6rOTNpN4USeNrLw%2B9NPwGRqe5Qtca7Su0OKmldSfXAw1yuXCZPrBvx5Q2Q77trSQgqifmrHFclzjaVH%2F9W7TO9rTtnbrX80zxkYMrSaTw5idBWb4kKa%2FAfPQrZUVKTImkkH%2BUg92Uzjg7B4L2XcRDIPPvR6IYpFOlDqPy9u7Pd5cWjPOhURm8yViqWw1EAxHqhZubz3bNi7ENG7ZUgYr890yYwB%2BZ1fkJxfy08TZyHbf1CeuwcgnbVOrALlCtpg3Q2aU4SRjo9NXzKADThd%2B07v%2FtyDXEaVhANcs8P5o8pPuadSfYamDBqW6iX3s7VV3GBBXCFuNmiA%3D%3D",
            "timestamp": 31.2
          },
          "fd8ac278-6e24-4116-abd9-9dd72c584494": {
            "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/fd8ac278-6e24-4116-abd9-9dd72c584494?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=ZxLKga0%2F1a638kMd0Kg5%2Bykbfy7iCSluEayzRJD3xQjOvlIv0D4qrq5won3faT4u7FzETIQ7f4mghLrxufKTVONIeFVAxjoZ55MF5118ZWXE8CEnWzI3Rxckzk78nNyxh7FKJLBSQ67l5vf0rkVVYswz%2B2fY%2B5z9wSrM2%2BrkJOZ%2BY298rrXfqisAX%2FM9ivxzNa2kwpf9OHoKe0Z2nOjHt2xu2vKWdBM2S0MiPk68pviTAI3xRgXoGkTYVTd9uw%2BE1SI%2FmHrflA3uETIZrJO9W3Tw9bzCrb%2Fli5%2BK12mSXTjoANAeYmHDSrYNvtbmjo0tvBrWrVIHGWGrsstXwXZP5A%3D%3D",
            "timestamp": 41.6
          }
        }
      },
      "62c1cace-ffaf-4e0b-81b9-004019d8a79a": {
        "filters": {
          "preset": ""
        },
        "original": "\"ae8c16e7-2348-4119-9b07-a1043f646159\"",
        "filename": "62c1cace-ffaf-4e0b-81b9-004019d8a79a",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/62c1cace-ffaf-4e0b-81b9-004019d8a79a?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RTCGz8earYv8JuxfMNrBmuyBIzT99U33LbYxfJG8D48F6YxRCRS3bTMx4U6qb0gAwiOKBPwrSpfdISE3YMk9wg2k8d%2BJSYkFaytqM7M%2FIYDB%2BddUgsHs6VsucD9s9ZBhXPGD49F7YKaBhVggEI5z3tFjG5bw%2F3OuFirbNrOyU6dXKEm5G1qNi98D%2FWr%2F44RlVsMoSGX6BRZm6sX7BjkHqV0SBZL8B%2B7fUNiMKVJMBbiHQLWPhmlhRONIEdjbUwSNs5ThB%2FCt0OSiHd0EX6O%2FPRFOJTDnPvnW9meS2fMDRRl%2FU3VLG%2F0pKIq3l5Dn02Rg%2FhqW%2FUnU3LUywalECMPe2A%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/62c1cace-ffaf-4e0b-81b9-004019d8a79a?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RTCGz8earYv8JuxfMNrBmuyBIzT99U33LbYxfJG8D48F6YxRCRS3bTMx4U6qb0gAwiOKBPwrSpfdISE3YMk9wg2k8d%2BJSYkFaytqM7M%2FIYDB%2BddUgsHs6VsucD9s9ZBhXPGD49F7YKaBhVggEI5z3tFjG5bw%2F3OuFirbNrOyU6dXKEm5G1qNi98D%2FWr%2F44RlVsMoSGX6BRZm6sX7BjkHqV0SBZL8B%2B7fUNiMKVJMBbiHQLWPhmlhRONIEdjbUwSNs5ThB%2FCt0OSiHd0EX6O%2FPRFOJTDnPvnW9meS2fMDRRl%2FU3VLG%2F0pKIq3l5Dn02Rg%2FhqW%2FUnU3LUywalECMPe2A%3D%3D",
        "type": 3,
        "thumbnail": {}
      },
      "986ef2f8-f6dc-4899-9c23-9e4e6d34e336": {
        "filters": {
          "preset": ""
        },
        "original": "Output-AudioOnly-ae8c16e7-2348-4119-9b07-a1043f646159",
        "filename": "986ef2f8-f6dc-4899-9c23-9e4e6d34e336",
        "extension": "",
        "cdn_url": "https://storage.googleapis.com/staging-videoo-storage-11/986ef2f8-f6dc-4899-9c23-9e4e6d34e336?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RtTao1A%2FUoENRT5uJ5AkKIT2wBanwfa%2F%2FYD5zYpLLCVRHpFcHY6CnauXulfFvR55wEZi1WnPXYL9gdMZgZ%2BlhQ6SqsJjbB3b9R%2FSRzeImYqA4SrrfimycxSwVyT4oUuWmj7Xd4OlV%2Bom0u%2F9RnnUqLGls7KABg3uKLZGA6iBtuJtNkBL3o%2FvXVZYTb7RI5EQnVaCBO05V3dCzo9IzFdMTUArN5aiyOE6mVc25guwIANFWnNwSgOnGPaQ2588GxNSMeBPPYP3mDHwnRJb4RipmtJ9NwazjCNaSW4XWSat8Ic893OGJedCuAUGmq1JKzhgyNJNU17A8TOAcL6jrVxgPQ%3D%3D",
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/986ef2f8-f6dc-4899-9c23-9e4e6d34e336?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=RtTao1A%2FUoENRT5uJ5AkKIT2wBanwfa%2F%2FYD5zYpLLCVRHpFcHY6CnauXulfFvR55wEZi1WnPXYL9gdMZgZ%2BlhQ6SqsJjbB3b9R%2FSRzeImYqA4SrrfimycxSwVyT4oUuWmj7Xd4OlV%2Bom0u%2F9RnnUqLGls7KABg3uKLZGA6iBtuJtNkBL3o%2FvXVZYTb7RI5EQnVaCBO05V3dCzo9IzFdMTUArN5aiyOE6mVc25guwIANFWnNwSgOnGPaQ2588GxNSMeBPPYP3mDHwnRJb4RipmtJ9NwazjCNaSW4XWSat8Ic893OGJedCuAUGmq1JKzhgyNJNU17A8TOAcL6jrVxgPQ%3D%3D",
        "type": 5,
        "thumbnail": {}
      }
    },
    "title": "Project - 11",
    "width": 1920,
    "audios": {},
    "height": 1080,
    "images": {},
    "videos": {
      "701d59d3-5595-4189-83c9-9400e892b22e": {
        "alpha": 1,
        "groups": "701d59d3-5595-4189-83c9-9400e892b22e",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 41600,
        "rotation": 0,
        "sequence": 0,
        "dimension": {
          "h": 1080,
          "w": 1467
        },
        "asset_uuid": "9183d546-70e0-4860-928d-b45ad4d0c890",
        "coordinate": {
          "x": 226.5,
          "y": 0,
          "zIndex": 0
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/01057931-65e6-445f-af07-1e5391b28086",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 41600,
        "timeline_end": 41600,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      },
      "90472e7f-d6db-4685-a3bc-f9152aa81b2e": {
        "alpha": 1,
        "groups": "90472e7f-d6db-4685-a3bc-f9152aa81b2e",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 22257,
        "rotation": -38.0162,
        "sequence": 1,
        "dimension": {
          "h": 309.94370370370365,
          "w": 413.26814814814816
        },
        "asset_uuid": "0be3f3c4-97a7-4c86-a3b9-6b130dd52a62",
        "coordinate": {
          "x": 85.53086419753086,
          "y": 511.6049382716049,
          "zIndex": 3
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/186436ef-1981-40b5-bb56-44d8b9d94220",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 22257,
        "timeline_end": 22257,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      },
      "93e728dd-4543-4212-8bf6-220d516de2a8": {
        "alpha": 1,
        "groups": "93e728dd-4543-4212-8bf6-220d516de2a8",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 51851,
        "rotation": -35.5642,
        "sequence": 2,
        "dimension": {
          "h": 236.3753086419753,
          "w": 420.26469135802466
        },
        "asset_uuid": "e9cf8635-1b80-4064-9fea-ed43caa84954",
        "coordinate": {
          "x": 21.728395061728392,
          "y": 246.91358024691357,
          "zIndex": 3
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/43b6199b-0c5b-48cc-a752-c7e929df1637",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 51851,
        "timeline_end": 51851,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      },
      "992b2643-460d-4274-88d0-861ca6fc9513": {
        "alpha": 1,
        "groups": "992b2643-460d-4274-88d0-861ca6fc9513",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 51851,
        "rotation": 0,
        "sequence": 3,
        "dimension": {
          "h": 197.28158024691356,
          "w": 350.7812345679012
        },
        "asset_uuid": "623693bb-ccda-4288-9fe4-32b8056ed487",
        "coordinate": {
          "x": 576.7901234567901,
          "y": 525.4320987654321,
          "zIndex": 4
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/d6d81394-7d6a-449f-bebd-735dc84d68f2",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 51851,
        "timeline_end": 51852,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      },
      "d0ee3f1d-41e8-40e6-9a8b-5974e0586f64": {
        "alpha": 1,
        "groups": "d0ee3f1d-41e8-40e6-9a8b-5974e0586f64",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 52002,
        "rotation": -32.5477,
        "sequence": 4,
        "dimension": {
          "h": 215.99999999999997,
          "w": 384
        },
        "asset_uuid": "638c0c7e-9c2a-4197-954a-b3fc061cd9c7",
        "coordinate": {
          "x": 1.9753086419753085,
          "y": 37.53086419753086,
          "zIndex": 1
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/d7470b28-eef1-417c-b9fe-94dcf8ba0965",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 52002,
        "timeline_end": 52003,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      },
      "e13cf568-e7b8-463c-b128-5c4975a5ea0d": {
        "alpha": 1,
        "groups": "e13cf568-e7b8-463c-b128-5c4975a5ea0d",
        "volume": 1,
        "filters": {
          "value": "#FFFFFFFF",
          "preset": ""
        },
        "ratioWH": {
          "h": 1080,
          "w": 1920
        },
        "duration": 52001,
        "rotation": -130.776,
        "sequence": 5,
        "dimension": {
          "h": 215.99999999999997,
          "w": 384
        },
        "asset_uuid": "98d6ba7c-2079-479c-825f-ff00507a4519",
        "coordinate": {
          "x": 1536.79012345679,
          "y": 497.77777777777777,
          "zIndex": 2
        },
        "is_flipped": 0,
        "signed_url": "blob:https://www.videoo.dev/479a46f3-04dd-4e86-bb62-cbf45fc57a6a",
        "transition": {
          "end": {
            "preset": "",
            "duration": 0
          },
          "start": {
            "preset": "",
            "duration": 0
          }
        },
        "segment_end": 52001,
        "timeline_end": 52002,
        "disable_video": false,
        "segment_start": 0,
        "timeline_start": 0
      }
    },
    "effects": {},
    "filters": {
      "value": "#ffffffff",
      "preset": ""
    },
    "version": 1.1,
    "bg_color": "00000000",
    "duration": 52003,
    "elements": {},
    "subtitles": {},
    "user_uuid": "cf95ba98-e051-4a60-a34c-3f4cd25bd044",
    "audio_only": false,
    "created_at": "2023-06-21 07:30:54",
    "updated_at": "2023-07-25 08:11:44",
    "subtitle_url": null,
    "thumbnail_url": {
      "42e68f46-f4ea-4cfd-90bb-5b1722e4e36a": {
        "signed_url": "https://storage.googleapis.com/staging-videoo-storage-11/42e68f46-f4ea-4cfd-90bb-5b1722e4e36a?Expires=1690303994&GoogleAccessId=videoo11%40videoo11.iam.gserviceaccount.com&Signature=UgauPiyidCR1Xtus%2BjPa9dBOY1YJebxW1WjT2bY6T1LDUenZBfdTEnr6eUlCHKYINscpjxA7uZeY1tgr6zTJbl6YX459Ldq9DTV0XA2oUJ%2Fg0%2BlTm%2BwxTtE1mF5dWUAS%2FfSVyQgXvcvCSBPAreZYxwZt3%2F3Tnmn1PIz5Ov6KhWHmkPr9HfhxuSpI6PI327%2B2Hz8CXiWY3VEUgcMtVSu3dG9EIBYAotPv5zlo4jV8e04GUiWFpq8pdkxet5EkukotXaZqgoik%2FFVGN5XbDyzEp4dGweU8Py1Ia8BvbDb9NKbzZrBCD%2BJZMFXRUfQKOZybPDlS6Oa%2Fkx8S53iOeBo9hQ%3D%3D"
      }
    },
    "workspace_uuid": "5a4abed8-4dd8-44f1-a63a-38ad10b99234",
    "templateproject": {},
    "sequence": 4
  }
}


export const settingBackData = {
  "uuid": "2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9",
  "sequence": 4,
  "history": {
    "fps": 24,
    "text": {},
    "asset": {},
    "title": "Your Video - 2f9c8c6fd3e94f0da8755d81e879b6d9",
    "width": 1920,
    "height": 1080,
    "images": {},
    "videos": {},
    "audios": {},
    "audios": {},
    "effects": {},
    "filters": { "preset": "" },
    "version": 1.0,
    "bg_color": "00000000",
    "duration": 0,
    "elements": {},
    "subtitles": {},
    "user_uuid": null,
    "audio_only": false,
    "created_at": "2022-11-30 18:16:35",
    "updated_at": "2022-11-30 18:21:12",
    "subtitle_url": null,
    "thumbnail_url": {},
    "workspace_uuid": '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9'
  },
  "current": {
    "fps": 24,
    "text": {
      "b825bed1-c5e1-44bf-b06e-f91b4be43f94": {
        "alpha": "FF",
        "groups": "b825bed1-c5e1-44bf-b06e-f91b4be43f94",
        "context": "Text",
        "ratioWH": { "h": 1920, "w": 1080 },
        "duration": 3000,
        "rotation": 0,
        "dimension": {
          "h": 513.4642105263157,
          "w": 577.7147368421053
        },
        "coordinate": {
          "x": 326.84210526315786,
          "y": 1199.9984210526316,
          "zIndex": 50
        },
        "is_flipped": 0,
        "properties": {
          "bold": "bold",
          "italic": "italic",
          "alignment": "center",
          "font_size": 40,
          "text_color": "#000000",
          "font_family": "Archivo-Black",
          "background_color": "#ffffff"
        },
        "transition": {
          "end": { "preset": "", "duration": 0 },
          "start": { "preset": "", "duration": 0 }
        },
        "timeline_end": 3000,
        "timeline_start": 0
      },
      "ca28a36f-364e-41ca-832a-c72f0f181795": {
        "alpha": "FF",
        "groups": "ca28a36f-364e-41ca-832a-c72f0f181795",
        "context": "Text",
        "ratioWH": { "h": 1920, "w": 1080 },
        "duration": 3000,
        "rotation": 0,
        "dimension": {
          "h": 540.4736842105262,
          "w": 608.0921052631578
        },
        "coordinate": {
          "x": 71.05263157894737,
          "y": 25.26157894736842,
          "zIndex": 50
        },
        "is_flipped": 0,
        "properties": {
          "bold": "bold",
          "italic": "italic",
          "alignment": "center",
          "font_size": 40,
          "text_color": "#000000",
          "font_family": "Archivo-Black",
          "background_color": "#ffffff"
        },
        "transition": {
          "end": { "preset": "", "duration": 0 },
          "start": { "preset": "", "duration": 0 }
        },
        "timeline_end": 3000,
        "timeline_start": 0
      }
    },
    "asset": {},
    "title": "Your Video - 2f9c8c6fd3e94f0da8755d81e879b6d9",
    "width": 1920,
    "height": 1080,
    "images": {},
    "videos": {},
    "audios": {},
    "effects": {},
    "filters": {
      "preset": ""
    },
    "version": 1.0,
    "bg_color": "00000000",
    "duration": 3000,
    "elements": {},
    "subtitles": {},
    "user_uuid": null,
    "audio_only": false,
    "created_at": "2022-11-30 18:16:35",
    "updated_at": "2022-11-30 18:21:46",
    "subtitle_url": null,
    "thumbnail_url": {},
    "workspace_uuid": '2f9c8c6f-d3e9-4f0d-a875-5d81e879b6d9'
  }
}